import React, { useState, useRef } from "react"
import { AvField } from "availity-reactstrap-validation"

const SearchField = ({ onChange, value = "", ...props }) => {
  const [inputValue, setInputValue] = useState(value)
  const searchTimeout = useRef(null)

  const handleInputChange = e => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (searchTimeout.current) clearTimeout(searchTimeout.current)

    searchTimeout.current = setTimeout(() => {
      onChange(e)
    }, 500)
  }

  return (
    <AvField
      name="search"
      value={inputValue || ""}
      onChange={handleInputChange}
      {...props}
    />
  )
}

export default SearchField
