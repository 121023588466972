import React, { useState, useRef, useEffect } from "react"

import Select from "react-select"
import queryString from "query-string"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { get } from "../../../helpers/api_helper"

import "./styles.scss"
import { useLocation } from "react-router-dom"
import {
  dateConverter,
  getDate,
  numberToCurrency,
  renderTableValue,
  setTitle,
  timeConverter,
} from "../../../helpers/functions"
import { Tooltip } from "@mui/material"

const TransactionLog = () => {
  setTitle("Transaction Log")

  const formRef = useRef()
  const { state } = useLocation()

  const date = getDate()

  const initialState = {
    from: "",
    to: "",
    type: "",
    account: "",
  }

  const [userOptions, setUserOptions] = useState([])
  const [chartOfAccountOptions, setChartOfAccountOptions] = useState([])
  const [accountTypeOptions, setAccountTypeOptions] = useState([])

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState(initialState)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    if (state && state?.account)
      setMasterObject(prev => ({ ...prev, account: state.account }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData(1)
    handleUserOptions()
    handleAccountHeadOptions()
    handleChartOfAccountOptions()
    setPage(1)
    // eslint-disable-next-line
  }, [masterObject])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleAccountHeadOptions = async () => {
    try {
      const params = `${queryString.stringify(masterObject)}`
      const response = await get(`/transaction/options/account-head?${params}`)
      setAccountTypeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChartOfAccountOptions = async () => {
    try {
      const params = `${queryString.stringify(masterObject)}`
      const response = await get(`/transaction/options/chart-account?${params}`)
      setChartOfAccountOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUserOptions = async () => {
    try {
      const params = `${queryString.stringify(masterObject)}`
      const response = await get(`/transaction/options/user?${params}`)
      setUserOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (perPage = page) => {
    try {
      const params = `page=${perPage}&limit=${limit}&${queryString.stringify(
        masterObject,
      )}`
      const response = await get(`/transaction/list?${params}`)

      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        if (item.type === 1) {
          item.credit = numberToCurrency(item.amount)
          item.debit = "---"
          item.type = "Credit"
        } else {
          item.debit = numberToCurrency(item.amount)
          item.credit = "---"
          item.type = "Debit"
        }

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.head = renderTableValue(item.head?.name)
        item.subHead = renderTableValue(item.subHead?.name)
        item.account = renderTableValue(item.chartOfAccount?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        item.balance = numberToCurrency(item.balance)

        item.remarks = (
          <Tooltip title={item.remarks} arrow placement="top">
            <p>{item.remarks || ""}</p>
          </Tooltip>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {}
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    handleValueChange({ value: selected.value, name })
  }
  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    formRef.current.reset()
    setMasterObject(initialState)
    setSelectedFields(initialState)
    setPage(1)
    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Head", field: "head" },
      { label: "Sub Head", field: "subHead" },
      { label: "Account", field: "account" },
      { label: "Tra No.", field: "uniqueId" },
      { label: "Debit", field: "debit" },
      { label: "Credit", field: "credit" },
      { label: "Balance", field: "balance" },
      { label: "Remark", field: "remarks" },
      { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transaction Log" />

          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>From </Label>
                      <AvField
                        name="from"
                        className="form-control"
                        type="date"
                        value={masterObject.from || ""}
                        max={date}
                        onChange={e => {
                          handleValueChange(e.target)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>To </Label>
                      <AvField
                        name="to"
                        className="form-control"
                        type="date"
                        value={masterObject.to || ""}
                        min={masterObject.from}
                        max={date}
                        onChange={e => {
                          handleValueChange(e.target)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Accounts Head</Label>
                      <Select
                        name="type"
                        placeholder="Select..."
                        options={accountTypeOptions}
                        value={selectedFields.type || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "type")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Chart of account</Label>
                      <Select
                        name="account"
                        placeholder="Select..."
                        options={chartOfAccountOptions}
                        value={selectedFields.account || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "account")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="user"
                        placeholder="Select..."
                        options={userOptions}
                        value={selectedFields.user || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "user")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      className="mb-3 d-flex gap-2"
                      style={{ paddingTop: "25px" }}
                    >
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>

              <TablePagination
                data={data}
                page={page}
                count={totalPage}
                onChange={handleChange}
                tableId="transactionLog"
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default TransactionLog
