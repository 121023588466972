import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
  dateConverter,
  renderTableValue,
  setTitle,
  getDate,
  handleImageValidation,
  toTop,
  isIncludesAll,
  jsonToExcel,
} from "../../helpers/functions"
import { del, get, post, put } from "../../helpers/api_helper"
import { typeOptions, typeValues } from "./helper"

import "./styles.scss"
import FileUpload from "../../components/Common/FileUpload"
import { useNavigate } from "react-router-dom"
import SearchField from "../../components/Common/SearchField"
import { SELECT_ALL } from "../../config"
import moment from "moment"

const Institute = () => {
  setTitle("Institute")

  const todayDate = getDate()
  const navigate = useNavigate()
  const statusValues = {
    0: { text: "Active", color: "success" },
    2: { text: "In Active", color: "warning" },
    4: { text: "Rejected", color: "danger" },
  }

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "In Active", value: 2 },
  ]

  const formRef = useRef()
  const filterRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])
  const [universityOptions, setUniversityOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])

  const [courseOptions, setCourseOptions] = useState([])
  const [specializationOptions, setSpecializationsOptions] = useState([])

  const [filterUniversityOptions, setFilterUniversityOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(0)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleDesignationOptions()

    handleFilterUniversityOptions()

    handleCoursesOptions()
    handleSpecializationOptions()
  }, []) // eslint-disable-line

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleDistrictOptions = async (state = "") => {
    try {
      const response = await get(`options/districts?state=${state}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleUniversityOptions = async (state = "") => {
    try {
      const response = await get(`options/university?state=${state}`)
      setUniversityOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleDesignationOptions = async () => {
    try {
      const response = await get(`options/designation`)
      setDesignationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleCoursesOptions = async () => {
    try {
      const response = await get(`options/course`)
      setCourseOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSpecializationOptions = async (course = "") => {
    try {
      const params = queryString.stringify({ course })
      const response = await get(`options/specializations?${params}`)
      setSpecializationsOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterUniversityOptions = async () => {
    try {
      const response = await get(`college/options/filter/university`)
      setFilterUniversityOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`

      const response = await get(`/college${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.type = renderTableValue(typeValues[item.type])
        item.university = renderTableValue(item.university?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        item.mobile = renderTableValue(item.mobile)
        item.shortCode = renderTableValue(item.shortCode)

        item.status = getStatus(item._id, item.status)

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => {
                  navigate(`/institute/${item?._id}`)
                }}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(masterObject)}`
      const response = await get(`college${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}

        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)
        exportData["Institute Id"] = item.uniqueId
        exportData["University"] = item.university?.name
        exportData["Name"] = item.name
        exportData["Short Code"] = item.shortCode
        exportData["Mobile"] = item.mobile
        exportData["Type"] = typeValues[item.type]
        exportData["Status"] = statusValues[item.status]?.text || ""

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Institutes-${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`college`, masterObject)
      else response = await post(`college`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`/college/update/${id}`)

      const data = response.data

      setMasterObject({
        ...data,
        id,
        state: data?.state?.value,
        university: data?.university?.value,
        district: data?.district?.value,
        designation: data?.designation?.value,
        courses: data?.coursesIds,
        specializations: data?.specializationsIds,
      })
      setSelectedFields({
        state: data.state,
        university: data.university,
        district: data.district,
        courses: data.courses,
        specializations: data.specializations,
        designation: data?.designation,

        type: typeOptions[data.type - 1],
      })

      handleUniversityOptions(data?.state?.value)
      handleDistrictOptions(data?.state?.value)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`college/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`college/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      await handleImageValidation(selectedFile)
      const fd = new FormData()
      fd.append("logo", e.target.files[0])
      const response = await post(`college/logo`, fd)
      handleValueChange({ value: response.data.new_filename, name: "logo" })
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
      e.target.value = null
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }

    if (name === "state") {
      obj.university = null
      obj.district = null
    }

    if (name === "courses") {
      if (isIncludesAll(selected)) selected = courseOptions
      obj.specializations = null
    }

    if (name === "specializations") {
      if (isIncludesAll(selected)) selected = specializationOptions
    }

    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value
    if (name === "state") {
      obj.university = null
      obj.district = null
      handleDistrictOptions(value)
      handleUniversityOptions(value)
    }

    if (name === "courses") {
      obj.specializations = null

      handleSpecializationOptions(value)
    }

    setMasterObject(obj)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const getStatus = (id, status) => {
    if (status === 3) {
      return (
        <Select
          menuPosition="fixed"
          options={[
            { label: "Approve", value: 0 },
            { label: "Reject", value: 4 },
          ]}
          onChange={selected => handleStatusChange(id, selected.value)}
        />
      )
    } else {
      const statusObj = statusValues[status]
      return (
        <Button
          size="sm"
          color={statusObj.color}
          onClick={() => handleStatusChange(id, status === 0 ? 2 : 0)}
          disabled={status === 4}
        >
          {statusObj.text}
        </Button>
      )
    }
  }

  const getSelectAllOptions = ({ options, name }) => {
    return options.length === selectedFields[name]?.length ||
      options.length === 0
      ? options
      : [SELECT_ALL, ...options]
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()

    setMasterObject({})
    setSelectedFields({})

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})

    handleFilterUniversityOptions()
    handleSpecializationOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Institute Id", field: "uniqueId" },
      { label: "University", field: "university" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Mobile", field: "mobile" },
      { label: "Type", field: "type" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Institute" />

          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                onValidSubmit={handleSubmit}
                id="collegeForm"
              >
                <Row>
                  <Col md={3}>
                    <Label>
                      State<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={stateOptions}
                      value={selectedFields.state || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "state")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      University<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={universityOptions}
                      isDisabled={!masterObject.state}
                      value={selectedFields.university || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "university")
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Label>
                      Type<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={typeOptions}
                      value={selectedFields.type || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "type")
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Label>
                      Name<span className="required">*</span>
                    </Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      errorMessage="Enter Name"
                      validate={{ required: { value: true } }}
                      value={masterObject.name || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Short Code</Label>
                    <AvField
                      name="shortCode"
                      placeholder="Short Code"
                      type="text"
                      errorMessage="Enter Short Code"
                      // validate={{ required: { value: true } }}
                      value={masterObject.shortCode || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Courses</Label>
                    <Select
                      isMulti
                      menuPosition="fixed"
                      options={getSelectAllOptions({
                        options: courseOptions,
                        name: "courses",
                      })}
                      value={selectedFields.courses || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "courses")
                      }
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Specializations</Label>
                    <Select
                      isMulti
                      menuPosition="fixed"
                      isDisabled={!masterObject.courses?.length}
                      options={getSelectAllOptions({
                        options: specializationOptions,
                        name: "specializations",
                      })}
                      value={selectedFields.specializations || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "specializations")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Contact Person<span className="required">*</span>
                    </Label>
                    <AvField
                      name="contactPerson"
                      placeholder="Contact Person"
                      type="text"
                      errorMessage="Enter Contact Person"
                      validate={{ required: { value: true } }}
                      value={masterObject.contactPerson || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Mobile<span className="required">*</span>
                    </Label>
                    <AvField
                      name="mobile"
                      placeholder="Mobile"
                      type="text"
                      errorMessage="Enter Mobile"
                      value={masterObject.mobile || ""}
                      validate={{
                        number: true,
                        required: { value: true },
                        pattern: { value: "(0|91)?[6-9][0-9]{9}" },
                      }}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>
                      Email<span className="required">*</span>
                    </Label>
                    <AvField
                      name="email"
                      placeholder="Email"
                      type="text"
                      errorMessage="Enter Email"
                      validate={{ email: true, required: { value: true } }}
                      value={masterObject.email || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Designation</Label>
                      <Select
                        menuPosition="fixed"
                        options={designationOptions}
                        value={selectedFields.designation || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "designation")
                        }
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <Label>Website Url</Label>
                    <AvField
                      name="website"
                      placeholder="Website Url"
                      type="text"
                      errorMessage="Enter Website Url"
                      value={masterObject.website || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md={3}>
                    <Label>District</Label>
                    <Select
                      menuPosition="fixed"
                      options={districtOptions}
                      isDisabled={!masterObject.state}
                      value={selectedFields.district || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "district")
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Address</Label>
                    <AvField
                      name="address"
                      placeholder="Address"
                      type="text"
                      errorMessage="Enter Address"
                      value={masterObject.address || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>City</Label>
                    <AvField
                      name="city"
                      placeholder="City"
                      type="text"
                      errorMessage="Enter City"
                      value={masterObject.city || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <FileUpload
                      label="Logo"
                      imageSize="250x140"
                      name="logo"
                      fileUrl={masterObject?.logo}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "logo" })
                      }
                      accept="image/*"
                      // validate={{ required: { value: true } }}
                    />
                  </Col>

                  <Col md={3} style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      <Button color={masterObject.id ? "warning" : "primary"}>
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color="danger" type="reset" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.from}
                      max={todayDate}
                    />
                  </Col>

                  <Col md={3}>
                    <Label>University</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterUniversityOptions}
                      value={selectFilter.university || ""}
                      onChange={selected =>
                        handleFilterSelectChange({
                          selected,
                          name: "university",
                        })
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Label>Type</Label>
                    <Select
                      menuPosition="fixed"
                      options={typeOptions}
                      value={selectFilter.type || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "type" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Status</Label>
                    <Select
                      menuPosition="fixed"
                      options={statusOptions}
                      value={selectFilter.status || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "status" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search name, short code, mobile"
                    />
                  </Col>
                  <Col>
                    <div className="d-flex gap-3 form-button">
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                      <Button color="warning" onClick={handleExport}>
                        {loading.export ? (
                          <i className="fas fa-spinner fa-spin" />
                        ) : (
                          "Export"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="collegeTable"
                count={totalPage}
                totalCount={totalCount}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Institute
