import React, { Suspense, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import { post } from "../../helpers/api_helper"
import { startTransition } from "react"
import { setTitle } from "../../helpers/functions"

const AuthMiddleware = props => {
  const [allowed, setAllowed] = useState(null)
  const [options, setOptions] = useState({})

  useEffect(() => {
    const checkPrivilege = async () => {
      startTransition(async () => {
        try {
          if (!Cookies.get("_token")) return
          const response = await post("auth/allowed", {
            module_url: props.path,
          })

          if (response && response.allowed) {
            setAllowed(true)
            setOptions(response.options)
          } else setAllowed(false)
        } catch (error) {
          console.error("Error checking privileges:", error)
          setAllowed(false)
        }
      })
    }

    if (Cookies.get("token")) checkPrivilege()

    if (!Cookies.get("token") && Cookies.get("_token"))
      setTimeout(() => checkPrivilege(), 500)
  }, [props.path])

  if (!Cookies.get("_token")) return <Navigate to="/login" />
  if (allowed === null) return <></>

  if (props.title) setTitle(props.title)

  return (
    <React.Fragment>
      <Suspense>{React.cloneElement(props.children, { options })}</Suspense>
    </React.Fragment>
  )
}

export default AuthMiddleware
