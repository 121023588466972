import React, { useState, useRef, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Swal from "sweetalert2"
import toastr from "toastr"

import { del, get, post, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  setTitle,
  timeConverter,
  toTop,
} from "../../../helpers/functions"

import "./styles.scss"
import { Tooltip } from "@mui/material"

const AccountSubHead = ({ options }) => {
  setTitle("Account Sub Head")
  const formRef = useRef()

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [headOptions, setHeadOptions] = useState([])

  useEffect(() => {
    handleTableData()

    handleHeadOptions()
  }, []) // eslint-disable-line

  const handleHeadOptions = async () => {
    try {
      const response = await get(`options/account-head`)
      setHeadOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTableData = async () => {
    try {
      const response = await get(`accounts/sub-head/list`)
      const { data } = response
      data.map((item, index) => {
        item.id = index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.headName = item.head?.label
        item.staff = item.addedBy?.name

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id)
        response = await put("/accounts/sub-head/update", masterObject)
      else response = await post("/accounts/sub-head/add", masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response?.data?.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`accounts/sub-head/delete?id=${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleUpdate = item => {
    toTop()
    setMasterObject({ id: item._id, name: item.name, head: item.head?.value })
    setSelectedFields({ head: item.head })
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected?.value, name })
  }
  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const reset = () => {
    formRef.current.reset()
    toTop()
    handleTableData()
    setMasterObject({})
    setSelectedFields({})

    handleHeadOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Head", field: "headName" },
      { label: "Name", field: "name" },
      { label: "Added By", field: "staff" },
      { label: "Actions", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Account Sub Head" />

        <Card>
          <CardBody>
            <AvForm onValidSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col md={3} className="mb-3">
                  <Label>Head</Label>
                  <Select
                    name="head"
                    options={headOptions}
                    value={selectedFields.head || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "head" })
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Name</Label>
                  <AvField
                    name="name"
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{ required: { value: true } }}
                    placeholder="Name"
                    errorMessage="Enter Name"
                  />
                </Col>

                <Col>
                  <div className="mb-3 d-flex gap-2 form-button">
                    <Button
                      color={`${masterObject.id ? "warning" : "primary"}`}
                      type="submit"
                    >
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <MDBDataTable
              id="accountSubHeadTable"
              data={data}
              bordered
              responsive
              searching={false}
              entries={100}
              info={false}
              sortable={false}
              disableRetreatAfterSorting={true}
            ></MDBDataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AccountSubHead
