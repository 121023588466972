import { MDBDataTable } from "mdbreact"
import React, {useState } from "react"
import { Col, Row } from "reactstrap"
import "./style.scss"

const EnrolledCourse = (props) => {
 
  const [selectedEnrolled, setSelectedEnrolled] = useState([])

  const jobPosts = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      { label: "Date", field: "date", sort: "asc", width: 400 },
      { label: "Batch", field: "batch1", sort: "asc", width: 400 },
      { label: "Course", field: "course1", sort: "asc", width: 400 },
      { label: "Start Date", field: "startdate", sort: "asc", width: 400 },
      { label: "End Date", field: "enddate", sort: "asc", width: 400 },
      { label: "Duration", field: "duration", sort: "asc", width: 400 },
      { label: "Trainer", field: "trainer1", sort: "asc", width: 400 },
    ],
    rows: selectedEnrolled.length ? selectedEnrolled : [], // Empty array if no data
  }

  return (
    <Row>
      <Col className="col-12">
        <MDBDataTable
          id="EnrolledDataTableId"
          bordered
          responsive
          sortable={false}
          searching={true}
          entries={20}
          disableRetreatAfterSorting={true}
          info={false}
          data={jobPosts}
        />
      </Col>
    </Row>
  )
}

export default EnrolledCourse
