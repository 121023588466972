export const CATEGORY_VALUES = { 1: "Candidate", 2: "Institute", 3: "Company" }

export const STATUS_VALUES = {
  0: { text: "Unread", color: "danger", disabled: false, size: "sm" },
  1: { text: "Read", color: "success", disabled: true, size: "sm" },
}

export const statusOptions = [
  { label: "Unread", value: 0 },
  { label: "Read", value: 1 },
]

export const categoryOptions = [
  { label: "Candidate", value: 1 },
  { label: "Institute", value: 2 },
  { label: "Company", value: 3 },
]
