import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"

import toastr from "toastr"
import Swal from "sweetalert2"
import queryString from "query-string"

import Breadcrumb from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import {
  statusValues,
  sunEditorOptions,
  typeOptions,
  typeValues,
} from "./helper"
import {
  numberToString,
  renderTableValue,
  toTop,
} from "../../../helpers/functions"
import { del, get, post, put } from "../../../helpers/api_helper"

import "./styles.scss"
import { useNavigate } from "react-router-dom"

const TestCreation = () => {
  const formRef = useRef()
  const navigate = useNavigate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({
    questions: [{ questions: 0, mark: 0 }],
    generalQuestions: [{ questions: 0, mark: 0 }],
    instructions: "",
  })
  console.log(masterObject)
  const [selectedFields, setSelectedFields] = useState({})
  const [total, setTotal] = useState({ questions: 0, marks: 0 })

  const [filterObject, setFilterObject] = useState({})
  const [selectedFilter, setSelectFilter] = useState({})

  const [specializationOptions, setSpecializationOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleSpecializationOptions()
    handleInternshipOptions()
  }, []) // eslint-disable-line

  const handleSpecializationOptions = async () => {
    try {
      const response = await get(`options/specializations`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`screening/list?${query}`)

      const { count, data } = response
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.type = typeValues[item.type]

        if (Array.isArray(item.specialization) && item.specialization?.length) {
          item.specialization = (
            <div>
              {item?.specialization?.map((specialization, index) => (
                <div key={index}>{specialization?.name}</div>
              ))}
            </div>
          )
        } else {
          item.specialization = renderTableValue("")
        }

        item.maxQuestions = numberToString(item.maxQuestions)
        item.mark = numberToString(item.mark)
        item.passMark = numberToString(item.passMark)
        item.duration = numberToString(item.duration)

        const status = Number(item.status)
        const statusObj = statusValues[status]
        item.status = (
          <Button
            size="sm"
            color={statusObj.color}
            onClick={() => handleStatusChange(item._id, status === 0 ? 2 : 0)}
          >
            {statusObj.text}
          </Button>
        )

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleNavigate(item.uniqueId)}
              />
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              />
            </Tooltip>

            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              />
            </Tooltip>
          </div>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response

      if (masterObject.id)
        response = await put(`screening/update`, masterObject)
      else response = await post(`screening/create`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`screening/update/${id}`)
      const data = response.data
      setMasterObject({
        ...data,
        specialization: data?.specializationIds,
        id,
      })
      setSelectedFields({
        ...data,
        type: { label: typeValues[data.type], value: data.type },
      })
      toTop()
      calculateTotal(data)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`screening/delete/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`screening/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "type") {
      obj.internship = null
      obj.specialization = null
    }

    setSelectedFields(obj)
    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ name, value })
  }

  const handleValueChange = ({ value, name }) => {
    setMasterObject(prev => {
      const updatedObject = {
        ...prev,
        [name]: value,
      }

      if (name === "type") {
        updatedObject.internship = null
        updatedObject.specialization = null
      }

      return updatedObject
    })
  }

  const handleQuestionsValueChange = ({ value, name, index, field }) => {
    const obj = { ...masterObject }
    obj[field][index][name] = value

    setMasterObject(obj)
    calculateTotal(obj)
  }

  const handleNavigate = url => {
    navigate(`/test/${url}`)
  }
  const handleQuestionsList = async ({ add = false, index, field }) => {
    setMasterObject(prev => {
      const updatedObject = {
        ...prev,
        [field]: add
          ? [...(prev[field] || []), {}]
          : (prev[field] || []).filter((_, i) => i !== index),
      }

      calculateTotal(updatedObject)
      return updatedObject
    })
  }

  const calculateTotal = async (obj = masterObject) => {
    let questions = 0,
      marks = 0,
      generalQuestions = 0,
      generalMarks = 0

    obj.questions.forEach(item => {
      questions += parseInt(item.questions || 0)
      marks += parseInt(item.questions || 0) * parseInt(item.mark || 0)
    })
    if (Array.isArray(obj.generalQuestions)) {
      obj.generalQuestions.forEach(item => {
        generalQuestions += parseInt(item.questions || 0)
        generalMarks += parseInt(item.questions || 0) * parseInt(item.mark || 0)
      })
    }

    setTotal({ questions, marks, generalQuestions, generalMarks })
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFilter }
    obj[name] = selected

    setSelectFilter(obj)

    handleFilterValueChange({ name, value: selected?.value })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value

    setFilterObject(obj)
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()

    setMasterObject({
      questions: [{ questions: 0, mark: 0 }],
      generalQuestions: [{ questions: 0, mark: 0 }],
      instructions: "",
    })
    setSelectedFields({})

    setFilterObject({})
    setSelectFilter({})

    setTotal({})
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Type", field: "type" },
      { label: "Specialization", field: "specialization" },
      { label: "Name", field: "name" },
      { label: "Max. Questions", field: "maxQuestions" },
      { label: "Pass Mark", field: "passMark" },
      { label: "Duration", field: "duration" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Test creation" />
        <Card>
          <CardBody>
            <AvForm
              className="needs-validation"
              ref={formRef}
              onValidSubmit={handleSubmit}
            >
              <Row>
                <Col md={2}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "type" })
                    }
                  />
                </Col>
                {masterObject.type === typeValues.KTU ||
                masterObject.type === typeValues.SPECIALIZATION ? (
                  <Col className="mb-2" md={4}>
                    <Label>Specialization</Label>
                    <Select
                      isMulti
                      isDisabled={!masterObject.type}
                      options={specializationOptions}
                      value={selectedFields.specialization || ""}
                      onChange={selected =>
                        handleSelectChange({ selected, name: "specialization" })
                      }
                    />
                  </Col>
                ) : masterObject.type === typeValues.INTERNSHIP ? (
                  <Col md={3}>
                    <Label>Internship</Label>
                    <Select
                      isMulti
                      isDisabled={!masterObject.type}
                      options={internshipOptions}
                      value={selectedFields.internship || ""}
                      onChange={selected =>
                        handleSelectChange({ selected, name: "internship" })
                      }
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col md={3}>
                  <Label>Name</Label>
                  <AvField
                    name="name"
                    placeholder="Name"
                    errorMessage="Enter Name"
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Duration (Min)</Label>
                  <AvField
                    type="number"
                    name="duration"
                    placeholder="Duration"
                    errorMessage="Enter duration"
                    validate={{ required: { value: true }, min: { value: 1 } }}
                    value={masterObject.duration || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <span>
                  <b>Note</b>(Total Questions Should be equal to Max.questions &
                  Total Mark Should be equal to max mark)
                </span>
                <Row className="mt-3 mb-3">
                  <Col md={6}>
                    <span>
                      <b>Total Question: {numberToString(total.questions)}</b>
                    </span>
                    <span className="mx-3">
                      <b>Total Marks: {numberToString(total.marks)}</b>
                    </span>

                    {masterObject?.questions?.map((question, index) => (
                      <>
                        <Row className="mt-2">
                          <Col md={4}>
                            <Label>Questions</Label>
                            <AvField
                              name={`questions-${index}`}
                              placeholder="Questions"
                              value={question?.questions || ""}
                              defaultValue={""}
                              type="number"
                              errorMessage="Enter Questions"
                              validate={{
                                required: { value: true },
                                min: { value: 1 },
                              }}
                              onChange={e =>
                                handleQuestionsValueChange({
                                  value: e.target?.value,
                                  name: "questions",
                                  field: "questions",
                                  index,
                                })
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Mark</Label>
                            <AvField
                              name={`mark-${index}`}
                              placeholder="Mark"
                              value={question?.mark || ""}
                              defaultValue={""}
                              type="number"
                              errorMessage="Enter Marks"
                              validate={{
                                required: { value: true },
                                min: { value: 1 },
                              }}
                              onChange={e =>
                                handleQuestionsValueChange({
                                  value: e.target?.value,
                                  name: "mark",
                                  field: "questions",
                                  index,
                                })
                              }
                            />
                          </Col>
                          {masterObject.questions.length !== 1 && (
                            <Col col={2}>
                              <Button
                                color="danger"
                                type="button"
                                style={{ marginTop: "1.5rem" }}
                                onClick={() =>
                                  handleQuestionsList({
                                    index,
                                    field: "questions",
                                  })
                                }
                              >
                                Remove
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </>
                    ))}
                    <AddMoreButton
                      onClick={() =>
                        handleQuestionsList({ add: true, field: "questions" })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    {masterObject.type === typeValues.KTU ? (
                      <>
                        <span>
                          <b>
                            Total Question:{" "}
                            {numberToString(total.generalQuestions)}
                          </b>
                        </span>
                        <span className="mx-3">
                          <b>
                            Total Marks: {numberToString(total.generalMarks)}
                          </b>
                        </span>
                        {masterObject?.generalQuestions?.map(
                          (question, index) => (
                            <>
                              <Row className="mt-2">
                                <Col md={4}>
                                  <Label>General Questions</Label>
                                  <AvField
                                    name={`generalQuestions-${index}`}
                                    placeholder="Questions"
                                    value={question?.questions || ""}
                                    defaultValue={""}
                                    type="number"
                                    errorMessage="Enter Questions"
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1 },
                                    }}
                                    onChange={e =>
                                      handleQuestionsValueChange({
                                        value: e.target?.value,
                                        name: "questions",
                                        field: "generalQuestions",
                                        index,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={4}>
                                  <Label>General Mark</Label>
                                  <AvField
                                    name={`generalMark-${index}`}
                                    placeholder="Mark"
                                    value={question?.mark || ""}
                                    defaultValue={""}
                                    type="number"
                                    errorMessage="Enter Marks"
                                    validate={{
                                      required: { value: true },
                                      min: { value: 1 },
                                    }}
                                    onChange={e =>
                                      handleQuestionsValueChange({
                                        value: e.target?.value,
                                        name: "mark",
                                        field: "generalQuestions",
                                        index,
                                      })
                                    }
                                  />
                                </Col>
                                {masterObject.questions.length !== 1 && (
                                  <Col col={2}>
                                    <Button
                                      color="danger"
                                      type="button"
                                      style={{ marginTop: "1.5rem" }}
                                      onClick={() =>
                                        handleQuestionsList({
                                          index,
                                          field: "generalQuestions",
                                        })
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </>
                          ),
                        )}
                        <AddMoreButton
                          onClick={() =>
                            handleQuestionsList({
                              add: true,
                              field: "generalQuestions",
                            })
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Col md={2}>
                  <Label>Max Questions</Label>
                  <AvField
                    name="maxQuestions"
                    type="number"
                    placeholder="Max Questions"
                    errorMessage="Enter Max Questions"
                    value={masterObject.maxQuestions || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Max Questions is required",
                      },
                      max: {
                        value: total.questions,
                        errorMessage: `Max Questions cannot exceed 'Total Questions'`,
                      },
                      min: {
                        value: total.questions,
                        errorMessage: `Max Questions must be at least 'Total Questions'`,
                      },
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Mark</Label>
                  <AvField
                    name="mark"
                    type="number"
                    placeholder="Mark"
                    errorMessage="Enter Mark"
                    value={masterObject.mark || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Mark is required",
                      },
                      max: {
                        value: total.marks,
                        errorMessage: `Mark cannot exceed 'Total Mark'`,
                      },
                      min: {
                        value: total.marks,
                        errorMessage: `Mark must be at least 'Total Mark'`,
                      },
                    }}
                  />
                </Col>

                <Col md={2}>
                  <Label>Pass Mark</Label>
                  <AvField
                    type="number"
                    name="passMark"
                    placeholder="Pass Mark"
                    errorMessage="Enter Pass Mark"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter the Pass Mark",
                      },
                      min: {
                        value: 1,
                        errorMessage: "Pass Mark must be at least 1",
                      },
                      max: {
                        value: masterObject.mark,
                        errorMessage: `Pass Mark cannot exceed 'Total Mark'`,
                      },
                    }}
                    value={masterObject.passMark || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                {masterObject.type === typeValues.KTU ? (
                  <>
                    <Col md={2}>
                      <Label>General Max Questions</Label>
                      <AvField
                        name="generalMaxQuestions"
                        type="number"
                        placeholder="General Questions"
                        value={masterObject.generalMaxQuestions || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{
                          max: {
                            value: total.generalQuestions,
                            errorMessage: `Max Questions cannot exceed 'Total Questions'`,
                          },
                          min: {
                            value: total.generalQuestions,
                            errorMessage: `Max Questions must be at least 'Total Questions'`,
                          },
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Label>General Mark</Label>
                      <AvField
                        name="generalMark"
                        type="number"
                        placeholder="General Mark"
                        value={masterObject.generalMark || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{
                          max: {
                            value: total.generalMarks,
                            errorMessage: `Mark cannot exceed 'Total Mark'`,
                          },
                          min: {
                            value: total.generalMarks,
                            errorMessage: `Mark must be at least 'Total Mark'`,
                          },
                        }}
                      />
                    </Col>

                    <Col md={2}>
                      <Label>General Pass Mark</Label>
                      <AvField
                        type="number"
                        name="generalPassMark"
                        placeholder="General Pass Mark"
                        errorMessage="Enter Pass Mark"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter the Pass Mark",
                          },
                          min: {
                            value: 1,
                            errorMessage: "Pass Mark must be at least 1",
                          },
                          max: {
                            value: masterObject.generalMark,
                            errorMessage: `Pass Mark cannot exceed 'Total Mark'`,
                          },
                        }}
                        value={masterObject.generalPassMark || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col className="mt-3" md="12">
                  <Label>Instructions</Label>
                  <SunEditor
                    lang="en"
                    setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                    setOptions={sunEditorOptions}
                    setContents={masterObject?.instructions || ""}
                    onChange={value =>
                      handleValueChange({ name: "instructions", value })
                    }
                  />
                </Col>
                <Col md={3}>
                  <div className="d-flex gap-3 mt-3">
                    <Button color={masterObject?._id ? "warning" : "primary"}>
                      {masterObject?._id ? "Update" : "Submit"}
                    </Button>
                    <Button type="reset" color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm>
              <Row>
                <Col md={2}>
                  <Label>Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFilter.type || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "type" })
                    }
                  />
                </Col>
                <Col className="form-button">
                  <Button color="danger" type="reset" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              tableId="screeningTest"
              data={data}
              count={totalPage}
              hover
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
export default TestCreation

const AddMoreButton = ({ onClick }) => {
  return (
    <span
      style={{ cursor: "pointer", textAlign: "center" }}
      className="mb-3"
      onClick={onClick}
    >
      + Add New
    </span>
  )
}
