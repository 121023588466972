import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Label, Button, Modal } from "reactstrap"
import { get, post } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

import toastr from "toastr"
import moment from "moment"

import {
  dateConverter,
  getDate,
  numberToCurrency,
  timeConverter,
} from "../../../helpers/functions"
import ListItem from "../../../components/Common/ListItem"
import FileUpload from "../../../components/Common/FileUpload"

import { STATUS_VALUES, TYPE_VALUES } from "./helper"

const CreditFollowup = ({ id, isOpen, onToggle, isCredit = true }) => {
  console.log(`Is credit`, isCredit)
  const ref = useRef()
  const todayDate = getDate()
  const time = moment().format("HH:mm")

  const initialState = {
    id: "",
    date: todayDate,
    time,
    amount: "",
    fundSource: "",
    attachment: "",
    remarks: "",
    isDisabled: false,
  }

  const [masterObject, setMasterObject] = useState({ ...initialState, id: id })
  const [selectedFields, setSelectedFields] = useState({ ...initialState })

  const [selectedData, setSelectedData] = useState({})
  const [tableData, setTableData] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])

  useEffect(() => {
    if (isOpen && id) {
      handleData()
      setMasterObject({ ...initialState, id: id })
      setSelectedFields({ ...initialState })
      handleFundSourceOptions()
    }
    // eslint-disable-next-line
  }, [isOpen, id])

  const handleData = async () => {
    try {
      const response = await get(`income-expense/details?id=${id}`)
      const data = response.data
      if (!data) return

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.type = TYPE_VALUES[data.type]

      const amount = data.amount || 0
      const paid = data.received || 0

      data.paid = numberToCurrency(paid)
      data.amount = numberToCurrency(amount)
      data.pending = numberToCurrency(amount - paid)

      data.approvedDate = dateConverter(data.approvedDate)
      data.approvedTime = timeConverter(data.approvedTime)

      data.staff = `${data.staffFirstName || ""} ${data.staffLastName || ""}`
      data.approvedBy = `${data.approvedFirstName || ""} ${
        data.approvedLastName || ""
      }`

      if (data.attachment)
        data.attachment = (
          <a href={API_URL + data.attachment} target="_blank" rel="noreferrer">
            <i className="far fa-file-image image-icon" />
          </a>
        )

      data.statusText =
        data.status === STATUS_VALUES.CANCELED
          ? "Cancelled"
          : data.status === STATUS_VALUES.APPROVED
            ? "Approved"
            : "Pending"

      setSelectedData(data)
      handleFollowupData(data.creditPayments)
    } catch (err) {
      onToggle()
    }
  }

  const handleFollowupData = async data => {
    if (!Array.isArray(data) || data.length < 1) setTableData([])

    data.map((item, index) => {
      item.id = index + 1
      item.date = dateConverter(item.date)
      item.amount = numberToCurrency(item.amount)
      item.due = numberToCurrency(item.due)
      item.staff = `${item.staffFirstName || ""} ${item.staffLastName || ""}`
      if (item.attachment)
        item.attachment = (
          <a href={API_URL + item.attachment} target="_blank" rel="noreferrer">
            <i
              className="far fa-file-image"
              style={{
                cursor: "pointer",
                fontSize: "1.5em",
                color: "black",
                // color: "red",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </a>
        )

      return item
    })
    setTableData(data)
  }

  const handleFundSourceOptions = async () => {
    try {
      const response = await get(`income-expense/options/fund-source`)
      setFundSourceOptions(response.data)
    } catch (err) {}
  }

  const handleSubmit = async () => {
    if (!masterObject.fundSource) {
      return toastr.error("Fund Source is required")
    }
    try {
      const response = await post(`income-expense/credit/add`, masterObject)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected.value, name)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      fd.append("image", e.target.files[0])
      const response = await post(`income-expense/image`, fd)
      handleValueChange(response.data.new_filename, "attachment")
    } catch (error) {
      toastr.error(error.response.data.message)

      e.target.value = ""
    }
  }

  const reset = () => {
    ref.current?.reset()
    handleData()
    setMasterObject({ ...initialState, id: id })
    setSelectedFields({ ...initialState })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Amount", field: "amount" },
      { label: "Due", field: "due" },
      { label: "Fund Source", field: "fundSource" },
      { label: "Attachment", field: "attachment" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment Details</h5>
          <button
            type="button"
            onClick={onToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <ul className="list-details">
            <ListItem label="Date" value={selectedData?.date} />
            <ListItem label="Time" value={selectedData?.time} />
            <ListItem label="Head" value={selectedData?.type} />
            <ListItem label="Sub Head" value={selectedData?.subHead} />
            <ListItem
              label="Chart Of Account"
              value={selectedData?.chartOfAccount}
            />
            <ListItem label="Amount" value={selectedData?.amount} />
            <ListItem label="Fund Source" value={selectedData?.fundSource} />
            <ListItem label="Paid" value={selectedData?.paid} />
            {selectedData.paymentType !== 2 && (
              <ListItem label="Pending" value={selectedData?.pending} />
            )}
            <ListItem label="Attachment" value={selectedData?.attachment} />
            <ListItem label="Remarks" value={selectedData?.remarks} />
            <ListItem label="Added By" value={selectedData?.staff} />
          </ul>
          <ul
            className="list-details"
            hidden={selectedData.status !== 2 && selectedData.status !== 0}
          >
            <ListItem
              label={`${selectedData?.statusText} Date`}
              value={selectedData?.approvedDate}
            />
            <ListItem
              label={`${selectedData?.statusText} Time`}
              value={selectedData?.approvedTime}
            />
            <ListItem
              label={`${selectedData?.statusText} By`}
              value={selectedData?.approvedBy}
            />
          </ul>
          {isCredit && selectedData.status === STATUS_VALUES.PENDING && (
            <Card>
              <CardBody>
                <AvForm ref={ref} onValidSubmit={() => handleSubmit()}>
                  <Row>
                    <Col md={2}>
                      <Label>Date</Label>
                      <AvField
                        name={`date`}
                        type="date"
                        placeholder="Date"
                        errorMessage="Enter valid Date"
                        defaultValue={todayDate}
                        value={masterObject.date || ""}
                        validate={{ required: { value: true } }}
                        max={todayDate}
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Label>Time</Label>
                      <AvField
                        name={`time`}
                        type="time"
                        placeholder="Time"
                        errorMessage="Enter valid Time"
                        defaultValue={time}
                        value={masterObject.time || ""}
                        validate={{ required: { value: true } }}
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Label>Amount (₹)</Label>
                      <AvField
                        name={`amount`}
                        type="number"
                        placeholder="Amount"
                        errorMessage="Enter valid Amount"
                        validate={{ required: { value: true } }}
                        value={masterObject.amount || ""}
                        min={1}
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </Col>

                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Fund Source</Label>
                        <Select
                          name="fundSource"
                          options={fundSourceOptions}
                          value={selectedFields.fundSource || ""}
                          onChange={selected =>
                            handleSelectValueChange(selected, "fundSource")
                          }
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <FileUpload
                        label="Attachments"
                        name="attachment"
                        fileUrl={masterObject?.attachment || ""}
                        onFileChange={handleFileUpload}
                        onDelete={() => handleValueChange("", "attachment")}
                        accept="image/*, .pdf, .xls, .xlsx"
                      />
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Remarks</Label>
                        <AvField
                          type="textarea"
                          rows={1}
                          name="remarks"
                          placeholder="remarks"
                          value={masterObject.remarks || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="d-flex gap-3 form-button">
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                        <Button color="danger" type="button" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          )}
          {isCredit && (
            <Card>
              <CardBody>
                <MDBDataTable
                  id="creditsDetails"
                  responsive
                  bordered
                  data={data}
                  searching={false}
                  paging={false}
                  info={false}
                  sortable={false}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </Modal>
    </>
  )
}

export default CreditFollowup
