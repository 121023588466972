import React from "react"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import { APP_NAME } from "../../config"

const Footer = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              ©{new Date().getFullYear()} {APP_NAME}. All Rights Reserved
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block cursor-pointer">
                <i
                  className="fas fa-info-circle"
                  onClick={() => navigate(`/about`)}
                  style={{
                    fontSize: "1.2em",
                    color: "gray",
                  }}
                ></i>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
