import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import TablePagination from "../../components/Common/TablePagination"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ListItem from "../../components/Common/ListItem"
import CandidateCard from "./CandidateCard"

import { get, post } from "../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  numberToCurrency,
  numberToString,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../helpers/functions"

import "./styles.scss"
import { Tooltip } from "@mui/material"
import EnrollDetails from "./Details"
import SearchField from "../../components/Common/SearchField"

const Enroll = () => {
  const filterRef = useRef()
  const todayDate = getDate()

  const fromOptions = [
    { label: "Institute", value: 1 },
    { label: "CRM", value: 2 },
  ]

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState([])

  const [modal, setModal] = useState({ show: false, id: null })

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [interData, setInternshipData] = useState({})

  const [selectAll, setSelectAll] = useState(false)

  const [collegeOptions, setCollegeOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])
  const [candidateOptions, setCandidateOptions] = useState([])
  const [selectedCandidates, setSelectedCandidates] = useState([])

  const [specializationOptions, setSpecializationOptions] = useState([])

  const [filterCollegeOptions, setFilterCollegeOptions] = useState([])
  const [filterInternshipOptions, setFilterInternshipOptions] = useState([])
  const [filterCompanyOptions, setFilterCompanyOptions] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleCollegeOptions()
    handleInternshipOptions()

    handleFilterCollegeOptions()
    handleFilterCompanyOptions()
    handleFilterInternshipOptions()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (masterObject.college && masterObject.internship) {
      handleCandidateOptions()
    }
  }, [masterObject]) // eslint-disable-line

  useEffect(() => {
    if (masterObject.college && masterObject.internship)
      handleSpecializationOptions()
  }, [masterObject.college, masterObject.internship]) // eslint-disable-line

  const handleCollegeOptions = async () => {
    try {
      const response = await get(`enroll/options/college`)
      setCollegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`enroll/options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCandidateOptions = async (obj = masterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`enroll/options/candidate?${params}`)
      setCandidateOptions(response.data)
    } catch (error) {
      console.error(error)
      toastr.error(error.response.data.message)
      setCandidateOptions([])
    }
  }
  const handleSpecializationOptions = async (obj = masterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`enroll/options/specialization?${params}`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCollegeOptions = async () => {
    try {
      const response = await get(`enroll/options/filter/college`)
      setFilterCollegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterInternshipOptions = async (company = "") => {
    try {
      const params = queryString.stringify({ company })
      const response = await get(`enroll/options/filter/internship?${params}`)
      setFilterInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCompanyOptions = async () => {
    try {
      const response = await get(`enroll/options/filter/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/enroll${query}`)

      const { data, count } = response
      data?.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.college = renderTableValue(item.college?.name)
        item.company = renderTableValue(item.company?.name)
        item.internship = renderTableValue(item.internship?.name)

        if (item.from === 1) {
          item.staff = renderTableValue(item.collegeUser?.name)
        } else {
          item.staff = renderTableValue(item.addedBy?.name)
        }

        item.from = renderTableValue(item.from === 1 ? "Institute" : "CRM")

        item.total = numberToString(item.count?.success)

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleToggleModal({ show: true, id: item._id })}
              ></i>
            </Tooltip>
          </div>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await post(`enroll`, {
        ...masterObject,
        candidates: selectedCandidates,
      })

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    if (name === "internship") {
      const duration = `${selected.duration || ""} ${selected.unit === 1 ? "days" : selected.unit === 2 ? "weeks" : selected.unit === 3 ? "months" : "years"}`

      setInternshipData({
        duration,
        company: selected?.company?.name,
        vacancies: numberToString(selected?.vacancies),
        available: numberToString(selected?.available),
        assigned: numberToString(selected?.assigned),
        placed: numberToString(selected?.placed),
        stipend: numberToCurrency(Number(selected?.stipend)),
        specializations: selected?.specializations,
      })
    }

    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))

    if (name === "college" || name === "internship") setSelectedCandidates([])
  }

  const handleCheckboxChange = ({ id, checked } = {}) => {
    const candidates = [...candidateOptions]
    const selected = [...selectedCandidates]

    if (checked) {
      selected.push(id)
    } else {
      const candidateIndex = selected.indexOf(id)
      if (candidateIndex !== -1) selected.splice(candidateIndex, 1)
    }

    setCandidateOptions(candidates)
    setSelectedCandidates(selected)
  }

  const handleSelectAll = async () => {
    if (selectAll) {
      setSelectedCandidates([])
    } else {
      const allSelected = candidateOptions.map(candidate => candidate.id)
      setSelectedCandidates(allSelected)
    }
    setSelectAll(!selectAll)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    setSelectFilter(prev => ({ ...prev, [name]: selected }))
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
    if (name === "company") handleFilterInternshipOptions(value)
  }

  const handleToggleModal = ({ show = false, id = null }) => {
    setModal({ show, id })
    if (!show) handleTableData()
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    toTop()
    setMasterObject({})
    setSelectedFields({})

    handleCollegeOptions()
    handleInternshipOptions()

    setCandidateOptions([])
    setSelectedCandidates([])

    setSelectAll(false)

    setFilterObject({})
    setSelectFilter({})

    handleFilterInternshipOptions()

    filterRef.current?.reset()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Institute", field: "college" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "internship" },
      { label: "Candidates", field: "total" },
      { label: "From", field: "from" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Enroll" />
        <Card>
          <CardBody>
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3} className="mb-3">
                  <Label>Institute</Label>
                  <Select
                    menuPosition="fixed"
                    options={collegeOptions}
                    value={selectedFields.college || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "college")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Internship</Label>
                  <Select
                    menuPosition="fixed"
                    options={internshipOptions}
                    value={selectedFields.internship || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "internship")
                    }
                  />
                </Col>

                <Col md={6} style={{ marginTop: "26px" }}>
                  <div className="d-flex gap-3">
                    <Button color="primary">Submit</Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                    {candidateOptions.length ? (
                      <Button
                        type="button"
                        color={selectAll ? "warning" : "success"}
                        onClick={handleSelectAll}
                      >
                        {selectAll ? "Unselect All" : "Select All"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              {masterObject.internship ? (
                <>
                  <ul className="list-details mt-4" style={{ padding: 0 }}>
                    <ListItem label="Company" value={interData?.company} />
                    <ListItem label="Vacancies" value={interData?.vacancies} />
                    <ListItem label="Available" value={interData?.available} />
                    <ListItem label="Assigned" value={interData?.assigned} />
                    <ListItem label="Placed" value={interData?.placed} />
                    <ListItem label="Duration" value={interData?.duration} />
                    <ListItem label="Stipend" value={interData?.stipend} />
                  </ul>
                  <Label style={{ marginLeft: "0.8rem" }}>
                    Specializations :
                  </Label>
                  <ul className="specialization-list mb-4">
                    {interData?.specializations?.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )}
              <Row>
                {masterObject.internship && masterObject.college ? (
                  <>
                    <Col md={3} className="mb-3">
                      <Label>CGPA</Label>
                      <SearchField
                        name="cgpa"
                        type="number"
                        min={1}
                        max={10}
                        placeholder="CGPA between 1 and 10"
                        value={masterObject.cgpa || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <Label>Specialization</Label>
                      <Select
                        isMulti
                        menuPosition="fixed"
                        options={specializationOptions}
                        value={selectedFields.specialization || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "specialization")
                        }
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col md={6}></Col>
                {candidateOptions.map((candidate, index) => (
                  <Col md={4} xxl={3}>
                    <CandidateCard
                      {...candidate}
                      index={index}
                      checked={selectedCandidates.includes(candidate.id)}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  </Col>
                ))}
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  ></AvField>
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  ></AvField>
                </Col>

                <Col md={2}>
                  <Label>Institute</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCollegeOptions}
                    value={selectFilter.college || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "college" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCompanyOptions}
                    value={selectFilter.company || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "company" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterInternshipOptions}
                    value={selectFilter.internship || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "internship" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>From</Label>
                  <Select
                    menuPosition="fixed"
                    options={fromOptions}
                    value={selectFilter.addedFrom || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "addedFrom" })
                    }
                  />
                </Col>
                <Col className="mb-3">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="enrollTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>

      <EnrollDetails
        isOpen={modal.show}
        onToggle={() => handleToggleModal({})}
        id={modal.id}
      />
    </div>
  )
}

export default Enroll
