import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col } from "reactstrap"
import useSearchValue from "../../hooks/useSearchValue"

const SearchBar = ({ onDataSelected }) => {
  const selectedRef = useRef(null)

  const [keyWord, setKeyWord] = useState()
  const [highlightedIndex] = useState(-1)

  const [optionsCustom, setOptionsCustom] = useState([])

  const studentRef = useRef()

  const searchValue = useSearchValue(keyWord, 500)

  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue)
    }
  }, [searchValue])

  const handleSearchChange = search => {}
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    show: false,
  })

  const handleKeyDown = e => {}

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [selectedRef, highlightedIndex])

  const handleSelectSearch = e => {
    setOptionsCustom([])
    setKeyWord("")
  }
  const handleSearch1 = () => {}

  useEffect(() => {
    function handleCloseStudentSearch(e) {
      if (!studentRef.current.contains(e.target)) {
        setKeyWord("")
        setOptionsCustom([])
      }
    }
    document.addEventListener("mousedown", handleCloseStudentSearch)
    return () => {
      document.removeEventListener("mousedown", handleCloseStudentSearch)
    }
  })

  return (
    <div className="search-bar" ref={studentRef}>
      <AvForm onValidSubmit={handleSearch1}>
        <Row>
          <Col md="12">
            <div
              style={{ position: "relative", minWidth: "215px" }}
              className="mb-1 mt-3 ms-2 "
            >
              <AvField
                menuPosition="fixed"
                placeholder="Search Candidate"
                value={keyWord}
                name="search"
                onKeyDown={e => {
                  handleKeyDown(e)
                  let keyWord = e.target.value

                  if (
                    e.key === "Backspace" &&
                    (!keyWord || keyWord.key === 0)
                  ) {
                    setOptionsCustom([])
                  }
                }}
                onChange={e => {
                  if (e.target.value.length === 0) {
                    setOptionsCustom([])
                  }
                  setKeyWord(e.target.value)
                }}
                classNamePrefix="select2-selection"
                onFocus={() => setShowAdditionalFields({ show: false })}
              />
              {optionsCustom.length > 0 && !showAdditionalFields.show && (
                <div class="fetch-names-div">
                  {optionsCustom.map((item, index) => (
                    <>
                      <a href="candidate" key={item.label}>
                        <p
                          className="select-email-p"
                          ref={highlightedIndex === index ? selectedRef : null}
                          onClick={e => {
                            handleSelectSearch(item)
                          }}
                          style={{
                            backgroundColor:
                              index === highlightedIndex
                                ? "rgb(240, 249, 255)"
                                : "",
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

export default SearchBar
