import { EditorView } from "codemirror"
import { en } from "suneditor/src/lang"
import plugins from "suneditor/src/plugins"
import katex from "katex"
import { getDate } from "../../../helpers/functions"
import moment from "moment"

const todayDate = getDate()
const tomorrowDate = moment().add(1, "day").format("YYYY-MM-DD")

export const sunEditorOptions = {
  plugins: plugins,
  height: 250,
  EditorView: {
    src: EditorView,
    options: { indentWithTabs: true, tabSize: 2 },
  },
  katex: katex,
  lang: en,
  buttonList: [
    [
      "font",
      "fontSize",
      "formatBlock",
      "bold",
      "underline",
      "italic",
      "paragraphStyle",
      "blockquote",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "undo",
      "redo",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",
      "fullScreen",
      "showBlocks",
      "codeView",
      "preview",
    ],
  ],
}

export const unitValues = {
  1: "days",
  2: "weeks",
  3: "months",
  4: "years",
}

export const unitOptions = [
  { label: "Days", value: 1 },
  { label: "Weeks", value: 2 },
  { label: "Months", value: 3 },
  { label: "Years", value: 4 },
]

export const statusOptions = [
  { label: "Ongoing", value: 0 },
  { label: "Inactive", value: 2 },
  { label: "Expired", value: 3 },
  { label: "Filled", value: 4 },
]

export const approvalOptions = [
  { label: "Approve", value: 1 },
  { label: "Reject", value: 2 },
]

export const typeOptions = [
  { label: "KTU Internship", value: 1 },
  { label: "General Internship", value: 2 },
  { label: "Public Sector Internship", value: 3 },
]

export const tabsOptions = [
  { label: "Description", value: "1" },
  { label: "Eligibility", value: "2" },
]

export const statusValues = {
  1: { text: "Approved", color: "success" },
  2: { text: "Rejected", color: "danger" },
}

export const initialState = {
  master: {
    duration: 1,
    startDate: todayDate,
    endDate: tomorrowDate,
    unit: unitOptions[0].value,
    details: [
      {
        tab: tabsOptions[0].value,
        tabDescription: tabsOptions[0].label,
        contents: "",
      },
      {
        tab: tabsOptions[1].value,
        tabDescription: tabsOptions[1].label,
        contents: "",
      },
    ],
  },
  select: {
    unit: unitOptions[0],
    details: [{ tab: tabsOptions[0] }, { tab: tabsOptions[1] }],
  },
}

export const filterStatusOptions = [
  { label: "Approved", value: 6 },
  { label: "Rejected", value: 7 },
  { label: "Pending", value: 8 },

  { label: "Ongoing", value: 0 },
  { label: "Inactive", value: 2 },
  { label: "Expired", value: 3 },
  { label: "Filled", value: 4 },
]

export const filterShowOptions = [
  { label: "Show", value: true },
  { label: "Hidden", value: false },
]

export const TYPE_VALUES = {
  KTU: 1,
  GENERAL: 2,
  PSU: 3,
}
