import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import "./style.scss";
import { get } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";

const JobApplied = ({ candidateId }) => {
  const [studentInternship, setStudentInternship] = useState([]);
  const [popupView, setPopupView] = useState(false); 
  const [modalData, setModalData] = useState(null); 

  const statusMap = {
    0: "Applied",
    1: "Deleted",
    2: "Shortlisted",
    3: "Placed",
    4: "Rejected from Application",
    5: "Rejected from Shortlist",
    6:"WithDrawn",
    7:"WithDraw Pending",
    8:"WithDraw Rejected",
    9:"Completed",
    10:"Pending"
  };

  const Internshiptype = {
    1: "KTU",
    2: "General",
    3: "Public Sector",
  };

  useEffect(() => {
    handleTableData();
  }, [candidateId]);

  function handleTableData() {
    get(`candidate/internship?candidate=${candidateId}`)
      .then(res => {
        const result = res?.data || [];
        console.log("Fetched data:", result);
        setStudentInternship(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  const rows = studentInternship.map((application, index) => ({
    id: index + 1,
    date: moment(application.date).format("DD-MM-YYYY"),
    type: Internshiptype[application.internship?.type] || "",
    uniqueId: application.uniqueId,
    name: application.internship?.name,
    status1: statusMap[application.status] || "",
    options: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          className="fas fa-eye"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            setPopupView(true); 
            setModalData(application); 
          }}
        ></i>
        {application?.offerLetter?.company ? (
          <Tooltip title="Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${application.offerLetter?.company}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-file-pdf pdf-icon" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
        {application?.offerLetter?.asap ? (
          <Tooltip title="ASAP Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${application.offerLetter?.asap}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fas fa-file-download asap-offer-letter-icon"
                style={{ color: "gray" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
        {application?.completion?.file?  (
          <Tooltip title="Completion Letter" arrow placement="top">
            <a
              href={`${API_URL}${application?.completion?.file}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-solid fa-file-circle-check completion-letter-icon"
                style={{ color: "yellow" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}

{application?.report?.file?  (
          <Tooltip title="Completion Report" arrow placement="top">
            <a
              href={`${API_URL}${application?.report?.file}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa-solid fa-file-arrow-down report-icon"
                // style={{ color: "yellow" }}
              />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    ),
  }));

  const jobPosts = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      { label: "Date", field: "date", sort: "asc", width: 400 },
      { label: "Type", field: "type", sort: "asc", width: 400 },
      { label: "ID", field: "uniqueId", sort: "asc", width: 400 },
      { label: "Name", field: "name", sort: "asc", width: 400 },
      { label: "Status", field: "status1", sort: "asc", width: 200 },
      { label: "Action", field: "options", sort: "asc", width: 200 },
    ],
    rows: rows,
  };

  const closeModal = () => {
    setPopupView(false); 
    setModalData(null); 
  };

  return (
    <>
      <Modal
        show={popupView} 
        onHide={closeModal} 
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Internship Details</h5>
          </div>
          <button
            type="button"
            onClick={closeModal} 
            className="close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {modalData && (
            <div>
              <p><strong>Date:</strong>{modalData.date?moment(modalData.date).format("DD-MM-YYYY"):""}</p>
              <p><strong>ID:</strong> {modalData.uniqueId}</p>
              <p><strong>Name:</strong> {modalData.internship?.name}</p>
              <p><strong>Type:</strong> {Internshiptype[modalData.internship?.type] || ""}</p>
              <p><strong>Status:</strong> {statusMap[modalData.status] || ""}</p>
              <p><strong>Stipend:</strong> {modalData.internship?.stipend}</p>
            </div>
          )}
        </div>
      </Modal>

      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="shortlistedJobDataId"
            className="job-applied"
            data={jobPosts}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default JobApplied;
