import { useState, useEffect } from "react"

const useSearchValue = (value, delay) => {
  const [deferredValue, setDeferredValue] = useState(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDeferredValue(value)
    }, delay)

    return () => clearTimeout(timeoutId)
  }, [value, delay])

  return deferredValue
}

export default useSearchValue
