export const typeOptions = [
  { label: "Internship", value: 1 },
  { label: "Job", value: 2 },
]

export const qualificationOptions = [
  { label: "Qualified", value: 1 },
  { label: "Not Qualified", value: 2 },
]

export const TYPES = { 1: "Internship", 2: "Job", INTERNSHIP: 1, JOB: 2 }
export const QUALIFIED = {
  QUALIFIED: 1,
  NOT_QUALIFIED: 2,
  1: { text: "Qualified", color: "success", size: "sm", disabled: true },
  2: { text: "Not Qualified", color: "danger", size: "sm", disabled: true },
}
