import React, { useState, useRef, useEffect } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"

import toastr from "toastr"
import moment from "moment"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  dateConverter,
  getDate,
  numberToCurrency,
  timeConverter,
} from "../../../helpers/functions"
import { get, post } from "../../../helpers/api_helper"

import "./styles.scss"

const CreditDebit = ({ options }) => {
  const formRef = useRef()

  const typeOptions = [
    { label: "Credit", value: 1 },
    { label: "Debit", value: 2 },
  ]

  const todayDate = getDate()
  const time = moment().format("HH:mm")

  const initialState = { date: todayDate, time }

  const [balance, setBalance] = useState(0)

  const [accountsOptions, setAccountsOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({ ...initialState })
  const [selectedFields, setSelectedFields] = useState({})

  useEffect(() => {
    handleAccountOptions()
  }, []) // eslint-disable-line

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleAccountOptions = async () => {
    try {
      const response = await get(`options/chartOfAccount`)
      setAccountsOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (pevPage = page) => {
    try {
      const response = await get(
        `/credit-debit/list?page=${pevPage}&limit=${limit}`,
      )
      const data = response.data
      const count = response.count
      data.map((item, index) => {
        item.id = index + 1 + (pevPage - 1) * limit

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.account = item.account?.name
        item.staff = item.addedBy?.name

        item.credit = "---"
        item.debit = "---"

        if (item.type === 1) item.credit = numberToCurrency(item.amount)
        else item.debit = numberToCurrency(item.amount)

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTableData(data)
      setTotalPage(totalPage)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit = async () => {
    let errorAvailable = false

    if (errorAvailable) return

    try {
      const response = await post("/credit-debit/create", masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name, selected })
  }

  const handleValueChange = async ({ value, name, selected }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "account") setBalance(selected.balance)
    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    toTop()
    handleTableData(1)
    setPage(1)
    setMasterObject({ ...initialState })
    setSelectedFields({})
    handleAccountOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "No.", field: "uniqueId" },
      { label: "Account", field: "account" },
      { label: "Debit", field: "debit" },
      { label: "Credit", field: "credit" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Credit/Debit Fund" />
          {options.edit ? (
            <Card>
              <CardBody>
                <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                  <Row>
                    <Col md={2}>
                      <div className="mb-3">
                        <AvField
                          name="date"
                          label="Date"
                          type="date"
                          value={masterObject.date || ""}
                          defaultValue={masterObject.date}
                          max={todayDate}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter Date"
                          onChange={e => handleValueChange(e.target)}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <AvField
                          name="time"
                          label="Time"
                          type="time"
                          defaultValue={masterObject.time}
                          value={masterObject.time}
                          onChange={e => handleValueChange(e.target)}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter Time"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                          name="type"
                          options={typeOptions}
                          value={selectedFields.type || ""}
                          onChange={selected =>
                            handleSelectValueChange(selected, "type")
                          }
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Fund Source</Label>
                        <Select
                          name="account"
                          options={accountsOptions}
                          value={selectedFields.account || ""}
                          onChange={selected =>
                            handleSelectValueChange(selected, "account")
                          }
                        />

                        {masterObject.account ? (
                          <span className="mt-2 mb-2">
                            <b>Balance :</b> {numberToCurrency(balance)}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Amount</Label>
                        <AvField
                          name="amount"
                          className="form-control"
                          type="number"
                          value={masterObject.amount || ""}
                          onChange={e => {
                            handleValueChange(e.target)
                          }}
                          validate={{
                            required: { value: true },
                            min: { value: 1 },
                          }}
                          placeholder="Amount"
                          errorMessage="Enter Amount"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Remarks</Label>
                        <AvField
                          name="remarks"
                          type="textarea"
                          value={masterObject.remarks || ""}
                          onChange={e => handleValueChange(e.target)}
                          placeholder="Remarks"
                          rows={1}
                          errorMessage="Enter Remarks"
                        />
                      </div>
                    </Col>
                    <Col className="form-button">
                      <div className="d-flex gap-2">
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                        <Button color="danger" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          ) : (
            ""
          )}

          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="creditDebitTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default CreditDebit
