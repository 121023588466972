import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { dateConverter } from "../../helpers/functions"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../config"
import { del, get, post, put } from "../../helpers/api_helper"
import TablePagination from "../../components/Common/TablePagination"
import Swal from "sweetalert2"
import toastr from "toastr"
import Select from "react-select"



const AddDesignation = () => {

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  
  const [filterobj, setfilterobj] = useState({
    search: "",
    type: null
  }) 
 const [searchQuery, setSearchQuery] = useState("")
  const [selectedType, setSelectedType] = useState(null)

  
  const [tableData, setTableData] = useState([])
  const [selectedFields,setSelectedFields] = useState({});


  
  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })


  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const typeOptions = [
    { label: "Institute", value: 1 },
    { label: "Company", value: 2 }
  ]


  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearchQuery(value)
    setfilterobj({ ...filterobj, [name]: value })
  }



  useEffect(() => {
    handleTableData()
  }, [filterobj]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`/designation${query}&search=${filterobj.search}&type=${filterobj?.type}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = dateConverter(item.date)
        item.type = item.type === 1 ? "Institute" : item.type === 2 ? "Company" : "";

        item.state = item.state?.name

        item.staff = item.addedBy?.name

        if (item.logo)
          item.file = (
            <a href={API_URL + item.logo} target="_blank" rel="noreferrer">
              <i className="far fa-file-image file-icon"></i>
            </a>
          )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                 onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                 onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }


  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "For", field: "type" },
      { label: "Designation Name", field: "name" },
      { label: "ShortCode", field: "shortCode" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }


  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`designation`, masterObject)
   else response = await post(`designation`, masterObject)

    
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }
  


  const handleValueChange = ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
    if (name === "type") {
      setSelectedType(typeOptions.find(option => option.value === value))
    }
  }


  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    setfilterobj({ ...filterobj, [name]: selected.value })
  }

  const reset = () => {
    if (formRef.current) {  
      formRef.current.reset();
    }
    setMasterObject({});
    setSelectedFields({});
    setfilterobj({})
    setSearchQuery("") 
    handleTableData(1);
    setPage(1);
    setTotalPage(1);
    toTop();
  };


  const handleUpdate = async id => {
    try {
      const response = await get(`designation/update/${id}`)
      const data = response.data
      
      const typeOption = typeOptions.find(option => option.value === data.type)
      
      setMasterObject({ 
        ...data, 
        id,
        state: data?.state?.value,
        type: data.type
      })
      
      setSelectedType(typeOption)
      
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      
      if (!result.isConfirmed) return;
  
      const response = await del(`designation/${id}`);
      toastr.success(response.message);
      reset();
    } catch (error) {
      // Check if error.response is defined
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        // toastr.error("An unexpected error occurred.");
      }
    }
  };
  

  return (
    <div className="page-content">
      <Container fluid>
      <Breadcrumb title="Home" breadcrumbItem="Designation" />

      <Card>
  <CardBody>
    <AvForm ref={formRef} onValidSubmit={handleSubmit} id="departmentForm">
      <Row>
      <Col md="3">
  <Label>For</Label>
  <Select
    menuPosition="fixed"
    options={[
      { label: "Institute", value: 1 },
      { label: "Company", value: 2 }
    ]}
    value={
      masterObject.type
        ? { label: masterObject.type === 1 ? "Institute" : "Company", value: masterObject.type }
        : null
    }
    onChange={selected =>
      handleValueChange({
        name: "type",
        value: selected.value, 
      })
    }
    placeholder="-- Select --"
  />
</Col>

      
        <Col md="3">
          <Label>Designation Name</Label>
          <AvField
            name="name"
            placeholder="Enter Designation Name"
            errorMessage="Please enter the designation name"
            validate={{ required: { value: true } }}
            value={masterObject.name || ""}
            onChange={e => handleValueChange(e.target)}
          />
        </Col>

        <Col md="3">
  <Label>Short Code</Label>
  <AvField
    name="shortCode"
    placeholder="Short Code"
    type="text"
    errorMessage="Enter Short Code (only letters and numbers allowed)"
    validate={{
      required: { value: true, errorMessage: "Short Code is required" },
      pattern: { value: '^[a-zA-Z0-9]+$', errorMessage: "Only letters and numbers are allowed" },
    }}
    value={masterObject.shortCode || ""}
    onChange={e => handleValueChange(e.target)}
  />
</Col>
       
        <Col md="3" style={{ marginTop: "26px" }}>
          <div className="d-flex gap-3">
            <Button color="primary">Submit</Button>
            <Button color="danger" type="reset" onClick={reset}>
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </AvForm>
  </CardBody>
</Card>
<Card>
            <CardBody>
            <AvForm>
              <Row>
                <Col md={3}>
                  <Label>Search</Label>
                  <AvField
                    name="search"
                    placeholder="Search name,shortcode"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>

                <Col md={3}>
                  <Label>Filter by For</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFields.type || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "type")
                    }
                  />
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="universityTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
      </Container>
    </div>
  )
}

export default AddDesignation
