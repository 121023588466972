import React, { useState, useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"
import { OverlayTrigger } from "react-bootstrap"
import { MDBDataTable } from "mdbreact"
import { useNavigate } from "react-router-dom"
import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { del, get, post, put } from "../../helpers/api_helper"
import toastr from "toastr"
import FileUpload from "../../components/Common/FileUpload"
import { handleImageValidation } from "../../helpers/functions"
import TablePagination from "../../components/Common/TablePagination"
import Swal from "sweetalert2"
import { API_URL } from "../../config"

const Addjobfaire = () => {
  const formRef = useRef()
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([]);
  const optionssun = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }


  const [masterObj, setmasterObj] = useState({})
  const [categoryOptions, setcategoryOptions] = useState([])
  const [valueSelect, setvalueSelect] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const [filterObj, setfilterObj] = useState({})
  const [filterValueselect, setfilterValueselect] = useState({})

  useEffect(() => {
    getAllcategories()
  }, [])

  useEffect(() => {
    fetchallJobfairList()
  }, [page, filterObj])

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   width: 150,
      // },
      // {
      //   label: "Category",
      //   field: "category",
      //   width: 150,
      // },
      {
        label: "Job ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Venue",
        field: "venue",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
      },
      {
        label: " JobFair Status",
        field: "jobstatus",
        width: 150,
      },

      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  function handleValueChange(e) {
    let { name, value } = e.target
    setmasterObj({ ...masterObj, [name]: value })
  }

  function getAllcategories() {
    get(`options/category`).then((res) => {
      setcategoryOptions(res.data)
    }).catch((err) => {

    })
  }

  function handleselected(name, selected) {
    setvalueSelect({ ...valueSelect, [name]: selected })
    if (name == "category") {
      setmasterObj({ ...masterObj, [name]: selected?.map(item => item.value) })
    } else {
      setmasterObj({ ...masterObj, [name]: selected.value })
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      if (e.target?.name === "image") await handleImageValidation(selectedFile, 413, 413)
      if (e.target?.name === "banner") await handleImageValidation(selectedFile, 1300, 400)
      fd.append("file", e.target.files[0])
      const response = await post(`job-fair/file`, fd)
      handleCchaange({
        value: response.data.new_filename,
        name: e.target.name,
      })
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
      e.target.value = ""
    }
  }

  const handleCchaange = async ({ value, name }) => {
    const obj = { ...masterObj }
    obj[name] = value
    setmasterObj(obj)
  }

  const onChangeSuneditor = content => {
    setmasterObj(prevState => ({
      ...prevState, // Copy previous state
      details: content, // Update editor content
    }))
  }

  function handleSubmit() {
    if (masterObj?.id) {
      put(`job-fair`, masterObj).then((res) => {
        toastr.success(res.message)
        formRef.current.reset()
        setmasterObj({})
        setvalueSelect({})
        fetchallJobfairList()
      }).catch((err) => {
        toastr.error(err.response.data.message)
      })
    } else {
      post(`job-fair`, masterObj).then((res) => {
        toastr.success(res.message)
        formRef.current.reset()
        setmasterObj({})
        setvalueSelect({})
        fetchallJobfairList()
      }).catch((err) => {
        toastr.error(err.response.data.message)
      })

    }

  }

  function fetchallJobfairList() {
    get(`job-fair?page=${page}&limit=${limit}&jobfairStatus=${filterObj?.jobfairStatus}&status=${filterObj?.status}&search=${filterObj?.search}`).then((response) => {
      const { data, count } = response
      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        // item.status1 = item?.status == "0" ? "Active" : "In-Active"
        item.status1 = (
          <>
            {item?.status == "0" ? (
              <>
                <Button onClick={() => { chageStatus(2, item?._id) }} size="sm" color="primary" title="Click to change status">Active</Button>
              </>
            ) : null}
            {item?.status == "2" ? (
              <>
                <Button onClick={() => { chageStatus(0, item?._id) }} size="sm" color="danger" title="Click to change status" >In-Active</Button>
              </>
            ) : null}
          </>
        )
        item.jobstatus = item?.jobfairStatus == "1" ? "Up-Coming" : item?.jobfairStatus == "2" ? "Ongoing" : item?.jobfairStatus == "3" ? "Completed" : "--"
        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => {
                  navigate(`/job-fair-details/${item?.uniqueId}`, {
                    state: { id: item?.uniqueId },
                  })
                }}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>

            <Tooltip title="View Qr Code " arrow placement="top">
              <i
                className="fas fa-qrcode ms-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(`${API_URL}${item?.qrCode}`, "_blank");
                }}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data) 
    })
  }

  const handlePageChange = value => {
    setPage(value)
  }


  function handleUpdate(id) {
    toTop()
    get(`job-fair/single?id=${id}`).then((res) => {
      let result = res.data
      const typeValue = result?.type === 1
        ? { label: "Offline", value: 1 }
        : result?.type === 2
          ? { label: "Online", value: 2 }
          : null;
      setvalueSelect({ category: result?.category, type: typeValue })
      result.category = result.category.map(item => item.value)
      result.id = result._id
      setmasterObj(result)
    })
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`job-fair?id=${id}`)
      toastr.success(response.message)
      fetchallJobfairList()
    } catch (error) {
      toastr.error(error.message)
    }
  }

  function filterValuechange(name, select) {
    setPage(1)
    setfilterObj({ ...filterObj, [name]: select.value })
    setfilterValueselect({ ...filterValueselect, [name]: select })

  }

  function handleFilterChnage(e) {
    let { name, value } = e.target;
    setfilterObj({ ...filterObj, [name]: value })
  }
  function handlereset() {
    formRef.current.reset()
    setmasterObj({})
    setvalueSelect({})
  }

  function chageStatus(status, id) {
    put(`job-fair/status`, { id: id, status: status }).then((res) => {
      toastr.success(res.message)
      fetchallJobfairList()
    }).catch((err) => {
      toastr.error(err.response.data.message)

    })
  }

  const generatePermalink = (name) => {
    const permalink = name
      .toLowerCase() // Convert to lowercase
      .trim() // Remove whitespace
      .replace(/[^a-z0-9\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-"); // Replace spaces with dashes

    // Update the masterObj with the generated permalink
    setmasterObj((prev) => ({ ...prev, permalink }));
  };


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Job-fair" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={handleSubmit}

                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Job Fair Name</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="Event Name"
                            errorMessage="Enter Name"
                            validate={{ required: { value: true } }}
                            value={masterObj?.name || ""}
                            // onChange={handleValueChange}
                            onChange={(e) => {
                              handleValueChange(e);
                              generatePermalink(e.target.value); // Call function to update permalink
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Permalink</Label>
                          <AvField
                            name="permalink"
                            className="form-control"
                            type="text"
                            placeholder="Permalink"
                            errorMessage="permalink"
                            validate={{ required: { value: true } }}
                            value={masterObj?.permalink || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Venue</Label>
                          <AvField
                            name="venue"
                            className="form-control"
                            type="text"
                            placeholder="Venue Name"
                            errorMessage="Enter Venue"
                            validate={{ required: { value: true } }}
                            value={masterObj?.venue || ""}
                            onChange={handleValueChange}


                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="mb-3">
                          <Label> Fee</Label>
                          <AvField
                            name="fee"
                            className="form-control"
                            type="number"
                            placeholder="Fee"
                            errorMessage="Enter Fee"
                            validate={{ required: { value: true } }}
                            value={masterObj?.fee || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Name </Label>
                          <AvField
                            name="resourcePerson"
                            className="form-control"
                            type="text"
                            placeholder="Resource Person Name"
                            errorMessage="Enter Resource Person"
                            validate={{ required: { value: true } }}
                            value={masterObj?.resourcePerson || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Mobile </Label>
                          <AvField
                            name="resourceMobile"
                            className="form-control"
                            type="text"
                            placeholder="Resource Person Mobile"
                            errorMessage="Enter Resource Person Mobile"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                                errorMessage: "Enter valid mobile no",
                              },
                            }}
                            value={masterObj?.resourceMobile || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Email </Label>
                          <AvField
                            name="resourceEmail"
                            className="form-control"
                            type="email"
                            placeholder="Resource Person Email"
                            validate={{ required: { value: true } }}
                            value={masterObj?.resourceEmail || ""}
                            onChange={handleValueChange}


                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="type"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Online", value: 2 },
                              { label: "Offline", value: 1 },
                            ]}
                            onChange={(selected) => {
                              handleselected("type", selected)
                            }}
                            value={valueSelect?.type || null}
                            required
                          />

                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            isMulti
                            name="category"
                            classNamePrefix="select2-selection"
                            options={categoryOptions}
                            required
                            onChange={(selected) => {
                              handleselected("category", selected)
                            }}
                            value={valueSelect?.category || null}

                          />

                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company Registartion closing </Label>
                          <AvField
                            name="companyRegistrationClosing"
                            className="form-control"
                            type="date"
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            value={masterObj?.companyRegistrationClosing || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Student Registartion closing </Label>
                          <AvField
                            name="candidateRegistrationClosing"
                            className="form-control"
                            type="date"
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            value={masterObj?.candidateRegistrationClosing || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <FileUpload
                          label="Image"
                          imageSize="413x413"
                          name="image"
                          fileUrl={masterObj?.image}
                          onFileChange={handleFileUpload}
                          onDelete={() =>
                            handleCchaange({ value: "", name: "image" })
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <FileUpload
                          label="Banner"
                          imageSize="1300x400"
                          name="banner"
                          fileUrl={masterObj?.banner}
                          onFileChange={handleFileUpload}
                          onDelete={() =>
                            handleCchaange({ value: "", name: "banner" })
                          }
                        />
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Start Date</Label>
                          <input
                            name="startDate"
                            className="form-control"
                            type="date"
                            validate={{ required: { value: true } }}
                            errorMessage="This field is required"
                            value={masterObj?.startDate || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Start Time</Label>
                          <input
                            name="startTime"
                            className="form-control"
                            type="time"
                            validate={{ required: { value: true } }}
                            errorMessage="This field is required"
                            value={masterObj?.startTime || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>End Date</Label>
                          <AvField
                            name="endDate"
                            className="form-control"
                            type="date"
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            value={masterObj?.endDate || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>End Time</Label>
                          <input
                            name="endTime"
                            className="form-control"
                            type="time"
                            validate={{ required: { value: true } }}
                            errorMessage="This field is required"
                            value={masterObj?.endTime || ""}
                            onChange={handleValueChange}

                          />
                        </div>
                      </Col>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <Row className="mt-2">
                        <h5 className="mt-2">Job fair Details</h5>
                        <Col md="12" className="events">
                          <SunEditor
                            setDefaultStyle="font-family: Arial; font-size: 14px; z-index:0"
                            style={{ zIndex: 0 }}
                            lang="en"
                            setOptions={optionssun}
                            onChange={content => onChangeSuneditor(content)}
                            setContents={masterObj?.details || ""}
                          />
                        </Col>
                      </Row>



                      <Col>
                        <div
                          className="mb-3 d-flex"
                          style={{ paddingTop: "25px" }}
                        >
                          <Button
                            color={masterObj?.id ? "warning" : "primary"}
                            type="submit"
                          >
                            {masterObj?.id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            className="mx-2"
                            color="danger"
                            type="button"
                            onClick={handlereset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <Label>Status</Label>
                        <Select onChange={(selected) => { filterValuechange("status", selected) }} options={[{ label: "Active", value: 0 }, { label: "In-Active", value: 2 }]} value={filterValueselect?.status || null} />
                      </Col>
                      <Col md="3">
                        <Label>JobFair Status</Label>
                        <Select onChange={(selected) => { filterValuechange("jobfairStatus", selected) }} options={[{ label: "Upcoming", value: 1 }, { label: "Ongoing", value: 2 }, { label: "Completed", value: 3 }]} value={filterValueselect?.jobfairStatus || null} />
                      </Col>
                      <Col md="3">
                        <Label>
                          Search
                        </Label>
                        <AvField onChange={handleFilterChnage} name="search" placeholder="Serach..." value={filterObj?.search || ""} />

                      </Col>

                    </Row>
                  </AvForm>
                  <TablePagination
                    tableId="companyTable"
                    page={page}
                    onChange={handlePageChange}
                    data={data}
                    count={totalPage}
                    totalCount={totalCount}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </>

  )
}

export default Addjobfaire
