import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

const JobfairAttended = () => {
    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Job Fair", field: "name" },
            { label: "Student", field: "student" },
            { label: "Reg.Id", field: "idR" },
            { label: "Source", field: "staff" },
            { label: "Status", field: "status" },
        ],
        rows: [
            {
                id: "1", date: "12-10-2024", name: "Aspire", idR: "JDSRV450JJ", student: "Jamsheer jasi", staff: "Website", status: (<>
                    <Button size="sm" color="success" disabled>
                        Attended
                    </Button>
                </>)
            },
            {
                id: "2", idR: "JDSRV450PP", date: "12-10-2024", name: "Aspire", student: "Habeeb Ashi", staff: "Website", status: (<>
                    <Button size="sm" color="success" disabled>
                        Attended
                    </Button>
                </>)
            },
            {
                id: "3", idR: "JDSRV450SS", date: "12-10-2024", name: "Aspire", student: "Nhal srv", staff: "Website", status: (<>
                    <Button size="sm" color="success" disabled>
                        Attended
                    </Button>

                </>)
            },
            // { id: "4", date: "12-10-2024", name: "Aspire", companies: "Infosys", staff: "Website", },

        ],
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="JobFair-Attended" />
                    <Card>
                        <CardBody>
                            <AvForm>
                                <Row>
                                <Col md={3}>
                                        <Label>Select Job Fair</Label>
                                        <Select
                                         options={[{ label: "TCS", value: "1" },
                                            { label: "Aspire-2024", value: "2" },
                                            { label: "Dev-fest", value: "3" },
                                            ]}

                                        />
                                    </Col>

                                    {/* <Col md={3}>
                                <Label>Select Company</Label>
                                <Select
                                    isMulti
                                    options={[{ label: "TCS", value: "1" },
                                    { label: "Qburst", value: "2" },
                                    { label: "Infosys", value: "3" },
                                    { label: "Wipro", value: "4" },
                                    ]}
                                />
                            </Col> */}
                                    {/* <Col md="3">
                                <Label>Mobile</Label>
                                <AvField name="mobile" placeholder="Mobile" disabled={true} />
                            </Col> */}
                                    <Col md={3} style={{ marginTop: "26px" }}>
                                        <Button type="button" color="success" >
                                            Filter
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>
                            <Row className="mt-4">
                                <TablePagination
                                    tableId="managecmpanyTable"
                                    page={1}
                                    // onChange={handlePageChange}
                                    data={data}
                                    count={2}
                                />

                            </Row>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default JobfairAttended
