import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  UncontrolledAlert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { withTranslation } from "react-i18next"

import PropTypes from "prop-types"
import Cookies from "js-cookie"

import SearchBar from "./SearchBar"
import ProfileMenu from "../CommonForBoth/TopBarDropDown/ProfileMenu"
import NotificationDropdown from "../CommonForBoth/TopBarDropDown/NotificationDropdown"

import logoSm from "../../assets/images/asapsm.png"
import logoDark from "../../assets/images/asapsm.png"

import { showRightSidebarAction, toggleLeftMenu } from "../../store/actions"
import { getModule } from "../../store/module/actions"
import { getMenu } from "../../store/menu/actions"
import { post, get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
import "./Header.scss"

const Header = props => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const module = useSelector(state => state.Module.data)

  const [search, setSearch] = useState(false)
  const [socialDrp, setSocialDrp] = useState(false)
  const [askForToken, setAskForToken] = useState(false)

  useEffect(() => {
    dispatch(getModule(JSON.parse(localStorage.getItem("authUser")).privilage))
  }, [dispatch])

  useEffect(() => {
    getCurrentToken()
  }, [])

  const handleModule = data => {
    try {
      sessionStorage.setItem("module", data.moduleName)
      sessionStorage.setItem("module_code", data.shortCode)
      sessionStorage.setItem("module_id", data._id)
      sessionStorage.setItem("module_url", data.redirect_url)

      const token = Cookies.get("_token")
      const authUser = localStorage.getItem("authUser")

      if (token && authUser) {
        const user = JSON.parse(authUser)._id
        dispatch(getMenu({ user, module: data._id }))
        navigate(data.redirect_url)
      }
    } catch (error) {
      console.error("Error handling module:", error)
    }
  }

  const enableNotify = async () => {
    try {
      const browserToken = localStorage.getItem("browserToken")
      const data = localStorage.getItem("authUser")
      const user = JSON.parse(data)

      await post(`user/browser/token`, { browserToken })

      user.browserToken = localStorage.getItem("browserToken")
      localStorage.setItem("authUser", JSON.stringify(user))

      setAskForToken(false)
    } catch (error) {
      console.error("Error enabling notifications:", error)
    }
  }

  const getCurrentToken = async () => {
    try {
      const response = await get(`user/browser/token`)
      const token = response.data.browserToken

      if (token !== localStorage.getItem("browserToken")) setAskForToken(true)
      else setAskForToken(false)
    } catch (error) {
      console.error("Error getting current token:", error)
    }
  }

  const toggleFullscreen = () => {
    const doc = document.documentElement

    if (!document.fullscreenElement) {
      if (doc.requestFullscreen) {
        doc.requestFullscreen()
      } else if (doc.mozRequestFullScreen) {
        doc.mozRequestFullScreen()
      } else if (doc.webkitRequestFullscreen) {
        doc.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      } else if (doc.msRequestFullscreen) {
        doc.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  const tToggle = () => {
    const body = document.body
    const windowSize = document.documentElement.clientWidth
    const currentSidebarSize = body.getAttribute("data-sidebar-size")

    body.classList.toggle("vertical-collapsed")
    body.classList.toggle("sidebar-enable")

    if (windowSize > 991) {
      const newSize = currentSidebarSize === "sm" ? "lg" : "sm"
      body.setAttribute("data-sidebar-size", newSize)
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <div
              style={{ display: "flex" }}
              className={"header_responsive_dropdown "}
            >
              <SearchBar />
            </div>

            <UncontrolledAlert
              color="success"
              className={`alert-dismissible fade show d-none ${
                askForToken ? "d-xl-block" : "d-none"
              }`}
              role="alert"
              style={{
                width: "100%",
                margin: "auto",
                marginLeft: "12px",
              }}
            >
              <i className="uil uil-exclamation-octagon me-2"></i>{" "}
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => enableNotify()}
              >
                Click here{" "}
              </strong>
              to enable desktop notifications
            </UncontrolledAlert>
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-none d-inline-block d-lg-none ms-2"
              onClick={() => {
                setSearch(!search)
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            {/* <LanguageDropdown /> */}

            <Dropdown
              className="d-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setSocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
              >
                <i className="uil-apps"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" end>
                <div className="px-lg-2">
                  <Row className="g-0">
                    {module &&
                      module.map((el, index) => {
                        let color = ""
                        if (sessionStorage.getItem("module_id") === el._id) {
                          color = "#dddced"
                        }
                        return (
                          <Col
                            key={el._id}
                            md="4"
                            id={"module" + el?.moduleName}
                            className="module_box"
                            style={{ backgroundColor: color }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              className="dropdown-icon-item"
                              // to="#"
                              onClick={() => {
                                if (
                                  sessionStorage.getItem("module_id") !== el._id
                                ) {
                                  handleModule(el)
                                }

                                setSocialDrp(!socialDrp)
                              }}
                            >
                              <img
                                src={`${API_URL}uploads/module_images/${el.icon}`}
                                alt=""
                              />
                              <span>{el?.moduleName}</span>
                            </span>
                          </Col>
                        )
                      })}
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            <NotificationDropdown />

            <ProfileMenu
              showRightSidebarAction={() =>
                props.showRightSidebarAction(!props.showRightSidebar)
              }
            />

            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <Link to={"/logout"}>
                <i className="uil uil-power"></i>
              </Link>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftMenu: PropTypes.func,
}

const mapStateToProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStateToProps, {
  showRightSidebarAction,
  toggleLeftMenu,
  // changeSidebarType,
})(withTranslation()(Header))
