import React, { useEffect, useState } from "react"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import Stack from "@mui/material/Stack"
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"
import MiniWidget from "./mini-widget"
import { useParams, useNavigate } from "react-router-dom"
import { get, put } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import moment from "moment"
// import TablePagination from "../../../components/Common/TablePagination"
import toastr from "toastr"

const ViewInstitute = props => {
  const { instituteId } = useParams()

  const [instituteDetails, setinstituteDetails] = useState(null)
  const [studentsData, setStudentsData] = useState([])
  const [summary, setSummary] = useState(null)
  const [modalData] = useState(null)
  const [popupView4, setPopupView4] = useState(false)
  const [tab3, setTab3] = React.useState("5")
  const [totalPage, setTotalPage] = useState(1)
  const [page, setPage] = useState(1)
  const limit = 100000
  const [state, setState] = useState(false)
  const status = [
    { label: "Ongoing", value: 0 },
    { label: "Inactive", value: 2 },
    { label: "Expired", value: 3 },
    { label: "Filled", value: 4 },
  ]
  useEffect(() => {
    fetchinstituteDetails()
  }, []) // eslint-disable-line
  useEffect(() => {
    fetchSummary()
  }, []) // eslint-disable-line
  useEffect(() => {
    fetchStudents(1)
  }, [page]) // eslint-disable-line

  useEffect(() => {
    let status = -1
    if (tab3 === 1) {
      status = 0
    } else if (tab3 === 3) {
      status = 2
    } else if (tab3 === 4) {
      status = 3
    }
    if (status >= 0) {
      fetchStudents(status)
    }
  }, [tab3, page]) // eslint-disable-line

  const navigate = useNavigate()

  const handleChange = (event, newPage) => {
      setPage(newPage) 
  
  }
  const fetchinstituteDetails = async () => {
    try {
      const response = await get(
        `/college/institute-details?collegeId=${instituteId}&type=1`,
      )
      console.log("response", response)
      setinstituteDetails(response?.data[0])
    } catch (error) {}
  }
  const fetchSummary = async () => {
    try {
      const response = await get(
        `/college/institute-details?collegeId=${instituteId}&type=3`,
      )
      console.log("Summary count", response?.data)
      setSummary(response?.data)
    } catch (error) {}
  }
  const fetchStudents = async (currentPage = page) => {
    try {
      console.log("Fetching students for page:", currentPage)
      // const filterQuery = `instituteId=${instituteId}&page=${page}&limit=${limit}`
      const response = await get(
        `/college/institute-details?collegeId=${instituteId}&type=2&page=${currentPage}&limit=${limit}`,
      )
      console.log("response of college list", response)
      setSummary(response?.count)
      setStudentsData(response?.data || [])
      const count = response?.count
      setTotalPage(Math.ceil(Number(count) / limit))
    } catch (error) {}
  }
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const handleStatusChange = async (requestId, approvedStatus) => {
    put("/job/status", { _id: requestId, status: approvedStatus })
      .then(res => {
        setState(!state)
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  const students = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Student Name",
        field: "fullName",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile No",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Register No",
        field: "regNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Profile %	",
        field: "profileCompletion",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "currentStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: studentsData.map((item, index) => {
      return {
        id: index + 1 + (page - 1) * 20,
        date: moment(item.date).format("DD-MM-YYYY"),
        uniqueId: item.uniqueId,
        fullName: item.fullName,
        mobile: item.mobile,
        email: item.email,
        regNo: item.regNo,
        profileCompletion: parseFloat(item.profileCompletion.toFixed(2)),
        currentStatus:
          item.currentStatus === 0
            ? "APPLIED"
            : item.currentStatus === 1
              ? "SCREENING"
              : item.currentStatus === 2
                ? "SHORTLISTED"
                : item.currentStatus === 3
                  ? "PLACED"
                  : item.currentStatus === 4
                    ? "REJECTED_FROM_APPLICATION"
                    : item.currentStatus === 5
                      ? "REJECTED_FROM_SHORTLIST"
                      : item.currentStatus === 6
                        ? "WITHDRAWN"
                        : item.currentStatus === 7
                          ? "WITHDRAW_PENDING"
                          : item.currentStatus === 8
                            ? "WITHDRAW_REJECTED"
                            : item.currentStatus === 9
                              ? "COMPLETED"
                              : "-",
        status: (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="job_status"
              placeholder="status"
              onChange={selected => {
                handleStatusChange(item?._id, selected.value)
              }}
              value={status?.find(status => status.value === item?.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/candidate-profile/${item?.uniqueId}`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </div>
        ),
      }
    }),
  }

  const series1 = [50]
  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series5 = [33]

  const options5 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const series6 = [33]

  const options6 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const series7 = [33]

  const options7 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series8 = [33]

  const options8 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      title: "Students",
      value: summary?.student,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      title: "Placed",
      value: summary?.placed,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      title: "Shortlisted",
      value: summary?.shortlisted,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      title: "Rejected",
      value: summary?.rejected,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series4,
      options: options4,
    },
    {
      id: 2,
      title: "Withdraw",
      value: summary?.withdraw,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series5,
      options: options5,
    },
    {
      id: 2,
      title: "Pending",
      value: summary?.pending,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series6,
      options: options6,
    },
    {
      id: 2,
      title: "Profile Completion",
      value: summary?.profileCompletionCount,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series7,
      options: options7,
    },
    {
      id: 2,
      title: "Eligible Student",
      value: summary?.eligibleCount,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series8,
      options: options8,
    },
  ]

  return (
    <React.Fragment>
      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>
                    {modalData?.jobDetails?.name}
                  </td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  {/* <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{recruiterDetails?.name}</td> */}
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>{modalData?.uniqueId}</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.name}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.mobile}
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.email}
                  </td>
                </tr>
                {tab3 === 4 && (
                  <tr>
                    <th style={{ textAlign: "left", padding: "10px" }}>
                      Placed Date
                    </th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px" }}>
                      {moment(modalData?.placedDate).format("DD-MM-YYYY")}
                    </td>
                    <th style={{ textAlign: "left" }}>Placed Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                      {moment(modalData?.placedTime, "HH:mm:ss").format(
                        "hh:mm A",
                      )}{" "}
                    </td>
                  </tr>
                )}

                {tab3 === 4 && (
                  <tr>
                    <th style={{ textAlign: "left", padding: "10px" }}>
                      Shortlisted Date
                    </th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px" }}>
                      {moment(modalData?.shortlistDate).format("DD-MM-YYYY")}
                    </td>
                    <th style={{ textAlign: "left" }}>Shortlisted Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                      {moment(modalData?.shortlistTime, "HH:mm:ss").format(
                        "hh:mm A",
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.qualificationDetails?.name}
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">
                  Institute - {instituteDetails?.instituteName}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/college">Institute Details</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{instituteDetails?.instituteName}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div
                        className="mt-2"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      ></div>
                      {/* <div className="card-body" style={{ maxHeight: "400px", overflowY: "auto",marginTop:"-12px" }}> */}
                      <div className="float-end dropdown"></div>
                      <h5
                        className="mt-2 mb-2"
                        style={{
                          textAlign: "center",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >
                        {instituteDetails?.instituteName}
                      </h5>
                      <Stack
                        direction="row"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          alt="Imag"
                          src={`${API_URL}/${instituteDetails?.logo}`}
                          style={{
                            width: "5rem",
                            height: "4rem",
                            borderRadius: "5px",
                          }}
                        />
                      </Stack>
                      <div
                        className="text-muted"
                        style={{
                          textAlign: "center",
                          color: "green !important",
                          fontFamily: "IBM Plex Sans,sans-serif",
                          fontSize: "14px",
                          marginBottom: "3px",
                        }}
                      ></div>
                      <Divider />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Institute ID:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.instituteCode}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Institute Code:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.shortCode}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Contact Person:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.contactPerson}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Mobile:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.mobile}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Email:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.email}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Type:</p>
                        <h5 className="font-size-14">
                          {" "}
                          {instituteDetails?.type === 1
                            ? "Private"
                            : instituteDetails?.type === 2
                              ? "Public"
                              : instituteDetails?.type === 3
                                ? "Government"
                                : instituteDetails?.type === 4
                                  ? "Deemed"
                                  : ""}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Address:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.address}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">District:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.district}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">State:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.state}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Designation:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.designations}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Principal:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.principal}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">From:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.from === 1 ? "CRM" : "WEB"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">city:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.city}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Pincode:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.pinCode}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Courses:</p>
                        {instituteDetails?.courses?.[0]?.join(", ")}
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Specializations:</p>
                        {instituteDetails?.specializations?.[0]?.join(", ")}
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">University:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.university}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Website:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.website}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Staff:</p>
                        <h5 className="font-size-14">
                          {instituteDetails?.addedBy}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Students"
                                value="6"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <MiniWidget reports={reports} />
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="dashboardTableId"
                                  responsive
                                  bordered
                                  searching={true}
                                  disableRetreatAfterSorting={true}
                                  data={students}
                                  displayEntries={false}
                                  paging={true}
                                  sortable={false}
                                  onChange={handleChange}

                                />
                                {/* <TablePagination
                                  page={page}
                                  onChange={handleChange}
                                  count={totalPage}
                                /> */}
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ViewInstitute
