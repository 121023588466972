import React, { useEffect, useState } from "react";
import { Container, Row, Col, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import MiniWidget from "./mini-widget";
import { APP_NAME } from "../../../config";
import InternshipAnalyticsChart from "./salesanalytics-chart";
import PieChartBreakdown from "./candidate-piechart";
import { get } from "../../../helpers/api_helper";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { numberToCurrency } from "../../../helpers/functions";

const Dashboard = (props) => {
  document.title = `Dashboard | ${APP_NAME}`;

  const [counts, setCounts] = useState({
    collegeCount: 0,
    companyCount: 0,
    vacancyCount: 0,
    regCandidateCount: 0,
    offerletterCount: 0,
    mappedCandidates: 0,
    groomed: 0,
    profileCompleted: 0,
    shortlisted: 0,
    internshipCompleted: 0,
    rejected: 0
  });

  const [period, setPeriod] = useState("yearly");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  useEffect(() => {
    handleDashboardCounts();
  }, [period, startDate, endDate, selectedYear, selectedMonth]);

  const handlePeriodChange = (e) => {
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);

    if (selectedPeriod !== "custom") {
      setStartDate("");
      setEndDate("");
      setSelectedYear("");
      setSelectedMonth("");
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const buildQueryString = (params) => {
    const queryParams = new URLSearchParams(params).toString();
    return queryParams;
  };

  const handleDashboardCounts = () => {
    const params = { period, startDate, endDate, selectedYear, selectedMonth };
    const queryString = buildQueryString(params);

    get(`dashboard/status?${queryString}`)
      .then((res) => {
        setCounts(res?.data || {});
      })
      .catch((error) => console.error("Error fetching dashboard counts:", error));
  };

  const reports = [
    { id: 1, title: "Total Candidates Registered", value: counts?.candidateCount },
    { id: 2, title: "Profile Completed Candidates", value: counts?.profileCompletedStudents },
    { id: 3, title: "Total Companies Registered", value: counts?.companyCount },
    { id: 4, title: "Total Colleges", value: counts?.collegeCount },
    { id: 5, title: "Total Internship Vacancies", value: counts?.totalVacancies },
    { id: 6, title: "Total Candidates Groomed", value: counts?.totalGroomedCandidates },
    { id: 7, title: "Total Candidates Mapped", value: counts?.totalCandidatesWithKtuTrue },
    { id: 8, title: "Total Shortlisted Candidates", value: counts?.shortlistedCandidates },
    { id: 9, title: "Total Rejected Candidates", value: counts?.rejectedCandidates },
    { id: 10, title: "Candidates Offer Letters Given", value: counts?.totalOfferLettersGiven },
    { id: 11, title: "Internship Completed Candidates", value: counts?.internshipCompletedCandidates },
    { id: 12, title: "Total Fees Collected", value: counts?.totalPayments, prefix: "₹" },
    { id: 13, title: "1st Installment Completed", value: counts?.firstInstallment },
    { id: 14, title: "2nd Installment Completed", value: counts?.secondInstallment },
    { id: 16, title: "Screen Test Attended", value: counts?.screentestAttended }
  ];

  const getFinancialYear = (startYear) => {
    const nextYear = startYear + 1;
    return `${startYear}-${nextYear.toString().slice(-2)}`;
  };

  const currentYear = new Date().getFullYear();
  const financialYears = Array.from(new Array(2), (_, i) => currentYear - i);

  const months = [
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem={`${
              props.name ? props.name + " Dashboard" : "Dashboard"
            }`}
          />

          <AvForm>
            <Row>
              <Col md={4}>
                <Label for="periodSelect">Select Period</Label>
                <AvField
                  type="select"
                  name="period"
                  id="periodSelect"
                  value={period}
                  onChange={handlePeriodChange}
                  required
                >
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                  <option value="custom">Custom</option>
                </AvField>
              </Col>

              {(period === "monthly" || period === "yearly") && (
                <>
                  <Col md={4}>
                    <Label for="yearSelect">Select Year</Label>
                    <AvField
                      type="select"
                      name="year"
                      id="yearSelect"
                      value={selectedYear}
                      onChange={handleYearChange}
                      required
                    >
                      <option value="">Select Year</option>
                      {financialYears.map((year) => (
                        <option key={year} value={year}>
                          {getFinancialYear(year)}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {period === "monthly" && (
                    <Col md={4}>
                      <Label for="monthSelect">Select Month</Label>
                      <AvField
                        type="select"
                        name="month"
                        id="monthSelect"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        required
                      >
                        <option value="">Select Month</option>
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  )}
                </>
              )}

              {period === "custom" && (
                <>
                  <Col md={4}>
                    <Label for="startDate">Start Date</Label>
                    <AvField
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                      required
                    />
                  </Col>

                  <Col md={4}>
                    <Label for="endDate">End Date</Label>
                    <AvField
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                      min={startDate}
                      required
                    />
                  </Col>
                </>
              )}
            </Row>
          </AvForm>

          <Row>
            <h6>Candidate Status</h6>
            <MiniWidget
              reports={reports.filter(
                (report) =>
                  report.id === 1 ||
                  report.id === 2 ||
                  report.id === 6 ||
                  report.id === 7 ||
                  report.id === 8 ||
                  report.id === 10 ||
                  report.id === 11 ||
                  report.id === 13 ||
                  report.id === 14 ||
                  report.id === 15 ||
                  report.id === 16 ||
                  report.id === 12 ||
                  report.id === 9
              )}
            />
          </Row>

          <Row>
            <h6>Company Status</h6>
            <MiniWidget
              reports={reports.filter((report) => report.id === 3 || report.id === 5)}
            />
          </Row>

          <Row>
            <h6>College Status</h6>
            <MiniWidget reports={reports.filter((report) => report.id === 4)} />
          </Row>

          <Row>
            <PieChartBreakdown period={period} startDate={startDate} endDate={endDate}  selectedMonth={selectedMonth}  selectedYear={selectedYear} />
          </Row>
          <InternshipAnalyticsChart />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
