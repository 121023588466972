import React, { useEffect, useRef, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  FormGroup,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"
import queryString from "query-string"
import Swal from "sweetalert2"
import toastr from "toastr"
import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import {
  dateConverter,
  renderTableValue,
  setTitle,
  toTop,
  getDate,
} from "../../../helpers/functions"
import { get, post, put, del } from "../../../helpers/api_helper"

import "./styles.scss"
import SearchField from "../../../components/Common/SearchField"

const CompanyUser = () => {
  setTitle("Company Users")

  const formRef = useRef()
  const filterRef = useRef()

  const todayDate = getDate()

  const statusValues = {
    0: { text: "Active", color: "success" },
    2: { text: "In Active", color: "warning" },
    4: { text: "Rejected", color: "danger" },
  }

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "In Active", value: 2 },
  ]

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [branchOptions, setBranchOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const [modal, setModal] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterStateOptions, setFilterStateOptions] = useState([])
  const [filterDistrictOptions, setFilterDistrictOptions] = useState([])

  const [filterCompanyOptions, setFilterCompanyOptions] = useState([])
  const [filterBranchOptions, setFilterBranchOptions] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleDesignationOptions()

    handleFilterStateOptions()
    handleFilterDistrictOptions()

    handleFilterCompanyOptions()
    handleFilterBranchOptions()
  }, []) // eslint-disable-line

  const handleDesignationOptions = async () => {
    try {
      const response = await get(`options/designation`)
      setDesignationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCompanyOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`options/company?${params}`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleBranchOptions = async (company = "") => {
    try {
      const params = queryString.stringify({ company })
      const response = await get(`options/branch?${params}`)
      setBranchOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCompanyOptions = async () => {
    try {
      const response = await get(`company/user/options/filter/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterBranchOptions = async () => {
    try {
      const response = await get(`company/user/options/filter/branch`)
      setFilterBranchOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterStateOptions = async () => {
    try {
      const response = await get(`company/user/options/filter/state`)
      setFilterStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(
        `company/user/options/filter/district?${params}`,
      )
      setFilterDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/company/user${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)

        item.company = renderTableValue(item?.company?.name)
        item.staff = renderTableValue(item?.addedBy?.name)
        item.name = renderTableValue(item?.name)

        const status = Number(item.status)
        const statusObj = statusValues[status]
        item.status = (
          <Button
            size="sm"
            color={statusObj.color}
            onClick={() => handleStatusChange(item._id, status === 0 ? 2 : 0)}
          >
            {statusObj.text}
          </Button>
        )

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <Link to={`/company/users/${item.uniqueId}`}>
                {" "}
                <i
                  style={{ color: "black" }}
                  className="fas fa-eye eye-icon"
                ></i>
              </Link>
            </Tooltip>
            <Tooltip title="Reset" arrow placement="top">
              <i
                className="fas fa-key"
                style={{ marginRight: "8px", cursor: "pointer" }}
                onClick={() => {
                  setCompanyId(item._id)
                  setModal(true)
                }}
              ></i>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`company/user/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`company/user`, masterObject)
      else response = await post(`company/user`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
    if (name === "company") handleBranchOptions(value)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    console.log(name, value)
    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`company/user/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleUpdate = async item => {
    toTop()
    try {
      const response = await get(`company/user/details/${item._id}`)
      const data = response.data
      setSelectedFields({
        company: data.company,
        branch: data.branch,
        designation: data?.designation,
      })

      setMasterObject({
        ...data,
        id: data?._id,
        branch: data?.branch?.value,
        company: data.company?.value,
        designation: data?.designation?.value,
      })

      handleBranchOptions(data.company?.value)
    } catch (error) {
      console.error(error)
    }
  }

  const reset = () => {
    filterRef.current.reset()
    formRef.current.reset()
    setMasterObject({})
    setSelectedFields({})

    setPage(1)
    setTotalPage(1)
    toTop()
    setSelectFilter({})
    setFilterObject({})
    handleFilterDistrictOptions()
    handleFilterBranchOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "Added By", field: "staff" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  const handlePasswordReset = async event => {
    event.preventDefault()

    if (password !== confirmPassword) {
      toastr.error("Passwords do not match.")
      return
    }

    try {
      console.log("Password reset initiated with values:", {
        companyId,
        newPassword: password,
        confirmPassword,
      })

      const response = await put(`/company-user/reset-password`, {
        companyId,
        newPassword: password,
        confirmPassword,
      })
      setModal(false)
      if (response.status === 200) {
        toastr.success("Password has been reset successfully.")
        setModal(false)
      } else {
        toastr.error(response.data.message || "Error resetting password.")
      }
    } catch (error) {}
  }
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Company User" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md="3">
                  <Label>
                    Company<span className="required">*</span>
                  </Label>
                  <Select
                    menuPosition="fixed"
                    options={companyOptions}
                    value={selectedFields.company || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "company")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>
                    Branch<span className="required">*</span>
                  </Label>
                  <Select
                    menuPosition="fixed"
                    options={branchOptions}
                    isDisabled={!masterObject.company}
                    value={selectedFields.branch || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "branch")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>
                    First Name<span className="required">*</span>
                  </Label>
                  <AvField
                    name="firstName"
                    placeholder="First Name"
                    errorMessage="Enter First Name"
                    validate={{ required: { value: true } }}
                    value={masterObject.firstName || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>Last Name</Label>
                  <AvField
                    name="lastName"
                    placeholder="Last Name"
                    value={masterObject.lastName || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>
                    Mobile<span className="required">*</span>
                  </Label>
                  <AvField
                    name="mobile"
                    placeholder="Mobile"
                    type="text"
                    errorMessage="Enter Mobile"
                    value={masterObject.mobile || ""}
                    validate={{
                      required: { value: true },
                      pattern: { value: "(0|91)?[6-9][0-9]{9}" },
                    }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>
                    Email<span className="required">*</span>
                  </Label>
                  <AvField
                    name="email"
                    placeholder="Email"
                    type="text"
                    errorMessage="Enter Email"
                    value={masterObject.email || ""}
                    validate={{ email: true, required: { value: true } }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>Employee ID</Label>
                  <AvField
                    name="employeeId"
                    placeholder="Employee ID"
                    type="text"
                    errorMessage="Enter Employee ID"
                    value={masterObject.employeeId || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>
                      Designation<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={designationOptions}
                      value={selectedFields.designation || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "designation")
                      }
                    />
                  </div>
                </Col>
                <Col md="3">
                  <Label>Address</Label>
                  <AvField
                    name="address"
                    placeholder="Address"
                    type="text"
                    errorMessage="Enter Address"
                    value={masterObject.address || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3" style={{ marginTop: "26px" }}>
                  <div className="d-flex gap-3">
                    <Button color={masterObject.id ? "warning" : "primary"}>
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCompanyOptions}
                    value={selectFilter.company || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "company" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Branch</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterBranchOptions}
                    value={selectFilter.branch || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "branch" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>State</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterStateOptions}
                    value={selectFilter.state || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "state" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>District</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterDistrictOptions}
                    value={selectFilter.district || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "district" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    menuPosition="fixed"
                    options={statusOptions}
                    value={selectFilter.status || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "status" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name, short code, mobile"
                  />
                </Col>
                <Col md={2} style={{ marginTop: "26px" }}>
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="companyUserTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => setModal(false)}
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <AvForm
          className="needs-validation"
          onValidSubmit={handlePasswordReset}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="my-3 position-relative">
                  <AvField
                    name="password"
                    placeholder="New Password"
                    type="password"
                    errorMessage="Password must be at least 8 characters long, include lowercase, uppercase, a digit, and a special character."
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: passwordPattern,
                        errorMessage:
                          "Password must be at least 8 characters long, include lowercase, uppercase, a digit, and a special character.",
                      },
                    }}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="my-3 position-relative">
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage="Please confirm the password"
                    validate={{
                      required: { value: true },
                      match: {
                        value: "password",
                        errorMessage: "Passwords do not match",
                      },
                    }}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="dark" onClick={() => setModal(false)}>
              Close
            </Button>
            <Button color="primary" type="submit">
              Confirm
            </Button>
          </div>
        </AvForm>
      </Modal>
    </div>
  )
}

export default CompanyUser
