import { MDBDataTable } from "mdbreact";
import React, { useState } from "react";
import { Row, Col } from "reactstrap";

function Certification(props) {
  // Sample data for testing
  const [selectedenrolled, setselectedEnrolled] = useState([]);

  // Configuration for MDBDataTable
  const certificates = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "Issue Date",
        field: "issueDate",
        width: 100,
      },
      {
        label: "Type",
        field: "type",
        width: 100,
      },
      {
        label: "Published By",
        field: "addedBy",
        width: 100,
      },
      {
        label: "Certificate",
        field: "certificate",
        width: 150,
      },
    ],
    rows: selectedenrolled,
  };

  return (
    <>
      <Row>
        <Col xl="12">
          <MDBDataTable
            id="certificationTableId"
            className="certification-api"
            responsive
            bordered
            data={certificates}
            info={true}
            searching={true}
            disableRetreatAfterSorting={true}
            entries={20}
          />
        </Col>
      </Row>
    </>
  );
}

export default Certification;
