import React, { useState,useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { Divider } from "@mui/material";
import { get } from "../../../helpers/api_helper";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { API_URL } from "../../../config";



const TestTypeOptions = 
{
1: "KTU",
 2: "Specialization", 
 3: "Internship", 

}

const StatusValues = 
{
0: "Not Started",
 1: "Ongoing", 
 2: "Completed", 
 3:"Terminated",
 4:"Absent"

}

const ResultValues={
  0:"Passed",
  1:"Failed",
  2:"Retake"
}


const MarkAssessment = ({ candidateId }) => {
  const [selectedEnrolled, setSelectedEnrolled] = useState([
  ]);
  const [popupView, setPopupView] = useState(false); 
  const [modalData, setModalData] = useState(null); 

  const [activeTab, setActiveTab] = useState(null);
  const [assessmentMarks, setAssessmentMarks] = useState([]);

  const toggleTab = (tab) => {
    setActiveTab((prevTab) => (prevTab === tab ? null : tab));
  };
  


  useEffect(() => {
    handleTableData();
  }, [candidateId]);

  

  function handleTableData() {
    get(`candidate/screentest?candidate=${candidateId}`)
    
      .then(res => {
        const result = res?.data || [];
        console.log("Fetched data:", result);
        setAssessmentMarks(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }


  const rows = assessmentMarks?.map((test, index) => ({
    id: index + 1,
    date: moment(test.date).format("DD-MM-YYYY"),
    uniqueId: test.uniqueId,
    name: test.screeningTest?.name,

     type: TestTypeOptions[test.type] || "",
  status1: StatusValues[test?.status] || "",
  general:test.markObtained?.general,
  specialization:test.markObtained?.specialization,
  result:ResultValues[test?.status] || "",


    options: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          className="fas fa-eye"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            setPopupView(true); 
            setModalData(test); 
          }}
        ></i>
        {test?.offerLetter ? (
          <Tooltip title="Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${test.offerLetter?.company}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-file-pdf pdf-icon" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
       


      </div>
    ),
  }));



  const certificates = {
    columns: [
      { label: "#", field: "id", width: 50 },
      { label: "Date", field: "date", width: 50 },
      { label: "Type", field: "type", width: 50 },
      { label: "Name", field: "name", width: 50 },
      { label: "Status", field: "status1", width: 50 },
      { label: "General", field: "general", width: 50 },
      { label: "Specialization", field: "specialization", width: 50 },
      { label: "Result", field: "result", width: 50 },


    ],
    rows: rows,
  };

  return (
    <Row>
      <Col xl="12">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: activeTab !== 1,
                })}
                type="button"
                onClick={() => toggleTab(1)}
              >
                <h6>Screening Test</h6>
              </button>
            </h2>
            <Collapse isOpen={activeTab === 1} className="accordion-collapse">
              <div className="accordion-body">
                <Row>
                  <Col md="12">
                    <MDBDataTable
                      id="marksTableId"
                      className="marks-assessment"
                      responsive
                      bordered
                      data={certificates}
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>

          {/* {assessmentMarks.length > 0 && ( */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 2,
                  })}
                  type="button"
                  onClick={() => toggleTab(2)}
                >
                  <h6>Assessment Report</h6>
                </button>
              </h2>
              <Collapse isOpen={activeTab === 2} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {assessmentMarks?.map((item, index) => (
                      <Col lg="4" sm="6" xs="12" key={index}>
                        <Card>
                          <CardBody>
                            <h5>{item.course?.name}</h5>
                            <p>{item.branch?.name}</p>
                            <Divider className="mb-2" />
                            <Row className="mb-2">
                              <Col sm="12">
                                <h6>Internal Marks</h6>
                              </Col>
                              {item.internalMarks?.map((subItem, subIndex) => (
                                <React.Fragment key={subIndex}>
                                  <Col
                                    md="10"
                                    className="d-flex justify-content-between"
                                  >
                                    <span>{`Test ${subIndex + 1}`}</span>
                                    {`${subItem.mark} ${
                                      subItem.examType === 1 &&
                                      `/ ${subItem.maxMark}`
                                    }`}
                                  </Col>
                                  <Col sm="12">
                                    <Divider />
                                  </Col>
                                </React.Fragment>
                              ))}
                            </Row>
                            <h6>Final Assessment</h6>
                            <Row>
                              <Col md="4">
                                <span>{`Grade: ${item.finalGrade}`}</span>
                              </Col>
                              <Col md="8">
                                <span style={{ textAlign: "right" }}>
                                  {`Date: ${item.finalAssessmentDate}`}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Collapse>
            </div>
          {/* )} */}
        </div>
      </Col>
    </Row>
  );
};

export default MarkAssessment;
