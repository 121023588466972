import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../config"
import { get } from "../../helpers/api_helper"
import {
  dateConverter,
  numberToString,
  timeConverter,
} from "../../helpers/functions"
import "./styles.scss"
import { FORWARD, INTERNSHIP_TYPE } from "./Applications/helper"

const Details = ({ id, isOpen, onToggle }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (id && isOpen) fetchData()
    else setData(null)
  }, [id, isOpen]) // eslint-disable-line

  const fetchData = async () => {
    try {
      const response = await get(`internship/applications/details?id=${id}`)
      const data = response.data

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.staff =
        data.from === 1
          ? `${data.collegeUser?.name} - Institute`
          : data.from === 3
            ? `${data.addedBy?.name} - Staff`
            : `Candidate Login`

      data.isKtu = data.type === INTERNSHIP_TYPE.KTU
      if (!data.isKtu) {
        data.isForwarded = data.forwarded?.status === FORWARD.FORWARDED
        data.forwardedDate = dateConverter(data.forwarded?.date)
        data.forwardedTime = timeConverter(data.forwarded?.time)
      }

      setData(data)
    } catch (error) {
      console.error(error)
      onToggle()
    }
  }

  const getTypeLabel = type =>
    ({
      1: "KTU",
      2: "General",
      3: "Public sector",
    })[type] || ""

  const STATUS = {
    0: "Applied",
    2: "Shortlisted",
    3: "Placed",
    4: "Rejected",
    5: "Rejected",
    6: "Withdrawn",
    7: "Withdrawn Pending",
    9: "Completed",
    10: "Pending",
  }

  return (
    <Modal isOpen={isOpen} centered toggle={onToggle} size="lg">
      <ModalHeader toggle={onToggle}>Application Details</ModalHeader>
      <ModalBody>
        {data ? (
          <div className="details-container">
            <div className="section">
              <h5>Basic Information</h5>
              <DataField label="Application ID" value={data.uniqueId} />
              <DataField label="Type" value={getTypeLabel(data.type)} />
              <DataField label="Status" value={STATUS[data.status]} />
              <DataField
                label="Forwarded"
                value={data.isForwarded ? "Forwarded" : "Not Forwarded"}
                hidden={!data.isKtu || !data.isForwarded}
              />
              <DataField
                label="Forwarded Date"
                value={data.forwardedDate}
                hidden={!data.isKtu || !data.isForwarded}
              />
              <DataField
                label="Forwarded Time"
                value={data.forwardedTime}
                hidden={!data.isKtu || !data.isForwarded}
              />

              <DataField label="Date" value={data.date} />
              <DataField label="Time" value={data.time} />
              <DataField label="Staff" value={data.staff} />
            </div>
            <div className="section">
              <h5>Candidate Details</h5>
              <DataField label="Name" value={data.candidate?.name} />
              <DataField label="Id" value={data.candidate?.uniqueId} />
              <DataField label="Mobile" value={data.candidate?.mobile} />
              <DataField label="Email" value={data.candidate?.email} />
              <DataField label="Institute" value={data.college?.name} />
              <DataField
                label="Education"
                value={data.candidate?.education?.program?.name}
              />
            </div>
            <div className="section">
              <h5>Internship Details</h5>
              <DataField label="Company" value={data.company?.name} />
              <DataField label="Internship" value={data.internship?.name} />
              <RenderNumber
                label="Total Working Days"
                value={data.totalWorking}
              />
              <RenderNumber label="Attended Days" value={data.attendance} />
              <RenderNumber label="Stipend" value={data.stipend} />
              <RenderNumber
                label="Provided Stipend"
                value={data.providedStipend}
              />
            </div>
            <div className="section">
              <h5>Documents</h5>
              <ResumeLinks resume={data.resume} />
              <RenderReport label="Report" value={data.report} />
              <RenderReport label="Completion Report" value={data.completion} />
            </div>
            <div className="section">
              <h5>Remarks</h5>
              <DataField label="Remarks" value={data.remarks} />
            </div>
          </div>
        ) : (
          <div className="loading-spinner">
            <Spinner color="primary" />
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default Details

const DataField = ({ label, value, hidden }) => {
  if (hidden) return

  return (
    <div className="data-field">
      <span className="label">{label}</span>
      <span className="value">: {value || "-"}</span>
    </div>
  )
}

const RenderNumber = ({ label, value }) =>
  value && <DataField label={label} value={numberToString(value)} />

const RenderReport = ({ label, value }) =>
  value && (
    <DataField
      label={label}
      value={
        <Tooltip title={label} arrow placement="top">
          <a href={`${API_URL}${value}`} target="_blank" rel="noreferrer">
            <i className="fas fa-file-pdf pdf-icon"></i>
          </a>
        </Tooltip>
      }
    />
  )

const ResumeLinks = ({ resume }) =>
  resume && (
    <DataField
      label="Resume"
      value={
        <>
          {resume.generated && (
            <Tooltip title="Generated Resume" arrow placement="top">
              <a
                href={`${API_URL}${resume.generated}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-pdf pdf-icon"></i>
              </a>
            </Tooltip>
          )}
          {resume.uploaded && (
            <Tooltip title="Uploaded Resume" arrow placement="top">
              <a
                href={`${API_URL}${resume.uploaded}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-pdf pdf-icon"></i>
              </a>
            </Tooltip>
          )}
          {resume.video && (
            <Tooltip title="Video Resume" arrow placement="top">
              <a
                href={`${API_URL}${resume.video}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-video video-icon"></i>
              </a>
            </Tooltip>
          )}
        </>
      }
    />
  )
