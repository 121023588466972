import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  dateConverter,
  getDate,
  renderTableValue,
  setTitle,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"

import "./styles.scss"

export const EmailSubscription = () => {
  setTitle("Email Subscription")

  const todayDate = getDate()

  const statusOptions = [
    { label: "Verified", value: true },
    { label: "Not Verified", value: false },
  ]

  const statusValues = {
    0: { text: "Verified", color: "success" },
    1: { text: "Not Verified", color: "danger" },
  }

  const formRef = useRef()

  const limit = 100

  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  useEffect(() => {
    setPage(1)
    handleTableData(1)
  }, [masterObject]) //eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`
      const response = await get(`email-subscription/list?${query}`)
      const { count, data } = response
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.name = renderTableValue(item.candidate?.name)
        item.candidateId = renderTableValue(item.candidate?.uniqueId)

        const status = statusValues[item.verified ? 0 : 1]

        item.status = (
          <Button size="sm" disabled color={status?.color}>
            {status?.text}
          </Button>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected?.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()
    toTop()

    setMasterObject({})
    setSelectedFields({})

    setTotalCount(0)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Email", field: "email" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Name", field: "name" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Email Subscription" />
        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={masterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={masterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={masterObject.from}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    options={statusOptions}
                    value={selectedFields.verified || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "verified" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={2} className="form-button">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="emailSubscription"
              count={totalPage}
              totalCount={totalCount}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
