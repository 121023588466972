import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"
import { Link } from "react-router-dom"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import {
  dateConverter,
  getDate,
  handleImageValidation,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"
import { del, get, post, put } from "../../../helpers/api_helper"
import "./styles.scss"
import FileUpload from "../../../components/Common/FileUpload"
import SearchField from "../../../components/Common/SearchField"

const Branch = props => {
  setTitle("Branch")
  const todayDate = getDate()

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "In Active", value: 2 },
  ]

  const statusValues = {
    0: { text: "Active", color: "success" },
    2: { text: "In Active", color: "warning" },
    4: { text: "Rejected", color: "danger" },
  }

  const formRef = useRef()
  const filterRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [companyOptions, setCompanyOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterStateOptions, setFilterStateOptions] = useState([])
  const [filterDistrictOptions, setFilterDistrictOptions] = useState([])

  const [filterCompanyOptions, setFilterCompanyOptions] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleCompanyOptions()
    handleDesignationOptions()

    handleFilterStateOptions()
    handleFilterDistrictOptions()

    handleFilterCompanyOptions()
  }, []) // eslint-disable-line

  const handleCompanyOptions = async () => {
    try {
      const response = await get(`options/company`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const response = await get(`options/districts?state=${state}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDesignationOptions = async () => {
    try {
      const response = await get(`options/designation`)
      setDesignationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCompanyOptions = async () => {
    try {
      const response = await get(`branch/options/filter/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterStateOptions = async () => {
    try {
      const response = await get(`branch/options/filter/state`)
      setFilterStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`branch/options/filter/district?${params}`)
      setFilterDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`

      const response = await get(`branch${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.company = renderTableValue(item.company?.name)
        item.staff = renderTableValue(item.updatedBy?.name)
        item.status = getStatus(item._id, item.status)

        item.shortCode = renderTableValue(item.shortCode)
        item.contactPerson = renderTableValue(item.contactPerson)
        item.mobile = renderTableValue(item.mobile)

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <Link to={`/branch/${item.uniqueId}`}>
                {" "}
                <i
                  style={{ color: "rgb(52, 55, 71)" }}
                  className="fas fa-eye eye-icon"
                ></i>
              </Link>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`branch`, masterObject)
      else response = await post(`branch`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`/branch/update/${id}`)

      const data = response.data

      setMasterObject({
        ...data,
        id,
        state: data?.state?.value,
        district: data?.district?.value,
        designation: data?.designation?.value,
        company: data?.company?.value,
      })
      setSelectedFields({
        state: data.state,
        district: data.district,
        designation: data?.designation,
        company: data?.company,
      })

      handleDistrictOptions(data?.state?.value)
      console.log(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`branch/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`branch/status`, { id, status })
      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      await handleImageValidation(selectedFile)
      const fd = new FormData()
      fd.append("logo", e.target.files[0])
      const response = await post(`branch/logo`, fd)
      handleValueChange({
        value: response.data.new_filename,
        name: e.target.name,
      })
    } catch (error) {
      toastr.error(error.response.data.message)
      e.target.value = ""
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }

    setMasterObject(obj)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    console.log(name, value)
    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const getStatus = (id, status) => {
    if (status === 3) {
      return (
        <Select
          menuPosition="fixed"
          options={[
            { label: "Approve", value: 0 },
            { label: "Reject", value: 4 },
          ]}
          onChange={selected => handleStatusChange(id, selected.value)}
        />
      )
    } else {
      const statusObj = statusValues[status]
      return (
        <Button
          size="sm"
          color={statusObj.color}
          onClick={() => handleStatusChange(id, status === 0 ? 2 : 0)}
          disabled={status === 4}
        >
          {statusObj.text}
        </Button>
      )
    }
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()

    setMasterObject({})
    setSelectedFields({})

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
    handleFilterDistrictOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Id", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Contact Person", field: "contactPerson" },
      { label: "Mobile", field: "mobile" },
      { label: "Status", field: "status" },
      { label: "Staff", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Branch" />

          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                onValidSubmit={handleSubmit}
                id="branchForm"
              >
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Company<span className="required">*</span>
                      </Label>
                      <Select
                        menuPosition="fixed"
                        options={companyOptions}
                        value={selectedFields.company || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "company")
                        }
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Name<span className="required">*</span>
                      </Label>
                      <AvField
                        name="name"
                        value={masterObject.name || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter Name"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Short Code</Label>
                      <AvField
                        name="shortCode"
                        value={masterObject.shortCode || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Short Code"
                        type="text"
                        errorMessage="Enter Short code"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Contact Person<span className="required">*</span>
                      </Label>
                      <AvField
                        name="contactPerson"
                        value={masterObject.contactPerson || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Contact Person"
                        type="text"
                        errorMessage="Enter Contact Person"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Designation<span className="required">*</span>
                      </Label>
                      <Select
                        menuPosition="fixed"
                        options={designationOptions}
                        value={selectedFields.designation || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "designation")
                        }
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Mobile No.<span className="required">*</span>
                      </Label>
                      <AvField
                        name="mobile"
                        value={masterObject.mobile || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Mobile No."
                        type="text"
                        errorMessage="Enter valid mobile no"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "(0|91)?[6-9][0-9]{9}",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>
                        Email<span className="required">*</span>
                      </Label>
                      <AvField
                        name="email"
                        value={masterObject.email || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Email"
                        type="email"
                        errorMessage=" Please provide a valid Email."
                        validate={{ email: true, required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Landline No.</Label>
                      <AvField
                        name="landLine"
                        value={masterObject.landLine || ""}
                        onChange={e => handleValueChange(e.target)}
                        placeholder="Landline No."
                        type="text"
                        errorMessage="Enter valid 11 digit number"
                        validate={{ pattern: { value: "^[0-9]{11}$" } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Website</Label>
                      <AvField
                        name="website"
                        placeholder="Website"
                        type="text"
                        value={masterObject?.website || ""}
                        onChange={e => handleValueChange(e.target)}
                        validate={{
                          pattern: {
                            value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/,
                            errorMessage:
                              "Enter a valid URL (e.g. https://example.com)",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <Label>
                      State<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={stateOptions}
                      value={selectedFields.state || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "state")
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Label>
                      District<span className="required">*</span>
                    </Label>
                    <Select
                      menuPosition="fixed"
                      options={districtOptions}
                      isDisabled={!masterObject.state}
                      value={selectedFields.district || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "district")
                      }
                    />
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Address</Label>
                      <AvField
                        name="address"
                        placeholder="Address"
                        type="text"
                        value={masterObject?.address || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Pin code</Label>
                      <AvField
                        name="pinCode"
                        placeholder="Pin code"
                        type="text"
                        validate={{
                          pattern: {
                            value: "^[0-9]{6}$",
                            errorMessage: "Enter a valid pin code",
                          },
                        }}
                        value={masterObject?.pinCode || ""}
                        onChange={e => handleValueChange(e.target)}
                        min={0}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label>Latitude</Label>
                      <AvField
                        name="latitude"
                        placeholder="Latitude"
                        type="number"
                        value={masterObject?.latitude || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Longitude</Label>
                      <AvField
                        name="longitude"
                        placeholder="Longitude"
                        type="number"
                        value={masterObject?.longitude || ""}
                        onChange={e => handleValueChange(e.target)}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <FileUpload
                      label="Logo"
                      imageSize="250x140"
                      name="logo"
                      fileUrl={masterObject?.logo}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "logo" })
                      }
                    />
                  </Col>

                  <Col md={2} className="d-flex align-items-center mt-2 pt-2">
                    <Button
                      color={masterObject?.id ? "warning" : "primary"}
                      className="me-2"
                      type="submit"
                    >
                      {masterObject?.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.from}
                      max={todayDate}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Company</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterCompanyOptions}
                      value={selectFilter.company || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "company" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>State</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterStateOptions}
                      value={selectFilter.state || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "state" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>District</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterDistrictOptions}
                      value={selectFilter.district || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "district" })
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Status</Label>
                    <Select
                      menuPosition="fixed"
                      options={statusOptions}
                      value={selectFilter.status || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "status" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search name, short code, mobile"
                    />
                  </Col>
                  <Col md={2} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="branchTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Branch
