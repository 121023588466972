import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { dateConverter } from "../../../helpers/functions"
import Select from "react-select"
import { Tooltip } from "@mui/material"
// import { API_URL } from "../../config";
import { del, get, post, put } from "../../../helpers/api_helper"
import TablePagination from "../../../components/Common/TablePagination"
import Swal from "sweetalert2"
import toastr from "toastr"

const AddInterview = () => {
  const formRef = useRef()
  const [masterObject, setMasterObject] = useState({})
  const [tableData, setTableData] = useState([])
  const [DropDownData, setDropDownData] = useState([])
  const [JobOptions, setJobOptions] = useState([])
  const [InterviewOptions, setInterviewOptions] = useState([])
  const [selectedFields, setSelectedFields] = useState({})
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedType, setSelectedType] = useState('');
  const [filterobj, setfilterobj] = useState({
    search: "",
    type: ""
  })  


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })



  const [filteredData, setFilteredData] = useState([])


  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)



  const typeOptions=[
    { label: "Internship", value:1 },
    { label: "Job", value: 2 },
  ]

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await get(`options/internship`)
        setDropDownData(result.data)
      } catch (err) {
        toastr.error("Failed to fetch data")
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getJob = async () => {
      try {
        const result = await get(`options/job`)
        setJobOptions(result.data)
      } catch (err) {
        toastr.error("Failed to fetch data")
      }
    }
    getJob()
  }, [])




  useEffect(() => {
    handleTableData();
  }, [filterobj]);
  

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&search=${filterobj.search || ""}&type=${filterobj.type || ""}`;
      const response = await get(`/interview${query}`);
      console.log("res",response);
      
      if (response && response.data) {
        const { data, count } = response;
        const formattedData = data.map((item, index) => ({
          ...item,
          id: (currentPage - 1) * limit + index + 1,
          date: dateConverter(item.date),
          type: item.type === 1 ? "Internship" : "Job",
          typeName: item.internship?.name || item.job?.name || "",
          staff: item.addedBy?.name || "",
          options: (
            <div>
              <Tooltip title="Edit" arrow placement="top">
                <i className="fas fa-edit edit-icon" onClick={() => handleUpdate(item._id)}></i>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <i className="fas fa-trash-alt delete-icon" onClick={() => handleDelete(item._id)}></i>
              </Tooltip>
            </div>
          ),
        }));
        setTotalPage(Math.ceil(Number(count) / limit));
        setTableData(formattedData);
        setFilteredData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  

  

  
  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearchQuery(value)
    setfilterobj({ ...filterobj, [name]: value })
  }

  const handleValueChange = ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    setfilterobj({ ...filterobj, [name]: selected.value })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Type", field: "type" },
      { label: "Internship/Job Name", field: "typeName" },
      { label: "Interview Name", field: "name" },
      { label: "Total Mark", field: "totalMark" },
      { label: "Description", field: "description" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: filteredData.map(item => ({
      ...item,
      courseName: item.course?.name || "",
    })),
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`interview`, masterObject)
      else response = await post(`interview`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const reset = () => {
    if (formRef.current) {
      formRef.current.reset()
    }
    setMasterObject({})
    setSelectedFields({})
    setSearchQuery("")
    handleTableData(1)
    setPage(1)
    setTotalPage(1)
    setfilterobj({ search: "", type: "" })
    toTop()
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`interview/details?id=${id}`)
      const data = response.data
      setMasterObject({ ...data, id, state: data?.state?.value })
      setSelectedFields({ state: data?.state })
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })

      if (!result.isConfirmed) return

      const response = await del(`interview/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message)
      }
    }
  }



  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Interview" />

        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
              <Col md="3">
        <Label>Select Type<span className="required">*</span></Label>
        <Select
          menuPosition="fixed"
          options={typeOptions}
          value={
            masterObject.type
              ? { label: masterObject.type === 1 ? "Internship" : "Job", value: masterObject.type }
              : null
          }
          onChange={selected =>
            handleValueChange({
              name: "type",
              value: selected.value, 
            })
          }
          placeholder="Select Type"
        />
      </Col>

      {masterObject.type === 1 && (
        <Col md="3">
          <Label>Select Internship<span className="required">*</span></Label>
          <Select
            menuPosition="fixed"
            options={[
              ...(DropDownData?.map(internship => ({
                label: internship.label,
                value: internship._id,
              })) || []),
            ]}
            value={
              masterObject.internship
                ? {
                    label: masterObject.internship?.label,
                    value: masterObject.internship?._id,
                  }
                : null
            }
            onChange={selected => {
              handleValueChange({
                name: "internship",
                value: selected.value
                  ? { _id: selected.value, label: selected.label }
                  : null,
              });
            }}
            placeholder="Select Internship"
          />
        </Col>
      )}

      {masterObject.type === 2 && (
        <Col md="3">
          <Label>Select Job<span className="required">*</span></Label>
          <Select
            menuPosition="fixed"
            options={[
              ...(JobOptions?.map(job => ({
                label: job.label,
                value: job._id,
              })) || []),
            ]}
            value={
              masterObject.job
                ? {
                    label: masterObject.job.label,
                    value: masterObject.job._id,
                  }
                : null
            }
            onChange={selected => {
              handleValueChange({
                name: "job",
                value: selected.value
                  ? { _id: selected.value, label: selected.label }
                  : null,
              });
            }}
            placeholder="Select Job"
          />
        </Col>
      )}

                <Col md="3">
                  <Label>Interview Name<span className="required">*</span></Label>
                  <AvField
                    name="name"
                    placeholder="Enter Interview Name"
                    errorMessage="Please enter the interview name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>Total Mark<span className="required">*</span></Label>
                  <AvField
                    name="totalMark"
                    placeholder="Enter Mark"
                    errorMessage="Please enter the total Mark"
                    validate={{ required: { value: true }, min: { value: 1, errorMessage: 'Mark cannot be zero' } }}
                    type="number"
                    value={masterObject.totalMark || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3">
  <Label>Description</Label>
  <AvField
    name="description"
    placeholder="description"
    type="text"    
    value={masterObject.description || ""}
    onChange={e => handleValueChange(e.target)}
  />
</Col>
              </Row>

              <Row style={{ marginTop: "26px" }}>
                <Col md="6">
                  <div className="d-flex gap-3">
                    <Button color="primary">Submit</Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm>
              <Row>
              <Col md={3}>
                  <Label>Search</Label>
                  <AvField
                    name="search"
                    placeholder="Search interview name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>

                <Col md={3}>
                  <Label>Filter by Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFields.type || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "type")
                    }
                  />
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
  page={page}
  tableId="SMEdetails"
  data={data}
  count={totalPage}
  onChange={handlePageChange}
/>

          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AddInterview
