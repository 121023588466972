import React, { useEffect, useState } from "react"

import { Modal, Card, CardBody, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import ListItem from "../../../components/Common/ListItem"

import {
  dateConverter,
  genderValue,
  jsonToExcel,
  numberToString,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

const BulkUploadDetails = ({ isOpen, onToggle, id }) => {
  const statusValue = {
    0: { text: "Success", color: "success" },
    1: { text: "Failed", color: "danger" },
  }
  const [tableData, setTableData] = useState([])
  const [masterData, setMasterData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [isOpen && id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`bulk-upload/candidate/details?id=${id}`)
      const data = response.data
      const tableData = response?.tableData || []

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.university = data.university?.name
      data.college = data.college?.name
      data.stream = data.stream?.name
      data.staff = data.addedBy?.name

      data.total = numberToString(data.count?.total)
      data.failed = numberToString(data.count?.failed)
      data.success = numberToString(data.count?.success)

      setMasterData(data)

      tableData.map((item, index) => {
        item.id = index + 1

        item.name = renderTableValue(item.name)
        item.email = renderTableValue(item.email)
        item.mobile = renderTableValue(item.mobile)
        item.remarks = renderTableValue(item.remarks)
        item.gender = renderTableValue(genderValue(item.gender))
        item.specialization = renderTableValue(
          item.specialization?.name || item.specializationName,
        )

        const status = statusValue[item.status]
        item.statusButton = (
          <Button size="sm" disabled color={status.color}>
            {status.text}
          </Button>
        )
        return item
      })

      setTableData(tableData)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = () => {
    const exports = []
    tableData.map(item => {
      const exportData = {}

      const status = statusValue[item.status]

      exportData["Name"] = item.name
      exportData["Mobile"] = item.mobile
      exportData["Email"] = item.email
      exportData["Register No"] = item.registerNo
      exportData["CGPA"] = item.cgpa
      exportData["Gender"] = item.gender
      exportData["Specialization"] = item.specialization
      exportData["Status"] = status?.text
      exportData["Remarks"] = item.remarks

      exports.push(exportData)

      return item
    })

    jsonToExcel(exports, `uploaded_candidates`)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "Register No", field: "registerNo" },
      { label: "CGPA", field: "cgpa" },
      { label: "Gender", field: "gender" },
      { label: "Specialization", field: "specialization" },
      { label: "Status", field: "statusButton" },
      { label: "Remarks", field: "remarks" },
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>View Details</h5>
          </div>
          <button
            type="button"
            onClick={onToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ul className="list-details mb-3">
            <ListItem label="Date" value={masterData.date} />
            <ListItem label="Time" value={masterData.time} />
            <ListItem label="University" value={masterData.university} />
            <ListItem label="Institute" value={masterData.college} />
            <ListItem label="Stream" value={masterData.stream} />
            <ListItem label="Total" value={masterData.total} />
            <ListItem label="Success" value={masterData.success} />
            <ListItem label="Failed" value={masterData.failed} />
            <ListItem label="Added By" value={masterData.staff} />
            <ListItem
              label="File"
              value={
                <a href={`${API_URL}` + masterData?.fileName} download>
                  <i className="fa fa-file-excel excel-icon"></i>
                </a>
              }
            />
            <ListItem label="Remarks" value={masterData.remarks} />
          </ul>

          <Card>
            <CardBody>
              {tableData.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Button onClick={handleExport} color="warning">
                    Export
                  </Button>
                </div>
              )}
              <MDBDataTable
                id="bulkUploadDetailsTable"
                responsive
                bordered
                data={data}
                info={false}
                paging={false}
                sortable={false}
                searching={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default BulkUploadDetails
