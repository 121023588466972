import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchSelect from "../../../components/Common/SearchSelect"

import {
  dateConverter,
  renderTableValue,
  setTitle,
  toTop,
  getDate,
} from "../../../helpers/functions"
import { get, post } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import "./styles.scss"
import Details from "../Details"
const InternshipPlacements = () => {
  setTitle("Internship Placements")

  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])
  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [filterObject, setFilterObject] = useState({ status: 3 })
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleInternshipOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/company?${params}`,
      )
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/internship?${params}`,
      )
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`internship/applications/placed?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.internship = renderTableValue(item.internship?.name)
      item.company = renderTableValue(item.company?.name)

      const candidate = item.candidate
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(candidate?.name)

      item.resume = item.resume?.uploaded

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Tooltip title="View" arrow placement="top">
            <i
              className="fas fa-eye eye-icon"
              onClick={() => handleViewModal(item._id, true)}
            ></i>
          </Tooltip>
          {item?.offerLetter?.company ? (
            <Tooltip title="Offer Letter" arrow placement="top">
              <a
                href={`${API_URL}${item.offerLetter?.company}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-pdf pdf-icon" />
              </a>
            </Tooltip>
          ) : (
            ""
          )}
          {item?.offerLetter?.asap ? (
            <Tooltip title="ASAP Offer Letter" arrow placement="top">
              <a
                href={`${API_URL}${item.offerLetter?.asap}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fas fa-file-download asap-offer-letter-icon"
                  style={{ color: "gray" }}
                />
              </a>
            </Tooltip>
          ) : (
            <Tooltip title="Generate ASAP Offer Letter" arrow placement="top">
              <i
                id={`icon-${item._id}`}
                className="fas fa-file-signature generate-icon"
                onClick={() => handleGenerateOfferLetter(item._id)}
              />
            </Tooltip>
          )}
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleGenerateOfferLetter = async id => {
    const iconElement = document.getElementById(`icon-${id}`)

    if (iconElement) {
      iconElement.classList.remove("fa-file-signature", "generate-icon")
      iconElement.classList.add("fa-spinner", "fa-spin")
    }

    try {
      const response = await post(`/internship/offer-letter/generate`, {
        applicationId: id,
      })

      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    } finally {
      if (iconElement) {
        iconElement.classList.remove("fa-spinner", "fa-spin")
        iconElement.classList.add("fa-file-signature", "generate-icon")
      }
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }
  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({ status: 3 })
    setSelectedFields({})
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "internship" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Internship - Placements" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  ></AvField>
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  ></AvField>
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "company" })
                    }}
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    options={internshipOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "internship" })
                    }}
                    value={selectedFields.internship || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="internship/applications/options/candidate"
                  />
                </Col>

                <Col className="form-button">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="internshipPlacementTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      />
    </div>
  )
}

export default InternshipPlacements
