import React, { useEffect, useState } from "react"
import MiniWidget from "./mini-widget"
import { Row } from "reactstrap"
import moment from "moment"
// import "../style.scss"
function Summary(props) {
    const eventid = props.data
    useEffect(() => {
    }, [eventid])

    const [summary, setSummary] = useState([])
    const [bookingData, setbookingData] = useState([])



    console.log("booking", bookingData);
    const series1 = [100]

    const options1 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const series2 = [70]

    const options2 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const series3 = [55]

    const options3 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const reports = [
        {
         id: 1,
        title: "Total Registration Count",
        value: "7",
        decimal: 0,
        charttype: "radialBar",
        chartheight: 45,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        series: series1,
        options: options1,
    },
    {
        id: 2,
       title: "Attended Count",
       value: "7",
       decimal: 0,
       charttype: "radialBar",
       chartheight: 45,
       chartwidth: 45,
       prefix: "",
       suffix: "",
       series: series1,
       options: options1,
   },
   {
    id: 1,
   title: "Percentage",
   value: "7",
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
},
{
    id: 1,
   title: "Total Amount Paid",
   value: "7",
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
},
{
    id: 1,
   title: "Due Amount",
   value: "7",
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
}
]


    return (
        <div>
            <Row>
                <MiniWidget reports={reports} />
            </Row>
            <Row>
                {bookingData?.length > 0 ? (
                    <>
                        <h5>Venue Details</h5>
                        <table className="table table-bordered eventdetails-table">
                            <tr>
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Date</th>
                                <th>End Time</th>
                                <th>Venue</th>
                            </tr>
                            {bookingData?.map((item, index) => (
                                <tr key={index}>
                                    <th>{item?.startDate ? moment(item.startDate).format("DD-MM-YYYY") : "--"}</th>
                                    <th>{item?.startTime ? moment(item.startTime, "HH:mm").format("hh:mm A") : "--"}</th>
                                    <th>{item?.endDate ? moment(item?.endDate).format("DD-MM-YYYY") : "--"}</th>
                                    <th>{item?.endTime ? moment(item?.endTime, "HH:mm").format("hh:mm A") : "--"}</th>
                                    <th>{item?.classroom?.name || "--"}</th>

                                </tr>
                            ))

                            }
                        </table>
                    </>
                ) : null}

            </Row>
        </div>
    )
}

export default Summary
