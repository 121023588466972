import React, { useEffect, useState } from "react"

import { Modal, Card, CardBody, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import ListItem from "../../../components/Common/ListItem"

import {
  dateConverter,
  genderValue,
  numberToString,
  timeConverter,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

const BulkUploadDetails = ({ isOpen, onToggle, id }) => {
  const statusValue = {
    0: { text: "Success", color: "success" },
    1: { text: "Failed", color: "danger" },
  }
  const [tableData, setTableData] = useState([])
  const [masterData, setMasterData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [isOpen && id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`bulk-upload/company/details?id=${id}`)
      const data = response.data
      const tableData = response?.tableData || []

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.staff = data.addedBy?.name

      data.total = numberToString(data.count?.total)
      data.failed = numberToString(data.count?.failed)
      data.success = numberToString(data.count?.success)

      setMasterData(data)

      tableData.map((item, index) => {
        item.id = index + 1

        item.name = item.name || "----"
        item.email = item.email || "----"
        item.mobile = item.mobile || "----"
        item.remarks = item.remarks || "----"
        item.gender = genderValue(item.gender) || "----"

        const status = statusValue[item.status]
        item.status = (
          <Button size="sm" disabled color={status.color}>
            {status.text}
          </Button>
        )
        return item
      })

      setTableData(tableData)
    } catch (error) {
      console.error(error)
    }
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "Contact Person", field: "contactPerson" },
      { label: "CIN No", field: "cinNo" },
      { label: "Status", field: "status" },
      { label: "Remarks", field: "remarks" },
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>View Details</h5>
          </div>
          <button
            type="button"
            onClick={onToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ul className="list-details mb-3">
            <ListItem label="Date" value={masterData.date} />
            <ListItem label="Time" value={masterData.time} />
            <ListItem label="Total" value={masterData.total} />
            <ListItem label="Success" value={masterData.success} />
            <ListItem label="Failed" value={masterData.failed} />
            <ListItem label="Added By" value={masterData.staff} />
            <ListItem
              label="File"
              value={
                <a href={`${API_URL}` + masterData?.fileName} download>
                  <i className="fa fa-file-excel excel-icon"></i>
                </a>
              }
            />
            <ListItem label="Remarks" value={masterData.remarks} />
          </ul>

          <Card>
            <CardBody>
              <MDBDataTable
                id="companyBulkDetailsTable"
                responsive
                bordered
                data={data}
                info={false}
                paging={false}
                sortable={false}
                searching={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default BulkUploadDetails
