import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import "./style.scss";
import { get } from "../../../helpers/api_helper.js";
import { API_URL } from "../../../config"
import moment from "moment";



const Fee = ({ candidateId }) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null); 
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });

  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(':').map(Number);
    let period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes; 

    return `${hours}:${minutes} ${period}`;
  }

  useEffect(() => {
    handleTableData();
  }, [candidateId]);

  useEffect(() => {
    const rows = selectedPayment.length > 0
      ? selectedPayment.map(payment => ({
          date:payment.date? moment(payment.date).format("DD-MM-YYYY"):"",
          time: convertTo12HourFormat(payment.time),
          uniqueId: payment._id,
          orderId: payment.razorpayDetails.orderId,
          amount: `₹${payment.amount}`,
          type: payment.fee.name,
          status: (
            <Button
              style={{ width: "100%" }}
              color={
                payment.status === 0 ? "success" :
                payment.status === 1 ? "danger" :
                payment.status === 2 ? "warning" : "default"
              }
              disabled
            >
              {payment.status === 0 ? "Paid" :
               payment.status === 1 ? "Deleted" :
               payment.status === 2 ? "Pending" : ""}
            </Button>
          ),
          options: (
            <div>
              <i
                className="fas fa-eye"
                onClick={() => handlePopUp(payment)}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
              {payment.receipt && (
                <a href={`${API_URL}${payment.receipt}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                  <i className="uil-file-download" style={{ fontSize: "1.5em", cursor: "pointer" }}></i>
                </a>
              )}
            </div>
          ),
        }))
      : [];
  
    setTableData({
      columns: [
        { label: "Date", field: "date", width: 150 },
        { label: "Time", field: "time", width: 150 },
        { label: "Transaction ID", field: "orderId", width: 150 },
        { label: "Type", field: "type", sort: "asc", width: 400 },
        { label: "Amount", field: "amount", sort: "asc", width: 400 },
        { label: "Status", field: "status", sort: "asc", width: 400 },
        { label: "Action", field: "options", sort: "asc", width: 200 },
      ],
      rows,
    });
  }, [selectedPayment]);
  ;

  function handleTableData() {
    get(`candidate/candidate-fees?candidate=${candidateId}`)
      .then(res => {
        const result = res?.data || [];
        console.log("Fetched data:", result);
        setSelectedPayment(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  const handlePopUp = (paymentDetails) => {
    setModalData(paymentDetails); 
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setModalData(null); 
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={closeModal}
        size="lg"
        centered
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment Details</h5>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {modalData && (
            <div>
              <p><strong>Date:</strong>{moment(modalData.date).format('DD-MM-YYYY')}</p>
              <p><strong>Time:</strong> {convertTo12HourFormat(modalData.time)}</p>
              <p><strong>Transaction ID:</strong> {modalData.razorpayDetails?.orderId}</p>
              <p><strong>Type:</strong> {modalData.fee?.name}</p>
              <p><strong>Amount:</strong> {modalData.amount}</p>
              <p><strong>Details:</strong> {modalData.remarks}</p>
            </div>
          )}
        </div>
      </Modal>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="studentFeePaymentsTable"
            className="mt-3"
            bordered
            responsive
            searching={false}
            entries={20}
            paging={false}
            info={false}
            disableRetreatAfterSorting={true}
            data={tableData.rows.length > 0 ? tableData : { ...tableData, rows: [] }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Fee;
