import React, { useState } from "react"
import { Button, Col, Label, Modal, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import toastr from "toastr"

import { put } from "../../../helpers/api_helper"
import { TYPE_VALUES } from "./helper"

const Approve = ({ id, isOpen, onToggle, feeOptions, typeOptions }) => {
  const [masterObject, setMasterObject] = useState({ status: 1 })
  const [selectedFields, setSelectedFields] = useState({})

  const handleApprove = async () => {
    try {
      const response = await put(`internship/approval`, { ...masterObject, id })
      onToggle()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    setMasterObject(prev => ({ ...prev, [name]: selected.value }))
  }

  const toggle = () => {
    setSelectedFields({})
    setMasterObject({ status: 1 })

    onToggle()
  }

  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-header">
        <h5 className="modal-title">Approve Internship</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={toggle}
        ></button>
      </div>
      <div className="modal-body">
        <AvForm>
          <Row>
            <Col md={6}>
              <Label>
                Type<span className="required">*</span>
              </Label>
              <Select
                name="type"
                menuPosition="fixed"
                options={typeOptions}
                value={selectedFields.type}
                onChange={selected =>
                  handleValueChange({ selected, name: "type" })
                }
              />
            </Col>
            {masterObject.type === TYPE_VALUES.KTU ? (
              <Col md={6}>
                <Label>
                  Fees<span className="required">*</span>
                </Label>
                <Select
                  name="fees"
                  menuPosition="fixed"
                  options={feeOptions}
                  value={selectedFields.fees}
                  onChange={selected =>
                    handleValueChange({ selected, name: "fees" })
                  }
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </AvForm>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleApprove}>
          Approve
        </Button>
        <Button color="light" type="button" onClick={toggle}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default Approve
