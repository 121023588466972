import React, { useState } from 'react'
import MiniWidget from './mini-widget';
import { Container, Row, Col, Label } from "reactstrap";
// import ReactApexChart from "react-apexcharts";
import ReactECharts from 'echarts-for-react';

const Dashboard = () => {
    const [counts, setCounts] = useState({
        collegeCount: 20,
        companyCount: 40,
        vacancyCount: 60,
        regCandidateCount: 70,
        offerletterCount: 10,
        mappedCandidates: 50,
        groomed: 60,
        profileCompleted: 30,
        shortlisted: 90,
        internshipCompleted: 40,
        rejected: 60
    });

    const reports = [
        { id: 1, title: "Total Internships", value: counts?.collegeCount },
        { id: 2, title: "Active Internships", value: counts?.vacancyCount },
        { id: 3, title: "Inactive Internships", value: counts?.companyCount },
        { id: 4, title: "Filled Internships", value: counts?.collegeCount },
        { id: 5, title: "Expired intersnhip ", value: counts?.mappedCandidates },
        { id: 6, title: "Approved internship", value: counts?.internshipCompleted },
        { id: 7, title: "Rejected internship ", value: counts?.rejected },
    ];

    const optionpi1 = {
        title: {
            //   text: 'Main ware house 1',
            subtext: 'Internships',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color: ['#ff6f61', '#6b8e23', '#4682b4'], // Define custom colors
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: 1048, name: 'PSU' },
                    { value: 735, name: 'KTU' },
                    { value: 580, name: 'GENERAL' },
                ],
                label: {
                    show: true,
                    formatter: '{b}: {c} ({d}%)', // Displays name, value, and percentage
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const optionpi2 = {
        title: {
            subtext: 'Interns',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            data: ['Applications', 'Completed', 'Leave/absent', 'Rejected'],
            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    200,
                    150,
                    80,
                    20
                ],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };


    const optionpi3 = {
        title: {
            subtext: 'Applications',
            left: 'center'
        },
        tooltip: {
            trigger: 'item', // Triggered when hovering over a specific item (bar)
            formatter: '{b}: {c}', // Format the tooltip content: {b} is the category name, {c} is the value
        },
        xAxis: {
            type: 'category',
            data: ['Accepted', 'Rejected', 'In-Progress', 'Withdrawn'],
            axisLabel: {
                rotate: 45, // Rotate labels by 45 degrees for better visibility
                interval: 0, // Ensure all labels are displayed, even if they overlap
            },
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    200,
                    150,
                    80,
                    20
                ],
                type: 'bar',
                barWidth: '30%' // Adjust bar width
            }
        ]
    };

    const optionDG = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '',
            left: 'center'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 10,
                        fontWeight: ''
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 484, name: 'Approved' },
                    { value: 300, name: 'Rejected' }
                ]
            }
        ]
    };

    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <Row>
                    <h6></h6>
                    <MiniWidget reports={reports} />
                </Row>
                <Row className='mt-4'>
                    <Col md="6">
                        <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                    </Col>
                    <Col md="6">
                        <ReactECharts option={optionpi2} style={{ height: 400, width: '100%' }} />
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md="6">
                        <h6></h6>
                        <ReactECharts option={optionpi3} style={{ height: 400, width: '100%' }} />
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6">
                                <h6>KTU Internships</h6>
                                <ReactECharts option={optionDG} style={{ height: 200, width: '100%' }} />
                            </Col>
                            <Col md="6">
                                <h6>GENERAL Internships</h6>
                                <ReactECharts option={optionDG} style={{ height: 200, width: '100%' }} />
                            </Col>
                            <Col md="6">
                                <h6>PSU Internships</h6>
                                <ReactECharts option={optionDG} style={{ height: 200, width: '100%' }} />
                            </Col>
                            {/* <Col md="6">
                                <ReactECharts option={optionDG} style={{ height: 200, width: '100%' }} />
                            </Col> */}

                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Dashboard
