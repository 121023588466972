import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import "./style.scss";
import { get } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";

const Jobs = ({ candidateId }) => {
  const [studentInternship, setStudentInternship] = useState([]);
  const [popupView, setPopupView] = useState(false); 
  const [modalData, setModalData] = useState(null); 




   const workTypeOptions = 
    {
    1: "Part Time",
     2: "Full Time", 
     3: "Contract", 
    
    }
 

   const workModeOptions = {
   1:"Remote",
   2:"Hybrid",
   3:"In Person"
   }

   const ScheduleOptions = {
    1:"Remote",
    2:"Hybrid",
    3:"In Person"
    }

  const statusMap = {
    0: "Applied",
    1: "Deleted",
    2: "Shortlisted",
    3: "Placed",
    4: "Rejected from Application",
    5: "Rejected from Shortlist",
    6:"WithDrawn",
    7:"WithDraw Pending",
    8:"WithDraw Rejected",
    9:"Completed",
    10:"Pending"
  };



  useEffect(() => {
    handleTableData();
  }, [candidateId]);

  console.log("id",candidateId);
  

  function handleTableData() {
    get(`candidate/job?candidate=${candidateId}`)
    
      .then(res => {
        const result = res?.data || [];
        console.log("Fetched data:", result);
        setStudentInternship(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  const rows = studentInternship.map((application, index) => ({
    id: index + 1,
    date: moment(application.date).format("DD-MM-YYYY"),
    uniqueId: application.uniqueId,
    name: application.job?.name,
    status1: statusMap[application.status] || "",
    jobType: workTypeOptions[application?.job?.workType] || "",

    options: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          className="fas fa-eye"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            setPopupView(true); 
            setModalData(application); 
          }}
        ></i>
        {application?.offerLetter ? (
          <Tooltip title="Offer Letter" arrow placement="top">
            <a
              href={`${API_URL}${application.offerLetter?.company}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-file-pdf pdf-icon" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
       


      </div>
    ),
  }));

  const jobPosts = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      { label: "Date", field: "date", sort: "asc", width: 400 },
      { label: "Work Type", field: "jobType", sort: "asc", width: 400 },


      { label: "ID", field: "uniqueId", sort: "asc", width: 400 },
      { label: "Name", field: "name", sort: "asc", width: 400 },
      { label: "Status", field: "status1", sort: "asc", width: 200 },
      { label: "Action", field: "options", sort: "asc", width: 200 },
    ],
    rows: rows,
  };

  const closeModal = () => {
    setPopupView(false); 
    setModalData(null); 
  };

  return (
    <>
      <Modal
        show={popupView} 
        onHide={closeModal} 
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Job Details</h5>
          </div>
          <button
            type="button"
            onClick={closeModal} 
            className="close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {modalData && (
             <div className="container">
             <div className="row">
               <div className="col-md-12">
                 <div className="table-responsive">
                   <table className="table table-bordered">
                   <tbody>
  {/* First Column (Left Column) */}
  <tr>
    <td><strong>Date:</strong></td>
    <td>{modalData.date ? moment(modalData.date).format("DD-MM-YYYY") : ""}</td>
    <td><strong>Schedule:</strong></td>
    <td>{ScheduleOptions[modalData?.job?.schedule] || ""}</td>
  </tr>
  <tr>
    <td><strong>ID:</strong></td>
    <td>{modalData.uniqueId || ""}</td>
    <td><strong>work Type:</strong></td>
    <td>{workTypeOptions[modalData?.job?.workType] || ""}</td>
  </tr>
  <tr>
    <td><strong>Name:</strong></td>
    <td>{modalData.job?.name || ""}</td>
    {/* <td><strong>Dummy Field 3:</strong></td>
    <td>Extra information</td> */}
  </tr>
  <tr>
    <td><strong>Recruiter:</strong></td>
    <td>{modalData.company?.name}</td>
    {/* <td><strong>Dummy Field 4:</strong></td>
    <td>Placeholder data</td> */}
  </tr>
  <tr>
    <td><strong>Work Mode:</strong></td>
    <td>{workModeOptions[modalData?.job?.workMode] || ""}</td>
    <td><strong>Status:</strong></td>
    <td>{statusMap[modalData.status] || ""}</td>
  </tr>
</tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
       
          )}
        </div>
      </Modal>

      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="shortlistedJobDataId"
            className="job-applied"
            data={jobPosts}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default Jobs;
