import React from "react"
import { Navigate } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import KtuDashboard from "../pages/Dashboard/CRM/index"

import Candidate from "../pages/Candidate"
import Enroll from "../pages/Enroll"

import CandidateBulkUpload from "../pages/BulkUpload/Candidate"
import CompanyBulkUpload from "../pages/BulkUpload/Company"
import CollegeBulkUpload from "../pages/BulkUpload/College"

import College from "../pages/College"
import CollegeUsers from "../pages/College/Users"
import CollegeRegistrations from "../pages/College/Registrations"
import CollegeDashboard from "../pages/College/Dashboard/CollegeDashboard"

import Company from "../pages/Company"
import CompanyDashboard from "../pages/Company/Dashboard"
import Branch from "../pages/Company/Branch"
import CompanyUsers from "../pages/Company/Users"
import CompanyRegistrations from "../pages/Company/Registrations"

import Education from "../pages/Education"
import Course from "../pages/Course"
import Specialization from "../pages/Specialization"
import University from "../pages/University"

import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"

import JobPost from "../pages/Job/Post"
import JobApplications from "../pages/Job/Applications"
import JobShortlist from "../pages/Job/Shortlisted"
import JobPlacement from "../pages/Job/Placement"
import JobRejected from "../pages/Job/Rejected"

import InternshipPost from "../pages/Internship/Post"
import InternshipApplications from "../pages/Internship/Applications"
import InternshipShortlist from "../pages/Internship/Shortlisted"
import InternshipPlacement from "../pages/Internship/Placement"
import InternshipRejected from "../pages/Internship/Rejected"
import InternshipPostDashboard from "../pages/Internship/Post/Dashboard"

import AddUser from "../pages/User"

import TicketCategory from "../pages/Ticket/TicketCategory"

// Master Settings
import Rules from "../pages/MaterSettings/Rules"
import Modules from "../pages/MaterSettings/Modules"
import Privilege from "../pages/MaterSettings/Privilege"
import CandidateProfile from "../pages/Candidate/CandidateProfile"

import About from "../pages/About"
import AddDesignation from "../pages/Designation"
import AddFee from "../pages/Fee"

import Payments from "../pages/Finance/Payments"
import UserDashboard from "../pages/User/Dashboard"
import ViewJob from "../pages/Job/ViewJob"

// Finance
import AccountHead from "../pages/Finance/AccountHead"
import AccountSubHead from "../pages/Finance/AccountSubHead"
import ChartOfAccount from "../pages/Finance/ChartOfAccount"
import TransactionLog from "../pages/Finance/TransactionLog"
import IncomeReport from "../pages/Finance/Reports/Income"
import ExpenseReport from "../pages/Finance/Reports/Expense"
import IncomeExpenseReport from "../pages/Finance/Reports/IncomeExpense"
import ProfitLoss from "../pages/Finance/Reports/ProfitLoss"

import CollegeUserDashboaard from "../pages/College/Users/CollegeUserDashboard"
import BranchView from "../pages/Company/Branch/BranchView"
import CompanyUsersView from "../pages/Company/Users/CompanyUsersView"

// Reports
import CollegeReports from "../pages/Reports/CollegeReport"
import OfferLetterReports from "../pages/Reports/OfferletterReport"
import CollegeInternshipReports from "../pages/Reports/College-internshipReports"
import CollegeWiseStatus from "../pages/Reports/Collegewise-Status"
import InternshipCompany from "../pages/Reports/InternshipCompany"
import OverallCompanyStatus from "../pages/Reports/Overall-Company-Status"
import CompanyInternshipReports from "../pages/Reports/Company-internshipReports"
import CompanyUserWiseReport from "../pages/Reports/CompanyUserWiseReport"
import InternshipWiseReport from "../pages/Reports/internshipWiseReport"
import CompanyWiseInternshipReport from "../pages/Reports/companyWiseInternshipReport"
import InternshipWithdrawal from "../pages/Internship/Widrawal"
import InternshipCompleted from "../pages/Internship/Completed"

import CandidateReport from "../pages/Reports/Candidate"
import PaymentsReport from "../pages/Reports/Payments"
import CompanyReport from "../pages/Reports/Company"

//Assesment
import AddInterview from "../pages/Assesment/Interview/index"
import AssignCandidate from "../pages/Assesment/Interview/AssignCandidate"
import InterviewDetail from "../pages/Assesment/Interview/InterviewDetails"
// import AddSME from "../pages/Assesment/SME/index"
// import SMEDetail from "../pages/Assesment/SME/SMEDetails"

import Questions from "../pages/ScreeningTest/Questions"
import TestCreation from "../pages/ScreeningTest/TestCreation"
import ScreeningAssign from "../pages/ScreeningTest/AssignTest"
import ResultDetails from "../pages/ScreeningTest/ResultDetails"

import SpecializationReport from "../pages/Reports/SpecializtionwiseReport"
import { EmailSubscription } from "../pages/Website/EmailSubscription"
import Testimonials from "../pages/Website/Testimonials"
import Faq from "../pages/Website/Faq"
import ContactUs from "../pages/ContactUs"
import Category from "../pages/Manage/Category"
import FaqCategory from "../pages/Manage/FaqCategory"
import IncomeExpense from "../pages/Finance/IncomeExpense"
import Addjobfaire from "../pages/JobFaire/Addjobfaire"
import UniversityOrBoard from "../pages/Temporary/UniversityOrBoard"
import InstitutionName from "../pages/Temporary/InstitutionName"
import TestResults from "../pages/ScreeningTest/TestResults"
import JobfaireDetails from "../pages/JobFaire/JobfaireDetails"
import Jobfair from "../pages/JobFaire/Jobfair"
import DashboardInternship from "../pages/Internship/Dashboard/Dashboard"
import Skills from "../pages/Manage/Skills"
import Jobdashboard from "../pages/Job/Dashboard/Jobdashboard"
import ManageCompany from "../pages/JobFaire/ManageCompany"
import MessageLog from "../pages/Security/MessageLog"
import RankList from "../pages/ScreeningTest/RankList"
import FundTransfer from "../pages/Finance/FundTransfer"
import CreditDebit from "../pages/Finance/CreditDebit"
import Credits from "../pages/Finance/Credits"
import JobfairAttended from "../pages/JobFaire/JobfairAttended"
import JobfairShortListed from "../pages/JobFaire/JobfairShortListed"
import JobfairPlaced from "../pages/JobFaire/JobfairPlaced"
import CreateTicket from "../pages/Ticket/ManageTicket"
import JobfairRegistration from "../pages/JobFaire/JobfairRegistration"
import RankListDetails from "../pages/ScreeningTest/RankListDetails"
import TestDetails from "../pages/ScreeningTest/Details"
import AssignTestDetails from "../pages/ScreeningTest/AssignTestDetails"
import AssessmentReport from "../pages/AssessmentReport"
import JobfairJobpost from "../pages/JobFaire/JobfairJobpost"
import Notification from "../pages/Notification"
import JobPostDetail from "../pages/JobFaire/JobPostDetail"
import CampaignCreate from "../pages/campaign/campaignCreate"
import RegistrationDetails from "../pages/JobFaire/RegistrationDetails"

import AddSME from "../pages/Assessment/SME/Create"
import AssignSME from "../pages/Assessment/SME/Assign"
import SmeUpdateScore from "../pages/Assessment/SME/UpdateScore"
// import SMEDetail from "../pages/Assesment/SME/SMEDetails"

const defaultRoute = <Navigate to={sessionStorage.getItem("module_url")} />

const userRoutes = [
  { path: "/ktu-dashboard", component: <KtuDashboard /> },

  { path: "/university", component: <University /> },
  { path: "/candidate", component: <Candidate /> },
  { path: "/enroll", component: <Enroll /> },

  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },

  { path: "/internship/post", component: <InternshipPost /> },
  { path: "/internship/applications", component: <InternshipApplications /> },
  { path: "/internship/shortlists", component: <InternshipShortlist /> },
  { path: "/internship/placements", component: <InternshipPlacement /> },
  { path: "/internship/rejected", component: <InternshipRejected /> },
  { path: "/internship/withdrawal", component: <InternshipWithdrawal /> },
  { path: "/internship/completed", component: <InternshipCompleted /> },
  { path: "/internship-dashboard", component: <DashboardInternship /> },
  {
    path: "/internship/post/:internshipId",
    component: <InternshipPostDashboard />,
  },

  { path: "/job/post", title: "Job Post", component: <JobPost /> },
  { path: "/job/post/:id", component: <ViewJob /> },
  { path: "/job/applications", component: <JobApplications /> },
  { path: "/job/shortlists", component: <JobShortlist /> },
  { path: "/job/placements", component: <JobPlacement /> },
  { path: "/job/rejected", component: <JobRejected /> },

  { path: "/job-post", title: "Job Post", component: <JobPost /> },
  { path: "/job-post/:id", component: <ViewJob /> },
  { path: "/job-applications", component: <JobApplications /> },
  { path: "/job-shortlists", component: <JobShortlist /> },
  { path: "/job-placements", component: <JobPlacement /> },
  { path: "/job-rejected", component: <JobRejected /> },

  { path: "/add-job-fair", component: <Addjobfaire /> },
  { path: "/job-fair-details/:jobfairId", component: <JobfaireDetails /> },
  { path: "/job-fair", component: <Jobfair /> },
  { path: "/job-dashboard", component: <Jobdashboard /> },
  { path: "/job-fair/manage-company", component: <ManageCompany /> },
  { path: "/job-fair/attended", component: <JobfairAttended /> },
  { path: "/job-fair/shortlisted", component: <JobfairShortListed /> },
  { path: "/job-fair/placed", component: <JobfairPlaced /> },
  { path: "/job-fair/registrations", component: <JobfairRegistration /> },
  { path: "/job-fair/registrations", component: <JobfairRegistration /> },
  { path: "/manage-job-fair-post", component: <JobfairJobpost /> },
  { path: "/job-fair/job/details/:jobId", component: <JobPostDetail /> },
  {
    path: "/job-fair/registration/details/:regId",
    component: <RegistrationDetails />,
  },

  { path: "/institute", component: <College /> },
  { path: "/institute/users", component: <CollegeUsers /> },
  { path: "/institute/registrations", component: <CollegeRegistrations /> },
  { path: "/institute/:instituteId", component: <CollegeDashboard /> },
  { path: "/institute/users/:id", component: <CollegeUserDashboaard /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },
  { path: "/company/users", component: <CompanyUsers /> },
  { path: "/company/registrations", component: <CompanyRegistrations /> },
  { path: "/branch/:id", component: <BranchView /> },
  { path: "/company/users/:id", component: <CompanyUsersView /> },
  { path: "/users", component: <AddUser /> },
  { path: "/users/:id", component: <UserDashboard /> },
  { path: "/bulk_upload/candidate", component: <CandidateBulkUpload /> },
  { path: "/bulk_upload/company", component: <CompanyBulkUpload /> },
  { path: "/bulk_upload/institute", component: <CollegeBulkUpload /> },
  { path: "/rules", component: <Rules /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/company/:companyId", component: <CompanyDashboard /> },
  { path: "/branch", component: <Branch /> },
  { path: "/privilege", component: <Privilege /> },
  { path: "/educations", component: <Education /> },
  { path: "/course", component: <Course /> },
  { path: "/specialization", component: <Specialization /> },
  { path: "/candidate-profile/:id", component: <CandidateProfile /> },
  { path: "/designation", component: <AddDesignation /> },
  { path: "/fees", component: <AddFee /> },
  { path: "/create-ticket", component: <CreateTicket /> },
  { path: "/ticket-category", component: <TicketCategory /> },

  { path: "/credits", component: <Credits /> },

  { path: "/account-head", component: <AccountHead /> },
  { path: "/account-sub-head", component: <AccountSubHead /> },
  { path: "/chart-of-account", component: <ChartOfAccount /> },
  { path: "/transactions", component: <TransactionLog /> },
  { path: "/income-report", component: <IncomeReport /> },
  { path: "/campaign-create", component: <CampaignCreate /> },
  { path: "/expense-report", component: <ExpenseReport /> },
  { path: "/income-expense-report", component: <IncomeExpenseReport /> },
  { path: "/profit-loss", component: <ProfitLoss /> },
  { path: "/payments", component: <Payments />, title: "Payments" },
  {
    path: "/income-expense",
    component: <IncomeExpense />,
    title: "Income/Expense",
  },

  { path: "/interview", component: <AddInterview /> },
  { path: "/assign-candidate", component: <AssignCandidate /> },
  { path: "/interview-details", component: <InterviewDetail /> },

  { path: "/sme", component: <AddSME /> },
  { path: "/sme/assign", component: <AssignSME /> },
  { path: "/sme/score", component: <SmeUpdateScore /> },

  { path: "/about", component: <About /> },
  { path: "/reports-institute", component: <CollegeReports /> },
  { path: "/reports-company-user", component: <CompanyUserWiseReport /> },
  { path: "/reports-internship-wise", component: <InternshipWiseReport /> },
  {
    path: "/reports-company-wise-internship",
    component: <CompanyWiseInternshipReport />,
  },
  { path: "/reports-offerletter", component: <OfferLetterReports /> },
  {
    path: "/reports-college-internship",
    component: <CollegeInternshipReports />,
  },
  {
    path: "/reports-company-vacancies",
    component: <CompanyInternshipReports />,
  },
  { path: "/reports-collegewise-status", component: <CollegeWiseStatus /> },
  { path: "/reports-company-internship", component: <InternshipCompany /> },
  {
    path: "/reports-overall-companystatus",
    component: <OverallCompanyStatus />,
  },
  { path: "/reports-specialization", component: <SpecializationReport /> },

  { path: "/reports-payments", component: <PaymentsReport /> },
  {
    path: "/reports/candidate",
    title: "Candidate Report",
    component: <CandidateReport />,
  },
  {
    path: "/reports/company",
    title: "Company Report",
    component: <CompanyReport />,
  },

  {
    path: "/test-creation",
    title: "Test Creation",
    component: <TestCreation />,
  },
  { path: "/questions", component: <Questions />, title: "Add Questions" },
  {
    path: "/assign-test",
    title: "Assign test",
    component: <ScreeningAssign />,
  },
  { path: "/test-results", title: "Test Results", component: <TestResults /> },
  {
    path: "/test-results/:id",
    title: "Results Details",
    component: <ResultDetails />,
  },
  { path: "/rank-list", title: "Rank list", component: <RankList /> },
  {
    path: "/rank-list-results/:id",
    title: "Rank List Details",
    component: <RankListDetails />,
  },
  {
    path: "/test/:id",
    title: "Screening Test Details",
    component: <TestDetails />,
  },
  {
    path: "/assign-test/:id",
    title: "Assign Test Details",
    component: <AssignTestDetails />,
  },

  // Website
  { path: "/email-subscription", component: <EmailSubscription /> },
  { path: "/testimonials", component: <Testimonials /> },
  { path: "/faq", component: <Faq /> },
  { path: "/contact-us", component: <ContactUs /> },

  { path: "/category", component: <Category />, title: "Category" },
  { path: "/faq-category", component: <FaqCategory />, title: "Faq Category" },

  // Temp
  {
    path: "/university-or-board",
    component: <UniversityOrBoard />,
    title: "University Or Board",
  },
  {
    path: "/institution-name",
    component: <InstitutionName />,
    title: "Institution Name",
  },

  { path: "/skills", title: "Skills", component: <Skills /> },
  { path: "/message-log", title: "Message Log", component: <MessageLog /> },
  {
    path: "/fund-transfer",
    title: "Fund Transfer",
    component: <FundTransfer />,
  },
  {
    path: "/credit-debit",
    title: "Credit/Debit",
    component: <CreditDebit />,
  },
  {
    path: "/assessment/report",
    title: "Assessment Report",
    component: <AssessmentReport />,
  },
  {
    path: "/notifications",
    title: "Notification",
    component: <Notification />,
  },
  { path: "/", exact: true, component: defaultRoute },
  { path: "*", component: defaultRoute },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { userRoutes, authRoutes }
