import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchSelect from "../../../components/Common/SearchSelect"
import Icon from "../../../components/Common/Icon"
// import Details from "../Details"

import {
  dateConverter,
  renderTableValue,
  toTop,
  getDate,
} from "../../../helpers/functions"
import { get, post } from "../../../helpers/api_helper"

import "./styles.scss"
import { STATUS } from "../helper"

const JobPlacements = () => {
  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])

  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [filterObject, setFilterObject] = useState({ status: STATUS.PLACED })
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleJobOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/company?${params}`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/job?${params}`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`job/applications/placed?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.job = renderTableValue(item.job?.name)
      item.company = renderTableValue(item.company?.name)

      const candidate = item.candidate
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(candidate?.name)

      item.resume = item.resume?.uploaded

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Icon
            icon="eye"
            title="View"
            onClick={() => handleViewModal(item._id, true)}
          />
          <Icon
            icon="pdf"
            title="Offer Letter"
            href={item.offerLetter?.company}
            hidden={!item.offerLetter?.company}
          />
          <Icon
            icon="pdf"
            title="ASAP Offer Letter"
            href={item.offerLetter?.asap}
            hidden={!item.offerLetter?.asap}
          />

          <Icon
            icon="generate"
            title="Generate ASAP Offer Letter"
            hidden={item.offerLetter?.asap}
            id={`icon-${item._id}`}
            onClick={() => handleGenerateOfferLetter(item._id)}
          />
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleGenerateOfferLetter = async id => {
    const iconElement = document.getElementById(`icon-${id}`)

    if (iconElement) {
      iconElement.classList.remove("fa-file-signature", "generate-icon")
      iconElement.classList.add("fa-spinner", "fa-spin")
    }

    try {
      const response = await post(`/job/applications/offer-letter`, { id })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    } finally {
      if (iconElement) {
        iconElement.classList.remove("fa-spinner", "fa-spin")
        iconElement.classList.add("fa-file-signature", "generate-icon")
      }
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }
  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({ status: STATUS.PLACED })
    setSelectedFields({})
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Job", field: "job" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Job - Placements" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "company" })
                    }}
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={2}>
                  <Label>Job</Label>
                  <Select
                    options={jobOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "job" })
                    }}
                    value={selectedFields.job || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="job/applications/options/candidate"
                  />
                </Col>

                <Col className="form-button">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="jobPlacementTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      {/* <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      /> */}
    </div>
  )
}

export default JobPlacements
