import React, { useEffect, useState } from "react"
import { Button, Modal } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  numberToString,
  timeConverter,
} from "../../../helpers/functions"
import ListItem from "../../../components/Common/ListItem"
import { statusValues } from "./helper"

export default function QuestionDetails({ id, isOpen, onToggle }) {
  const [data, setData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [id, isOpen]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`screening-questions/details?id=${id}`)
      const data = response.data

      const status = Number(data.status)
      const statusObj = statusValues[status]

      setData({
        ...data,

        date: dateConverter(data.date),
        time: timeConverter(data.time),

        staff: data?.addedBy?.name,
        specialization: data?.specialization?.name,

        mark: numberToString(data.mark),

        status: (
          <Button
            size="sm"
            disabled
            style={{
              marginLeft: "0.25rem",
              padding: "0.25rem",
              color: "white",
            }}
            color={statusObj.color}
          >
            {statusObj.text}
          </Button>
        ),
      })
    } catch (error) {
      console.error(error)
      onToggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg" centered={true}>
      <div className="modal-header">
        <div className="modal-title">
          <h5 style={{ marginBottom: "0px" }}>Details</h5>
        </div>
        <button
          type="button"
          onClick={onToggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <ul className="list-details mb-3 screening-questions-details">
          <ListItem label="Date" value={data.date} />
          <ListItem label="Time" value={data.time} />
          <ListItem label="Added By" value={data.staff} />
          <ListItem label="Question Id" value={data.uniqueId} />
          <ListItem label="Mark" value={data.mark} />
          <ListItem label="Status" value={data.status} />
          <ListItem label="Specialization" value={data.specialization} />
        </ul>
        <RenderValue label={"Question"} value={data.question} />
        <RenderValue label={"Option 1"} value={data.option1} />
        <RenderValue label={"Option 2"} value={data.option2} />
        <RenderValue label={"Option 3"} value={data.option3} />
        <RenderValue label={"Option 4"} value={data.option4} />
        <RenderValue label={"Answer"} value={data[`option${data.answer}`]} />
      </div>
    </Modal>
  )
}

const RenderValue = ({ label, value }) => {
  if (!value) return
  return (
    <div className="mb-2">
      <strong>{label} :</strong> {value}
    </div>
  )
}
