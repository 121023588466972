import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useLocation, useNavigate } from "react-router-dom"
import moment from "moment"
import { Select } from "@mui/material"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
import Pagination from "@mui/material/Pagination";
function Batchview() {
    const navigate = useNavigate()
    const location = useLocation();
    const { eventStatus, startDate, endDate } = location.state || {};
    const [cards, setcards] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setlimit] = useState(4)
    const [totalPage, setTotalPage] = useState(1);
    const [inputValue, setinputValue] = useState("")

    useEffect(() => {
        getAllJobfairCard()
    }, [page,inputValue])


    function getAllJobfairCard() {
        get(`job-fair/cards?limit=${limit}&page=${page}&search=${inputValue}`).then((res) => {
            setTotalPage(Math.ceil(res.count / limit));
            setcards(res.data)
        }).catch((err) => {

        })
    }

    const handleChange = (event, value) => {
        setPage(value); // Update the page number
    
      };

      function handleinputChange(e){
        setPage(1)
        setinputValue(e.target.value) 
      }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Job-fair" />
                    <Row className="mb-2">
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="form-inline float-md-end mb-3">
                                <div className="search-box ml-2">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control rounded border-0"
                                            placeholder="Search Jobfiares...."
                                            onChange={handleinputChange}
                                        />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {cards.length > 0 ? (
                            <>
                                {cards.map((item, index) => (
                                    <>
                                        <Col md="6" xl="6">
                                            <Card>
                                                <CardBody
                                                    onClick={() => {
                                                        navigate(`/job-fair-details/${item?.uniqueId}`, {

                                                        })
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <div>
                                                        <div>
                                                            <h5 class="font-size-16 mb-1">
                                                                <a class="text-dark" href="">
                                                                    {item.name}
                                                                    <span
                                                                        style={{ marginLeft: "10px" }}
                                                                        className={`badge font-size-12 ${item.jobfairStatus === 1
                                                                            ? "bg-soft-success"
                                                                            : item.
                                                                                jobfairStatus === 2
                                                                                ? "bg-soft-warning"
                                                                                : item.jobfairStatus === 3
                                                                                    ? "bg-soft-danger"
                                                                                    : ""
                                                                            }`}
                                                                    >
                                                                        {item?.jobfairStatus === 1 ? "Up-Coming" : item.jobfairStatus === 2 ? "Ongoing" : item?.jobfairStatus === 3 ? "Completed" : ""}
                                                                    </span>
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div className="d-flex justify-content-between">
                                                            <div style={{ paddingTop: "10px" }}>
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    {/* <p style={{ fontSize: "14px" }} className="mb-0">
                              Category:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                test 
                              </span>
                            </p> */}
                                                                </div>
                                                                <p style={{ fontSize: "14px" }} className="mb-0">
                                                                    Fee:{" "}
                                                                    <span style={{ fontWeight: "bold" }}>
                                                                        Rs.{item?.fee}
                                                                    </span>
                                                                </p>
                                                                <p style={{ fontSize: "14px" }} className="mb-0">
                                                                    Start Date & Time:{" "}
                                                                    <span style={{ fontWeight: "bold" }}>
                                                                        {moment(item?.startDate).format("DD-MM-YYYY")} , {moment(item?.startTime, "hh mm ss").format("hh:mm A")}
                                                                    </span>
                                                                </p>
                                                                <div>
                                                                    <p style={{ fontSize: "14px" }} className="mb-0">
                                                                        {" "}
                                                                        Venue :{" "}
                                                                        <span style={{ fontWeight: "bold" }}>
                                                                            {item?.venue}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex gap-3">
                                                                    <div>
                                                                        {" "}
                                                                        <p style={{ fontSize: "14px" }} className="mb-0">
                                                                            {" "}
                                                                            Registrations:{" "}
                                                                            <span className="badge bg-success font-size-12 ms-2">
                                                                                {item?.registrations}
                                                                            </span>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                                <p style={{ fontSize: "14px" }} className="mb-0">
                                                                    Status:{" "}
                                                                    <span
                                                                        style={{ marginLeft: "10px" }}
                                                                        className={`badge font-size-12 ${item.status === 0
                                                                            ? "bg-soft-success"
                                                                            : item.
                                                                                jobfairStatus === 1
                                                                                ? "bg-soft-danger" : ""
                                                                            }`}
                                                                    >
                                                                        {item?.status === 0 ? "Active" : item.jobfairStatus === 1 ? "In-Active" : ""}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    style={{ width: "8rem", height: "7.5rem" }}
                                                                    src={`${API_URL}${item?.image}`}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                                ))}

                            </>
                        ) : null}
                        <div className="mt-4 d-flex justify-content-end">
                            <Pagination
                                color="primary"
                                page={page}
                                count={totalPage}
                            onChange={handleChange}
                            />
                        </div>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Batchview
