import React, { useEffect, useState } from "react";
import { Row, Col, Collapse,Modal } from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import moment from "moment";
import { get } from "../../../helpers/api_helper";
import "./style.scss";
import { API_URL } from "../../../config"
import { Tooltip } from "@mui/material"



const Profile = () => {
  const { id } = useParams();
  const [studentDetail, setStudentDetail] = useState([]);
 const [activeTab, setActiveTab] = useState(null);
 const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null); 

  useEffect(() => {
    handleTableData();
    // eslint-disable-next-line
  }, [id]);

  const handleTableData = () => {
    get(`candidate/resume-education?id=${id}`)
      .then((res) => {
        const result = res?.data;
        setStudentDetail(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
    } else {
      setActiveTab(tab);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pursuing";
      case 2:
        return "Completed";
      case 3:
        return "Dropout";
      default:
        return "N/A";
    }
  };
  

  const handlePopUp = (certificateDetails) => {
    setModalData(certificateDetails);
    setModal(true); 
  };

  const closeModal = () => {
    setModal(false);
    setModalData(null);  
  };

  return (
    <div>

<Modal
        isOpen={modal}
        toggle={closeModal}
        size="lg"
        centered
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Certificate Details</h5>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {modalData && (
            <div>
              <p><strong>Document Name:</strong> {modalData.name}</p>
              <p><strong>Issued By:</strong> {modalData.issuedBy}</p>
              <p><strong>ID:</strong>{modalData.id || ""}</p> 
                  <p><strong>URL:</strong>
                      {modalData.url ? (
                        <a href={modalData.url} target="_blank" rel="noreferrer">
                          {modalData.url}
                        </a>
                      ) : (
                        ""
                      )} </p>
                    
                    
                    <div><strong>Valid from:</strong> {modalData.validity.from}</div>
                    <br />
                    {modalData.validity.to !== "null" && modalData.validity.to && (
        <p><strong>Valid To:</strong> {modalData.validity.to}</p>
      )}

      {modalData.validity.doesNotExpire && (
        <p><strong>Valid To:</strong>This certificate does not expire</p>
      )}
  </div>
          )}
        </div>
      </Modal>
      <Row>
        <Col xl={12}>
          <div className="accordion" id="accordionExample">
            {/* Education Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingQualification">
                <button
                  type="button"
                  onClick={() => toggleTab(1)}
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 1,
                  })}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Education</h6>
                </button>
              </h2>
              <Collapse
                id="collapseQualification"
                className="accordion-collapse"
                isOpen={activeTab === 1}
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="12">
                      <table className="table table-bordered dataTable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Program / Degree</th>
                            <th>University</th>
                            <th>College</th>
                            {/* <th>Start Date </th>
                            <th>End Date</th> */}
                            <th>Status</th>
                            <th>CGPA</th>






                          </tr>
                        </thead>
                        <tbody>
                          {studentDetail.education && studentDetail.education.length > 0 ? (
                            studentDetail.education.map((qual, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{qual.program.name || "N/A"}</td>
                                {/* <td>{qual.stream.name || "N/A"}</td> */}
                                <td>{qual.university || "N/A"}</td>
                                <td>{qual.institute || "N/A"}</td>
                                <td>{getStatusText(qual.status)}</td>
                                <td>{qual.percentageOrCGPA || "N/A"}</td>





                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No education details available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>

             {/* Resume Section */}
             <div className="accordion-item">
              <h2 className="accordion-header" id="headingDocuments">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 4,
                  })}
                  type="button"
                  onClick={() => toggleTab(4)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Resume</h6>
                </button>
              </h2>
              <Collapse
                id="collapseDocuments"
                className="accordion-collapse"
                isOpen={activeTab === 4}
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="12">
                      <table className="table table-bordered dataTable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Type</th>
                            {/* <th>File Name</th> */}
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentDetail.resume && studentDetail.resume.uploaded ? (
                            <tr>
                              <td>1</td>
                              <td>{studentDetail.resume?.uploadedDate?moment(studentDetail.resume?.uploadedDate).format("DD-MM-YYYY"):""}</td>
                              <td>Resume</td>
                              {/* <td>{studentDetail.resume.uploadedFileName}</td> */}
                              <td>
                                {/* <a href={`/${studentDetail.resume.uploaded}`} target="_blank" rel="noopener noreferrer">
                                  View
                                </a> */}
                               
            <Tooltip title="Uploaded Resume" arrow placement="top">
              <a
                href={`${API_URL}${studentDetail.resume.uploaded}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-pdf pdf-icon"></i>
              </a>
            </Tooltip>
          
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                No resume available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>


            {/* Experience Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingExperience">
                <button
                  type="button"
                  onClick={() => toggleTab(2)}
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 2,
                  })}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Experience</h6>
                </button>
              </h2>
              <Collapse
                id="collapseExperience"
                className="accordion-collapse"
                isOpen={activeTab === 2}
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="12">
                      <table className="table table-bordered dataTable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Company</th>
                            <th>Designation</th>
                            <th>From Date</th>
                            <th>To Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {studentDetail.work_experience && studentDetail.work_experience.length > 0 ? (
                            studentDetail.work_experience?.map((exp, index) => (
                            <tr>
                              <td>{index+1}</td>
                              <td>{exp.company_name}</td>
                              <td>{exp.designation}</td>
                              <td>{exp.started_date ? moment(exp.started_date).format("DD-MM-YYYY"):""}</td>
                              <td>{exp.end_date? moment(exp.end_date).format("DD-MM-YYYY"):""}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              No Experience details available.
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>

            {/* Career Objective Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingCareerObjective">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 3,
                  })}
                  type="button"
                  onClick={() => toggleTab(3)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Career Objective</h6>
                </button>
              </h2>
              <Collapse
                id="collapseCareerObjective"
                className="accordion-collapse"
                isOpen={activeTab === 3}
              >
                <div className="accordion-body">
                  <p>{studentDetail.career_objective}</p>
                </div>
              </Collapse>
            </div>

           
            {/* Key Skills Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingKeySkills">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 5,
                  })}
                  type="button"
                  onClick={() => toggleTab(5)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Key Skills</h6>
                </button>
              </h2>
              <Collapse
                id="collapseKeySkills"
                className="accordion-collapse"
                isOpen={activeTab === 5}
              >
                <div className="accordion-body">
                  <Row>
                  <Col md="12">
  <ul>
  {studentDetail.skills && studentDetail.skills.length > 0 ? (
                            studentDetail.skills.map((qual, index) => (
          <li key={index}>{qual.skillName}</li>
        ))
      ) : (
        <tr>
          <td colSpan="4" style={{ textAlign: "center" }}>
            No skillName details available.
          </td>
        </tr>
      )}
  </ul>
</Col>
                  </Row>
                </div>
              </Collapse>
            </div>

             {/* Portfolio Section */}
             <div className="accordion-item">
  <h2 className="accordion-header" id="headingPortfolio">
    <button
      type="button"
      onClick={() => toggleTab(6)}
      className={classnames("accordion-button", "fw-medium", {
        collapsed: activeTab !== 6,
      })}
      style={{ cursor: "pointer" }}
    >
      <h6>Portfolio</h6>
    </button>
  </h2>
  <Collapse
    id="collapsePortfolio"
    className="accordion-collapse"
    isOpen={activeTab === 6}
  >
    <div className="accordion-body">
      <Row>
        <Col md="12">
          <table className="table table-bordered dataTable">
            <thead>
              <tr>
                <th>#</th>
                <th>Portfolio Type</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {studentDetail.portfolio && Object.keys(studentDetail.portfolio).length > 0 ? (
                Object.keys(studentDetail.portfolio).map((key, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}</td>
                    <td>
                      <a href={studentDetail.portfolio[key]} target="_blank" rel="noreferrer">
                        {studentDetail.portfolio[key]}
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No portfolio available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  </Collapse>
</div>



{/* preference section */}
<div className="accordion-item">
  <h2 className="accordion-header" id="headingPreferences">
    <button
      type="button"
      onClick={() => toggleTab(7)}
      className={classnames("accordion-button", "fw-medium", {
        collapsed: activeTab !== 7,
      })}
      style={{ cursor: "pointer" }}
    >
      <h6>Preferences</h6>
    </button>
  </h2>
  <Collapse
    id="collapsePreferences"
    className="accordion-collapse"
    isOpen={activeTab === 7}
  >
    <div className="accordion-body">
      <Row>
        <Col md="12">
          <table className="table table-bordered dataTable">
            <thead>
              <tr>
                <th>#</th>
                <th>Preferred Job Role</th>
                <th>Preferred Cities</th>
                <th>Expected Annual Salary</th>
                <th>Job Type</th>
                <th>Employment Type</th>
                <th>Preferred Shift</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  {studentDetail.preference?.jobRole && studentDetail.preference?.jobRole?.length > 0
                    ? studentDetail.preference?.jobRole.map((role, index) => role.jobRoleName).join(", ")
                    : ""}
                </td>
                <td>
                  {studentDetail.preference?.cities && studentDetail.preference?.cities?.length > 0
                    ? studentDetail.preference?.cities.map((city, index) => city.cityName).join(", ")
                    : ""}
                </td>
                <td> ₹{studentDetail.preference?.expectedSalary || ""}</td>
                <td>
                  {studentDetail.preference?.jobType && studentDetail.preference?.jobType?.length > 0
                    ? studentDetail.preference?.jobType?.map((type, index) => type.label).join(", ")
                    : ""}
                </td>
                <td>
                  {studentDetail.preference?.employmentType && studentDetail.preference?.employmentType?.length > 0
                    ? studentDetail.preference?.employmentType?.map((type, index) => type.label).join(", ")
                    : ""}
                </td>
                <td>
                {
  studentDetail.preference?.shift ? (
    <div>
      {[
        studentDetail.preference?.shift?.day && "Day",
        studentDetail.preference?.shift?.night && "Night",
        studentDetail.preference?.shift?.flexible && "Flexible"
      ]
        .filter(Boolean) 
        .join(", ")} 
    </div>
  ) : (
    ""
  )
}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  </Collapse>
</div>




{/* Certification Section */}
 <div className="accordion-item">
        <h2 className="accordion-header" id="headingCertifications">
          <button
            type="button"
            onClick={() => toggleTab(8)}
            className={classnames("accordion-button", "fw-medium", {
              collapsed: activeTab !== 8,
            })}
            style={{ cursor: "pointer" }}
          >
            <h6>Certifications</h6>
          </button>
        </h2>
        <Collapse
          id="collapseCertifications"
          className="accordion-collapse"
          isOpen={activeTab === 8}
        >
          <div className="accordion-body">
            <Row>
              <Col md="12">
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document/Certificate Name</th>
                      <th>Issued By</th>
                      <th>Certificate Upload</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentDetail.certificate && studentDetail.certificate.length > 0 ? (
                      studentDetail.certificate.map((cert, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{cert.name || "N/A"}</td>
                          <td>{cert.issuedBy || "N/A"}</td>
                          <td>
                            {cert.file ? (
                              <a href={`${API_URL}${cert.file}`} target="_blank" rel="noreferrer">
                                <i className="fas fa-file-pdf pdf-icon"></i>
                              </a>
                            ) : (
                              "No file chosen"
                            )}
                            <i
                              className="fas fa-eye"
                              onClick={() => handlePopUp(cert)}  
                              style={{
                                fontSize: "1.2em",
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No certification details available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>


          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
