import React, { useEffect, useRef } from "react"

import { useState } from "react"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Tooltip from "@mui/material/Tooltip"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"
import moment from "moment"

import TablePagination from "../../../components/Common/TablePagination"
import FileUpload from "../../../components/Common/FileUpload"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import CreditFollowup from "./followup"

import { API_URL } from "../../../config"
import { get, post, put } from "../../../helpers/api_helper"

import {
  dateConverter,
  getDate,
  numberToCurrency,
  reactSelectCustomStyle,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"
import "./styles.scss"
import {
  TYPE_VALUES,
  PAYMENT_TYPES,
  paymentOptions,
  statusOptions,
  STATUS_VALUES,
  headOptions,
  filterStatusOptions,
} from "./helper"

const IncomeExpense = () => {
  setTitle("Income/Expense")

  const ref = useRef()
  const filterRef = useRef()
  const todayDate = getDate()
  const time = moment().format("HH:mm")

  const initialState = {
    date: todayDate,
    time,
    head: "",
    amount: "",
    remarks: "",
    attachment: "",
    paymentType: PAYMENT_TYPES.CASH,
  }
  const selectInitialState = {
    paymentType: paymentOptions[1],
  }

  const [masterObject, setMasterObject] = useState({ ...initialState })
  const [selectedFields, setSelectedFields] = useState({
    ...selectInitialState,
  })

  const [subHeadOptions, setSubHeadOptions] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])
  const [chartAccountOptions, setChartAccountOptions] = useState([])

  const [filterSubHeadOptions, setFilterSubHeadOptions] = useState([])

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [filterSelectObject, setFilterSelectObject] = useState({})

  const [viewModal, setViewModal] = useState({})

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    handleTableData()
    handleFilterSubHeadOptions()
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleFundSourceOptions()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const params = `page=${currentPage}&limit=${limit}&${queryString.stringify(
        filterObject,
      )}`
      const response = await get(`income-expense/list?${params}`)
      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (currentPage - 1) * limit

        item.date = dateConverter(item.date)
        item.actionDate = dateConverter(item.actionDate)
        item.amount = numberToCurrency(item.amount)

        item.subHead = renderTableValue(item.subHead?.name)
        item.chartOfAccount = renderTableValue(item.chartOfAccount?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        const status = Number(item.status)
        item.head = TYPE_VALUES[item.head]
        item.paymentType = PAYMENT_TYPES[item.paymentType]

        const isCreditPayment = item.isCreditPayment
        const isPending = status === 3
        const isStatusChange = isPending && !isCreditPayment

        if (isPending) {
          if (isCreditPayment) {
            item.options = (
              <div>
                <Tooltip title="Edit" arrow placement="top">
                  <i
                    className="far fa-edit edit-icon"
                    onClick={() => handleEdit(item._id)}
                  />
                </Tooltip>
                <Tooltip title="View" arrow placement="top">
                  <i
                    className="fas fa-eye eye-icon"
                    onClick={() => handleViewDetails(item._id, true)}
                  />
                </Tooltip>
              </div>
            )
          } else {
            item.options = (
              <Tooltip title="Edit" arrow placement="top">
                <i
                  className="far fa-edit edit-icon"
                  onClick={() => handleEdit(item._id)}
                />
              </Tooltip>
            )
          }
        } else {
          if (isCreditPayment) {
            item.options = (
              <i
                className="fas fa-money-check money-icon"
                onClick={() => handleViewDetails(item._id, true)}
              />
            )
          } else if (status === 0) {
            item.options = (
              <div>
                <Tooltip title="Edit" arrow placement="top">
                  <i
                    className="far fa-edit edit-icon"
                    onClick={() => handleEdit(item._id)}
                  />
                </Tooltip>
                <Tooltip title="View" arrow placement="top">
                  <i
                    className="fas fa-eye eye-icon"
                    onClick={() => handleViewDetails(item._id, false)}
                  />
                </Tooltip>
              </div>
            )
          } else {
            item.options = (
              <Tooltip title="View" arrow placement="top">
                <i
                  className="fas fa-eye eye-icon"
                  onClick={() => handleViewDetails(item._id, false)}
                />
              </Tooltip>
            )
          }
        }

        if (isStatusChange) {
          item.status = (
            <Select
              styles={reactSelectCustomStyle}
              name="status"
              options={statusOptions}
              menuPosition="fixed"
              value={""}
              onChange={selected => handleStatusChange(item._id, selected)}
            />
          )
        } else {
          item.status = (
            <div>
              <Button color={STATUS_VALUES[status]?.color} size="sm" disabled>
                {STATUS_VALUES[status]?.title}
              </Button>
            </div>
          )
        }

        if (item.attachment)
          item.attachment = (
            <a
              href={API_URL + item.attachment}
              target="_blank"
              rel="noreferrer"
            >
              <i className="far fa-file-image image-icon" />
            </a>
          )

        return item
      })
      setTableData(data)

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {}
  }

  const handleSubHeadOptions = async (obj = masterObject) => {
    if (!obj.head) return
    try {
      const query = queryString.stringify(obj)
      const response = await get(`income-expense/options/sub-head?${query}`)
      setSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleChartAccountOptions = async ({ subHead } = masterObject) => {
    if (!subHead) return
    try {
      const query = queryString.stringify({ subHead })
      const response = await get(`options/chartOfAccount?${query}`)
      setChartAccountOptions(response.data)
    } catch (err) {}
  }

  const handleFundSourceOptions = async (obj = masterObject) => {
    try {
      const response = await get(`income-expense/options/fund-source`)
      setFundSourceOptions(response.data)
    } catch (err) {}
  }

  const handleFilterSubHeadOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `income-expense/filter/options/sub-head?${params}`,
      )
      setFilterSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleValidSubmit = async () => {
    if (!masterObject.head) return toastr.error("Head Required")
    if (!masterObject.chartOfAccount)
      return toastr.error("Chart Of Account Required")

    try {
      let response
      if (masterObject.id)
        response = await put("/income-expense/edit", masterObject)
      else response = await post("/income-expense/create", masterObject)

      toastr.success(response.message)
      reset()
    } catch (err) {
      console.error(err)
      toastr.error(err?.response?.data?.message)
    }
  }

  const handleStatusChange = async (id, selected) => {
    try {
      const response = await put(`/income-expense/status`, {
        id,
        status: selected?.value,
      })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleEdit = async id => {
    if (!id) return
    try {
      const response = await get(`income-expense/edit?id=${id}`)
      const data = response.data

      const obj = { ...masterObject }
      const selectObj = { ...selectedFields, ...data }

      obj.id = data._id
      obj.amount = data.amount
      obj.prevAmount = data.paidAmount

      obj.date = data.date
      obj.time = data.time

      obj.head = data.head
      obj.remarks = data.remarks
      obj.dueDate = data.dueDate
      obj.attachment = data.attachment
      obj.paymentType = data.paymentType

      obj.subHead = data.subHeadId
      obj.chartOfAccount = data.chartOfAccountId
      obj.fundSource = data.fundSourceId

      obj.isDisabled = data.isDisabled
      obj.isPaymentApproved =
        data.status === 0 && data.paymentType === PAYMENT_TYPES.CASH

      selectObj.head = headOptions[data.head - 1]
      selectObj.paymentType = paymentOptions[data.paymentType - 1]

      setMasterObject(obj)
      setSelectedFields(selectObj)
      toTop()

      handleSubHeadOptions(obj)
      handleChartAccountOptions(obj)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    if (name === "subHead") obj.chartOfAccount = ""
    if (name === "head") {
      obj.subHead = ""
      obj.chartOfAccount = ""
    }
    setSelectedFields(obj)
    handleValueChange(selected.value, name)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    if (name === "subHead") {
      obj.chartOfAccount = ""
      handleChartAccountOptions(obj)
    }

    if (name === "head") {
      handleSubHeadOptions(obj)
      obj.subHead = ""
      obj.chartOfAccount = ""
    }
    setMasterObject(obj)
  }

  const handleFilterSelectValueChange = (selected, name) => {
    const obj = { ...filterSelectObject }
    obj[name] = selected
    setFilterSelectObject(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      const fd = new FormData()
      fd.append("image", e.target.files[0])
      const response = await post(`income-expense/image`, fd)
      handleValueChange(response.data.new_filename, "attachment")
    } catch (error) {
      toastr.error(error.response.data.message)

      e.target.value = ""
    }
  }

  const handleViewDetails = async (id, isCredit = true) => {
    setViewModal({ show: true, id, isCredit })
  }

  const handleModalClose = () => {
    setViewModal({ show: false, id: null, isCredit: false })
    handleTableData()
  }

  const reset = () => {
    toTop()

    setFilterObject({})
    setFilterSelectObject({})

    setMasterObject({ ...initialState })
    setSelectedFields({ ...selectInitialState })

    ref.current?.reset()
    filterRef.current?.reset()

    setSubHeadOptions([])
    setChartAccountOptions([])
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Head", field: "head" },
      { label: "Sub Head", field: "subHead" },
      { label: "Chart Of Account", field: "chartOfAccount" },
      { label: "Credit/Cash", field: "paymentType" },
      { label: "Amount", field: "amount" },
      { label: "Attachment", field: "attachment" },
      { label: "Status", field: "status" },
      { label: "Added By", field: "staff" },
      { label: "Actions", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Income/Expense" />
        <Card>
          <CardBody>
            <AvForm ref={ref} onValidSubmit={handleValidSubmit}>
              <Row>
                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="head"
                    options={headOptions}
                    value={selectedFields.head || ""}
                    isDisabled={masterObject.isDisabled}
                    onChange={selected => {
                      handleSelectValueChange(selected, "head")
                    }}
                  />
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label>Sub Head</Label>
                    <Select
                      name="subHead"
                      value={selectedFields.subHead || ""}
                      options={subHeadOptions}
                      isDisabled={!masterObject.head || masterObject.isDisabled}
                      onChange={selected => {
                        handleSelectValueChange(selected, "subHead")
                      }}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Chart Of Account</Label>
                    <Select
                      name="chartOfAccount"
                      value={selectedFields.chartOfAccount || ""}
                      options={chartAccountOptions}
                      isDisabled={
                        masterObject.isDisabled || !masterObject.subHead
                      }
                      onChange={selected => {
                        handleSelectValueChange(selected, "chartOfAccount")
                      }}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="mb-1">
                    <Label>Amount (₹)</Label>
                    <AvField
                      name="amount"
                      type="text"
                      placeholder="Amount"
                      value={masterObject.amount}
                      errorMessage="Enter valid Amount"
                      onChange={e =>
                        handleValueChange(e.target.value, e.target.name)
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Amount is required",
                        },
                        min: {
                          value: masterObject.isDisabled
                            ? masterObject.prevAmount
                            : 1,
                          errorMessage: `Amount must be at least ${
                            masterObject.isDisabled
                              ? masterObject.prevAmount || 0
                              : 1
                          }`,
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <Label>Credit/Cash</Label>
                  <Select
                    name="paymentType"
                    options={paymentOptions}
                    isDisabled={
                      masterObject.isDisabled || masterObject.isPaymentApproved
                    }
                    onChange={selected => {
                      handleSelectValueChange(selected, "paymentType")
                    }}
                    value={selectedFields.paymentType || ""}
                  />
                </Col>
                {masterObject.paymentType === 2 ? (
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Bank / Fund Source</Label>
                      <Select
                        name="fundSource"
                        value={selectedFields.fundSource || ""}
                        options={fundSourceOptions}
                        isDisabled={masterObject.isDisabled}
                        onChange={selected => {
                          handleSelectValueChange(selected, "fundSource")
                        }}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col md="2">
                    <div className="mb-1">
                      <Label>Due Date</Label>
                      <AvField
                        name="dueDate"
                        type="date"
                        value={masterObject.dueDate || ""}
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Due Date"
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </div>
                  </Col>
                )}

                <Col md={2}>
                  <Label>Date</Label>
                  <AvField
                    name={`date`}
                    className="form-control"
                    type="date"
                    placeholder="Date"
                    errorMessage="Enter valid Date"
                    defaultValue={todayDate}
                    value={masterObject.date || ""}
                    validate={{ required: { value: true } }}
                    max={todayDate}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Time</Label>
                  <AvField
                    name={`time`}
                    className="form-control"
                    type="time"
                    placeholder="Time"
                    errorMessage="Enter valid Time"
                    defaultValue={time}
                    value={masterObject.time || ""}
                    validate={{ required: { value: true } }}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>
                <Col md={3}>
                  <FileUpload
                    label="Attachments"
                    name="attachment"
                    fileUrl={masterObject?.attachment || ""}
                    onFileChange={handleFileUpload}
                    onDelete={() => handleValueChange("", "attachment")}
                    accept="image/*, .pdf, .xls, .xlsx"
                  />
                </Col>
                <Col md={3}>
                  <Label>Remarks</Label>
                  <AvField
                    type="textarea"
                    rows={1}
                    name="remarks"
                    placeholder="Remarks"
                    errorMessage="Enter Remark"
                    value={masterObject.remarks}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>

                <Col md={2}>
                  <div
                    className="d-flex"
                    style={{
                      marginTop:
                        masterObject.paymentType === PAYMENT_TYPES.CASH
                          ? "26px"
                          : "0",
                    }}
                  >
                    <Button
                      color={masterObject.id ? "warning" : "primary"}
                      type="submit"
                      className="me-2"
                    >
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>From</Label>
                    <AvField
                      name="from"
                      type="date"
                      value={filterObject.form}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>To</Label>
                    <AvField
                      name="to"
                      type="date"
                      value={filterObject.to}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="head"
                    options={headOptions}
                    value={filterSelectObject.head || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "head")
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Sub Head</Label>
                  <Select
                    name="subHead"
                    options={filterSubHeadOptions}
                    value={filterSelectObject.subHead || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "subHead")
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Credit/Cash</Label>
                  <Select
                    name="paymentType"
                    options={paymentOptions}
                    value={filterSelectObject.paymentType || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "paymentType")
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    value={filterSelectObject.status || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "status")
                    }
                  />
                </Col>
                <Col md="1">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="creditPaymentTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </div>
      <CreditFollowup
        id={viewModal.id}
        onToggle={handleModalClose}
        isOpen={viewModal.show}
        isCredit={viewModal.isCredit}
      />
    </div>
  )
}

export default IncomeExpense
