import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import Swal from "sweetalert2"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import {
  dateConverter,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { del, get, post, put } from "../../../helpers/api_helper"

import "./styles.scss"

const Category = () => {
  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}`
      const response = await get(`category?${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.staff = renderTableValue(item.addedBy?.name)

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item)}
              />
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              />
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`category`, masterObject)
      else response = await post(`category`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async item => {
    toTop()
    setMasterObject({ id: item._id, name: item.name })
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`category/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})

    handleTableData(1)

    setPage(1)
    setTotalPage(1)
    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Name", field: "name" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Category" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Name</Label>
                  <AvField
                    name="name"
                    placeholder="Name"
                    errorMessage="Enter Name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md={3} className="form-button">
                  <Button
                    color={masterObject.id ? "warning" : "primary"}
                    className="me-2"
                  >
                    {masterObject.id ? "Update" : "Submit"}
                  </Button>
                  <Button color="danger" type="reset" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="categoryTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Category
