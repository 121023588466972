import React, { useEffect, useState } from "react"
import { Row, Col, Label, Button, Collapse } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import Select from "react-select"
import classnames from "classnames"
import toastr from "toastr"

function Registrations(props) {
  const EventId = props.data
  const [registration, setRegistration] = useState([])
  const [bookingDates, setbookingDates] = useState([])
  const [filter, setFilter] = useState({ date: null, attended: null })
  const [participationTable, setParticipationTable] = useState([])
  const [collapse, setCollapse] = useState(null)
  const [attendaceStatus, setAttendanceStatus] = useState(true)

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Registered Date",
        field: "date",
        width: 150,
      },

      {
        label: "Ticket No.",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "profile1",
        sort: "desc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Paid Status",
        field: "paidStatus",
        width: 150,
      },
      {
        label: "Attendance",
        field: "attdate",
        width: 150,
      },
    ],
    rows: registration,
  }

  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Booking Date",
        field: "bookingDate",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Ticket No.",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Status",
        field: "attendance",
        width: 150,
      },
    ],
    rows: [
      {id:"1",bookingDate:"12-05-2024",name:"Anjal Rajeev",uniqueId:"JBF123456",mobile:"9587459645",attendance:"Attended"},
      {id:"2",bookingDate:"12-05-2024",name:"Jamsheer Jasi",uniqueId:"JBF123456",mobile:"9587459645",attendance:"Not Attended"},
      {id:"3",bookingDate:"12-05-2024",name:"Habeeb Ashi",uniqueId:"JBF123456",mobile:"9587459645",attendance:"Attended"},
      {id:"4",bookingDate:"12-05-2024",name:"Anjal Rajeev",uniqueId:"JBF123456",mobile:"9587459645",attendance:"Attended"},

    ],
  }

  const handleCollapse = index => {
    if (collapse === index) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  return (
    <div>
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: collapse !== 1,
              })}
              type="button"
              onClick={() => handleCollapse(1)}
              style={{ cursor: "pointer" }}
            >
              Job Fair Registrations
            </button>
          </h2>
          <Collapse
            id="collapseOne"
            className="accordion-collapse"
            isOpen={collapse === 1}
          >
            <div className="accordion-body">

              <Row>
                <Col className="col-12 mt-2">
                  <MDBDataTable
                    id="registeredpeopleid"
                    responsive
                    bordered
                    data={data1}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: collapse !== 2,
              })}
              type="button"
              onClick={() => handleCollapse(2)}
              style={{ cursor: "pointer" }}
            >
              Job Fair Participation
            </button>
          </h2>
          <Collapse
            id="collapseOne"
            className="accordion-collapse"
            isOpen={collapse === 2}
          >
            <div className="accordion-body">
              <Row>
                <Col md="3">
                  <Label>Staus</Label>
                  <Select options={[{label:"Attended",value:"1"},{label:"Not Attended",value:"2"}]} />
                </Col>

              </Row>
              <Row>
                <Col className="col-12 mt-2">
                  <MDBDataTable
                    id="participationTableId"
                    responsive
                    bordered
                    data={data2}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default Registrations
