import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { get } from "../../../helpers/api_helper";
import { Card, CardBody, CardTitle } from "reactstrap";

const PieChartBreakdown = ({ period, startDate, endDate, selectedMonth, selectedYear }) => {
  const [seriesCandidates, setSeriesCandidates] = useState([0, 0, 0, 0]);
  // const [seriesVacancies, setSeriesVacancies] = useState([0, 0]);

  useEffect(() => {
    fetchCandidateStats(); 
    // fetchVacancyStats();  
  }, [period, startDate, endDate, selectedMonth, selectedYear]);

  const buildQueryString = (params) => {
    const queryParams = new URLSearchParams(params).toString(); 
    return queryParams;
  };

  const fetchCandidateStats = () => {
    const params = { period, startDate, endDate, selectedMonth, selectedYear };
    const queryString = buildQueryString(params);

    get(`dashboard/internshipstatus?${queryString}`)
      .then((res) => {
        const { applied, shortlisted, placed, rejected } = res?.data || {};
        setSeriesCandidates([applied || 0, shortlisted || 0, placed || 0, rejected || 0]);
      })
      .catch((error) => {
        console.error("Error fetching candidate data:", error);
      });
  };

  // const fetchVacancyStats = () => {
  //   const params = { period, startDate, endDate, selectedMonth, selectedYear };
  //   const queryString = buildQueryString(params);

  //   get(`dashboard/vacancystatus?${queryString}`)
  //     .then((res) => {
  //       const { filled, remaining } = res?.data || {};
  //       const filledValue = parseFloat(filled);
  //       const remainingValue = parseFloat(remaining);

  //       if (isNaN(filledValue) || isNaN(remainingValue)) {
  //         console.error("Invalid vacancy data:", { filledValue, remainingValue });
  //       }

  //       setSeriesVacancies([filledValue || 0, remainingValue || 0]);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching vacancy data:", error);
  //     });
  // };

  const optionsCandidates = {
    labels: ["Applied", "Shortlisted", "Placed", "Rejected"],
    colors: ["#42A5F5", "#FFCA28", "#28a745", "#dc3545"],
    legend: {
      position: "bottom",
      labels: {
        colors: ["#495057"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`,
    },
    tooltip: {
      enabled: false, 
    },
    plotOptions: {
      pie: {
        events: {
          mouseEnter: (e) => e.preventDefault(), 
        },
      },
    },
    chart: {
      type: "pie",
      background: "transparent",
    },
  };

  // const optionsVacancies = {
  //   labels: ["Filled", "Remaining"],
  //   colors: ["#007bff", "#ffc107"],
  //   legend: {
  //     position: "bottom",
  //     labels: {
  //       colors: ["#495057"],
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     formatter: (val) => `${val.toFixed(0)}%`,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: (val) => `${val}%`,
  //     },
  //   },
  //   chart: {
  //     type: "pie",
  //     background: "transparent",
  //   },
  // };

  return (
    <React.Fragment>
      <Card className="shadow-sm border-0">
        <CardBody>
          <CardTitle className="mb-4 h4 text-dark">Internship Status</CardTitle>

          <div className="row">
            {/* <div className="col-lg-6 col-md-12 mb-4"> */}
              <h5 className="text-center mb-3 text-muted">Candidates Breakdown</h5>
              <ReactApexChart
                options={optionsCandidates}
                series={seriesCandidates}
                type="pie"
                height="300"
              />
            {/* </div> */}

            {/* <div className="col-lg-6 col-md-12 mb-4">
              <h5 className="text-center mb-3 text-muted">Vacancies Breakdown</h5>
              <ReactApexChart
                options={optionsVacancies}
                series={seriesVacancies}
                type="pie"
                height="300"
              />
            </div> */}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PieChartBreakdown;
