import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Spinner,
  Container,
} from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import { get, put } from "../../../helpers/api_helper"

import TablePagination from "../../../components/Common/TablePagination"
import SearchSelect from "../../../components/Common/SearchSelect"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import { resultValues, statusValues, testStatusValues } from "./helper"

import "./styles.scss"
import { typeValues } from "../TestCreation/helper"
import { renderTableValue } from "../../../helpers/functions"
import { useNavigate } from "react-router-dom"
import Retake from "./Retake"

const TestResults = () => {
  const navigate = useNavigate()

  const statusOptions = [
    { label: "Attending", value: 1 },
    { label: "Absent", value: 2 },
    { label: "Completed", value: 3 },
  ]

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [loading, setLoading] = useState(false)

  const [screeningTestOptions, setScreeningTestOptions] = useState([])

  const [modal, setModal] = useState({ show: false, id: null, status: 4 })

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  useEffect(() => {
    handleTestOptions()
  }, [masterObject]) // eslint-disable-line

  const handleTestOptions = async () => {
    try {
      const query = `?${queryString.stringify(masterObject)}`
      const response = await get(`screening/result/options/test${query}`)
      setScreeningTestOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`

      const response = await get(`screening/result${query}`)
      const { count, data } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.candidateId = item.candidate?.uniqueId
        item.candidate = item.candidate?.name

        item.test = item.screeningTest?.name

        if (item.type === typeValues.KTU) {
          item.general = (
            <Tooltip title={`Mark/Total`} arrow placement="top">
              {Number(item?.markObtained?.general || 0).toFixed(0)}/
              {Number(item?.generalMark || 0).toFixed(0)}
            </Tooltip>
          )
        } else {
          item.general = renderTableValue("")
        }

        item.specialization = (
          <Tooltip title={`Mark/Total`} arrow placement="top">
            {Number(item?.markObtained?.specialization || 0).toFixed(0)}/
            {Number(item?.maxMark || 0).toFixed(0)}
          </Tooltip>
        )

        let status = statusValues.COMPLETED
        if (item.status === testStatusValues.ABSENT)
          status = statusValues.ABSENT
        else if (item.status === testStatusValues.ONGOING)
          status = statusValues.ONGOING

        item.status = statusValues[status]
        item.type = typeValues[item.type]

        if (status === statusValues.COMPLETED) {
          item.options = (
            <div>
              <Tooltip title="View" arrow placement="top">
                <i
                  className="fas fa-eye eye-icon"
                  onClick={() => handleNavigate(item.uniqueUrl)}
                />
              </Tooltip>
              {item.result !== resultValues.RETAKE ? (
                <Tooltip title="Retake" arrow placement="top">
                  <i
                    className="fa fa-history ml-2"
                    style={{ cursor: "pointer" }}
                    // onClick={() => handleRetake(item._id)}
                    onClick={() => handleToggle({ id: item._id, show: true })}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )
        } else if (
          status === statusValues.ABSENT &&
          item.result !== resultValues.RETAKE
        ) {
          item.options = (
            <div>
              <Tooltip title="Retake" arrow placement="top">
                <i
                  className="fa fa-history ml-2"
                  style={{ cursor: "pointer" }}
                  // onClick={() => handleRetake(item._id)}
                  onClick={() => handleToggle({ id: item._id, show: true })}
                />
              </Tooltip>
            </div>
          )
        }

        const result = resultValues[item.result]

        if (result) {
          item.result = (
            <Button size="sm" disabled color={result?.color}>
              {result?.text}
            </Button>
          )
        } else item.result = renderTableValue("")
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async () => {
    try {
      setLoading(true)
      const response = await put("screening/result/refresh")
      if (response.code === 201) toastr.warning(response.message)
      else toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleNavigate = url => {
    navigate(`/test-results/${url}`)
  }
  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleToggle = ({ show = false, id = null } = {}) => {
    setModal({ show, id })
    handleTableData()
  }

  const reset = () => {
    setMasterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Type", field: "type" },
      { label: "Test", field: "test" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Candidate", field: "candidate" },
      { label: "Status", field: "status" },
      { label: "General", field: "general" },
      { label: "Specialization", field: "specialization" },
      { label: "Result", field: "result" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Test Results" />
        <Card>
          <CardBody>
            <Row>
              <Col md={3}>
                <Label>Screening Test</Label>
                <Select
                  menuPosition="fixed"
                  options={screeningTestOptions}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "screeningTest" })
                  }}
                  value={selectedFields.screeningTest || ""}
                  isMulti
                />
              </Col>
              <Col md={3}>
                <Label>Candidate</Label>
                <SearchSelect
                  api={`screening/result/options/candidate`}
                  onChange={selected => {
                    handleSelectValueChange({ selected, name: "candidate" })
                  }}
                  value={selectedFields.candidate}
                  master={masterObject}
                  isMulti
                />
              </Col>
              <Col md={2}>
                <Label>Status</Label>
                <Select
                  menuPosition="fixed"
                  options={statusOptions}
                  onChange={selected =>
                    handleSelectValueChange({ selected, name: "status" })
                  }
                  value={selectedFields.status || ""}
                  isMulti
                  isClearable
                />
              </Col>
              <Col md={2}>
                <div className="d-flex gap-3 form-button">
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>

                  <Tooltip title="Refresh" placement="top" arrow>
                    <div>
                      <Button
                        outline
                        color="success"
                        disabled={loading}
                        style={{ width: "60px" }}
                        onClick={handleRefresh}
                      >
                        {loading ? (
                          <Spinner style={{ height: "20px", width: "20px" }} />
                        ) : (
                          <i class="fas fa-redo"></i>
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <TablePagination
              page={page}
              tableId="testResults"
              data={data}
              count={totalPage}
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
      <Retake
        id={modal.id}
        isOpen={modal.show}
        onToggle={() => handleToggle({})}
      />
    </div>
  )
}

export default TestResults
