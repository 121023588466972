export const testStatusValues = {
  0: "Not Started",
  1: "Ongoing",
  2: "Completed",
  3: "Expired",
  4: "Absent",
  NOT_STARTED: 0,
  ONGOING: 1,
  COMPLETED: 2,
  EXPIRED: 3,
  ABSENT: 4,
}

export const statusValues = {
  0: "Completed",
  1: "Absent",
  2: "Ongoing",
  COMPLETED: 0,
  ABSENT: 1,
  ONGOING: 2,
}

export const resultValues = {
  1: { text: "Pass", color: "success" },
  2: { text: "Failed", color: "danger" },
  3: { text: "Retake", color: "info" },
  RETAKE: 3,
}
