import React, { useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"

import TablePagination from "../../components/Common/TablePagination"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  interviewStatusOptions,
  screeningStatusOptions,
  smeStatusOptions,
  statusOptions,
} from "./helper"

import "./styles.scss"

const AssessmentReport = () => {
  //  Candidate Company Internship Type Status, Screening  Interview SME

  // { text: "Shortlisted", color: "warning" }
  const [tableData] = useState([
    {
      id: 1,
      uniqueId: "IA052400726",
      candidate: "John Doe",
      candidateId: "REG042403578",
      company: "Tech Corp",
      internship: "Software Engineering",
      type: "KTU",
      status: (
        <Button disabled size="sm" color="info">
          Applied
        </Button>
      ),
      screening: (
        <Button disabled size="sm" color="success">
          Pass
        </Button>
      ),
      interview: (
        <Button disabled size="sm" color="info">
          Scheduled
        </Button>
      ),
      sme: (
        <Button disabled size="sm" color="warning">
          Not Scheduled
        </Button>
      ),
      options: (
        <div className="d-flex justify-content-center">
          <i className="fas fa-share share-icon" />
        </div>
      ),
    },
    {
      id: 2,
      uniqueId: "IA052400725",
      candidate: "Jane Smith",
      candidateId: "REG042403577",
      company: "Innovate Ltd",
      internship: "Product Management",
      type: "General",
      status: (
        <Button disabled size="sm" color="info">
          Applied
        </Button>
      ),
      screening: (
        <Button disabled size="sm" color="danger">
          Failed
        </Button>
      ),
      interview: (
        <Button disabled size="sm" color="success">
          Qualified
        </Button>
      ),
      sme: (
        <Button disabled size="sm" color="info">
          Scheduled
        </Button>
      ),
      options: (
        <div className="d-flex justify-content-center">
          <i className="fas fa-share share-icon" />
        </div>
      ),
    },
  ])

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Id", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "internship" },
      { label: "Type", field: "type" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Status", field: "status" },
      { label: "Screening", field: "screening" },
      { label: "Interview", field: "interview" },
      { label: "SME", field: "sme" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Assessment Report" />
        <Card>
          <CardBody>
            <Row>
              <Col md={3} className="mb-3">
                <Label>Candidate</Label>
                <Select options={[]} />
              </Col>
              <Col md={3} className="mb-3">
                <Label>Company</Label>
                <Select options={[]} />
              </Col>
              <Col md={3} className="mb-3">
                <Label>Internship</Label>
                <Select options={[]} />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Type</Label>
                <Select options={[]} />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Status</Label>
                <Select options={statusOptions} />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Screening</Label>
                <Select options={screeningStatusOptions} />
              </Col>
              <Col md={2} className="mb-3">
                <Label>Interview</Label>
                <Select options={interviewStatusOptions} />
              </Col>
              <Col md={2} className="mb-3">
                <Label>SME</Label>
                <Select options={smeStatusOptions} />
              </Col>
              <Col>
                <div className="d-flex gap-2 form-button">
                  <Button disabled color="danger">
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <TablePagination
              page={1}
              onChange={() => {}}
              data={data}
              tableId="assessmentReport"
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AssessmentReport
