import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Container, Card } from "reactstrap"
import { Divider, Tooltip } from "@mui/material"

import Breadcrumb from "../../../components/Common/Breadcrumb"

import { useParams } from "react-router-dom"
import { statusValues, typeValues } from "../TestCreation/helper"

import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  numberToString,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"

import "./styles.scss"
import { answerValues } from "../Questions/helper"
import TablePagination from "../../../components/Common/TablePagination"

const TestDetails = () => {
  const { id } = useParams()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])
  const [details, setDetails] = useState({})

  useEffect(() => {
    if (id) handleData()
  }, [id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`screening/details?id=${id}`)
      const data = response.data

      data.typeText = typeValues[data.type]

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.duration = numberToString(data.duration) + " Minutes"

      data.status = statusValues[data.status]?.text || ""

      data.specializations = data.specialization
        ?.map(item => item.name)
        .join(", ")

      data.mark = numberToString(data.mark)
      data.passMark = numberToString(data.passMark)
      data.maxQuestions = numberToString(data.maxQuestions)

      data.generalMark = numberToString(data.generalMark)
      data.generalPassMark = numberToString(data.generalPassMark)
      data.generalMaxQuestions = numberToString(data.generalMaxQuestions)

      setDetails(data)
      handleTableData({ id: data._id })
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async ({
    currentPage = page,
    id = details._id,
  } = {}) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&id=${id}`
      const response = await get(`screening/details/questions?${query}`)

      const TYPES = {
        1: "General",
        2: "Specialization",
      }

      const { count, data } = response
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.type = renderTableValue(TYPES[item.type])
        item.specialization = renderTableValue(item.specialization?.name)

        item.question = (
          <div>
            <Tooltip title={item.question} placement="top" arrow>
              <p>{item.question}</p>
            </Tooltip>
          </div>
        )
        item.answer = answerValues[item.answer]

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData({ currentPage: value })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Type", field: "type" },
      { label: "Question Id", field: "uniqueId" },
      { label: "Specialization", field: "specialization" },
      { label: "Question", field: "question" },
      { label: "Answer", field: "answer" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Test Result Details" />

        <Row>
          <Col lg={3}>
            <Card className="card-shadow">
              <CardBody>
                <InfoRow label="Name" value={details?.name} />
                <InfoRow label="Id" value={details?.uniqueId} />
                <InfoRow label="Type" value={details?.typeText} />
                <InfoRow label="Duration" value={details?.duration} />
                <InfoRow label={"Added By"} value={details.addedBy?.name} />
                <InfoRow label={"Date"} value={details.date} />
                <InfoRow label={"Time"} value={details.time} />
                <InfoRow
                  label="Specializations"
                  style={{}}
                  value={
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {details?.specialization?.map((item, index) => (
                        <li key={index}>{item.name},</li>
                      ))}
                    </ul>
                  }
                  hidden={details?.type === typeValues.INTERNSHIP}
                />
                <InfoRow
                  label="Internships"
                  style={{}}
                  value={
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {details?.internship?.map((item, index) => (
                        <li key={index}>{item.name},</li>
                      ))}
                    </ul>
                  }
                  hidden={details?.type !== typeValues.INTERNSHIP}
                />
                <Divider className="my-3" />
                <SectionHeading title="Specialization" />
                <InfoRow label={"Max Questions"} value={details.maxQuestions} />
                <InfoRow label={"Mark"} value={details.mark} />
                <InfoRow label={"Pass mark"} value={details.passMark} />
                <InfoRow
                  label="Questions"
                  style={{}}
                  value={
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {details?.questions?.map((item, index) => (
                        <li key={index}>
                          {`${item.questions || 0} Questions x ${item.mark || 0} mark`}
                          ,
                        </li>
                      ))}
                    </ul>
                  }
                />
                {details.type === typeValues.KTU && (
                  <>
                    <Divider className="my-3" />

                    <SectionHeading title="General" />
                    <InfoRow
                      label={"Max Questions"}
                      value={details.generalMaxQuestions}
                    />
                    <InfoRow label={"Mark"} value={details.generalMark} />
                    <InfoRow
                      label={"Pass mark"}
                      value={details.generalPassMark}
                    />
                    <InfoRow
                      label="Questions"
                      style={{}}
                      value={
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          {details?.generalQuestions?.map((item, index) => (
                            <li key={index}>
                              {`${item.questions || 0} Questions x ${item.mark || 0} mark`}
                              ,
                            </li>
                          ))}
                        </ul>
                      }
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg={9} style={{ marginBottom: "30px" }}>
            <Card>
              <CardBody>
                <h5 className="font-size-16 mt-3 mb-3">Instructions</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      details.instructions ||
                      `<span style="color: gray;">No instruction found.</span>`,
                  }}
                />
                <h5 className="font-size-16 mt-3 mb-3">Questions</h5>

                <TablePagination
                  page={page}
                  onChange={handlePageChange}
                  data={data}
                  tableId="questions"
                  count={totalPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TestDetails

const InfoRow = ({
  label,
  value,
  hidden,
  style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}) => {
  if (hidden) return null
  return (
    <>
      <div className="mt-2" style={style}>
        <p className="mb-1">{label}:</p>
        <h5
          className="font-size-14 mb-0 text-wrap"
          style={{ wordBreak: "break-word", maxWidth: "100%" }}
        >
          {value || ""}
        </h5>
      </div>
      <hr style={{ margin: "0", color: "#00afc3" }} />
    </>
  )
}

const SectionHeading = ({ title }) => (
  <h5 className="font-size-16 text-uppercase text-muted mt-3 mb-2">{title}</h5>
)
