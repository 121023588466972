import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
// import "./style.scss"

const TicketCategory = () => {
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const [master, setMaster] = useState({})
  const [selectedType, setSelectedType] = useState([])
  const [tableData, setTableData] = useState([])
  const [ticketCategoryIdToBeUpdated, setticketCategoryIdToBeUpdated] =
    useState(null)

  const ref = useRef()

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "type",
        sort: "asc",
        width: 150,
        className: "text-center",
      },
      {
        label: "Category Name",
        field: "name",
        sort: "asc",
        width: 150,
        className: "text-center",
      },
      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
        className: "text-center",
      },
    ],
    rows: tableData,
  }

  function handleValidSubmit(e, v) {
    if (ticketCategoryIdToBeUpdated) {
      put(`/ticket/category`, master)
        .then(res => {
          toastr.success(res.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setSelectedType([])
          setticketCategoryIdToBeUpdated(null)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setSelectedType([])
          setticketCategoryIdToBeUpdated(null)
        })
    } else {
      post(`/ticket/category`, master)
        .then(res => {
          if (res.success === true) {
            toastr.success(res.message)
            ref.current.reset()
            setMaster({})
            toTop()
            setticketCategoryIdToBeUpdated(null)
            setSelectedType([])
          } else {
            toastr.error("Failed to add ticket category")
            ref.current.reset()
            handleTableData()
            setMaster({})
            setSelectedType([])
            setticketCategoryIdToBeUpdated(null)
          }
          handleTableData()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setSelectedType([])
          setticketCategoryIdToBeUpdated(null)
        })
    }
  }

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const handleTableData = async () => {
    get("/ticket/category").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.type = item.type.join(", ")
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
                setticketCategoryIdToBeUpdated(item?._id)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )
        return item
      })
      setTableData(result)
    })
    function handleUpdate(item) {
    const selectedTypes = item?.type.split(", ").map(type => {
      return categoryOptions.find(option => option.value === type.trim());
    });
  
    setMaster({ id: item?._id, type: item?.type || [], name: item?.name });
    setSelectedType(selectedTypes);
    }
  }

  function handleTicketCategory(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }
  //DELETE
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/ticket/category?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleSelectValueChange = (selectedOption, fieldName) => {
    setMaster({
      ...master,
      [fieldName]: selectedOption
        ? selectedOption.map(option => option.value)
        : [],
    })
    if (fieldName == "type") {
      // let value = selectedOption.map(option => option.value)
      // console.log(selectedOption,'value')
      setSelectedType(selectedOption)
    }
  }
  function resetAll() {
    setMaster({})
    setSelectedType([])
    setticketCategoryIdToBeUpdated(null)
  }

  const categoryOptions = [
    { value: "INSTITUTE", label: "INSTITUTE" },
    { value: "COMPANY", label: "COMPANY" },
    { value: "CANDIDATE", label: "CANDIDATE" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Ticket" breadcrumbItem="Ticket Category" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={ref}
                    className="needs-validation"
                    onValidSubmit={handleValidSubmit}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Type
                          </Label>
                          <Select
                            menuPosition="fixed"
                            value={selectedType}
                            isMulti
                            options={categoryOptions}
                            onChange={selected =>
                              handleSelectValueChange(selected, "type")
                            }
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={master?.name || ""}
                            onChange={handleTicketCategory}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            className="me-2"
                            color={
                              ticketCategoryIdToBeUpdated
                                ? "warning"
                                : "primary"
                            }
                            type="submit"
                          >
                            {ticketCategoryIdToBeUpdated ? "Update" : "Submit"}
                          </Button>

                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="reset"
                            onClick={resetAll}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="ticketCategory"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TicketCategory
