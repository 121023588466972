import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import {
  dateConverter,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"
import { del, get, put } from "../../../helpers/api_helper"
import "./styles.scss"
import { AvForm } from "availity-reactstrap-validation"
import { typeValues } from "../helper"
import SearchField from "../../../components/Common/SearchField"

const CollegeRegistrations = props => {
  setTitle("Institute Registrations")

  const statusValues = {
    0: { text: "Active", color: "success" },
    2: { text: "In Active", color: "warning" },
    4: { text: "Rejected", color: "danger" },
  }

  const statusOptions = [
    { label: "Pending", value: 3 },
    { label: "Rejected", value: 4 },
  ]

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`
      const response = await get(`/college/registrations${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.type = renderTableValue(typeValues[item.type])
        item.university = renderTableValue(item.university?.name)
        item.staff = renderTableValue(item.addedBy?.name)

        item.mobile = renderTableValue(item.mobile)
        item.shortCode = renderTableValue(item.shortCode)

        item.status = getStatus(item._id, item.status)

        item.options = (
          <div>
            {/* <Tooltip title="View" arrow placement="top">
              <i className="fas fa-eye eye-icon"></i>
            </Tooltip> */}
            <Tooltip title="Delete" arrow placement="top">
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`college/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`college/status`, { id, status })
      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const getStatus = (id, status) => {
    if (status === 3) {
      return (
        <Select
          menuPosition="fixed"
          options={[
            { label: "Approve", value: 0 },
            { label: "Reject", value: 4 },
          ]}
          onChange={selected => handleStatusChange(id, selected.value)}
        />
      )
    } else {
      const statusObj = statusValues[status]
      return (
        <Button
          size="sm"
          color={statusObj.color}
          onClick={() => handleStatusChange(id, status === 0 ? 2 : 0)}
          disabled={status === 4}
        >
          {statusObj.text}
        </Button>
      )
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value

    setMasterObject(obj)
  }

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})
    setSelectedFields({})

    setTotalPage(1)
    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "University", field: "university" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Mobile", field: "mobile" },
      { label: "Type", field: "type" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Registrations" />
          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={masterObject.search || ""}
                      onChange={e => handleValueChange(e.target)}
                      placeholder="Search name, short code, mobile"
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Status</Label>
                    <Select
                      options={statusOptions}
                      value={selectedFields.status || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "status")
                      }
                    />
                  </Col>
                  <Col md={3} style={{ marginTop: "26px" }}>
                    <Button type="button" color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="collegeRegistrationsTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollegeRegistrations
