import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import {  AvForm } from "availity-reactstrap-validation"
import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
  dateConverter,
  renderTableValue,
  setTitle,
} from "../../helpers/functions"
import { get } from "../../helpers/api_helper"
import SearchField from "../../components/Common/SearchField"

const CollegeInternshipReports = props => {
  setTitle("offerletter")

 
 
  const formRef = useRef()
  const filterRef = useRef()


  const [tableData, setTableData] = useState([])


  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line


  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/company${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.district = renderTableValue(item.district?.name)

        item.shortCode = renderTableValue(item.shortCode)
        item.contactPerson = renderTableValue(item.contactPerson)
        item.mobile = renderTableValue(item.mobile)
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }




  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Candidate Name", field: "name" },
      { label: "Candidate ID", field: "" },
      { label: "Mobile", field: "" },
      { label: "Company", field: "" },
      { label: "Status", field: "" },


    ],
    // rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="College Internship Reports" />

          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      placeholder="Search name, short code, mobile"
                    />
                  </Col>
                  <Col md={2} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="companyTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollegeInternshipReports
