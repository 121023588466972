import React, { useEffect, useState } from "react";
import { Row, Col,Collapse} from "reactstrap";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import moment from "moment";
import MiniWidget from "../mini-widget";
import classnames from "classnames"
import { get } from "../../../../helpers/api_helper"
import { useParams } from "react-router-dom";



function Registrations(props) {
  // const jobpost = props.data;
  const { id } = useParams()

  // const [registration, setRegistration] = useState([]);
  const [registration1, setRegistration1] = useState([]);
  const [activeTab, setActiveTab] = useState(null)
  const [jobDescription, setJobDescription] = useState([])



  const toggleTab = tab => {
    if (activeTab === tab) {
      setActiveTab(null)
    } else {
      setActiveTab(tab)
    }
  }


  useEffect(() => {
    // handleTableData();
    handleTableData1();
  }, []);


  const fetchJobDetails = async () => {
    try {
      const response = await get(`/job/jobsummary?id=${id}`);
      const JobData = response?.data[0]?.details || [];
      setJobDescription(JobData);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  // function handleTableData() {
  //   const dummyData = [
  //     {
  //       date: new Date(),
  //       time: "10:30:00",
  //       addedBy: { name: "John Doe" },
  //       applicant: { uniqueId: "REG001", name: "Alice", mobile: "1234567890" },
  //       previousStatus: 0,
  //       status: 2,
  //     },
  //     {
  //       date: new Date(),
  //       time: "11:00:00",
  //       addedBy: { name: "Jane Smith" },
  //       applicant: { uniqueId: "REG002", name: "Bob", mobile: "0987654321" },
  //       previousStatus: 2,
  //       status: 3,
  //     },
  //     {
  //       date: new Date(),
  //       time: "11:30:00",
  //       addedBy: { name: "Emma Brown" },
  //       applicant: { uniqueId: "REG003", name: "Charlie", mobile: "5551234567" },
  //       previousStatus: 0,
  //       status: 4,
  //     },
  //   ];

  //   let data = dummyData.map((item, index) => {
  //     item.id = index + 1;
  //     item.date = moment(item?.date).format("DD-MM-YYYY");
  //     item.time1 = moment(item?.time, "hh:mm s").format("h:mm a");
  //     item.added = item?.addedBy?.name;
  //     item.registration = item?.applicant?.uniqueId;
  //     item.name = item?.applicant?.name;
  //     item.mobile = item?.applicant?.mobile;

  //     item.previousStatus =
  //       item?.previousStatus === 0 ? (
  //         <h6 style={{ color: "green" }}>{"Ongoing"}</h6>
  //       ) : item?.previousStatus === 2 ? (
  //         <h6 style={{ color: "orange" }}>{"Inactive"}</h6>
  //       ) : item?.previousStatus === 3 ? (
  //         <h6 style={{ color: "red" }}>{"Expired"}</h6>
  //       ) : item?.previousStatus === 4 ? (
  //         <h6 style={{ color: "blue" }}>{"Filled"}</h6>
  //       ) : (
  //         ""
  //       );

  //     item.status =
  //       item?.status === 0 ? (
  //         <h6 style={{ color: "green" }}>{"Ongoing"}</h6>
  //       ) : item?.status === 2 ? (
  //         <h6 style={{ color: "orange" }}>{"Inactive"}</h6>
  //       ) : item?.status === 3 ? (
  //         <h6 style={{ color: "red" }}>{"Expired"}</h6>
  //       ) : item?.status === 4 ? (
  //         <h6 style={{ color: "blue" }}>{"Filled"}</h6>
  //       ) : (
  //         ""
  //       );

  //     item.options = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <i
  //           className="fas fa-eye"
  //           style={{
  //             fontSize: "1em",
  //             cursor: "pointer",
  //             marginLeft: "0.2rem",
  //             marginRight: "0.5rem",
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     return item;
  //   });
  //   setRegistration(data);
  // }

  function handleTableData1() {
    const dummyCounts = {
      applied: 0,
      shortlisted: 0,
      placed: 0,
      rejected: 0,
    };

    setRegistration1(dummyCounts);
  }

  // const jobpostStatus = {
  //   columns: [
  //     { label: "#", field: "id", sort: "asc", width: 150 },
  //     { label: "Date", field: "date", sort: "asc", width: 400 },
  //     { label: "Time", field: "time1", sort: "asc", width: 400 },
  //     { label: "Staff", field: "added", sort: "asc", width: 200 },
  //     { label: "Prev Status", field: "previousStatus", sort: "asc", width: 200 },
  //     { label: "Current Status", field: "status", sort: "asc", width: 200 },
  //   ],
  //   rows: registration,
  // };

  const reports = [
    {
      id: 1,
      title: "Applied",
      value: registration1?.applied,
      series: [50],
      options: {
        fill: { colors: ["#34c38f"] },
        chart: { sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            hollow: { margin: 0, size: "60%" },
            track: { margin: 0 },
            dataLabels: { show: false },
          },
        },
      },
    },
    {
      id: 3,
      title: "Shortlisted",
      value: registration1?.shortlisted,
      series: [70],
      options: {
        fill: { colors: ["#34c38f"] },
        chart: { sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            hollow: { margin: 0, size: "60%" },
            track: { margin: 0 },
            dataLabels: { show: false },
          },
        },
      },
    },
    {
      id: 4,
      title: "Placed",
      value: registration1?.placed,
      series: [55],
      options: {
        fill: { colors: ["#34c38f"] },
        chart: { sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            hollow: { margin: 0, size: "60%" },
            track: { margin: 0 },
            dataLabels: { show: false },
          },
        },
      },
    },
    {
      id: 5,
      title: "Rejected",
      value: registration1?.rejected,
      series: [33],
      options: {
        fill: { colors: ["#34c38f"] },
        chart: { sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            hollow: { margin: 0, size: "60%" },
            track: { margin: 0 },
            dataLabels: { show: false },
          },
        },
      },
    },
  ];

  return (
    <div>
      <Row>
        <MiniWidget reports={reports} />
      </Row>
      <Row>
        {/* <Col className="col-12">
          <Card>
            <CardHeader style={{ fontSize: "18px" }}>
              Job Post Status Update Log
            </CardHeader>
            <CardBody>
              <MDBDataTable
                id="jobpostStatusLogTable"
                responsive
                bordered
                data={jobpostStatus}
                searching={true}
                info={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </Col> */}
        <Col xl={12}>
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  {/* Description Section */}
                                  <div className="accordion-item">
      <h2 className="accordion-header" id="headingQualification">
        <button
          type="button"
          onClick={() => toggleTab(1)}
          className={classnames("accordion-button", "fw-medium", {
            collapsed: activeTab !== 1,
          })}
          style={{ cursor: "pointer" }}
        >
          <h6>Description</h6>
        </button>
      </h2>

      <Collapse
        id="collapseQualification"
        className="accordion-collapse"
        isOpen={activeTab === 1}
      >
        <div className="accordion-body">
          <Row>
            <Col md="12">
              <table className="table table-bordered dataTable">
                <thead>
                  <tr>
                    <th>Tab</th>
                    <th>Tab Description</th>
                    <th>Contents</th>
                  </tr>
                </thead>
                <tbody>
                  {jobDescription && jobDescription.length > 0 ? (
                    jobDescription.map((item, index) => (
                      <tr key={index}>
                        <td>{item.tab || "-----"}</td>
                        <td>{item.tabDescription || "-----"}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.contents || "-----",
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No description details available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
                                </div>
                              </Col>
      </Row>
    </div>
  );
}

export default Registrations;
