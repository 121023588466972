import React, { useEffect, useRef, useState,useCallback } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import moment from "moment"
import toastr from "toastr"
import {
  dateConverter,
  renderTableValue,
  setTitle,
  jsonToExcel,
} from "../../helpers/functions"
import {  get } from "../../helpers/api_helper"

 import "./styles.scss"
import SearchField from "../../components/Common/SearchField"

const SpecializationReport
= () => {
  setTitle("Institute")

  // const todayDate = getDate()


  const formRef = useRef()
  const filterRef = useRef()


  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState({ export: false })

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [totalCount, setTotalCount] = useState(1)






  const handleTableData = useCallback(async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`;
  
      const response = await get(`report/specialization-report${query}`);
  
      const { data, count } = response;
  
      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1;
        item.date = dateConverter(item.date);
        item.specialization = renderTableValue(item.name);
        item.specializationID = renderTableValue(item.ShortCode);
        item.course = renderTableValue(item.courseName);
        item.totalInternships = renderTableValue(item.internshipCount);
        item.TotalcandidatesApplied = renderTableValue(item.appliedCount);
        item.TotalcandidatesShortListed = renderTableValue(item.shortlistedCount);
        item.TotalcandidatesRejected = renderTableValue(item.rejectedCount);
  
        return item;
      });
  
      const totalPage = Math.ceil(Number(count) / limit);
      setTotalPage(totalPage);
      setTableData(data);
      setTotalCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [filterObject, limit, page]);


  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject, handleTableData]) 

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

const reset = () => {
  if (formRef.current) {
    formRef.current.reset();
  }
  if (filterRef.current) {
    filterRef.current.reset();
  }

  setTotalPage(1);
  toTop();
  setFilterObject({});
  setTotalCount(0)

};


  const data = {
    columns: [
      { label: "#", field: "id" },
      // { label: "Date", field: "date" },
      { label: "Specialization", field: "name", style: { textAlign: 'left' } },
      { label: "Specialization ID", field: "shortCode" },
      { label: "Course", field: "course" },
      { label: "Total Internship", field: "internshipCount" },
      { label: "Total Candidates Applied", field: "appliedCount" },
      { label: "Total Candidates Shortlisted", field: "shortlistedCount" },
      { label: "Total Candidates Rejected", field: "rejectedCount" },

    ],
    rows: tableData,
  }


const handleExport = async () => {
  try {
    setLoading(prev => ({ ...prev, export: true }))
    
    const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
    const response = await get(`report/specialization-report${query}`)
    
    const { data } = response

    const exports = []
    
    if (!data.length) {
      toastr.info(`There are no Data to export`)
      return
    }

    data.map((item, index) => {
      const exportData = {}

      exportData["#"] = index + 1
    //   exportData["Date"] = dateConverter(item.date)
      exportData["specializationID"] = renderTableValue(item.shortCode)
      exportData["Specialization"] = renderTableValue(item.name)
      exportData["Course"] = renderTableValue(item.courseName)
      exportData["Total internship"] = renderTableValue(item.internshipCount)
      exportData["TotalCandidates Applied"] = renderTableValue(item.appliedCount);
      exportData["TotalCandidates ShortListed"] = renderTableValue(item.shortlistedCount);
      exportData["TotalCandidates Rejected"] = renderTableValue(item.rejectedCount);
      exports.push(exportData)

      return item
    })
    jsonToExcel(exports, `internship-${moment().format("DD-MM-YY")}`)
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(prev => ({ ...prev, export: false }))
  }
}

 
  

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Specializationwise Reports" />
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
              <Row className="align-items-center">
  <Col md={3}>
    <Label>Search</Label>
    <SearchField
      name="search"
      value={filterObject.search || ""}
      onChange={e => handleFilterValueChange(e.target)}
      placeholder="Search specialization name,short code"
    />
  </Col>
  <Col md="auto">
    <Button
      color="danger"
      onClick={reset}
      className="mt-2 mt-md-0" 
    >
      Reset
    </Button>
  </Col>
  <Col md="auto">
    <Button
      color="warning"
      onClick={handleExport}
      className="mt-2 mt-md-0"
    >
      {loading.export ? (
        <i className="fas fa-spinner fa-spin" />
      ) : (
        "Export"
      )}
    </Button>
  </Col>
</Row>

              </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="specReportTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SpecializationReport
