import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    BreadcrumbItem,
    CardHeader,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BookmarkIcon from "@mui/icons-material/Bookmark"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
import { Button } from "reactstrap"
// Tabs
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Stack from "@mui/material/Stack"
import Summary from "./Summary"
import Registrations from "./Registrations"
import { get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
import moment from "moment"

const RegistrationDetails = props => {
    const { state } = useLocation()
    // const uniqID = state.id
    const { regId } = useParams();
    const [details, setDetails] = useState({})
    const [tab3, setTab3] = React.useState("5")
    const [regDetails, setregDetails] = useState([])
    const toggle = (start = true) => {
        var body = document.body
        if (start) {
            body.classList.remove("vertical-collpsed")
            body.classList.remove("sidebar-enable")
            body.setAttribute("data-sidebar-size", "sm")
        } else {
            body.classList.add("vertical-collpsed")
            body.classList.add("sidebar-enable")
            body.setAttribute("data-sidebar-size", "lg")
        }
    }
    useEffect(() => {
        toggle()

        return () => {
            toggle(false)
        }
        // eslint-disable-next-line
    }, [])

    const handleChangeTab = (event, newValue) => {
        setTab3(newValue)
    }

    useEffect(() => {
        getAllRegistrationDetails()
    }, [regId])

    function getAllRegistrationDetails() {
        get(`job-fair/candidate-registration/detail?id=${regId}`).then((res) => {
            setregDetails(res.data)
        }).catch((err) => {

        })
    }

    const jobpostStatus = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 400,
            },
            {
                label: "Staff",
                field: "user_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Prev Status",
                field: "prevStatus",
                sort: "asc",
                width: 200,
            },
            {
                label: "Current Status",
                field: "currentStatus",
                sort: "asc",
                width: 200,
            },
        ],
        rows: [
            {
                id: "1",
                date: "13-10-2023",
                time: "11:44 am",
                user_name: "Admin",
                prevStatus: <h6 style={{ color: "green" }}>{"Upcoming"}</h6>,
                currentStatus: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
            },
        ],
    }

    const generateTooltip = name => {
        let displayText = name
        if (name && name.length > 20) {
            displayText = `${name.substring(0, 20)}...`
        }

        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                    >
                        {name}
                    </Tooltip>
                }
            >
                <span>{displayText}</span>
            </OverlayTrigger>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">
                                    Registered Candidate Details
                                </h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">Home</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="/">Job fair</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">{generateTooltip(regDetails?.candidate?.name)}</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            {/* First Dialogue */}
                            <div>
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div
                                            className="card"
                                            style={{
                                                // width: "fit-content",
                                                // width: "351px",
                                                height: "max-content",
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                            }}
                                        >
                                            <div className="card-body">
                                                <div className="float-end dropdown"></div>
                                                <h5
                                                    className="mt-2 mb-2"
                                                    style={{
                                                        textAlign: "center",
                                                        fontFamily: "IBM Plex Sans,sans-serif",
                                                    }}
                                                >
                                                    {regDetails.candidate?.name}
                                                </h5>
                                              
                                                <div
                                                    className="text-muted"
                                                    style={{
                                                        textAlign: "center",
                                                        color: "green !important",
                                                        fontFamily: "IBM Plex Sans,sans-serif",
                                                        fontSize: "14px",
                                                        marginBottom: "3px",
                                                    }}
                                                ></div>
                                                {regDetails?.jobfairStatus == "1" ? (
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            marginBottom: "3px",
                                                            color: "#f1b44c",
                                                        }}
                                                    >
                                                        {"Upcoming"}
                                                    </p>
                                                ) : regDetails?.jobfairStatus == "2" ? (
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            marginBottom: "3px",
                                                            color: "#f46a6a",
                                                        }}
                                                    >
                                                        Ongoing
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            marginBottom: "3px",
                                                            color: "#34c38f",
                                                        }}
                                                    >
                                                        Completed
                                                    </p>
                                                )}
                                                <Divider />
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <p className="mb-1">Status:</p>
                                                    <h5 className="font-size-14">{regDetails?.status == "0" ? "Active" : "In-Active"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div className="mt-2">
                                                    <p className="mb-1">Venue:</p>
                                                    <h5 className="font-size-14">{regDetails?.venue}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">QR Code:</p>
                                                    <h5 className="font-size-14">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="copy-event-tooltip">
                                                                    Download QR CODE
                                                                </Tooltip>
                                                            }
                                                        >

                                                            <i
                                                                className="fas fa-qrcode"
                                                                style={{
                                                                    fontSize: "1em",
                                                                    cursor: "pointer",
                                                                    marginRight: "0.5rem",
                                                                }}
                                                                onClick={() => {
                                                                    window.open(`${API_URL}${regDetails?.qrCode}`, "_blank");
                                                                }}
                                                            ></i>

                                                        </OverlayTrigger>
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Fee:</p>
                                                    <h5 className="font-size-14">Rs.{regDetails?.fee}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Type:</p>
                                                    <h5 className="font-size-14">{regDetails?.type == "1" ? "Offline" : "Online"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Start Date:</p>
                                                    <h5 className="font-size-14">{moment(regDetails?.startDate).format("DD-MM-YYYY")}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">End Date:</p>
                                                    <h5 className="font-size-14">{moment(regDetails?.endDate).format("DD-MM-YYYY")}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                // style={{
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "space-between",
                                                // }}
                                                >
                                                    <p className="mb-1">Registration Close for Candidate</p>
                                                    <h5 className="font-size-14">{moment(regDetails?.candidateRegistrationClosing).format("DD-MM-YYYY")}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                // style={{
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "space-between",
                                                // }}
                                                >
                                                    <p className="mb-1">Registration Close for Company</p>
                                                    <h5 className="font-size-14">{moment(regDetails?.companyRegistrationClosing).format("DD-MM-YYYY")}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Resource Person:</p>
                                                    <h5 className="font-size-14">
                                                        {regDetails?.resourcePerson}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Resource Person Mobile:</p>
                                                    <h5 className="font-size-14">
                                                        {regDetails?.resourceMobile}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Resource Person Email:</p>
                                                    <h5 className="font-size-14">
                                                        {regDetails?.resourceEmail}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Added By:</p>
                                                    <h5 className="font-size-14">
                                                        {regDetails?.addedBy?.name}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                                        <div
                                            className="mb-0 card"
                                            style={{
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                                width: "100%",
                                            }}
                                        >
                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={tab3}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            variant="fullWidth"
                                                            onChange={handleChangeTab}
                                                            aria-label="lab API tabs example"
                                                        >
                                                            <Tab
                                                                icon={
                                                                    <SummarizeIcon style={{ fontSize: "20px" }} />
                                                                }
                                                                label="Summary"
                                                                value="5"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />
                                                            <Tab
                                                                icon={
                                                                    <AssignmentIcon
                                                                        style={{ fontSize: "20px" }}
                                                                    />
                                                                }
                                                                label="Registration/Participants"
                                                                value="1"
                                                                size="small"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />

                                                        </TabList>
                                                    </Box>
                                                    <TabPanel
                                                        value="1"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <Registrations data={state?._id} />
                                                    </TabPanel>
                                                    <TabPanel
                                                        value="2"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        {/* <Costing data={state?._id} /> */}
                                                    </TabPanel>
                                                    <TabPanel
                                                        value="3"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        {/* <Gallery data={state?._id} /> */}
                                                    </TabPanel>
                                                    <TabPanel
                                                        value="5"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <Row>
                                                            <Summary data={state?._id} />
                                                        </Row>
                                                        <Row>
                                                            <Col md="3">
                                                                {details && details.pdf ? (
                                                                    <Button
                                                                        className="view-syllabus syllabus"
                                                                    >
                                                                        Download File <i class="fa fa-download"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegistrationDetails
