import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Tooltip } from "@mui/material";
import Select from "react-select";
import Swal from "sweetalert2";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TablePagination from "../../components/Common/TablePagination";

import { get, del } from "../../helpers/api_helper";
import { dateConverter, setTitle } from "../../helpers/functions";

const ContactUs = () => {
  setTitle("ContactUs");

  const formRef = useRef();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    source: null,
  });

  const limit = 18;

  useEffect(() => {
    handleTableData();
  }, [page, filters]); // Re-fetch data when filters or page change

  const handleTableData = async () => {
    try {
      const { from_date, to_date, source } = filters;
      const query = `?from_date=${from_date || ""}&to_date=${to_date || ""}&source=${
        source?.value || ""
      }&page=${page}&limit=${limit}`;
      const response = await get(`contact-us/${query}`);
      console.log("API Response:", response);

      const { data, count } = response;

      const transformedData = data.map((item, index) => ({
        id: (page - 1) * limit + index + 1,
        date: dateConverter(item.date),
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        messsage: item.message,
        options: (
          <Tooltip title="Delete" arrow>
            <i
              className="fas fa-trash-alt delete-icon"
              onClick={() => handleDelete(item._id)}
            ></i>
          </Tooltip>
        ),
      }));

      const totalPage = Math.ceil(Number(count) / limit);
      setTotalPage(totalPage);
      setTableData(transformedData);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (!result.isConfirmed) return;

      const response = await del(`contact-us/${id}`);
      toastr.success(response.message);
      handleTableData();
    } catch (error) {
      console.error(error);
      toastr.error("Failed to delete contact");
    }
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setPage(1); 
    handleTableData();
  };

  const handleReset = () => {
    formRef.current.reset();
    setFilters({ from_date: "", to_date: "", source: null });
    setPage(1);
    handleTableData();
  };

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Name", field: "name" },
      { label: "Email", field: "email" },
      { label: "Mobile", field: "mobile" },
      { label: "Message", field: "messsage" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="ContactUs" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        name="from_date"
                        className="form-control"
                        type="date"
                        onChange={(e) => handleFilterChange("from_date", e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        name="to_date"
                        className="form-control"
                        type="date"
                        onChange={(e) => handleFilterChange("to_date", e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Source</Label>
                      <Select
                        isClearable
                        menuPosition="fixed"
                        options={[
                          { value: 1, label: "WEBSITE" },
                          { value: 2, label: "APP" },
                        ]}
                        onChange={(option) => handleFilterChange("source", option)}
                      />
                    </div>
                  </Col>
                  <Col md={3} style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      {/* <Button color="primary" type="button" onClick={handleSubmit}>
                        Submit
                      </Button> */}
                      <Button color="danger" type="reset" onClick={handleReset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
