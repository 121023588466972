import { STATUS } from "../helper"

export const statusOptions = [
  { label: "Shortlist", value: STATUS.SHORTLISTED },
  { label: "Reject", value: STATUS.REJECTED },
]

export const filterStatusOptions = [
  { label: "Pending", value: STATUS.PENDING },
  { label: "Applied", value: STATUS.APPLIED },
  { label: "Shortlisted", value: STATUS.SHORTLISTED },
  { label: "Placed", value: STATUS.PLACED },
  { label: "Rejected", value: STATUS.REJECTED },
]
