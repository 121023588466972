import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"
import Swal from "sweetalert2"
import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import SearchSelect from "../../../components/Common/SearchSelect"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Icon from "../../../components/Common/Icon"

import {
  dateConverter,
  getDate,
  reactSelectCustomStyle,
  renderTableValue,
  toTop,
} from "../../../helpers/functions"

import { filterStatusOptions, statusOptions } from "./helper"
import { FORWARD, STATUS } from "../helper"

import { get, put } from "../../../helpers/api_helper"

import "./styles.scss"
import Reject from "../Reject"

const JobApplications = () => {
  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])

  const [companyOptions, setCompanyOptions] = useState([])
  const [jobsOptions, setJobOptions] = useState([])

  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [modal, setModal] = useState({ show: false, id: null })

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleJobOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/company?${params}`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`job/applications/options/job?${params}`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`job/applications/list?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.job = renderTableValue(item.job?.name)
      item.company = renderTableValue(item.company?.name)

      const candidate = item.candidate
      item.course = renderTableValue(candidate.course)
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(
        `${candidate?.firstName || ""} ${candidate?.lastName || ""}`.trim(),
      )

      item.skills = `${item.skills?.matched}/${item.skills?.total}`
      item.resume = item.resume?.uploaded

      const isForwarded = item.forwarded?.status === FORWARD.FORWARDED
      const isRejected = item.status === STATUS.REJECTED

      const status = STATUS[item.status]
      if (status) {
        item.status = (
          <Button size="sm" color={status.color} disabled>
            {status.text}
          </Button>
        )
      } else
        item.status = (
          <Select
            styles={reactSelectCustomStyle}
            menuPosition="fixed"
            options={statusOptions}
            value={""}
            isDisabled={!isForwarded}
            onChange={selected =>
              handleStatusChange({ id: item._id, status: selected?.value })
            }
          />
        )

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          <Icon
            icon="eye"
            title="View"
            // onClick={() => handleViewModal(item._id, true)}
          />
          <Icon
            icon="share"
            title="Forward"
            onClick={() => handleForward(item._id)}
            hidden={isForwarded || isRejected}
          />
          <Icon
            icon="pdf"
            title="Uploaded Resume"
            href={item.resume}
            hidden={!item.resume}
          />
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleStatusChange = async ({ id, status }) => {
    try {
      if (!id || !status) return

      if (status === STATUS.REJECTED) {
        handleToggle({ show: true, id })
        return
      }
      const response = await put(`job/applications/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const handleForward = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Forward",
      })
      if (!result.isConfirmed) return
      const response = await put(`job/applications/forward`, { id })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleViewModal = (id, show = false) => {
    setViewModal({ id, show })
  }

  const handleToggle = ({ show = false, id = null } = {}) => {
    setModal({ show, id })
    handleTableData()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Company", field: "company" },
      { label: "Job", field: "job" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Education", field: "course" },
      { label: "Skills", field: "skills" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Job - Applications" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "company" })
                    }
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={3}>
                  <Label>Job</Label>
                  <Select
                    options={jobsOptions}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "job" })
                    }
                    value={selectedFields.job || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    master={filterObject}
                    api="job/applications/options/candidate"
                  />
                </Col>

                <Col md={3}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "status" })
                    }}
                    value={selectedFields.status || ""}
                    isClearable
                    isMulti
                  />
                </Col>

                <Col className="form-button">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="jobApplicationsTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      {/* <Details
        id={viewModal.id}
        isOpen={viewModal.show}
        onToggle={() => handleViewModal()}
      /> */}
      <Reject
        id={modal.id}
        isOpen={modal.show}
        onToggle={() => handleToggle({})}
        status={modal.status}
      />
    </div>
  )
}

export default JobApplications
