import React, { useEffect, useRef, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  Container,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import SunEditor from "suneditor-react"
import Select from "react-select"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

import "katex/dist/katex.min.css"

import toastr from "toastr"
import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import FileUpload from "../../../components/Common/FileUpload"
import Approve from "./Approve"

import {
  approvalOptions,
  filterShowOptions,
  filterStatusOptions,
  initialState,
  statusOptions,
  statusValues,
  sunEditorOptions,
  TYPE_VALUES,
  typeOptions,
  unitOptions,
  unitValues,
} from "./helper"

import {
  dateConverter,
  getDate,
  // handleImageValidation,
  isIncludesAll,
  numberToString,
  reactSelectCustomStyle,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"

import { del, get, post, put } from "../../../helpers/api_helper"

import "suneditor/dist/css/suneditor.min.css"
import "./styles.scss"
import SearchField from "../../../components/Common/SearchField"
import moment from "moment"
import { SELECT_ALL } from "../../../config"

const InternshipPost = () => {
  setTitle("Internship Post")

  const formRef = useRef()
  const filterRef = useRef()

  const todayDate = getDate()
  const navigate = useNavigate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({
    ...initialState.master,
    details: [...initialState.master.details],
  })
  const [selectedFields, setSelectedFields] = useState({
    ...initialState.select,
  })

  const [modal, setModal] = useState({ show: false, id: null })

  const [companyOptions, setCompanyOptions] = useState([])
  const [branchOptions, setBranchOptions] = useState([])

  const [courseOptions, setCourseOptions] = useState([])
  const [specializationOptions, setSpecializationOptions] = useState([])
  const [feesOptions, setFeesOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [skillsOptions, setSkillsOptions] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const [filterCompanyOptions, setFilterCompanyOptions] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleCompanyOptions()
    handleCourseOptions()
    handleFeeOptions()
    handleCategoryOptions()

    handleFilterCompanyOptions()
  }, []) //eslint-disable-line

  const handleCompanyOptions = async () => {
    try {
      const response = await get(`options/company`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleBranchOptions = async (company = "") => {
    try {
      const response = await get(`options/branch?company=${company}`)
      setBranchOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCourseOptions = async () => {
    try {
      const response = await get(`options/course`)
      setCourseOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSpecializationOptions = async (course = "") => {
    try {
      const params = queryString.stringify({ course })
      const response = await get(`options/specializations?${params}`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`options/districts?${params}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFeeOptions = async () => {
    try {
      const response = await get(`options/fees`)
      setFeesOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCategoryOptions = async () => {
    try {
      const response = await get(`options/category`)
      setCategoryOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSkillsOptions = async (category = "") => {
    try {
      const response = await get(`options/skills?category=${category}`)
      setSkillsOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCompanyOptions = async () => {
    try {
      const response = await get(`internship/options/filter/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`/internship${query}`)

    const { data, count } = response
    data?.map((item, index) => {
      item.id = (currentPage - 1) * limit + index + 1

      item.date = dateConverter(item.date)
      item.expiryDate = dateConverter(item.expiryDate)

      item.name = renderTableValue(item?.name)
      item.company = renderTableValue(item?.company?.name)

      item.vacancy = (
        <Tooltip
          title="Available / Total"
          arrow
          placement="right"
        >{`${numberToString(item.availableVacancy)}/${numberToString(item.vacancies)}`}</Tooltip>
      )

      const isApproved = item.approvalStatus === 1
      const status = statusOptions.find(status => status.value === item.status)
      item.status = (
        <Select
          styles={reactSelectCustomStyle}
          menuPosition="fixed"
          options={statusOptions}
          defaultValue={status}
          isDisabled={!isApproved}
          onChange={selected => handleStatusChange(item._id, selected.value)}
        />
      )
      if (item.approvalStatus === 3) {
        item.approval = (
          <Select
            styles={reactSelectCustomStyle}
            menuPosition="fixed"
            options={approvalOptions}
            value={""}
            onChange={selected =>
              handleApproveStatusChange(item._id, selected.value)
            }
          />
        )
      } else {
        const statusObj = statusValues[item.approvalStatus]

        item.approval = (
          <Button size="sm" color={statusObj?.color} disabled>
            {statusObj?.text}
          </Button>
        )
      }

      item.options = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Show" arrow placement="top">
            <div style={{ marginRight: "0.5rem" }}>
              <Input
                name="status"
                type="checkbox"
                defaultChecked={item.show}
                disabled={!isApproved}
                onChange={e =>
                  handleShowCheckChange(item._id, e.target.checked)
                }
              />
            </div>
          </Tooltip>

          <Tooltip title="View" arrow placement="top">
            <i
              className="fas fa-eye eye-icon"
              onClick={() => {
                navigate(`/internship/post/${item._id}`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Edit" arrow placement="top">
            <i
              className="fas fa-edit edit-icon"
              onClick={() => handleUpdate(item._id)}
            ></i>
          </Tooltip>
          <Tooltip title="Delete" arrow placement="top">
            <i
              className="fas fa-trash-alt delete-icon"
              onClick={() => handleDelete(item._id)}
            ></i>
          </Tooltip>
        </div>
      )

      return item
    })
    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`internship/update/${id}`)
      const data = response.data

      const unit = data.unit
        ? unitOptions.find(option => option.value === data.unit)
        : ""

      const type = data.type
        ? typeOptions.find(option => option.value === data.type)
        : ""

      const details = initialState.master.details
        .map(defaultItem => {
          const responseItem = data?.details.find(
            item => item.tab === defaultItem.tab,
          )
          return responseItem
            ? { ...defaultItem, ...responseItem }
            : defaultItem
        })
        .sort((a, b) => Number(a.tab) - Number(b.tab))

      setMasterObject({
        ...data,
        details,
        id,

        company: data?.company?.value,
        branch: data?.branch?.value,

        fees: data?.fees?.value,
        category: data?.category?.value,

        courses: data?.coursesIds,
        specializations: data?.specializationsIds,

        skills: data?.skillIds,
        state: data?.stateIds,
        district: data?.districtIds,
      })

      setSelectedFields({
        ...initialState.select,
        unit,
        type,

        company: data.company,
        branch: data.branch,

        fees: data.fees,
        category: data.category,
        skills: data.skills,

        courses: data.courses,
        specializations: data.specializations,

        state: data.state,
        district: data.district,
      })

      handleSkillsOptions(data?.category)
      handleDistrictOptions(data?.state?.value)
      handleBranchOptions(data?.company?.value)
      handleSpecializationOptions(data?.coursesIds)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`internship/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`internship`, masterObject)
      else response = await post(`internship`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleShowCheckChange = async (id, checked) => {
    try {
      const response = await put(`internship/show`, { id, checked })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`internship/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleApproveStatusChange = async (id, status) => {
    try {
      if (status === 1) {
        handleToggle({ show: true, id })
        return
      }
      const response = await put(`internship/approval`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }

    if (name === "state") obj.district = null
    if (name === "company") obj.branch = null
    if (name === "category") obj.skills = null

    if (name === "courses") {
      if (isIncludesAll(selected)) selected = courseOptions
      obj.specializations = null
    }
    if (name === "specializations") {
      if (isIncludesAll(selected)) selected = specializationOptions
    }
    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value

    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }

    if (name === "company") {
      obj.branch = null
      handleBranchOptions(value)
    }

    if (name === "courses") {
      obj.specializations = null
      handleSpecializationOptions(value)
    }

    if (name === "category") {
      obj.skills = null
      handleSkillsOptions(value)
    }

    if (name === "startDate" || name === "duration" || name === "unit") {
      obj.endDate = moment(obj.startDate)
        .add(obj.duration, unitValues[obj.unit])
        .format("YYYY-MM-DD")
    }

    setMasterObject(obj)
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    setSelectFilter(prev => ({ ...prev, [name]: selected }))
    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleFilterValueChange({ value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handleSubEditorChange = ({ name, value, idx }) => {
    setMasterObject(prevState => ({
      ...prevState,
      details: prevState.details.map((item, i) =>
        i === idx ? { ...item, [name]: value } : item,
      ),
    }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleToggle = ({ show = false, id = null } = {}) => {
    setModal({ show, id })
    handleTableData()
  }

  const getSelectAllOptions = ({ options, name }) => {
    if (
      options.length === 0 ||
      options.length !== selectedFields[name]?.length
    ) {
      return [SELECT_ALL, ...options]
    }
    return options
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()

    toTop()
    setMasterObject({
      ...initialState.master,
      details: initialState.master.details.map(item => ({
        ...item,
        contents: "",
      })),
    })

    setSelectedFields({ ...initialState.select })

    handleFilterCompanyOptions()
    setFilterObject({})
    setSelectFilter({})
  }

  const filterReset = () => {
    filterRef.current.reset()

    setFilterObject({})
    setSelectFilter({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Name", field: "name" },
      { label: "Expiry Date", field: "expiryDate" },
      { label: "Vacancies", field: "vacancy" },
      { label: "Status", field: "status" },
      { label: "Approval", field: "approval" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Internship Post" />

        <Card>
          <CardBody>
            <AvForm
              onValidSubmit={handleSubmit}
              ref={formRef}
              id="internshipPostForm"
            >
              <Row>
                <Col md={3} className="mb-3">
                  <Label>
                    Company<span className="required">*</span>
                  </Label>
                  <Select
                    name="company"
                    menuPosition="fixed"
                    options={companyOptions}
                    value={selectedFields.company || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "company")
                    }
                  />
                </Col>

                <Col md={3} className="mb-3">
                  <Label>
                    Branch<span className="required">*</span>
                  </Label>
                  <Select
                    name="branch"
                    menuPosition="fixed"
                    options={branchOptions}
                    value={selectedFields.branch || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "branch")
                    }
                    isDisabled={!masterObject.company}
                  />
                </Col>

                <Col md={3} className="mb-3">
                  <Label>
                    Name<span className="required">*</span>
                  </Label>
                  <AvField
                    name="name"
                    type="text"
                    placeholder="Name"
                    errorMessage="Enter Name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                {!masterObject.id || masterObject.approvalStatus === 1 ? (
                  <>
                    <Col md={3} className="mb-3">
                      <Label>
                        Type<span className="required">*</span>
                      </Label>
                      <Select
                        name="type"
                        menuPosition="fixed"
                        options={typeOptions}
                        value={selectedFields.type || ""}
                        onChange={selected =>
                          handleSelectValueChange(selected, "type")
                        }
                      />
                    </Col>
                    {masterObject.type === TYPE_VALUES.KTU ? (
                      <Col md={3} className="mb-3">
                        <Label>
                          Fees<span className="required">*</span>
                        </Label>
                        <Select
                          name="fees"
                          menuPosition="fixed"
                          options={feesOptions}
                          value={selectedFields.fees || ""}
                          onChange={selected =>
                            handleSelectValueChange(selected, "fees")
                          }
                        />
                      </Col>
                    ) : masterObject.type === TYPE_VALUES.PSU ? (
                      <Col md={3} className="mb-3">
                        <Label>
                          Fees<span className="required">*</span>
                        </Label>
                        <AvField
                          name="feeAmount"
                          type="number"
                          placeholder="Fees"
                          errorMessage="Enter Fees"
                          validate={{
                            required: { value: true },
                            min: {
                              value: 1,
                              error: "Fees must be greater than 0",
                            },
                          }}
                          value={masterObject.feeAmount || ""}
                          onChange={e => handleValueChange(e.target)}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                <Col md={3} className="mb-3">
                  <Label>Category/Sector</Label>
                  <Select
                    name="category"
                    menuPosition="fixed"
                    options={categoryOptions}
                    value={selectedFields.category || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "category")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Skills</Label>
                  <Select
                    isMulti
                    isDisabled={!masterObject.category}
                    name="skills"
                    menuPosition="fixed"
                    options={skillsOptions}
                    value={selectedFields.skills || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "skills")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Job Roles</Label>
                  <AvField
                    name="jobRoles"
                    type="text"
                    placeholder="Job Roles"
                    value={masterObject.jobRoles || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>
                    Courses/Qualifications<span className="required">*</span>
                  </Label>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    options={getSelectAllOptions({
                      options: courseOptions,
                      name: "courses",
                    })}
                    value={selectedFields.courses || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "courses")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>
                    Specializations<span className="required">*</span>
                  </Label>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    isDisabled={!masterObject.courses?.length}
                    options={getSelectAllOptions({
                      options: specializationOptions,
                      name: "specializations",
                    })}
                    value={selectedFields.specializations || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "specializations")
                    }
                  />
                </Col>

                <Col md="3 i2">
                  <div className="mb-2 i1 ">
                    <Label>
                      Duration<span className="required">*</span>
                    </Label>
                    <AvField
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter Duration",
                        },
                        isPercentage: value => {
                          const percentage = parseFloat(value)
                          return !isNaN(percentage) && percentage >= 0
                        },
                      }}
                      errorMessage="Enter a valid value"
                      placeholder="duration"
                      name="duration"
                      type="Number"
                      defaultValue={masterObject.duration || ""}
                      value={masterObject.duration || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </div>

                  <div className="mb-2 i1 i3">
                    <Select
                      name="unit"
                      options={unitOptions}
                      value={selectedFields.unit || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "unit")
                      }
                      menuPosition="fixed"
                    />
                  </div>
                </Col>

                <Col md={2} className="mb-3">
                  <Label>
                    Vacancies<span className="required">*</span>
                  </Label>
                  <AvField
                    name="vacancies"
                    type="number"
                    placeholder="Vacancies"
                    validate={{
                      number: true,
                      required: {
                        value: true,
                        errorMessage: "Enter Vacancies",
                      },
                      min: {
                        value: 1,
                        errorMessage: "Vacancies must be greater than 0",
                      },
                      isPercentage: value => {
                        const percentage = parseFloat(value)
                        if (value && (isNaN(percentage) || percentage < 0)) {
                          return "Percentage must be a valid number greater than or equal to 0"
                        }
                        return true
                      },
                    }}
                    errorMessage="Enter a valid vacancies"
                    value={masterObject.vacancies || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>Stipend</Label>
                  <AvField
                    name="stipend"
                    type="text"
                    validate={{
                      isPercentage: value => {
                        const percentage = parseFloat(value)
                        if (value !== "")
                          return !isNaN(percentage) && percentage >= 0
                        return false
                      },
                    }}
                    errorMessage="Enter Stipend"
                    placeholder="Stipend"
                    value={masterObject.stipend || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>
                    Expected Start Date<span className="required">*</span>
                  </Label>
                  <AvField
                    name="startDate"
                    type="date"
                    defaultValue={masterObject.startDate || ""}
                    value={masterObject.startDate || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Enter Start Date",
                      },
                      min: !masterObject.id
                        ? {
                            value: todayDate,
                            errorMessage:
                              "Start Date should be greater than today date",
                          }
                        : { value: 0 },
                    }}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Label>
                    Expected End Date<span className="required">*</span>
                  </Label>
                  <AvField
                    name="endDate"
                    type="date"
                    defaultValue={masterObject.endDate || ""}
                    value={masterObject.endDate || ""}
                    onChange={e => handleValueChange(e.target)}
                    disabled
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Enter End Date",
                      },
                      min: {
                        value: masterObject.startDate,
                        errorMessage:
                          "End Date should be greater than start date",
                      },
                    }}
                  />
                </Col>

                <Col md={2} className="mb-3">
                  <Label>
                    Expiry Date<span className="required">*</span>
                  </Label>
                  <AvField
                    name="expiryDate"
                    type="date"
                    value={masterObject.expiryDate || ""}
                    onChange={e => handleValueChange(e.target)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Enter Expiry Date",
                      },
                      min: !masterObject.id
                        ? {
                            value: todayDate,
                            errorMessage:
                              "Expiry date should be greater than today date",
                          }
                        : { value: 0 },
                      max: {
                        value: masterObject.endDate,
                        errorMessage:
                          "Expiry date should be less than end date",
                      },
                    }}
                    errorMessage="Enter Expiry Date"
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>
                    State<span className="required">*</span>
                  </Label>
                  <Select
                    name="state"
                    isMulti
                    menuPosition="fixed"
                    options={stateOptions}
                    value={selectedFields.state || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "state")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <Label>
                      District<span className="required">*</span>
                    </Label>
                    <Select
                      isMulti
                      menuPosition="fixed"
                      options={districtOptions}
                      value={selectedFields.district || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "district")
                      }
                    />
                  </div>
                </Col>

                {masterObject.details?.map((item, idx) => (
                  <Row>
                    <Col md={3} className="mb-3">
                      <Label>Tab name ({idx + 1})</Label>
                      <AvField
                        name={`tabDescription${idx}`}
                        defaultValue={item.tabDescription || ""}
                        onChange={e =>
                          handleSubEditorChange({
                            name: "tabDescription",
                            value: e.target.value,
                            idx,
                          })
                        }
                      />
                    </Col>

                    <Col md={12} className="mb-3">
                      <SunEditor
                        lang="en"
                        setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                        setOptions={sunEditorOptions}
                        onChange={value =>
                          handleSubEditorChange({
                            name: "contents",
                            value,
                            idx,
                          })
                        }
                        setContents={item.contents || ""}
                      />
                    </Col>
                  </Row>
                ))}
                <Col className="mb-3" style={{ paddingTop: "25px" }}>
                  <Button
                    className="me-2"
                    color={masterObject._id ? "warning" : "primary"}
                    type="submit"
                  >
                    {masterObject._id ? "Update" : "Submit"}
                  </Button>
                  <Button color="danger" type="button" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Company</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCompanyOptions}
                    value={selectFilter.company || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "company" })
                    }
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterStatusOptions}
                    value={selectFilter.status || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "status" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={2}>
                  <Label>Show</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterShowOptions}
                    value={selectFilter.show || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "show" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectFilter.type || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "type" })
                    }
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name"
                  />
                </Col>
                <Col md={2} className="mb-3 form-button">
                  <Button color="danger" onClick={filterReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              key={JSON.stringify(data)}
              tableId="internshipPostTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
      <Approve
        id={modal.id}
        isOpen={modal.show}
        feeOptions={feesOptions}
        typeOptions={typeOptions}
        onToggle={() => handleToggle({})}
      />
    </div>
  )
}

export default InternshipPost
