export const statusOptions = [
  { label: "Place", value: 3 },
  { label: "Reject", value: 5 },
]

export const sampleJobApplicationData = [
  {
    applicant: {
      name: "John Doe",
      mobile: "9876543210",
      resume: "resume_john_doe.pdf",
    },
    job: { name: "Job 1" },
    company: { name: "Tech Corp" },
    status: 3,
    uniqueId: "JA240700001",
    date: "2024-07-01",
  },
  {
    applicant: { name: "Jane Smith", mobile: "9876543211", resume: "" },
    job: { name: "Job 2" },
    company: { name: "Data Minds" },
    status: 1,
    uniqueId: "JA240700002",
    date: "2024-07-02",
  },
  {
    applicant: {
      name: "David Johnson",
      mobile: "9876543212",
      resume: "resume_david_johnson.pdf",
    },
    job: { name: "Job 3" },
    company: { name: "Innovate Tech" },
    status: 4,
    uniqueId: "JA240700003",
    date: "2024-07-03",
  },
  {
    applicant: { name: "Emily Brown", mobile: "9876543213", resume: "" },
    job: { name: "Job 1" },
    company: { name: "Code Ninjas" },
    status: 2,
    uniqueId: "JA240700004",
    date: "2024-07-04",
  },
  {
    applicant: {
      name: "Michael Green",
      mobile: "9876543214",
      resume: "resume_michael_green.pdf",
    },
    job: { name: "Job 4" },
    company: { name: "Design Works" },
    status: 5,
    uniqueId: "JA240700005",
    date: "2024-07-05",
  },
  {
    applicant: {
      name: "Jessica Lee",
      mobile: "9876543215",
      resume: "resume_jessica_lee.pdf",
    },
    job: { name: "Job 5" },
    company: { name: "Tech Solutions" },
    status: 2,
    uniqueId: "JA240700006",
    date: "2024-07-06",
  },
  {
    applicant: { name: "Mark Wilson", mobile: "9876543216", resume: "" },
    job: { name: "Job 3" },
    company: { name: "Market Wizards" },
    status: 3,
    uniqueId: "JA240700007",
    date: "2024-07-07",
  },
  {
    applicant: {
      name: "Sophia Anderson",
      mobile: "9876543217",
      resume: "resume_sophia_anderson.pdf",
    },
    job: { name: "Job 1" },
    company: { name: "Human Connect" },
    status: 1,
    uniqueId: "JA240700008",
    date: "2024-07-08",
  },
  {
    applicant: {
      name: "Chris Evans",
      mobile: "9876543218",
      resume: "resume_chris_evans.pdf",
    },
    job: { name: "Job 2" },
    company: { name: "Network Solutions" },
    status: 5,
    uniqueId: "JA240700009",
    date: "2024-07-09",
  },
]
