import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import "./styles.scss"
import {
  dateConverter,
  numberToCurrency,
  toTop,
} from "../../../helpers/functions"
import CreditFollowup from "../IncomeExpense/followup"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import queryString from "query-string"
import TablePagination from "../../../components/Common/TablePagination"
import { STATUS_VALUES, TYPE_VALUES } from "../IncomeExpense/helper"

const Credits = () => {
  const filterRef = useRef()

  const filterStatusOptions = [
    { label: "Approved", value: STATUS_VALUES.APPROVED },
    { label: "Canceled", value: STATUS_VALUES.CANCELED },
    { label: "Pending", value: STATUS_VALUES.PENDING },
  ]

  const headOptions = [
    { label: "Income", value: TYPE_VALUES.INCOME },
    { label: "Expense", value: TYPE_VALUES.EXPENSE },
  ]

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({ paymentType: 1 })
  const [filterSelectObject, setFilterSelectObject] = useState({})

  const [filterSubHeadOptions, setFilterSubHeadOptions] = useState([])

  const [selectedId, setSelectedId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  useEffect(() => {
    handleFilterSubHeadOptions()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = async (perPage = page) => {
    try {
      const params = `page=${perPage}&limit=${limit}&${queryString.stringify(
        filterObject,
      )}`
      const response = await get(`income-expense/credit/list?${params}`)
      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        item.date = dateConverter(item.date)
        item.head = TYPE_VALUES[item.head]
        item.subHead = item?.subHead?.name
        item.chartOfAccount = item?.chartOfAccount?.name
        item.pending = Number(item.amount || 0) - Number(item.received || 0)

        item.pendingText = numberToCurrency(item.pending)
        item.amountText = numberToCurrency(item.amount)
        item.receivedText = numberToCurrency(item.received)

        const status = Number(item.status)
        const isPending = status === 3

        if (isPending) {
          item.options = (
            <i
              className="fas fa-money-check money-icon"
              onClick={() => handleCreditEdit(item._id)}
            />
          )
        } else {
          item.options = (
            <Button color={STATUS_VALUES[status]?.color} size="sm" disabled>
              {STATUS_VALUES[status]?.title}
            </Button>
          )
        }
        return item
      })
      setTableData(data)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {}
  }

  const handleFilterSubHeadOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `income-expense/filter/options/sub-head?${params}`,
      )
      setFilterSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleFilterSelectValueChange = (selected, name) => {
    const obj = { ...filterSelectObject }
    obj[name] = selected
    setFilterSelectObject(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleCreditEdit = async id => {
    setIsOpen(true)
    setSelectedId(id)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleModalClose = () => {
    setIsOpen(false)
    setSelectedId(null)
    handleTableData()
  }

  const reset = () => {
    filterRef.current?.reset()
    toTop()

    setFilterObject({ paymentType: 1 })
    setFilterSelectObject({})
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Head", field: "head" },
      { label: "Sub Head", field: "subHead" },
      { label: "Chart Of Account", field: "chartOfAccount" },
      { label: "Amount", field: "amountText" },
      { label: "Received", field: "receivedText" },
      { label: "Pending", field: "pendingText" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Credit Transactions" />
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>From</Label>
                    <AvField
                      name="from"
                      type="date"
                      value={filterObject.form}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>To</Label>
                    <AvField
                      name="to"
                      type="date"
                      value={filterObject.to}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>

                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="head"
                    options={headOptions}
                    value={filterSelectObject.head || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "head")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Sub Head</Label>
                  <Select
                    name="subHead"
                    options={filterSubHeadOptions}
                    value={filterSelectObject.subHead || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "subHead")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    value={filterSelectObject.status || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "status")
                    }
                  ></Select>
                </Col>
                <Col md="1" className="form-button">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="creditsTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </div>
      <CreditFollowup
        id={selectedId}
        onToggle={handleModalClose}
        isOpen={isOpen}
      />
    </div>
  )
}

export default Credits
