import PropTypes from "prop-types"

const SelectErrorMessage = ({
  message,
  show,
  customStyle = {
    marginTop: "-0.75rem",
    width: "100%",
    fontSize: "87.5%",
    color: "var(--bs-form-invalid-color)",
  },
}) => {
  return <>{show && <div style={customStyle}>{message}</div>}</>
}

SelectErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
}

export default SelectErrorMessage
