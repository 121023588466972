import React from "react"
import { Button } from "reactstrap"

function LoadingButton({
  onClick = () => {},
  loading = false,
  disabled,
  children,
  ...props
}) {
  return (
    <Button
      loading={true}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {children}{" "}
      {loading && (
        <>
          {" "}
          <i className="fas fa-spinner fa-spin" />
        </>
      )}
    </Button>
  )
}

export default LoadingButton
