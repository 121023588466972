import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { del, get, post, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  renderTableValue,
  setTitle,
} from "../../../helpers/functions"

import "./styles.scss"

const Faq = () => {
  setTitle("FAQ")

  const formRef = useRef()

  const typeOptions = [
    { label: "Candidate", value: 1 },
    { label: "Institute", value: 2 },
    { label: "Company", value: 3 },
  ]

  const typeValues = {
    1: "Candidate",
    2: "Institute",
    3: "Company",
  }

  const [categoryOptions, setCategoryOptions] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const [tableData, setTableData] = useState([])

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleCategoryOptions = async type => {
    try {
      const response = await get(`options/faq-category?type=${type}`)
      setCategoryOptions(response.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`faq${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.type = renderTableValue(typeValues[item.type])
        item.category = renderTableValue(item.category?.name)
        item.videoUrl = (
          <p>
            <a href={item.videoUrl} target="_blank" rel="noreferrer">
              {item.videoUrl}
            </a>
          </p>
        )

        item.question = (
          <div>
            <Tooltip title={item.question} placement="top" arrow>
              <p>{item.question}</p>
            </Tooltip>
          </div>
        )
        item.answer = (
          <Tooltip title={item.answer} placement="top" arrow>
            <p>{item.answer}</p>
          </Tooltip>
        )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`faq`, masterObject)
      else response = await post(`faq`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`faq/update/${id}`)
      const data = response.data
      setMasterObject({ ...data, category: data?.category?.value, id })
      setSelectedFields({
        ...data,
        type: { label: typeValues[data.type], value: data.type },
      })
      toTop()
      handleCategoryOptions(data.type)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`faq/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected?.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
    if (name === "type") {
      handleCategoryOptions(value)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})
    setSelectedFields({})
    setCategoryOptions([])

    handleTableData(1)

    setPage(1)
    setTotalPage(1)
    toTop()
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Type", field: "type" },
      { label: "Category", field: "category" },
      { label: "Question", field: "question" },
      { label: "Answer", field: "answer" },
      { label: "Video", field: "videoUrl" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Faq" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                <Row>
                  <Col md={2}>
                    <Label>Type</Label>
                    <Select
                      menuPosition="fixed"
                      options={typeOptions}
                      value={selectedFields.type || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "type")
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Category</Label>
                    <Select
                      menuPosition="fixed"
                      options={categoryOptions}
                      value={selectedFields.category || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "category")
                      }
                    />
                  </Col>

                  <Col md={8}>
                    <Label>Question</Label>
                    <AvField
                      name="question"
                      placeholder="Question"
                      errorMessage="Enter Question"
                      validate={{ required: { value: true } }}
                      value={masterObject.question || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={4}>
                    <Label>Answer</Label>
                    <AvField
                      name="answer"
                      placeholder="Answer"
                      type="text"
                      errorMessage="Enter Designation"
                      validate={{ required: { value: true } }}
                      value={masterObject.answer || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Video Url</Label>
                    <AvField
                      name="videoUrl"
                      placeholder="Video Url"
                      type="text"
                      errorMessage="Enter Video Url"
                      value={masterObject.videoUrl || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md={3} style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      <Button color={masterObject.id ? "warning" : "primary"}>
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color="danger" type="reset" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="faq"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Faq
