import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvForm,AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import queryString from "query-string"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import { jsonToExcel } from "../../helpers/functions"
import {
  dateConverter,
  renderTableValue,
  setTitle,
  getDate
} from "../../helpers/functions"
import { get } from "../../helpers/api_helper"
import SearchField from "../../components/Common/SearchField"
import { useTransition } from "react"
const CompanyUserWiseReport = props => {
  setTitle("Company")
  const formRef = useRef(null)
  const filterRef = useRef(null)
  const [tableData, setTableData] = useState([])
  const [filterCompanyOption, setFilterCompanyOptions] = useState([])
  const limit = 13
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})
  const [exports, setExports] = useState([])
  const todayDate = getDate()
  const [isExportPending, startExport] = useTransition()
  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleFilterCompanyOptions()
  }, []) // eslint-disable-line

  const handleFilterCompanyOptions = async (state = "") => {
    try {
      const response = await get(`/options/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      //   console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const exportData = []
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/report/company-user-report${query}`)
      console.log("response", response)
      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)
        item.shortCode = renderTableValue(item.shortCode)
        item.contactPerson = renderTableValue(item.contactPerson)
        item.mobile = renderTableValue(item.mobile)
        const dataExport = {}
        dataExport["#"] = index + 1
        dataExport["Date of Registration"] = item.date
        dataExport["Company ID"] = item.companyId
        dataExport["Company Name"] = item.companyName
        dataExport["Company Address"] = item.address
        dataExport["Company Location"] = item.location
        dataExport["Company Emailid"] = item.companyEmail
        dataExport["Short Code"] = item.shortCode
        dataExport["Company Type"] = item.companyType
        dataExport["Name of Contact Person"] = item.name
        dataExport["SPOC Designation"] = item.designation
        dataExport["SPOC Mobile"] = item.mobile
        dataExport["SPOC Email"] = item.email

        exportData.push(dataExport)
        setExports(exportData)
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      startExport(() => {
        handleExportData(count)
      })
      setTableData(data)
    } catch (error) {
      //   console.error(error)
    }
  }


  const handleExportData = async (count) => {
    try {
      const exportData = []
      const query = `?page=0&limit=${count}&${queryString.stringify(filterObject)}`
      const response = await get(`/report/company-user-report${query}`)
      const { data } = response
  
      data.forEach((item, index) => {
        item.date = dateConverter(item.date);
  
        exportData.push({
          "#": index + 1,
          "Date of Registration": item.date,
          "Company ID": item.companyId,
          "Company Name": item.companyName,
          "Company Address": item.address,
          "Company Location": item.location,
          "Company Emailid": item.companyEmail,
          "Short Code": renderTableValue(item.shortCode),
          "Company Type": item.companyType,
          "Name of Contact Person": renderTableValue(item.name),
          "SPOC Designation": item.designation,
          "SPOC Mobile": renderTableValue(item.mobile),
          "SPOC Email": item.email,
        });
      });
      setExports(exportData)
    } catch (error) {
      // console.error("Error exporting data:", error)
    }
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    if (name === "state") {
      obj.district = null
    }
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      //   handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    if (formRef.current) formRef.current.reset()
    if (filterRef.current) filterRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
    handleTableData([])
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Designation", field: "designation" },
      { label: "email", field: "email" },
      { label: "Company ID", field: "companyId" },
      { label: "Company Name", field: "companyName" },
      { label: "Company Address", field: "address" },
      { label: "Company Location", field: "location" },
      { label: "Company Email ID", field: "companyEmail" },
      { label: "Short Code", field: "shortCode" },
      { label: "Company Type", field: "companyType" },
      { label: "Branch", field: "branch" },
      { label: "Added By", field: "addedBy" },
    ],
    rows: tableData,
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `company-user-reports`)
    } else {
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Company User Wise Reports"
          />

          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.from}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>Company</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterCompanyOption}
                      value={selectFilter.company || ""}
                      onChange={selected =>
                        handleFilterSelectChange({ selected, name: "company" })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search Name,company ID,email, mobile"
                    />
                  </Col>
                  <Col md={2} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <Button
                      style={{ marginLeft: "14px" }}
                      color="warning"
                      type="button"
                      disabled={isExportPending}
                      onClick={handleExport}
                    >
                      Export{" "}
                      {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="companyTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyUserWiseReport
