import React, { useEffect, useState } from "react"
import MiniWidget from "./mini-widget"
import { Row } from "reactstrap"
import moment from "moment"
import { get } from "../../helpers/api_helper"
// import "../style.scss"
function JobpostSummary({ jobObjId }) {
    
    useEffect(() => {
        fetchSummary()
    }, [jobObjId])

    const [summary, setSummary] = useState([])

    function fetchSummary(){
        get(`job-fair/job/summary?id=${jobObjId}`).then((res)=>{
            setSummary(res.data)
        }).catch((err)=>{
            setSummary([])
        })
    }

    const series1 = [100]
    const options1 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const series2 = [70]

    const options2 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const series3 = [55]

    const options3 = {
        fill: {
            colors: ["#34c38f"],
        },
        chart: {
            sparkline: {
                enabled: !0,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "60%",
                },
                track: {
                    margin: 0,
                },
                dataLabels: {
                    show: !1,
                },
            },
        },
    }

    const reports = [
        {
         id: 1,
        title: "Total Placed",
        value: summary?.totalPlaced||0,
        decimal: 0,
        charttype: "radialBar",
        chartheight: 45,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        series: series1,
        options: options1,
    },
    {
        id: 2,
       title: "Total Rejected",
       value: summary?.totalRejected||0,
       decimal: 0,
       charttype: "radialBar",
       chartheight: 45,
       chartwidth: 45,
       prefix: "",
       suffix: "",
       series: series1,
       options: options1,
   },
   {
    id: 1,
   title: "Total Applied",
   value: summary?.totalApplied||0,
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
},
{
    id: 1,
   title: "Available Vacancy",
   value: summary?.availableVacancy||0,
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
},
{
    id: 1,
   title: "InterestedCandidates",
   value: summary?.interestedCandidates||0,
   decimal: 0,
   charttype: "radialBar",
   chartheight: 45,
   chartwidth: 45,
   prefix: "",
   suffix: "",
   series: series1,
   options: options1,
}
]
    return (
        <div>
            <Row>
                <MiniWidget reports={reports} />
            </Row>
        </div>
    )
}

export default JobpostSummary
