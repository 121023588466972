import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import TablePagination from "../../components/Common/TablePagination"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  dateConverter,
  getDate,
  renderTableValue,
  timeConverter,
} from "../../helpers/functions"
import { get, put } from "../../helpers/api_helper"
import {
  CATEGORY_VALUES,
  categoryOptions,
  STATUS_VALUES,
  statusOptions,
} from "./helper"

import "./styles.scss"

const Notification = () => {
  const today = getDate()

  const [list, setList] = useState([])

  const [selectedFields, setSelectedFields] = useState({})
  const [filterObject, setFilterObject] = useState({})

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    handleData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  const handleData = async (perPage = page) => {
    try {
      const params = `page=${perPage}&limit=${limit}&${queryString.stringify(
        filterObject,
      )}`
      const response = await get(`/notifications/list?${params}`)

      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        item.date = renderTableValue(dateConverter(item.date))
        item.time = renderTableValue(timeConverter(item.time))

        item.category = renderTableValue(CATEGORY_VALUES[item.category])
        const status = STATUS_VALUES[item.readStatus]

        if (status)
          item.readStatus = (
            <Button onClick={() => readNotification(item._id)} {...status}>
              {status.text}
            </Button>
          )
        else item.readStatus = ""
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setList(data)
    } catch (error) {}
  }

  const readNotification = async id => {
    try {
      await put("/notifications/read", { id })
      handleData()
    } catch (err) {}
  }

  const handleSelectValueChange = (selected, name) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))
    handleValueChange({ value: selected.value, name })
  }
  const handleValueChange = ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleData(value)
  }

  const reset = () => {
    setFilterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Category", field: "category" },
      { label: "Title", field: "title" },
      { label: "Message", field: "body" },
      { label: "Status", field: "readStatus" },
    ],
    rows: list,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Notification" />

        <Card>
          <CardBody>
            <AvForm>
              <Row>
                <Col md={2}>
                  <Label>From Date</Label>
                  <AvField
                    name="fromDate"
                    type="date"
                    value={filterObject.fromDate || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={today}
                  />
                </Col>
                <Col md={2}>
                  <Label>To Date</Label>
                  <AvField
                    name="toDate"
                    type="date"
                    value={filterObject.toDate || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={filterObject.from}
                  />
                </Col>
                <Col md={2}>
                  <Label>From Time</Label>
                  <AvField
                    name="fromTime"
                    type="time"
                    value={filterObject.fromTime || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>To Time</Label>
                  <AvField
                    name="toTime"
                    type="time"
                    value={filterObject.toTime || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Category</Label>
                  <Select
                    options={categoryOptions}
                    value={selectedFields.category || ""}
                    onChange={selected => {
                      handleSelectValueChange(selected, "category")
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={statusOptions}
                    value={selectedFields.status || ""}
                    onChange={selected => {
                      handleSelectValueChange(selected, "status")
                    }}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Button color="danger" type="button" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="notificationTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Notification
