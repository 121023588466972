import React, { useState, useEffect, useRef } from "react"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import toastr from "toastr"
import queryString from "query-string"

import {
  getDate,
  getOneMonthAgo,
  numberToCurrency,
  setTitle,
} from "../../../../helpers/functions"
import { get } from "../../../../helpers/api_helper"

import "./styles.scss"

const ProfitLoss = () => {
  setTitle("Profit And Loss")

  const ref = useRef()
  const todayDate = getDate()

  const emptyState = { amount: 0, total: 0, data: [] }
  const initialState = { from: getOneMonthAgo(), to: getDate(), subHead: [] }
  const selectInitialState = { subHead: "" }

  const [typeOptions, setTypeOptions] = useState([])

  const [tableData, setTableData] = useState({
    income: emptyState,
    expense: emptyState,
  })
  const [masterObject, setMasterObject] = useState({})

  const [selectedFields, setSelectedFields] = useState({})

  useEffect(() => {
    handleInitialFieldsValues()
  }, []) // eslint-disable-line

  const handleInitialFieldsValues = (
    state = { ...initialState },
    selectState = { ...selectInitialState },
  ) => {
    const obj = { ...state }
    const selected = { ...selectState }

    setMasterObject(obj)
    setSelectedFields(selected)
    handleData(obj)
    handleTypeOptions(obj)
  }

  // Fetch
  const handleData = async (obj = masterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(`finance-report/profit-loss?${params}`)

      if (!response?.data || response.data?.length < 1) {
        toastr.error("No data found")
        setTableData({
          income: emptyState,
          expense: emptyState,
        })
        return
      }
      if (response.data[0] && response.data[0]?.type === 1) {
        setTableData({
          income: response.data[0] || emptyState,
          expense: response.data[1] || emptyState,
        })
      } else {
        setTableData({
          income: emptyState,
          expense: response.data[0] || emptyState,
        })
      }
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data?.message)
      setTableData({
        income: emptyState,
        expense: emptyState,
      })
    }
  }

  const handleTypeOptions = async (obj = masterObject) => {
    try {
      const response = await get(
        `finance-report/profit-loss/options/head?from=${obj.from}&to=${obj.to}`,
      )
      setTypeOptions(response.data)
    } catch (error) {}
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (Array.isArray(selected)) {
      const values = selected.map(item => item.value)
      handleValueChange({ value: values, name })
    } else {
      handleValueChange({ value: selected.value, name })
    }
    setSelectedFields(obj)
  }

  const handleValueChange = ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const reset = () => {
    ref.current.reset()
    handleInitialFieldsValues()
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Profit And Loss" />

        <Card>
          <CardBody>
            <AvForm onValidSubmit={() => handleData()} ref={ref}>
              <Row>
                <Col md="2">
                  <div className="mb-3">
                    <Label>From</Label>
                    <AvField
                      name="from"
                      className="form-control"
                      type="date"
                      value={masterObject.from || ""}
                      onChange={e => handleValueChange(e.target)}
                      max={todayDate}
                      validate={{ required: { value: true } }}
                      errorMessage="From time required"
                    />
                  </div>
                </Col>

                <Col md="2">
                  <div className="mb-3">
                    <Label>To</Label>
                    <AvField
                      name="to"
                      type="date"
                      value={masterObject.to || ""}
                      onChange={e => handleValueChange(e.target)}
                      min={masterObject.from}
                      max={todayDate}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <Label>Sub Head</Label>
                  <Select
                    name="subHead"
                    placeholder="Select..."
                    options={typeOptions}
                    value={selectedFields.subHead}
                    isMulti
                    onChange={selected =>
                      handleSelectValueChange(selected, "subHead")
                    }
                  ></Select>
                </Col>
                <Col>
                  <div className="mb-3 d-flex gap-2 form-button">
                    <Button color="primary" type="submit">
                      Search
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <table className="table table-bordered" id="profitAndLossTable">
                  <thead>
                    <th style={{ textAlign: "center" }} colspan="4">
                      Income
                    </th>
                  </thead>
                  <tbody key={JSON.stringify(tableData)}>
                    {tableData.income.data.map(item => {
                      const rowspan =
                        item.data?.length >= 1 ? item.data.length : 0
                      return item.data?.map((sub, index) => {
                        return (
                          <tr key={JSON.stringify(item)}>
                            {index === 0 ? (
                              <td rowspan={rowspan}>{item.name}</td>
                            ) : (
                              ""
                            )}
                            <td colspan="1">
                              <span style={{ float: "left" }}>{sub.name}</span>
                              <span style={{ float: "right" }}>
                                {numberToCurrency(sub.amount)}
                              </span>
                            </td>
                            {index === 0 ? (
                              <th colspan="2" rowspan={rowspan}>
                                <span style={{ float: "right" }}>
                                  {numberToCurrency(item.amount)}
                                </span>
                              </th>
                            ) : (
                              ""
                            )}
                          </tr>
                        )
                      })
                    })}
                    <tr>
                      <th colspan="3">
                        <span style={{ float: "left" }}>TOTAL</span>
                        <span style={{ float: "right" }}>
                          {numberToCurrency(tableData.income.amount)}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                <table className="table table-bordered">
                  <thead>
                    <th style={{ textAlign: "center" }} colspan="4">
                      Expense
                    </th>
                  </thead>
                  <tbody key={JSON.stringify(tableData)}>
                    {tableData.expense.data.map(item => {
                      const rowspan =
                        item.data?.length >= 1 ? item.data.length : 0

                      return item.data?.map((sub, index) => {
                        return (
                          <tr key={JSON.stringify(item)}>
                            {index === 0 ? (
                              <td rowspan={rowspan}>{item.name}</td>
                            ) : (
                              ""
                            )}
                            <td colspan="1">
                              <span style={{ float: "left" }}>{sub.name}</span>
                              <span style={{ float: "right" }}>
                                {numberToCurrency(sub.amount)}
                              </span>
                            </td>
                            {index === 0 ? (
                              <th colspan="2" rowspan={rowspan}>
                                <span style={{ float: "right" }}>
                                  {numberToCurrency(item.amount)}
                                </span>
                              </th>
                            ) : (
                              ""
                            )}
                          </tr>
                        )
                      })
                    })}
                    <tr>
                      <th colspan="3">
                        <span style={{ float: "left" }}>TOTAL</span>
                        <span style={{ float: "right" }}>
                          {numberToCurrency(tableData.expense.amount)}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <span style={{ fontWeight: "bold" }}>Income</span>:{" "}
                    {numberToCurrency(tableData.income.amount)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span style={{ fontWeight: "bold" }}>Expense</span>:{" "}
                    {numberToCurrency(tableData.expense.amount)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span style={{ fontWeight: "bold" }}>Balance</span>:{" "}
                    {numberToCurrency(
                      (tableData.income.amount || 0) -
                        (tableData.expense.amount || 0),
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ProfitLoss
