import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { dateConverter } from "../../helpers/functions"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../config"
import { del, get, post, put } from "../../helpers/api_helper"
import TablePagination from "../../components/Common/TablePagination"
import Swal from "sweetalert2"
import toastr from "toastr"


const AddFee = () => {

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  
  const [tableData, setTableData] = useState([])
  const setSelectedFields = useState({})[1];


  
  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })


  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)



  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`/fee${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.state = item.state?.name

        item.staff = item.addedBy?.name

        if (item.logo)
          item.file = (
            <a href={API_URL + item.logo} target="_blank" rel="noreferrer">
              <i className="far fa-file-image file-icon"></i>
            </a>
          )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                 onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                 onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }


  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Fee Type", field: "name" },
      { label: "Amount", field: "amount" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }


  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`fee`, masterObject)
   else response = await post(`fee`, masterObject)
    
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }
  


  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }



  const reset = () => {
    if (formRef.current) {  // Check if formRef.current is defined
      formRef.current.reset();
    }
    setMasterObject({});
    setSelectedFields({});
    handleTableData(1);
    setPage(1);
    setTotalPage(1);
    toTop();
  };



  const handleUpdate = async id => {
    try {
      const response = await get(`fee/update/${id}`)
      const data = response.data
      setMasterObject({ ...data, id, state: data?.state?.value })
      setSelectedFields({ state: data?.state })
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      
      if (!result.isConfirmed) return;
  
      const response = await del(`fee/${id}`);
      toastr.success(response.message);
      reset();
    } catch (error) {
      // Check if error.response is defined
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        // toastr.error("An unexpected error occurred.");
      }
    }
  };
  

  return (
    <div className="page-content">
      <Container fluid>
      <Breadcrumb title="Home" breadcrumbItem="Fee" />

      <Card>
  <CardBody>
    <AvForm ref={formRef} onValidSubmit={handleSubmit} id="departmentForm">
      <Row>
        <Col md="3">
          <Label>Fee Type</Label>
          <AvField
            name="name"
            type="text" 
            placeholder="Enter Fee Type"
            errorMessage="Please enter the fee type"
            validate={{ required: { value: true } }}
            value={masterObject.name || ""}
            onChange={e => handleValueChange(e.target)}
          />
        </Col>

        <Col md="3">
          <Label>Amount</Label>
          <AvField
  name="amount"
  placeholder="Enter Amount"
  errorMessage="Please enter the Amount"
  validate={{ required: { value: true } }}
  type="number" 
  value={masterObject.amount || ""} // Update to use masterObject.Fee
  onChange={e => handleValueChange(e.target)}
  min="0" // to restrict to non-negative numbers
  step="0.01" // for decimal values
/>

        </Col>
       
        <Col md="3" style={{ marginTop: "26px" }}>
          <div className="d-flex gap-3">
            <Button color="primary">Submit</Button>
            <Button color="danger" type="reset" onClick={reset}>
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </AvForm>
  </CardBody>
</Card>
<Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="universityTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
      </Container>
    </div>
  )
}

export default AddFee
