import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  Container,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import SunEditor from "suneditor-react"
import Select from "react-select"

import queryString from "query-string"
import Swal from "sweetalert2"
import toastr from "toastr"

import { del, get, post, put } from "../../../helpers/api_helper"
import { SELECT_ALL } from "../../../config"

import TablePagination from "../../../components/Common/TablePagination"
import SearchField from "../../../components/Common/SearchField"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Icon from "../../../components/Common/Icon"

import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"

import {
  dateConverter,
  getDate,
  isIncludesAll,
  reactSelectCustomStyle,
  renderTableValue,
  toTop,
} from "../../../helpers/functions"

import {
  genderOptions,
  initialState,
  sunEditorOptions,
  workTypeOptions,
  workModeOptions,
  scheduleOptions,
  statusOptions,
  approvalOptions,
  statusValues,
} from "./helper"

import {
  filterShowOptions,
  filterStatusOptions,
} from "../../Internship/Post/helper"

import "./styles.scss"

const JobPost = () => {
  const formRef = useRef()
  const filterRef = useRef()

  const todayDate = getDate()
  const navigate = useNavigate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({
    ...initialState.master,
    details: [...initialState.master.details],
  })

  const [selectedFields, setSelectedFields] = useState({
    ...initialState.select,
  })

  const [companyOptions, setCompanyOptions] = useState([])
  const [branchOptions, setBranchOptions] = useState([])

  const [courseOptions, setCourseOptions] = useState([])
  const [specializationOptions, setSpecializationOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [skillsOptions, setSkillsOptions] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const [filterCompanyOptions, setFilterCompanyOptions] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleCompanyOptions()
    handleCourseOptions()
    handleCategoryOptions()

    handleFilterCompanyOptions()
  }, []) //eslint-disable-line

  const handleCompanyOptions = async () => {
    try {
      const response = await get(`options/company`)
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleBranchOptions = async (company = "") => {
    try {
      const response = await get(`options/branch?company=${company}`)
      setBranchOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCourseOptions = async () => {
    try {
      const response = await get(`options/course`)
      setCourseOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSpecializationOptions = async (course = "") => {
    try {
      const params = queryString.stringify({ course })
      const response = await get(`options/specializations?${params}`)
      setSpecializationOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`options/districts?${params}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCategoryOptions = async () => {
    try {
      const response = await get(`options/category`)
      setCategoryOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSkillsOptions = async (category = "") => {
    try {
      const response = await get(`options/skills?category=${category}`)
      setSkillsOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCompanyOptions = async () => {
    try {
      const response = await get(`job/options/filter/company`)
      setFilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`job${query}`)

    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1

      item.date = dateConverter(item.date)
      item.company = renderTableValue(item.company?.name)

      item.name = renderTableValue(item.name)
      item.staff = item.addedBy?.name

      const isApproved = item.approvalStatus === 1
      const status = statusOptions.find(status => status.value === item.status)

      item.status = (
        <Select
          styles={reactSelectCustomStyle}
          menuPosition="fixed"
          options={statusOptions}
          defaultValue={status}
          isDisabled={!isApproved}
          onChange={selected => handleStatusChange(item._id, selected.value)}
        />
      )
      if (item.approvalStatus === 3) {
        item.approval = (
          <Select
            styles={reactSelectCustomStyle}
            menuPosition="fixed"
            options={approvalOptions}
            value={""}
            onChange={selected =>
              handleApproveStatusChange(item._id, selected.value)
            }
          />
        )
      } else {
        const statusObj = statusValues[item.approvalStatus]

        item.approval = (
          <Button size="sm" color={statusObj?.color} disabled>
            {statusObj?.text}
          </Button>
        )
      }

      item.options = (
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip title="Show" arrow placement="top">
            <div style={{ marginRight: "0.5rem" }}>
              <Input
                name="status"
                type="checkbox"
                defaultChecked={item.show}
                disabled={!isApproved}
                onChange={e =>
                  handleShowCheckChange(item._id, e.target.checked)
                }
              />
            </div>
          </Tooltip>

          <Icon
            icon="eye"
            title="View"
            onClick={() => navigate(`/job/post/${item.uniqueId}`)}
          />
          <Icon
            icon="edit"
            title="Edit"
            onClick={() => handleUpdate(item._id)}
          />
          <Icon
            icon="delete"
            title="Delete"
            onClick={() => handleDelete(item._id)}
          />
        </div>
      )
      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`job/update/${id}`)

      const data = response.data

      const gender = data.gender
        ? data.gender?.map(option => genderOptions[option - 1])
        : ""
      const schedule = data.schedule
        ? scheduleOptions.find(option => option.value === data.schedule)
        : ""
      const workMode = data.workMode
        ? workModeOptions.find(option => option.value === data.workMode)
        : ""
      const workType = data.workType
        ? workTypeOptions.find(option => option.value === data.workType)
        : ""

      const details = initialState.master.details
        .map(defaultItem => {
          const responseItem = data?.details.find(
            item => item.tab === defaultItem.tab,
          )
          return responseItem
            ? { ...defaultItem, ...responseItem }
            : defaultItem
        })
        .sort((a, b) => Number(a.tab) - Number(b.tab))

      setMasterObject({
        ...data,
        details,
        id,

        company: data?.company?.value,
        branch: data?.branch?.value,
        category: data?.category?.value,

        courses: data?.coursesIds,
        specializations: data?.specializationsIds,

        skills: data?.skillIds,
        state: data?.stateIds,
        district: data?.districtIds,
      })

      setSelectedFields({
        company: data.company,
        branch: data.branch,

        fees: data.fees,
        category: data.category,
        skills: data.skills,

        courses: data.courses,
        specializations: data.specializations,

        state: data.state,
        district: data.district,

        gender,
        schedule,
        workMode,
        workType,
      })

      handleSkillsOptions(data?.category)
      handleBranchOptions(data?.company?.value)

      handleDistrictOptions(data?.stateIds)
      handleSpecializationOptions(data?.coursesIds)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`job/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`job`, masterObject)
      else response = await post(`job`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleShowCheckChange = async (id, checked) => {
    try {
      const response = await put(`job/show`, { id, checked })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`job/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleApproveStatusChange = async (id, status) => {
    try {
      const response = await put(`job/approval`, { id, status })
      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }

    if (name === "state") obj.district = null
    if (name === "company") obj.branch = null
    if (name === "category") obj.skills = null

    if (name === "courses") {
      if (isIncludesAll(selected)) selected = courseOptions
      obj.specializations = null
    }
    if (name === "specializations") {
      if (isIncludesAll(selected)) selected = specializationOptions
    }

    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value

    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }

    if (name === "company") {
      obj.branch = null
      handleBranchOptions(value)
    }

    if (name === "courses") {
      obj.specializations = null
      handleSpecializationOptions(value)
    }

    if (name === "category") {
      obj.skills = null
      handleSkillsOptions(value)
    }

    setMasterObject(obj)
  }

  const handleSubEditorChange = ({ name, value, idx }) => {
    setMasterObject(prevState => ({
      ...prevState,
      details: prevState.details.map((item, i) =>
        i === idx ? { ...item, [name]: value } : item,
      ),
    }))
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    setSelectFilter(prev => ({ ...prev, [name]: selected }))
    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleFilterValueChange({ value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const getSelectAllOptions = ({ options, name }) => {
    if (
      options.length === 0 ||
      options.length !== selectedFields[name]?.length
    ) {
      return [SELECT_ALL, ...options]
    }
    return options
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()

    toTop()
    setMasterObject({
      ...initialState.master,
      details: initialState.master.details.map(item => ({
        ...item,
        contents: "",
      })),
    })

    setSelectedFields({ ...initialState.select })

    handleFilterCompanyOptions()
    setFilterObject({})
    setSelectFilter({})
  }

  const filterReset = () => {
    filterRef.current.reset()

    setFilterObject({})
    setSelectFilter({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Name", field: "name" },
      { label: "Status", field: "status" },
      { label: "Approval", field: "approval" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Job Post" />

        <Card>
          <CardBody>
            <AvForm onValidSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col md={3} className="mb-3">
                  <Label>
                    Company<span className="required">*</span>
                  </Label>
                  <Select
                    name="company"
                    menuPosition="fixed"
                    options={companyOptions}
                    value={selectedFields.company || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "company")
                    }
                  />
                </Col>

                <Col md={3} className="mb-3">
                  <Label>
                    Branch<span className="required">*</span>
                  </Label>
                  <Select
                    name="branch"
                    menuPosition="fixed"
                    options={branchOptions}
                    value={selectedFields.branch || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "branch")
                    }
                    isDisabled={!masterObject.company}
                  />
                </Col>

                <Col md={3} className="mb-3">
                  <Label>
                    Name<span className="required">*</span>
                  </Label>
                  <AvField
                    name="name"
                    type="text"
                    placeholder="Name"
                    errorMessage="Enter Name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Category/Sector</Label>
                  <Select
                    name="category"
                    menuPosition="fixed"
                    options={categoryOptions}
                    value={selectedFields.category || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "category")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Skills</Label>
                  <Select
                    isMulti
                    isDisabled={!masterObject.category}
                    name="skills"
                    menuPosition="fixed"
                    options={skillsOptions}
                    value={selectedFields.skills || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "skills")
                    }
                  />
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      Work Type<span className="required">*</span>
                    </Label>
                    <Select
                      options={workTypeOptions}
                      value={selectedFields.workType || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "workType")
                      }
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      Work mode<span className="required">*</span>
                    </Label>
                    <Select
                      options={workModeOptions}
                      value={selectedFields.workMode || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "workMode")
                      }
                    />
                  </div>
                </Col>
                <Col md={3} className="mb-3">
                  <Label>
                    Courses/Qualifications<span className="required">*</span>
                  </Label>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    options={getSelectAllOptions({
                      options: courseOptions,
                      name: "courses",
                    })}
                    value={selectedFields.courses || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "courses")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>
                    Specializations<span className="required">*</span>
                  </Label>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    isDisabled={!masterObject.courses?.length}
                    options={getSelectAllOptions({
                      options: specializationOptions,
                      name: "specializations",
                    })}
                    value={selectedFields.specializations || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "specializations")
                    }
                  />
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      Minimum Experience (in years)
                      <span className="required">*</span>
                    </Label>
                    <AvField
                      name="minExperience"
                      type="number"
                      value={masterObject.minExperience || ""}
                      onChange={e => handleValueChange(e.target)}
                      min={0}
                      validate={{
                        isValidExperience: value => {
                          const min = parseFloat(value)
                          const max = parseFloat(masterObject.maxExperience)
                          return isNaN(min) || isNaN(max) || min <= max
                        },
                      }}
                      errorMessage="Minimum Experience must be less than or equal to Maximum Experience"
                      placeholder="Minimum Experience"
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Maximum Experience (in years)</Label>
                    <AvField
                      name="maxExperience"
                      type="number"
                      value={masterObject.maxExperience || ""}
                      onChange={e => handleValueChange(e.target)}
                      min={0}
                      validate={{
                        isValidExperience: value => {
                          if (!value) return true

                          const max = parseFloat(value)
                          const min = parseFloat(masterObject.minExperience)

                          if (!isNaN(min)) {
                            return !isNaN(max) && max >= min
                          }

                          return true
                        },
                      }}
                      errorMessage="Maximum Experience must be greater than or equal to Minimum Experience"
                      placeholder="Maximum Experience"
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label>Gender</Label>
                    <Select
                      isMulti
                      options={genderOptions}
                      value={selectedFields.gender || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "gender")
                      }
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      No. of Openings<span className="required">*</span>
                    </Label>
                    <AvField
                      name="vacancies"
                      type="number"
                      placeholder="No. of Openings"
                      validate={{
                        number: true,
                        required: {
                          value: true,
                          errorMessage: "Enter No. of Openings",
                        },
                        min: {
                          value: 1,
                          errorMessage: "Openings must be greater than 0",
                        },
                        isPercentage: value => {
                          const percentage = parseFloat(value)
                          if (value && (isNaN(percentage) || percentage < 0)) {
                            return "Percentage must be a valid number greater than or equal to 0"
                          }
                          return true
                        },
                      }}
                      errorMessage="Enter a No. of Openings"
                      value={masterObject.vacancies || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      Salary Range From (&#x20B9; &nbsp;LPA)
                      <span className="required">*</span>
                    </Label>
                    <AvField
                      name="salaryFrom"
                      type="number"
                      min={0}
                      value={masterObject.salaryFrom || ""}
                      onChange={e => handleValueChange(e.target)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter Salary Range From",
                        },
                        isValidSalary: value => {
                          const salaryFrom = parseFloat(value)
                          const salaryTo = parseFloat(masterObject.salaryTo)
                          return (
                            isNaN(salaryFrom) ||
                            isNaN(salaryTo) ||
                            salaryFrom <= salaryTo
                          )
                        },
                      }}
                      errorMessage="Salary Range From must be less than or equal to Salary Range To"
                      placeholder="Salary Range From"
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Salary Range To (&#x20B9; &nbsp;LPA)</Label>
                    <AvField
                      name="salaryTo"
                      type="number"
                      value={masterObject.salaryTo || ""}
                      onChange={e => handleValueChange(e.target)}
                      validate={{
                        isValidSalary: value => {
                          if (!value) return true

                          const salaryTo = parseFloat(value)
                          const salaryFrom = parseFloat(masterObject.salaryFrom)

                          if (!isNaN(salaryFrom)) {
                            return !isNaN(salaryTo) && salaryTo >= salaryFrom
                          }

                          return true
                        },
                      }}
                      errorMessage="Salary Range To must be greater than or equal to Salary Range From"
                      placeholder="Salary Range To"
                    />
                  </div>
                </Col>

                <Col md={3} className="mb-3">
                  <Label>
                    State<span className="required">*</span>
                  </Label>
                  <Select
                    name="state"
                    isMulti
                    menuPosition="fixed"
                    options={stateOptions}
                    value={selectedFields.state || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "state")
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <Label>
                      District<span className="required">*</span>
                    </Label>
                    <Select
                      isMulti
                      menuPosition="fixed"
                      options={districtOptions}
                      value={selectedFields.district || ""}
                      isDisabled={!masterObject.state}
                      onChange={selected =>
                        handleSelectValueChange(selected, "district")
                      }
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>
                      Schedule<span className="required">*</span>
                    </Label>
                    <Select
                      options={scheduleOptions}
                      value={selectedFields.schedule || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "schedule")
                      }
                    />
                  </div>
                </Col>

                {masterObject.details?.map((item, idx) => (
                  <Row>
                    <Col md={3} className="mb-3">
                      <Label>Tab name ({idx + 1})</Label>
                      <AvField
                        name={`tabDescription${idx}`}
                        defaultValue={item.tabDescription || ""}
                        onChange={e =>
                          handleSubEditorChange({
                            name: "tabDescription",
                            value: e.target.value,
                            idx,
                          })
                        }
                      />
                    </Col>

                    <Col md={12} className="mb-3">
                      <SunEditor
                        lang="en"
                        setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                        setOptions={sunEditorOptions}
                        onChange={value =>
                          handleSubEditorChange({
                            name: "contents",
                            value,
                            idx,
                          })
                        }
                        setContents={item.contents || ""}
                      />
                    </Col>
                  </Row>
                ))}
                <Col className="mb-3" style={{ paddingTop: "25px" }}>
                  <Button
                    className="me-2"
                    color={masterObject._id ? "warning" : "primary"}
                    type="submit"
                  >
                    {masterObject._id ? "Update" : "Submit"}
                  </Button>
                  <Button color="danger" type="button" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Company</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCompanyOptions}
                    value={selectFilter.company || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "company" })
                    }
                    isClearable
                    isMulti
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterStatusOptions}
                    value={selectFilter.status || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "status" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={2}>
                  <Label>Show</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterShowOptions}
                    value={selectFilter.show || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "show" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name"
                  />
                </Col>
                <Col md={2} className="mb-3 form-button">
                  <Button color="danger" onClick={filterReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              key={JSON.stringify(data)}
              tableId="jobPostTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default JobPost
