import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button, Container, Input } from "reactstrap"
import { AvForm} from "availity-reactstrap-validation"
import Select from "react-select"
import toastr from "toastr"
import queryString from "query-string"
import './style.scss'; 
import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import {  get, put } from "../../../helpers/api_helper"
import {
  renderTableValue,
  toTop,
} from "../../../helpers/functions"
import Swal from "sweetalert2"


const InterviewDetail = () => {
  const formRef = useRef()
  const filterRef = useRef()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  const [selectedFields, setSelectedFields] = useState({})

  const [filterObject, setFilterObject] = useState({})

  const [interviewOptions, setInterviewOptions] = useState([])
  const [AssignedInterviewOptions, setAssignedInterviewOptions] = useState([])

  const [marks, setMarks] = useState([]);  
  const [remark, setRemark] = useState([])
  
  const [DropDownData, setDropDownData] = useState([])
  const [JobOptions, setJobOptions] = useState([])

  



  const typeOptions=[
    { label: "Internship", value:1 },
    { label: "Job", value: 2 },
  ]

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await get(`options/internship`)
        
        setDropDownData(result.data)
      } catch (err) {
        toastr.error("Failed to fetch data")
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getJob = async () => {
      try {
        const result = await get(`options/job`)
        setJobOptions(result.data)
      } catch (err) {
        toastr.error("Failed to fetch data")
      }
    }
    getJob()
  }, [])


  useEffect(() => {
  const handleAssignInterviewOptions = async (internship = "") => {
    try {
      const response = await get(`options/assigned-interview?internship=${internship}`)
      setAssignedInterviewOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  handleAssignInterviewOptions()
}, [])

 const STATUS_VALUES = {
    1: { text: "Qualified", color: "success" },
    2: { text: "Disqualified", color: "danger" },
  }

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject])

  useEffect(() => {
    handleInterviewOptions();
  }, []);
  
  const handleInterviewOptions = async (internshipId = "") => {
    try {
      if (!internshipId) {
        setInterviewOptions([]);
        return;
      }
  
      const response = await get(`options/assigned-interview?internship=${internshipId}`);
      
      const transformedInterviews = response.data.map(interview => ({
        label: interview.label,
        value: interview._id,
      }));
  
      setInterviewOptions(transformedInterviews);
    } catch (error) {
      console.error(error);
      toastr.error("Failed to fetch interviews");
      setInterviewOptions([]); 
    }
  };
  


  const handleMarkChange = (candidateId, id, value, existingMark) => {
    setMarks((prevMarks) => {
      const existingMarkEntry = prevMarks.find(mark => mark.candidateId === candidateId && mark.id === id);
      if (existingMarkEntry) {
        return prevMarks.map(mark =>
          mark.candidateId === candidateId && mark.id === id 
            ? { ...mark, mark: value }
            : mark
        );
      }
      return [...prevMarks, { candidateId, id, mark: value, existingMark }];
    });
  };
  
  const handleReMarkChange = (candidateId, id, value, existingRemark) => {
    setRemark((prevRemarks) => {
      const existingRemarkEntry = prevRemarks.find(remark => remark.candidateId === candidateId && remark.id === id);
      if (existingRemarkEntry) {
        return prevRemarks.map(remark =>
          remark.candidateId === candidateId && remark.id === id 
            ? { ...remark, remark: value }
            : remark
        );
      }
      return [...prevRemarks, { candidateId, id, remark: value, existingRemark }];
    });
  };
  


  

  const handleAddMarks = async () => {
    const result = await Swal.fire({
      title: "Are you sure about updating Marks and Remarks?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submit!"
    })
  
    if (result.isConfirmed) {
      try {
        const response = await put("interview-assign/mark", {
          marks: marks.map(m => ({
            candidateId: m.candidateId,
            id: m.id,
            mark: m.mark,
            existingMark: m.existingMark
          })),  
          remarks: remark.map(r => ({
            candidateId: r.candidateId,
            id: r.id,
            remark: r.remark,
            existingRemark: r.existingRemark
          })),  
        });
  
        toastr.success(response.message);
        setMarks([]);  
        setRemark([]);  
        handleTableData(page);  
  
      } catch (error) {
        console.error(error);
        toastr.error(error?.response?.data?.message || "Error updating marks");
      }
    }
  };
  
  
  
  

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`;
      const response = await get(`interview-assign/candidate-list?${query}`);
      
      if (response && response.data) {
        const { count, data } = response;
        const updatedData = [];
        
        data.forEach((item, index) => {
          item.candidate.forEach((candidate, candidateIndex) => {
            const newItem = { ...item };
  
            newItem.id = (currentPage - 1) * limit + index + candidateIndex + 1;
            newItem.staff = renderTableValue(item.addedBy?.name || "");
            newItem.candidateName = `${candidate.candidateId?.firstName || ""} ${candidate.candidateId?.lastName || ""}`;
            newItem.regid = candidate.candidateId?.uniqueId || "";
  
            newItem.marks = candidate?.marks;
  
            const existingMarks = candidate?.marks;
            newItem.addMark = (
              <div className="d-flex align-items-center">
                <Input
                  type="number"
                  size="sm"
                  className="w-50 mr-2"
                  defaultValue={existingMarks || ''}
                  onChange={(e) => handleMarkChange(
                    candidate?.candidateId?._id, 
                    item._id, 
                    e.target.value, 
                    existingMarks
                  )}
                 
                />
              </div>
            );
  
            const existingRemarks = candidate?.remarks;
            newItem.addremark = (
              <div className="d-flex align-items-center">
                <Input
                  type="text"
                  size="sm"
                  className="w-50 mr-2"
                  defaultValue={existingRemarks || ''}
                  onChange={(e) => handleReMarkChange(
                    candidate?.candidateId?._id, 
                    item._id, 
                    e.target.value, 
                    existingRemarks
                  )}
                />
              </div>
            );
  
            const status = Number(candidate?.qualification_status);
            const statusObj = STATUS_VALUES[status];
            newItem.status = (
              <Button
                size="sm"
                color={statusObj.color}
                onClick={() => handleStatusChange(candidate.candidateId?._id,item._id, status === 2 ? 1 : 2)}
              >
                {statusObj.text}
              </Button>
            );
  
            updatedData.push(newItem);
          });
        });
  
        const totalPage = Math.ceil(Number(count) / limit);
        setTotalPage(totalPage);
        setTableData(updatedData);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  
  
  
  
  
  
  

// const handleSelectValueChange = (selected, name) => {
//     const obj = { ...selectedFields };
//     obj[name] = selected;
//     setSelectedFields(obj);

//     if (name === "interview") {
//       setFilterObject({ ...filterObject, interview: selected.value });
//     } else if (name === "type") {
//       if (selected.value === 1) {
//         setFilterObject({ ...filterObject, type: selected.value, internship: "" });
//       } else if (selected.value === 2) {
//         setFilterObject({ ...filterObject, type: selected.value, job: "" });
//       }
//     } else {
//       setFilterObject({ ...filterObject, [name]: selected.value });
//     }
//   };


   const handleSelectValueChange = (selected, name) => {
  const obj = { ...selectedFields };
  obj[name] = selected;
  setSelectedFields(obj);

  const newFilterObject = { ...filterObject };

  if (name === "type") {
    delete newFilterObject.internship;
    delete newFilterObject.job;

    newFilterObject.type = selected.value;

    if (selected.value === 1) {
      setInterviewOptions([]);
    } else if (selected.value === 2) {
      setInterviewOptions([]);
    }
  } else if (name === "interview") {
    newFilterObject.interview = selected?.value || "";
  } else if (name === "internship") {
    newFilterObject.internship = selected.value;
    handleInterviewOptions(selected.value);
  } else if (name === "job") {
    newFilterObject.job = selected.value;
  }

  setFilterObject(newFilterObject);
  handleTableData(1);
};
  
  
  
  
  const handleStatusChange = async (candidateId, id,qualification_status) => {
    try {
      const response = await put(`interview-assign/qualify-status`, { candidateId,id, qualification_status })
      

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    toTop();
  
    formRef.current?.reset();
    filterRef.current?.reset();
  
    setSelectedFields({});
    setFilterObject({});  
    handleTableData(1);    
  };
  
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Candidate Name", field: "candidateName" },  
      { label: "Reg ID", field: "regid" },  
      // { label: "Mark", field: "marks" }, 
      { label: "Add Mark", field: "addMark" }, 
      { label: "Add Remark", field: "addremark" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }
  

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Interview Details" />
    
        <Card>
          <CardBody>
          <AvForm ref={filterRef}>
  <Row>
    <Col md={3}>
      <Label>Select by Type</Label>
      <Select
        options={typeOptions}
        value={selectedFields.type || null}
        onChange={selected => handleSelectValueChange(selected, "type")}
      />
    </Col>

    {selectedFields.type?.value === 1 && (
  <Col md="3">
    <Label>Select by Internship</Label>
    <Select
      menuPosition="fixed"
      options={DropDownData.map(internship => ({
        label: internship.label,
        value: internship.value,
      }))}
      value={selectedFields.internship || { label: "-- Select Internship --", value: "" }}
      onChange={selected => handleSelectValueChange(selected, "internship")}
      placeholder="Select Internship"
    />
  </Col>
)}


    {selectedFields.type?.value === 2 && (
      <Col md="3">
        <Label>Filter by Job</Label>
        <Select
          menuPosition="fixed"
          options={[
            ...(JobOptions?.map(job => ({
              label: job.label,
              value: job._id,
            })) || []),
          ]}
          value={selectedFields.job}
          onChange={selected => handleSelectValueChange(selected, "job")}
          placeholder="-- Select Job --"
        />
      </Col>
    )}

<Col md="3">
  <Label>Filter by Interview</Label>
  <Select
    menuPosition="fixed"
    options={[
      ...(interviewOptions?.map(interview => ({
        label: interview.label,
        value: interview._id,
      })) || []),
    ]}
    value={selectedFields.interview  || ""}
    onChange={selected => handleSelectValueChange(selected, "interview")}
    placeholder="Select Interview"
  />
</Col>



    <Col md={2} style={{ marginTop: "26px" }}>
      <Button type="button" color="danger" onClick={reset}>
        Reset
      </Button>
    </Col>
  </Row>
</AvForm>

            <br />
            <br />
            <br />

            <div style={{ float:'right' ,marginTop:'-45px'}}>
  <Button
    type="button"
    color="primary"
    onClick={handleAddMarks}  
  >
    Submit
  </Button>
</div>       

<TablePagination
className="custom-table"
  page={page}
  tableId="Interviewdetails"
  data={data}
  count={totalPage}
  onChange={handlePageChange}
/>
{/* <br />
<br />
<br />

<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
  <Button
    type="button"
    color="primary"
    onClick={handleAddMarks}  
  >
    Submit
  </Button>
</div> */}
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default InterviewDetail




