import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import Breadcrumb from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
  dateConverter,
  getDate,
  jsonToExcel,
  numberToString,
  renderTableValue,
  setTitle,
  toTop,
} from "../../helpers/functions"
import { del, get, post, put } from "../../helpers/api_helper"

import "./styles.scss"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import SearchField from "../../components/Common/SearchField"
import moment from "moment"

const Candidate = () => {
  setTitle("Candidates")

  const formRef = useRef()
  const filterRef = useRef()
  const todayDate = getDate()

  const tagOptions = [
    { label: "ASAP", value: 1 },
    { label: "KTU", value: 2 },
    { label: "Groomed", value: 3 },
  ]

  const profileCompletionOptions = [
    { value: 30, label: "0 - 30%" },
    { value: 40, label: "31 - 40%" },
    { value: 50, label: "41 - 50%" },
    { value: 60, label: "51 - 60%" },
    { value: 70, label: "61 - 70%" },
    { value: 80, label: "71 - 80%" },
    { value: 90, label: "81 - 90%" },
    { value: 100, label: "91 - 100%" },
  ]

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState({ export: false })

  const [courseOptions, setCourseOptions] = useState([])
  const [specializationsOptions, setSpecializationsOptions] = useState([])
  const [collegeOptions, setCollegeOptions] = useState([])
  const [universityOptions, setUniversityOptions] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const [filterCollegeOptions, setFilterCollegeOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleCourseOptions()
    handleUniversityOptions()

    handleFilterCollegeOptions()
  }, []) // eslint-disable-line

  const handleUniversityOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`options/university?${params}`)
      setUniversityOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCollegeOptions = async (university = "") => {
    try {
      const params = queryString.stringify({ university })
      const response = await get(`options/college?${params}`)
      setCollegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStateOptions = async () => {
    try {
      const response = await get(`options/states`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`options/districts?${params}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCourseOptions = async () => {
    try {
      const response = await get(`options/course`)
      setCourseOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleSpecializationOptions = async (course = "") => {
    try {
      const params = queryString.stringify({ course })
      const response = await get(`options/specializations?${params}`)
      setSpecializationsOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterCollegeOptions = async () => {
    try {
      const response = await get(`candidate/options/filter/college`)
      setFilterCollegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
      const response = await get(`candidate${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no Candidates to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}

        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)
        exportData["Candidate Id"] = item.uniqueId
        exportData["Name"] = `${item.firstName || ""} ${item.lastName || ""}`
        exportData["Mobile"] = item.mobile
        exportData["Email"] = item.email
        exportData["Institute"] = item.college?.name || item?.tempInstitute

        exportData["Asap"] = item.isAsap ? "Yes" : "No"
        exportData["KTU"] = item.isKtu ? "Yes" : "No"
        exportData["Groomed"] = item.isGroomed ? "Yes" : "No"

        exportData["Profile %"] = item.profileCompletion?.toFixed(1) || 0
        exportData["Staff"] = item.addedBy?.name

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Candidates-${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`candidate${query}`)

    const { count, data } = response

    data.map((item, index) => {
      item.id = (currentPage - 1) * limit + index + 1
      item.date = dateConverter(item.date)

      item.name = `${item.firstName || ""} ${item.lastName || ""}`
      item.university = renderTableValue(item.university?.name)
      item.college = renderTableValue(item.college?.name || item?.tempInstitute)

      if (item.from === 1) {
        item.staff = item.collegeUser?.name ? (
          <>{item.collegeUser?.name} - Institute</>
        ) : (
          renderTableValue(item.collegeUser?.name)
        )
      } else {
        item.staff = renderTableValue(item.addedBy?.name)
      }

      item.completion = `${item.profileCompletion?.toFixed(1) || 0}%`

      item.asap = <StatusIcon isChecked={item.isAsap} />
      item.ktu = <StatusIcon isChecked={item.isKtu} />
      item.groomed = (
        <Input
          type="checkbox"
          key={item._id}
          name={`groomed${item._id}`}
          defaultChecked={item.isGroomed}
          onChange={e =>
            handleGroomChange({ checked: e.target.checked, id: item._id })
          }
        />
      )

      item.options = (
        <div>
          <Link to={`/candidate-profile/${item.uniqueId}`}>
            <Tooltip title="View" arrow placement="top">
              <i
                style={{ color: "rgb(52, 55, 71)" }}
                className="fas fa-eye eye-icon"
              ></i>
            </Tooltip>
          </Link>
          <Tooltip title="Edit" arrow placement="top">
            <i
              className="fas fa-edit edit-icon"
              onClick={() => handleUpdate(item._id)}
            ></i>
          </Tooltip>
          <Tooltip title="Delete" arrow placement="top">
            <i
              className="fas fa-trash-alt delete-icon"
              onClick={() => handleDelete(item._id)}
            ></i>
          </Tooltip>
        </div>
      )
      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
    setTotalCount(count)
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`candidate`, masterObject)
      else response = await post(`candidate`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      toTop()
      const response = await get(`candidate/details?id=${id}`)
      const data = response.data

      setMasterObject({
        ...data,
        id,

        university: data?.university?.value,
        college: data?.college?.value,
        course: data?.course?.value,
        specialization: data?.specialization?.value,

        state: data?.state?.value,
        district: data?.district?.value,
      })
      setSelectedFields({
        university: data.university,
        college: data.college,
        course: data.course,
        specialization: data.specialization,

        state: data.state,
        district: data?.district,
      })

      handleDistrictOptions(data?.state?.value)
      handleCollegeOptions(data?.university?.value)
      handleSpecializationOptions(data?.course?.value)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`candidate/${id}`)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleGroomAll = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Groom all",
      })
      if (!result.isConfirmed) return
      const response = await put(`candidate/grooming/all`, filterObject)
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleGroomChange = async ({ checked, id }) => {
    try {
      const response = await put(`candidate/grooming`, { checked, id })
      toastr.success(response?.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "state") obj.district = null
    if (name === "university") obj.college = null
    if (name === "course") obj.specialization = null

    setSelectedFields(obj)

    const value = selected.value
    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value

    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }
    if (name === "university") {
      obj.college = null
      handleCollegeOptions(value)
    }
    if (name === "course") {
      obj.specialization = null
      handleSpecializationOptions(value)
    }

    setMasterObject(obj)
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    setSelectFilter(prev => ({ ...prev, [name]: selected }))
    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleFilterValueChange({ value: value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()
    toTop()
    setMasterObject({})
    setSelectedFields({})

    setFilterObject({})
    setSelectFilter({})

    setTotalCount(0)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Institute", field: "college" },
      { label: "Candidate Id", field: "uniqueId" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "KTU", field: "ktu" },
      { label: "ASAP", field: "asap" },
      { label: "Groomed", field: "groomed" },
      { label: "Profile %", field: "completion" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Candidate" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md="3">
                  <Label>University</Label>
                  <Select
                    menuPosition="fixed"
                    options={universityOptions}
                    value={selectedFields.university || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "university")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>Institute</Label>
                  <Select
                    menuPosition="fixed"
                    options={collegeOptions}
                    isDisabled={!masterObject.university}
                    value={selectedFields.college || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "college")
                    }
                  />
                </Col>

                <Col md="3">
                  <Label>
                    First Name<span className="required">*</span>
                  </Label>
                  <AvField
                    name="firstName"
                    placeholder="First Name"
                    errorMessage="Enter First Name"
                    validate={{ required: { value: true } }}
                    value={masterObject.firstName || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3">
                  <Label>Last Name</Label>
                  <AvField
                    name="lastName"
                    placeholder="First Name"
                    errorMessage="Enter First Name"
                    value={masterObject.lastName || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>
                    Mobile<span className="required">*</span>
                  </Label>
                  <AvField
                    name="mobile"
                    placeholder="Mobile"
                    type="text"
                    errorMessage="Enter Mobile"
                    value={masterObject.mobile || ""}
                    validate={{ required: { value: true } }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>
                    Email<span className="required">*</span>
                  </Label>
                  <AvField
                    name="email"
                    placeholder="Email"
                    type="text"
                    errorMessage="Enter Email"
                    value={masterObject.email || ""}
                    validate={{ required: { value: true } }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>
                    State<span className="required">*</span>
                  </Label>
                  <Select
                    menuPosition="fixed"
                    options={stateOptions}
                    value={selectedFields.state || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "state")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>
                    District<span className="required">*</span>
                  </Label>
                  <Select
                    menuPosition="fixed"
                    options={districtOptions}
                    isDisabled={!masterObject.state}
                    value={selectedFields.district || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "district")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>Address</Label>
                  <AvField
                    name="address"
                    placeholder="Address"
                    type="text"
                    errorMessage="Enter Address"
                    value={masterObject.address || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md="3">
                  <Label>Course</Label>
                  <Select
                    menuPosition="fixed"
                    options={courseOptions}
                    value={selectedFields.course || ""}
                    onChange={selected =>
                      handleSelectValueChange(selected, "course")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>Specialization</Label>
                  <Select
                    menuPosition="fixed"
                    options={specializationsOptions}
                    value={selectedFields.specialization || ""}
                    isDisabled={!masterObject.course}
                    onChange={selected =>
                      handleSelectValueChange(selected, "specialization")
                    }
                  />
                </Col>
                <Col md="3">
                  <Label>Register No</Label>
                  <AvField
                    name="regNo"
                    placeholder="Register No"
                    type="text"
                    errorMessage="Enter Register No"
                    value={masterObject.regNo || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3">
                  <Label>CGPA</Label>
                  <AvField
                    name="cgpa"
                    placeholder="CGPA"
                    type="text"
                    errorMessage="Enter CGPA"
                    value={masterObject.cgpa || ""}
                    validate={{
                      max: {
                        value: 10,
                        errorMessage:
                          "Please enter a CGPA value that is less than or equal to 10",
                      },
                    }}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3" style={{ marginTop: "26px" }}>
                  <div className="d-flex gap-3">
                    <Button color={masterObject.id ? "warning" : "primary"}>
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Institute</Label>
                  <Select
                    menuPosition="fixed"
                    options={filterCollegeOptions}
                    value={selectFilter.college || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "college" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Profile Completion</Label>
                  <Select
                    menuPosition="fixed"
                    options={profileCompletionOptions}
                    value={selectFilter.completion || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "completion" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Tags</Label>
                  <Select
                    isMulti
                    isClearable
                    menuPosition="fixed"
                    options={tagOptions}
                    value={selectFilter.tags || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "tags" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name, mobile, email"
                  />
                </Col>
                <Col className="form-button d-flex gap-2">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                  <Button color="warning" onClick={handleExport}>
                    {loading.export ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <div className="candidate-table-heading gap-3">
              <Button color="primary" onClick={handleGroomAll}>
                Groom all
              </Button>
              <span>Total Count: {numberToString(totalCount)}</span>
            </div>
            <TablePagination
              page={page}
              key={JSON.stringify(data)}
              onChange={handlePageChange}
              data={data}
              tableId="candidateTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

const StatusIcon = ({ isChecked }) => (
  <i
    className={`fas ${isChecked ? "fa-check-circle" : "fa-times-circle"} ${isChecked ? "check-icon" : "un-check-icon"}`}
  />
)

export default Candidate
