import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import {  AvForm,AvField } from "availity-reactstrap-validation"
import queryString from "query-string"
import { useTransition } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../config"
import { dateConverter,setTitle,getDate } from "../../helpers/functions"
import { get } from "../../helpers/api_helper"
import SearchField from "../../components/Common/SearchField"
import { jsonToExcel } from "../../helpers/functions"
const OfferLetterReports = props => {
  setTitle("offerletter")
  const formRef = useRef()
  const filterRef = useRef()
  const todayDate = getDate()

  const [tableData, setTableData] = useState([])

  const [exports, setExports] = useState([])
  const limit = 18
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})
  const [isExportPending, startExport] = useTransition()

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line



  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`;
      const response = await get(`/report/offerletter-provided-report${query}`);
  
      const { data = [], count = 0 } = response;
  
      const processedData = data.map((item, index) => {
        return {
          ...item,
          id: (currentPage - 1) * limit + index + 1,
          date: dateConverter(item.date),
          offerletter: item.offerLetter?.company, 
          options: (
            <div className="d-flex align-items-center justify-content-center">
              {item.offerLetter?.company ? (
                <Tooltip title="Uploaded Offer Letter" arrow placement="top">
                  <a
                    href={`${API_URL}${item.offerLetter.company}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-file-pdf pdf-icon"></i>
                  </a>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            
          )
        };
      });
  
      const totalPage = Math.ceil(count / limit);
      setTotalPage(totalPage);
      startExport(() => {
        handleExportData(count)
      })
      setTableData(processedData);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  


  const handleExportData = async (count) => {
    try {
      const query = `?page=0&limit=${count}&${queryString.stringify(filterObject)}`;
      const response = await get(`/report/offerletter-provided-report${query}`);
      const { data = [] } = response;

      const exportData = data.map((item, index) => ({
        "#": index + 1,
        "Date": dateConverter(item.date),
        "Candidate Name": item.candidateName,
        "Candidate ID": item.candidateId,
        "Internship Name": item.internshipName,
        "Internship ID": item.internshipId,
        "Company": item.companyName,
        "Stipend": item.stipend,
        "Start Date": item.startDate,
        "End Date": item.endDate
      }));

      setExports(exportData);
      // jsonToExcel(exportData, "OfferLetterReports");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      //   handleFilterDistrictOptions(value)
    }

    setFilterObject(obj)
  }




  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    if (formRef.current) formRef.current.reset()
      if (filterRef.current) filterRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectFilter({})
    setFilterObject({})
    handleTableData([])
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Candidate Name", field: "candidateName" },
      { label: "Candidate ID", field: "candidateId" },
      { label: "Internship Name", field: "internshipName" },
      { label: "Internship ID", field: "internshipId"},
      { label: "Company", field: "companyName" },
      { label: "Stipend", field: "stipend" },
      { label: "Start Date", field: "startDate" },
      { label: "End Date", field: "endDate" },
      // { label: "Status", field: "" },
      { label: "Action", field: "options" },


    ],
    rows: tableData,
  }
  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `offerletter-reports`)
    } else {
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Internship Offer Letter Overview" />

          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={filterObject.from || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={filterObject.to || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.from}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      placeholder="Search name, short code, mobile"
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </Col>
                  <Col md={2} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <Button
                      style={{ marginLeft: "14px" }}
                      color="warning"
                      type="button"
                      disabled={isExportPending}
                      onClick={handleExport}
                    >
                      Export{" "}
                      {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                tableId="companyTable"
                page={page}
                onChange={handlePageChange}
                data={data}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OfferLetterReports
