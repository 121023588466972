import React, { useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import { get, put } from "../../../../helpers/api_helper"
import { qualificationOptions, QUALIFIED, typeOptions, TYPES } from "../helper"
import { MDBDataTable } from "mdbreact"

import "./styles.scss"
import {
  numberToString,
  reactSelectCustomStyle,
  renderTableValue,
} from "../../../../helpers/functions"

const SmeUpdateScore = () => {
  const formRef = useRef()

  // const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [internshipOptions, setInternshipOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])
  const [smeOptions, setSmeOptions] = useState([])

  const [candidates, setCandidates] = useState([])

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`sme/score/options/internship`)
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobOptions = async () => {
    try {
      const response = await get(`sme/score/options/job`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSmeOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)

      const response = await get(`sme/score/options/sme?${query}`)
      setSmeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCandidateOptions = async (obj = masterObject) => {
    try {
      if (!obj.sme) return

      formRef.current?.reset()

      const query = queryString.stringify(obj)
      const response = await get(`sme/score/candidates?${query}`)
      const { data } = response

      const now = new Date()
      data.map((item, index) => {
        item.id = index + 1

        if (!item.updated) {
          item.communicationField = (
            <div className="sme-input">
              <AvField
                className="small-input"
                name={`communication-${now}-${index}`}
                type="number"
                max={item?.smeMarks?.communication || 0}
                errorMessage={`Max mark :${item?.smeMarks?.communication || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "communication",
                    index,
                  })
                }
              />
            </div>
          )

          item.technicalCompetenceField = (
            <div className="sme-input">
              <AvField
                className="small-input"
                type="number"
                name={`technicalCompetence-${now}-${index}`}
                max={item?.smeMarks?.technicalCompetence || 0}
                errorMessage={`Max mark :${item?.smeMarks?.technicalCompetence || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "technicalCompetence",
                    index,
                  })
                }
              />
            </div>
          )
          item.problemSolvingField = (
            <div className="sme-input">
              <AvField
                className="small-input"
                name={`problemSolving-${now}-${index}`}
                type="number"
                max={item?.smeMarks?.problemSolving || 0}
                errorMessage={`Max mark :${item?.smeMarks?.problemSolving || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "problemSolving",
                    index,
                  })
                }
              />
            </div>
          )

          item.teamWorkField = (
            <div className="sme-input">
              <AvField
                className="small-input"
                name={`teamWork-${now}-${index}`}
                type="number"
                max={item?.smeMarks?.teamWork || 0}
                errorMessage={`Max mark :${item?.smeMarks?.teamWork || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "teamWork",
                    index,
                  })
                }
              />
            </div>
          )

          item.presentationField = (
            <div className="sme-input">
              <AvField
                className="small-input"
                name={`presentation-${now}-${index}`}
                type="number"
                max={item?.smeMarks?.presentation || 0}
                errorMessage={`Max mark :${item?.smeMarks?.presentation || 0}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "presentation",
                    index,
                  })
                }
              />
            </div>
          )

          item.remarksField = (
            <div className="remarks-input">
              <AvField
                placeholder="remarks"
                className="small-input"
                name={`remarks-${now}-${index}`}
                onChange={e =>
                  handleScoreChange({
                    value: e.target.value,
                    name: "remarks",
                    index,
                  })
                }
              />
            </div>
          )

          item.qualifiedField = (
            <div>
              <Select
                menuPosition="fixed"
                options={qualificationOptions}
                styles={reactSelectCustomStyle}
                onChange={selected =>
                  handleScoreChange({
                    value: selected?.value,
                    name: "qualified",
                    index,
                  })
                }
              />
            </div>
          )
        } else {
          item.communicationField = numberToString(item.communication)
          item.technicalCompetenceField = numberToString(
            item.technicalCompetence,
          )
          item.problemSolvingField = numberToString(item.problemSolving)
          item.teamWorkField = numberToString(item.teamWork)
          item.presentationField = numberToString(item.presentation)
          item.totalMarkField = numberToString(item.totalMark)
          item.remarksField = renderTableValue(item.remarks)

          const qualification = QUALIFIED[item.qualified]

          if (qualification) {
            item.qualifiedField = (
              <Button {...qualification}>{qualification.text}</Button>
            )
          }
        }

        return item
      })
      setCandidates(data)
    } catch (error) {
      console.error(error)
      setCandidates([])
    }
  }

  const handleSubmit = async () => {
    try {
      const updated = []

      for (let candidate of candidates) {
        const smeMark = candidate.smeMarks

        const hasValidFields =
          candidate.communication ||
          candidate.presentation ||
          candidate.problemSolving ||
          candidate.teamWork ||
          candidate.technicalCompetence

        if (
          !candidate.qualified ||
          (!hasValidFields && candidate.qualified === QUALIFIED.QUALIFIED)
        ) {
          continue
        }

        const isCommunicationValid =
          !candidate.communication ||
          smeMark.communication >= candidate.communication
        const isPresentationValid =
          !candidate.presentation ||
          smeMark.presentation >= candidate.presentation
        const isProblemSolvingValid =
          !candidate.problemSolving ||
          smeMark.problemSolving >= candidate.problemSolving
        const isTeamWorkValid =
          !candidate.teamWork || smeMark.teamWork >= candidate.teamWork
        const isTechnicalCompetenceValid =
          !candidate.technicalCompetence ||
          smeMark.technicalCompetence >= candidate.technicalCompetence

        if (
          candidate.qualified === QUALIFIED.QUALIFIED &&
          !isCommunicationValid &&
          !isPresentationValid &&
          !isProblemSolvingValid &&
          !isTeamWorkValid &&
          !isTechnicalCompetenceValid
        ) {
          continue
        }

        updated.push({
          id: candidate.candidate,
          communication: candidate.communication || 0,
          presentation: candidate.presentation || 0,
          problemSolving: candidate.problemSolving || 0,
          teamWork: candidate.teamWork || 0,
          technicalCompetence: candidate.technicalCompetence || 0,
          qualified: candidate.qualified,
          remarks: candidate.qualified,
        })
      }

      if (!updated.length) {
        toastr.error("Enter valid details")
        return
      }
      const response = await put(`sme/score/update`, {
        ...masterObject,
        candidates: updated,
      })
      toastr.success(response.message)
      reset()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleSelectValueChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "type") {
      obj.job = null
      obj.sme = null
      obj.internship = null
    }
    if (name === "internship" || name === "job") {
      obj.sme = ""
    }

    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "type") {
      if (value === TYPES.JOB && !jobOptions.length) {
        handleJobOptions()
      } else if (!internshipOptions.length) handleInternshipOptions()

      obj.job = null
      obj.sme = null
      obj.internship = null

      setCandidates([])
      formRef.current?.reset()
    }

    if (name === "internship" || name === "job") {
      obj.sme = null
      handleSmeOptions(obj)
      setCandidates([])
      formRef.current?.reset()
    }

    if (name === "sme") {
      handleCandidateOptions(obj)
    }
    setMasterObject(obj)
  }

  const handleScoreChange = ({ value, name, index }) => {
    setCandidates(prev =>
      prev.map((item, i) => (i === index ? { ...item, [name]: value } : item)),
    )
  }

  const reset = () => {
    formRef.current?.reset()

    setMasterObject({})
    setSelectedFields({})

    setSmeOptions([])
    setCandidates([])
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Register Id", field: "uniqueId" },
      { label: "Name", field: "name" },
      {
        label: (
          <Tooltip title="Communication Skills" arrow placement="top">
            Comm.
          </Tooltip>
        ),
        field: "communicationField",
      },
      {
        label: (
          <Tooltip title="Technical Competence" arrow placement="top">
            Tech.
          </Tooltip>
        ),
        field: "technicalCompetenceField",
      },
      {
        label: (
          <Tooltip title="Problem-Solving Skills" arrow placement="top">
            Solve.
          </Tooltip>
        ),
        field: "problemSolvingField",
      },
      {
        label: (
          <Tooltip title="Teamwork Skills" arrow placement="top">
            Team.
          </Tooltip>
        ),
        field: "teamWorkField",
      },
      {
        label: (
          <Tooltip title="Presentation Skills" arrow placement="top">
            Pres.
          </Tooltip>
        ),
        field: "presentationField",
      },

      { label: "Qualified", field: "qualifiedField" },
      { label: "Remarks", field: "remarksField" },
    ],
    rows: candidates,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Type</Label>
                  <Select
                    menuPosition="fixed"
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    onChange={selected =>
                      handleSelectValueChange({ name: "type", selected })
                    }
                  />
                </Col>

                {masterObject.type === TYPES.INTERNSHIP && (
                  <Col md={3}>
                    <Label>Internship</Label>
                    <Select
                      menuPosition="fixed"
                      options={internshipOptions}
                      value={selectedFields.internship || ""}
                      onChange={selected =>
                        handleSelectValueChange({
                          name: "internship",
                          selected,
                        })
                      }
                    />
                  </Col>
                )}

                {masterObject.type === TYPES.JOB && (
                  <Col md={3}>
                    <Label>Job</Label>
                    <Select
                      menuPosition="fixed"
                      options={jobOptions}
                      value={selectedFields.job || ""}
                      onChange={selected =>
                        handleSelectValueChange({ name: "job", selected })
                      }
                    />
                  </Col>
                )}

                <Col md={3}>
                  <Label>SME</Label>
                  <Select
                    options={smeOptions}
                    value={selectedFields.sme || ""}
                    isDisabled={!masterObject.internship && !masterObject.job}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "sme" })
                    }
                  />
                </Col>
                <Col>
                  <div className="d-flex gap-2 form-button">
                    <Button color="primary">Submit</Button>
                    <Button type="reset" onClick={reset} color="danger">
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
              <MDBDataTable
                id="smeUpdateScoreTable"
                data={data}
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
              />
            </AvForm>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default SmeUpdateScore
