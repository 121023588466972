import React from "react"
import { Card, CardBody, CardTitle, CardText, Input, Col } from "reactstrap"

const CardCandidate = ({ candidate, onSelect, isSelected }) => {
  const handleChange = (e) => {
    onSelect(candidate, e.target.checked);
  }

  return (
    <Col xs="12" sm="6" md="4" lg="3" xl="3" className="mb-3">
      <Card className={`candidate-card ${isSelected ? "selected" : ""}`}>
        <CardBody>
          <CardTitle tag="h5">{candidate?.label}</CardTitle>
          <CardText>Reg ID: {candidate?.RegId}</CardText>
          <Input
            type="checkbox"
            id={`candidate-${candidate?.RegId}`}
            label={isSelected ? "Selected" : "Select"}
            checked={isSelected}
            onChange={handleChange}
          />
        </CardBody>
      </Card>
    </Col>
  )
}

export default CardCandidate
