import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import TablePagination from "../../../components/Common/TablePagination"

import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  jsonToExcel,
  numberToCurrency,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"
import { API_URL } from "../../../config"
import { Tooltip } from "@mui/material"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SearchSelect from "../../../components/Common/SearchSelect"
import Select from "react-select"

import toastr from "toastr"
import queryString from "query-string"

import "./styles.scss"

import Breadcrumb from "../../../components/Common/Breadcrumb"
import PaymentDetails from "../../Finance/PaymentDetails"
import moment from "moment"
import {
  paymentForOptions,
  paymentForValues,
  statusOptions,
  statusValues,
} from "../../Finance/Payments/helper"

const Payments = () => {
  const formRef = useRef()
  const todayDate = getDate()

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPage, setTotalPage] = useState(1)

  const [detailsModal, setDetailsModal] = useState({ show: false, id: null })

  const [loading, setLoading] = useState({ export: false })
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`

      const response = await get(`report/payments${query}`)
      const { count, data } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        const candidate = item.candidate

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.uniqueId = renderTableValue(item?.uniqueId)
        item.college = renderTableValue(item?.college?.name)

        item.candidateId = renderTableValue(candidate?.uniqueId)
        item.candidate = renderTableValue(candidate?.name)
        item.mobile = renderTableValue(candidate?.mobile)
        item.email = renderTableValue(candidate?.email)
        item.state = renderTableValue(candidate?.state?.name)
        item.district = renderTableValue(candidate?.district?.name)
        item.course = renderTableValue(candidate?.course?.name)
        item.specialization = renderTableValue(candidate?.specialization?.name)

        item.feeAmount = numberToCurrency(item?.feeAmount)
        item.amount = numberToCurrency(item?.amount)

        item.paymentFor = renderTableValue(paymentForValues[item?.paymentFor])

        item.paymentId = renderTableValue(item.razorpayDetails?.paymentId)
        item.orderId = renderTableValue(item.razorpayDetails?.orderId)

        const status = statusValues[item.status]

        item.status = (
          <Button size="sm" disabled color={status?.color}>
            {status?.text}
          </Button>
        )

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleToggleModal({ show: true, id: item._id })}
              ></i>
            </Tooltip>
            {item.receipt ? (
              <Tooltip title="Receipt" arrow placement="top">
                <a
                  href={API_URL + item.receipt}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i
                    className="uil-file-download"
                    style={{ fontSize: "1.5em", cursor: "pointer" }}
                  ></i>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {}
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(masterObject)}`
      const response = await get(`report/payments${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}
        const candidate = item.candidate

        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)
        exportData["Time"] = timeConverter(item.time)

        exportData["Receipt No"] = item.uniqueId
        exportData["Institute"] = item.college?.name

        exportData["Candidate Id"] = candidate?.uniqueId
        exportData["Candidate"] = candidate?.name
        exportData["Mobile"] = candidate?.mobile
        exportData["Email"] = candidate?.email
        exportData["State"] = candidate?.state?.name
        exportData["District"] = candidate?.district?.name
        exportData["Course"] = candidate?.course?.name
        exportData["Specialization"] = candidate?.specialization?.name

        exportData["Fee"] = item?.feeAmount
        exportData["Amount"] = item?.amount

        exportData["Payment For"] = paymentForValues[item?.paymentFor]

        exportData["Status"] =
          item?.status === 0 ? "Success" : item.status === 1 ? "Failed" : ""

        exportData["Payment Id"] = item.razorpayDetails?.paymentId
        exportData["Order Id"] = item.razorpayDetails?.orderId

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Payments-${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleToggleModal = ({ show, id }) => {
    setDetailsModal({ show, id })
  }
  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current.reset()

    setMasterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Receipt No", field: "uniqueId" },
      { label: "Institute", field: "college" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Candidate", field: "candidate" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "State", field: "state" },
      { label: "District", field: "district" },
      { label: "Course", field: "course" },
      { label: "Specialization", field: "specialization" },
      { label: "Payment For", field: "paymentFor" },
      { label: "Fee", field: "feeAmount" },
      { label: "Amount", field: "amount" },
      { label: "Status", field: "status" },
      { label: "Payment Id", field: "paymentId" },
      { label: "Order Id", field: "orderId" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Payments" />
        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={masterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={masterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={masterObject.from}
                    max={todayDate}
                  />
                </Col>
                <Col md={3}>
                  <Label>Payment For</Label>
                  <Select
                    options={paymentForOptions}
                    value={selectedFields.paymentFor || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "paymentFor" })
                    }
                    isMulti
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    api={`report/options/payments/candidate`}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate}
                    isMulti
                    master={masterObject}
                  />
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    options={statusOptions}
                    value={selectedFields.status || ""}
                    onChange={selected =>
                      handleSelectValueChange({ selected, name: "status" })
                    }
                    isClearable
                  />
                </Col>
                <Col md={2} className="d-flex gap-3">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                  <Button color="warning" onClick={handleExport}>
                    {loading.export ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="paymentsReportTable"
              count={totalPage}
              totalCount={totalCount}
            />
          </CardBody>
        </Card>
      </Container>
      <PaymentDetails
        isOpen={detailsModal.show}
        onToggle={() => handleToggleModal({})}
        id={detailsModal.id}
      />
    </div>
  )
}

export default Payments
