import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { dateConverter } from "../../helpers/functions"
import Select from "react-select"
import { Tooltip } from "@mui/material"
// import { API_URL } from "../../config";
import { del, get, post, put } from "../../helpers/api_helper"
import TablePagination from "../../components/Common/TablePagination"
import Swal from "sweetalert2"
import toastr from "toastr"

const Specialization = () => {
  const formRef = useRef()
  const [masterObject, setMasterObject] = useState({})
  const [tableData, setTableData] = useState([])
  const [DropDownData, setDropDownData] = useState([])
  const [selectedFields, setSelectedFields] = useState({})
  const [searchQuery, setSearchQuery] = useState("")
  console.log(searchQuery)

  const [filteredData, setFilteredData] = useState([])

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await get(`/course`)
        setDropDownData(result)
      } catch (err) {
        toastr.error("Failed to fetch data")
      }
    }
    getData()
  }, [])

  useEffect(() => {
    handleTableData()
  }, []) //eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`/specialization${query}`)
      const { data, count } = response

      const formattedData = data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)
        item.state = item.state?.name
        item.staff = item.addedBy?.name
        item.courseLabel = item.course?.label

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(formattedData)
      setFilteredData(formattedData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    filterData()
  }, [searchQuery, selectedFields.course]) // eslint-disable-line

  const filterData = () => {
    let filtered = tableData

    if (searchQuery) {
      filtered = filtered.filter(
        item =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.shortCode.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    }

    if (selectedFields.course) {
      filtered = filtered.filter(
        item => item.course?._id === selectedFields.course.value,
      )
    }

    setFilteredData(filtered)
  }

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleValueChange = ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Specialization Name", field: "name" },
      { label: "Course", field: "courseName" },
      { label: "ShortCode", field: "shortCode" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: filteredData.map(item => ({
      ...item,
      courseName: item.course?.name || "N/A",
    })),
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`specialization`, masterObject)
      else response = await post(`specialization`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const reset = () => {
    if (formRef.current) {
      formRef.current.reset()
    }
    setMasterObject({})
    setSelectedFields({})
    setSearchQuery("")
    handleTableData(1)
    setPage(1)
    setTotalPage(1)
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`specialization/details?id=${id}`)
      const data = response.data
      setMasterObject({ ...data, id, state: data?.state?.value })
      setSelectedFields({ state: data?.state })
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })

      if (!result.isConfirmed) return

      const response = await del(`specialization/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message)
      }
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Specialization" />

        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md="3">
                  <Label>Select Course</Label>
                  <Select
                    menuPosition="fixed"
                    options={[
                      { label: "-- Select Course --", value: "" },
                      ...(DropDownData.data?.map(course => ({
                        label: course.name,
                        value: course._id,
                      })) || []),
                    ]}
                    value={
                      masterObject.course
                        ? {
                            label: masterObject.course.label,
                            value: masterObject.course._id,
                          }
                        : { label: "-- Select Course --", value: "" }
                    }
                    onChange={selected => {
                      handleValueChange({
                        name: "course",
                        value: selected.value
                          ? { _id: selected.value, label: selected.label }
                          : null,
                      })
                    }}
                    placeholder="-- Select Course --"
                  />
                </Col>

                <Col md="3">
                  <Label>Specialization Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter Specialization Name"
                    errorMessage="Please enter the specialization name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3">
  <Label>Short Code</Label>
  <AvField
    name="shortCode"
    placeholder="Short Code"
    type="text"
    errorMessage="Enter Short Code (only letters and numbers allowed)"
    validate={{
      required: { value: true, errorMessage: "Short Code is required" },
      pattern: { value: '^[a-zA-Z0-9]+$', errorMessage: "Only letters and numbers are allowed" },
    }}
    value={masterObject.shortCode || ""}
    onChange={e => handleValueChange(e.target)}
  />
</Col>
              </Row>

              <Row style={{ marginTop: "26px" }}>
                <Col md="6">
                  <div className="d-flex gap-3">
                    <Button color="primary">Submit</Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <AvForm>
              <Row>
                <Col md={3}>
                  <Label>Search</Label>
                  <AvField
                    name="search"
                    placeholder="Search name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>
                <Col md={3}>
                  <Label>Filter by Course</Label>
                  <Select
                    options={DropDownData.data?.map(course => ({
                      label: course.name,
                      value: course._id,
                    }))}
                    value={selectedFields.course || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "course")
                    }
                  />
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId=""
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Specialization
