import React, { useEffect, useState, useRef } from "react"

import PropTypes from "prop-types"
import queryString from "query-string"

import AsyncSelect from "react-select/async"

import { get } from "../../helpers/api_helper"

const SearchSelect = ({
  api,
  onChange,
  value,
  master,
  query,
  isDisabled,
  cacheOptions,
  ...props
}) => {
  const [options, setOptions] = useState([])
  const searchTimeout = useRef(null)

  useEffect(() => {
    if (!isDisabled) {
      handleOptions({ master }) // master if it's dependent
    } else setOptions([])
  }, [master, api, query]) // eslint-disable-line

  useEffect(() => {
    setOptions([])
  }, [query])

  const handleOptions = async ({ master = {}, search = "" }) => {
    try {
      const response = await get(
        `${api}?search=${search}&${queryString.stringify(master)}&${query}`,
      )
      if (!search) setOptions(response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const loadOptions = (inputValue, callback) => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current)

    searchTimeout.current = setTimeout(async () => {
      const data = await handleOptions({ master, search: inputValue })
      callback(data || [])
    }, 500)
  }

  return (
    <AsyncSelect
      // cacheOptions // It prevents fetching the latest options, even though the state is empty. (make it )
      cacheOptions={cacheOptions}
      loadOptions={loadOptions}
      defaultOptions={options}
      onChange={onChange}
      value={value || ""}
      menuPosition="fixed"
      disabled={isDisabled}
      {...props}
    />
  )
}

SearchSelect.propTypes = {
  api: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  master: PropTypes.object,
  query: PropTypes.string,
  disabled: PropTypes.bool,
  cacheOptions: PropTypes.bool,
}

SearchSelect.defaultProps = {
  api: "options/search/candidate",
  value: "",
  master: {},
  query: "",
  disabled: false,
  cacheOptions: true,
}

export default SearchSelect
