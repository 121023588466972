import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import { Card, CardBody, Col, Container, FormGroup, Row } from "reactstrap"
import { Modal } from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { Box, Divider } from "@mui/material"

import LocationCityIcon from "@mui/icons-material/LocationCity"
import TabContext from "@mui/lab/TabContext"
import Avatar from "@mui/material/Avatar"
import TabPanel from "@mui/lab/TabPanel"
import Stack from "@mui/material/Stack"
import TabList from "@mui/lab/TabList"
import Tab from "@mui/material/Tab"

import toastr from "toastr"

import { get, put } from "../../../helpers/api_helper"
import { API_URL, PRIVILEGES } from "../../../config"
import {
  dateConverter,
  genderValue,
  timeConverter,
} from "../../../helpers/functions"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useUser from "../../../hooks/useUser"

const UserDashboard = () => {
  const params = useParams()

  const user = useUser()
  const formRef = useRef()

  const [details, setDetails] = useState({})
  const [activityLog, setActivityLog] = useState([])

  const [modal, setModal] = useState(false)
  const [tab, setTab] = useState("1")

  const [master, setMaster] = useState({})
  const [passShow, setPassShow] = useState(false)

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  useEffect(() => {
    handleTableData()
  }, [params]) // eslint-disable-line

  useEffect(() => {
    if (details._id) handleUserActivity()
  }, [page, details]) // eslint-disable-line

  const handleTableData = async () => {
    try {
      const response = await get(`user/details?id=${params.id}`)
      const data = response?.data

      data.privilege = data?.privilege?.name || ""
      data.designation = data?.designation?.name || ""
      data.staff = data?.addedBy?.name || ""

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)
      data.gender = genderValue(data.gender)

      setDetails(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUserActivity = async (perPage = page) => {
    try {
      const response = await get(
        `security/activity?staff=${details?._id}&page=${page}&limit=${limit}`,
      )
      const data = response?.data
      const count = response?.count

      data.map((item, idx) => {
        item.id = idx + 1 + (perPage - 1) * limit

        item.date = dateConverter(item?.date)
        item.time = timeConverter(item?.time)
        return item
      })
      setActivityLog(data)

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await put("user/password", master)
      toastr.success(response.message)
      reset()
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleUserActivity(value)
  }

  const handleValueChange = e => {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const reset = () => {
    formRef.current.reset()
    setMaster({})
    setModal(false)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Created IP", field: "ip" },
      { label: "Activity", field: "description" },
    ],
    rows: activityLog,
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(pre => !pre)
          reset()
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              setModal(pre => !pre)
              reset()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          ref={formRef}
          onValidSubmit={(e, v) => {
            handleSubmit()
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="my-3 position-relative" id="confirm-pass">
                  <AvField
                    name="password"
                    placeholder="Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage=" Please provide a valid password"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master.password || ""}
                    onChange={handleValueChange}
                  />
                  {passShow ? (
                    <i
                      className="fas fa-eye-slash position-absolute"
                      onClick={() => setPassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye position-absolute"
                      onClick={() => setPassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      match: {
                        value: "password",
                        errorMessage: "Password not match",
                      },
                    }}
                    id="validationCustom05"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              type="button"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setModal(pre => !pre)
                reset()
              }}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem={details?.uniqueId} />

          <Row>
            <div className="col-xl-3">
              <Card className="shadow-sm" style={{ height: "max-content" }}>
                <CardBody>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ justifyContent: "center" }}
                  >
                    <Avatar
                      alt={String(details?.username)?.toUpperCase()}
                      src={`${API_URL}${details?.image}`}
                      sx={{ width: 56, height: 56 }}
                      style={{ width: "6rem", height: "6rem" }}
                    />
                  </Stack>
                  {(user._id === details._id ||
                    user.privilege === PRIVILEGES.ADMIN) && (
                    <i
                      className="fas fa-key "
                      style={{
                        fontSize: "1em",
                        position: "relative",
                        float: "right",
                        bottom: "83px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMaster(pre => ({ ...pre, id: details._id }))
                        setModal(pre => !pre)
                      }}
                    ></i>
                  )}
                  <h5
                    className="mt-3 mb-1"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  >
                    {details?.username}
                  </h5>
                  <div
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    {details?.mobile}
                  </div>
                  <div
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    {details?.designation}
                  </div>
                  <p
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  ></p>
                  <div
                    className="mt-1 mb-2"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  ></div>
                  <Divider />
                  <DetailField label="Full Name" value={details?.name} />
                  <DetailField label="Staff Id" value={details?.uniqueId} />
                  <DetailField label="Mobile" value={details?.mobile} />
                  <DetailField label="Email" value={details?.email} />
                  <DetailField label="Gender" value={details?.gender} />
                  <DetailField label="DOB" value={details?.dob} />
                  <DetailField label="Privilege" value={details?.privilege} />
                  <DetailField label="Added By" value={details?.staff} />
                  <DetailField label="Date" value={details?.date} />
                  <DetailField label="Time" value={details?.time} />
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-9" style={{ marginBottom: "30px" }}>
              <Card className="mb-0 shadow-sm" style={{ width: "100%" }}>
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        icon={<LocationCityIcon style={{ fontSize: "20px" }} />}
                        label="Activity log"
                        value="1"
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Card>
                      <CardBody>
                        <TablePagination
                          page={page}
                          onChange={handlePageChange}
                          data={data}
                          tableId="userActivityLog"
                          count={totalPage}
                        />
                      </CardBody>
                    </Card>
                  </TabPanel>
                </TabContext>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UserDashboard

const DetailField = ({ label, value }) => {
  if (!value) return null

  return (
    <div className="mt-2">
      <p className="mb-1">{label}:</p>
      <h5 className="font-size-14">{value}</h5>
    </div>
  )
}
