import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row, Badge } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { get } from "../../helpers/api_helper"
import moment from "moment"
import { Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"

const JobfairRegistration = () => {
    const navigate = useNavigate()
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [jobFairOptions, setjobFairOptions] = useState([])
    const [selectValues, setselectValues] = useState({})
    const [filterValues, setfilterValues] = useState({})

    const [tableData, settableData] = useState([])

    useEffect(() => {
        getAllJobFair()

    }, [])

    useEffect(() => {
        fetchAllRegistrations()

    }, [page, filterValues])


    function getAllJobFair() {
        get(`options/jobfair`).then((res) => {
            setjobFairOptions(res.data)
        }).catch((err) => {

        })
    }

    function fetchAllRegistrations() { 
        get(`job-fair/candidate-registration?page=${page}&limit=${limit}&source=${filterValues?.source}&jobFair=${filterValues?.jobFair}`).then((res) => {
            const { data, count } = res;
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1;
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : ""
                item.name = item?.jobFair?.name;
                item.student = item?.candidate?.name;
                item.from = item?.source === 1 ? "Website" : item?.source === 2 ? "Spot" : item?.source === 3 ? "CRM" : "--"
                item.attend=item?.attendance===1?"Not-Attended":item?.attendance===2?"Attended":""
                item.fee = (
                    <>
                        <Badge color={item?.feePaid===0?"warning":item?.feePaid===1?"success":""} style={{ fontSize: "12px", marginLeft: "10px" }}>
                           {item?.feePaid===0?"Pending":item?.feePaid===1?"Paid":"---"}
                        </Badge>
                    </>
                )
                item.view = (
                    <>
                        <div>
                            <Tooltip title="View" arrow placement="top">
                                <i
                                    className="fas fa-eye eye-icon"
                                    onClick={() => {
                                        navigate(`/job-fair/registration/details/${item?.uniqueId}`, {
                                            state: { id: item?.uniqueId },
                                        })
                                    }}
                                ></i>
                            </Tooltip>
                        </div>
                    </>
                )
                return item

            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(data)
        })
    }



    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Job Fair", field: "name" },
            { label: "Student", field: "student" },
            { label: "Reg.Id", field: "uniqueId" },
            { label: "Fee", field: "fee" },
            { label: "Attendance", field: "attend" },
            { label: "Source", field: "from" },
            { label: "View", field: "view" },
        ],
        rows: tableData

    }
    const handlePageChange = value => {
        setPage(value)
    }

    function handleFiltervalues(select, name) {
        setselectValues({ ...selectValues, [name]: select })
        setfilterValues({ ...filterValues, [name]: select.value })

    }
    function resetAll() {
        setfilterValues({})
        setselectValues({})
        setPage(1)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Registartions" />
                    <Card>
                        <CardBody>
                            <AvForm>
                                <Row>
                                    <Col md={3}>
                                        <Label>Select Job Fair</Label>
                                        <Select
                                            options={jobFairOptions}
                                            value={selectValues?.jobFair || null}
                                            onChange={(selected) => {
                                                handleFiltervalues(selected, "jobFair")
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Label>Source</Label>
                                        <Select
                                            options={[
                                                { label: "CRM", value: 3 },
                                                { label: "Spot", value: 2 },
                                                { label: "Website", value: 1 },
                                            ]}
                                            value={selectValues?.jobFair || null}
                                            onChange={(selected) => {
                                                handleFiltervalues(selected, "source")
                                            }}
                                        />
                                    </Col>

                                    <Col md={3} style={{ marginTop: "26px" }}>
                                        <Button type="button" color="danger" className="mx-2" onClick={resetAll} >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>
                            <Row className="mt-4">
                                <TablePagination
                                    tableId="managecmpanyTable"
                                    page={page}
                                    onChange={handlePageChange}
                                    data={data}
                                    count={totalPage}
                                    totalCount={totalCount}
                                />

                            </Row>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default JobfairRegistration
