export const statusValues = {
  0: { text: "Approved", color: "success" },
  1: { text: "Rejected", color: "danger" },
}
export const statusOptions = [
  { label: "Approve", value: 0 },
  { label: "Reject", value: 1 },
]

export const filterStatusOptions = [
  { label: "Applied", value: 0 },
  { label: "Shortlist", value: 2 },
  { label: "Placed", value: 3 },
  { label: "Rejected", value: 4 },
]

export const typeOptions = [
  { label: "KTU Internship", value: 1 },
  { label: "General Internship", value: 2 },
  { label: "Public Sector Internship", value: 3 },
]
