import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import Swal from "sweetalert2"
import toastr from "toastr"

import FileUpload from "../../../components/Common/FileUpload"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { API_URL } from "../../../config"
import { del, get, post, put } from "../../../helpers/api_helper"
import {
  dateConverter,
  setTitle,
  handleImageValidation,
} from "../../../helpers/functions"

import "./styles.scss"

const Testimonials = () => {
  setTitle("Testimonials")

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [tableData, setTableData] = useState([])

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}`
      const response = await get(`testimonials${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.testimonial = (
          <Tooltip title={item.testimonial} placement="top" arrow>
            {item.testimonial}
          </Tooltip>
        )

        if (item.image)
          item.file = (
            <a
              href={`${API_URL}${item.image}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ height: "30px", width: "40px", cursor: "pointer" }}
                src={API_URL + item.image}
                alt="img"
              />
            </a>
          )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`testimonials`, masterObject)
      else response = await post(`testimonials`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`testimonials/update/${id}`)
      const data = response.data
      setMasterObject({ ...data, id })
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    try {
      await handleImageValidation(selectedFile, 80, 80)
      const fd = new FormData()
      fd.append("image", e.target.files[0])
      const response = await post(`testimonials/image`, fd)
      handleValueChange({ value: response.data.new_filename, name: "image" })
    } catch (error) {
      toastr.error(error?.response?.data?.message || error)
      e.target.value = null
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (!result.isConfirmed) return
      const response = await del(`testimonials/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()
    setMasterObject({})

    handleTableData(1)

    setPage(1)
    setTotalPage(1)
    toTop()
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Name", field: "name" },
      { label: "Designation", field: "designation" },
      { label: "Company", field: "company" },
      { label: "Testimonial", field: "testimonial" },
      { label: "Image", field: "image" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Testimonials" />

          <Card>
            <CardBody>
              <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                <Row>
                  <Col md={3}>
                    <Label>Name</Label>
                    <AvField
                      name="name"
                      placeholder="Name"
                      errorMessage="Enter Name"
                      validate={{ required: { value: true } }}
                      value={masterObject.name || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Designation</Label>
                    <AvField
                      name="designation"
                      placeholder="Designation"
                      type="text"
                      errorMessage="Enter Designation"
                      validate={{ required: { value: true } }}
                      value={masterObject.designation || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Company</Label>
                    <AvField
                      name="company"
                      placeholder="Company"
                      type="text"
                      errorMessage="Enter Company"
                      validate={{ required: { value: true } }}
                      value={masterObject.company || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Testimonial</Label>
                    <AvField
                      name="testimonial"
                      placeholder="Testimonial"
                      type="text"
                      errorMessage="Enter Testimonial"
                      validate={{ required: { value: true } }}
                      value={masterObject.testimonial || ""}
                      onChange={e => handleValueChange(e.target)}
                    />
                  </Col>

                  <Col md={3}>
                    <FileUpload
                      label="Image"
                      imageSize="80x80"
                      name="image"
                      fileUrl={masterObject?.image}
                      onFileChange={handleFileUpload}
                      onDelete={() =>
                        handleValueChange({ value: "", name: "image" })
                      }
                      accept="image/*"
                      validate={{ required: { value: true } }}
                    />
                  </Col>

                  <Col md={3} style={{ marginTop: "26px" }}>
                    <div className="d-flex gap-3">
                      <Button color={masterObject.id ? "warning" : "primary"}>
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color="danger" type="reset" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="testimonials"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Testimonials
