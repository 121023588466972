export const TYPE_VALUES = { 1: "Income", 2: "Expense", INCOME: 1, EXPENSE: 2 }
export const PAYMENT_TYPES = { 1: "Credit", 2: "Cash", CREDIT: 1, CASH: 2 }

export const STATUS_VALUES = {
  0: { color: "success", title: "Approved" },
  2: { color: "danger", title: "Canceled" },
  3: { color: "warning", title: "Pending" },
  APPROVED: 0,
  CANCELED: 2,
  PENDING: 3,
}

export const paymentOptions = [
  { label: "Credit", value: PAYMENT_TYPES.CREDIT },
  { label: "Cash", value: PAYMENT_TYPES.CASH },
]

export const statusOptions = [
  { label: "Approve", value: STATUS_VALUES.APPROVED },
  { label: "Cancel", value: STATUS_VALUES.CANCELED },
]

export const headOptions = [
  { label: "Income", value: TYPE_VALUES.INCOME },
  { label: "Expense", value: TYPE_VALUES.EXPENSE },
]

export const filterStatusOptions = [
  { label: "Approved", value: STATUS_VALUES.APPROVED },
  { label: "Canceled", value: STATUS_VALUES.CANCELED },
  { label: "Pending", value: STATUS_VALUES.PENDING },
]
