import React, { useEffect, useState } from "react"

import { Modal, Card, CardBody, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import ListItem from "../../components/Common/ListItem"

import {
  dateConverter,
  numberToString,
  renderTableValue,
  timeConverter,
} from "../../helpers/functions"
import { get } from "../../helpers/api_helper"

const EnrollDetails = ({ isOpen, onToggle, id }) => {
  const statusValues = {
    0: { text: "Applied", color: "info" },
    2: { text: "Shortlisted", color: "warning" },
    3: { text: "Placed", color: "success" },
    4: { text: "Rejected", color: "danger" },
    5: { text: "Rejected", color: "danger" },
    6: { text: "Withdrawn", color: "danger" },
    7: { text: "Withdrawn Pending", color: "warning" },
    9: { text: "Completed", color: "info" },
  }
  const [tableData, setTableData] = useState([])
  const [masterData, setMasterData] = useState({})

  useEffect(() => {
    if (isOpen && id) handleData()
  }, [isOpen && id]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`enroll/details?id=${id}`)
      const { data, tableData } = response

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)
      data.college = data.college?.name
      data.company = data.company?.name
      data.internship = data.internship?.name

      if (data.from === 1) data.staff = renderTableValue(data.collegeUser?.name)
      else data.staff = renderTableValue(data.addedBy?.name)

      data.from = renderTableValue(data.from === 1 ? "Institute" : "CRM")
      data.total = numberToString(data.count?.success)

      tableData.map((item, index) => {
        item.id = index + 1

        item.name = item?.candidate?.name
        item.email = item?.candidate?.email
        item.mobile = item?.candidate?.mobile
        item.regNo = item?.candidate?.regNo
        item.cgpa = item?.candidate?.cgpa
        item.specialization = item?.candidate?.specialization?.name

        const status = statusValues[item.status]
        item.status = (
          <Button disabled size="sm" color={status.color}>
            {status.text}
          </Button>
        )
        return item
      })

      setMasterData(data)
      setTableData(tableData)
    } catch (error) {
      console.error(error)
    }
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "Register No", field: "regNo" },
      { label: "CGPA", field: "cgpa" },
      { label: "Specialization", field: "specialization" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>View Details</h5>
          </div>
          <button
            type="button"
            onClick={onToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ul className="list-details mb-3">
            <ListItem label="Date" value={masterData.date} />
            <ListItem label="Time" value={masterData.time} />
            <ListItem label="Institute" value={masterData.college} />
            <ListItem label="Company" value={masterData.company} />
            <ListItem label="Internship" value={masterData.internship} />
            <ListItem label="Candidates" value={masterData.total} />
            <ListItem label="From" value={masterData.from} />
            <ListItem label="Added By" value={masterData.staff} />
          </ul>

          <Card>
            <CardBody>
              <MDBDataTable
                id="enrollDetails"
                responsive
                bordered
                data={data}
                info={false}
                paging={false}
                sortable={false}
                searching={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default EnrollDetails
