import { EditorView } from "codemirror"
import { en } from "suneditor/src/lang"
import plugins from "suneditor/src/plugins"
import katex from "katex"
import moment from "moment"
import { getDate } from "../../../helpers/functions"

const todayDate = getDate()
const tomorrowDate = moment().add(1, "day").format("YYYY-MM-DD")

export const sunEditorOptions = {
  plugins: plugins,
  height: 250,
  EditorView: {
    src: EditorView,
    options: { indentWithTabs: true, tabSize: 2 },
  },
  katex: katex,
  lang: en,
  buttonList: [
    [
      "font",
      "fontSize",
      "formatBlock",
      "bold",
      "underline",
      "italic",
      "paragraphStyle",
      "blockquote",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "undo",
      "redo",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",
      "fullScreen",
      "showBlocks",
      "codeView",
      "preview",
    ],
  ],
}

export const unitOptions = [
  { label: "Days", value: 1 },
  { label: "Weeks", value: 2 },
  { label: "Months", value: 3 },
  { label: "Years", value: 4 },
]

export const tabsOptions = [
  { label: "Description", value: "1" },
  { label: "Eligibility", value: "2" },
]

export const initialState = {
  master: {
    duration: 1,
    startDate: todayDate,
    endDate: tomorrowDate,
    unit: unitOptions[0].value,
    details: [
      {
        tab: tabsOptions[0].value,
        tabDescription: tabsOptions[0].label,
        contents: "",
      },
      {
        tab: tabsOptions[1].value,
        tabDescription: tabsOptions[1].label,
        contents: "",
      },
    ],
  },
  select: {
    unit: unitOptions[0],
    details: [{ tab: tabsOptions[0] }, { tab: tabsOptions[1] }],
  },
}

export const statusOptions = [
  { value: 0, label: "Ongoing" },
  { value: 1, label: "Deleted" },
  { value: 2, label: "Inactive" },
  { value: 3, label: "Expired" },
  { value: 4, label: "Filled" },
]

export const approvalOptions = [
  { label: "Approve", value: 1 },
  { label: "Reject", value: 2 },
]
export const statusValues = {
  1: { text: "Active", color: "success" },
  2: { text: "Rejected", color: "danger" },
}

export const qualificationOptions = [
  { label: "Graduation", value: "graduation" },
  { label: "Post Graduation", value: "post_graduation" },
  { label: "Diploma", value: "diploma" },
  { label: "PhD", value: "phd" },
]

export const genderOptions = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Others", value: 3 },
]

export const workTypeOptions = [
  { label: "Part Time", value: 1 },
  { label: "Full Time", value: 2 },
  { label: "Contract", value: 3 },
]
export const workModeOptions = [
  { label: "Remote", value: 1 },
  { label: "Hybrid", value: 2 },
  { label: "In Person", value: 3 },
]

export const scheduleOptions = [
  { label: "Day-Shift", value: 1 },
  { label: "Night-Shift", value: 2 },
  { label: "Flexible", value: 3 },
]

export const experienceOptions = [
  { label: "0-1 Years", value: 0 },
  { label: "1-3 Years", value: 1 },
  { label: "3-5 Years", value: 2 },
  { label: "5-7 Years", value: 3 },
  { label: "7-9 Years", value: 4 },
  { label: "9+ Years", value: 5 },
]
