import React, { useEffect, useRef, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import queryString from "query-string"
import toastr from "toastr"

import { filterStatusOptions, statusOptions, statusValues } from "./helper"

import TablePagination from "../../../components/Common/TablePagination"
import SearchSelect from "../../../components/Common/SearchSelect"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  dateConverter,
  getDate,
  jsonToExcel,
  reactSelectCustomStyle,
  renderTableValue,
  setTitle,
  toTop,
} from "../../../helpers/functions"
import { get, put } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import "./styles.scss"
import LoadingButton from "../../../components/Common/LoadingButton"

const InternshipWithdrawal = () => {
  setTitle("Internship Withdrawals")

  const formRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState({ export: false })

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [companyOptions, setCompanyOptions] = useState([])
  const [internshipOptions, setInternshipOptions] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) //eslint-disable-line

  useEffect(() => {
    handleCompanyOptions()
    handleInternshipOptions()
  }, [filterObject]) //eslint-disable-line

  const handleCompanyOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)

      const response = await get(
        `internship/applications/options/withdraw/company?${params}`,
      )
      setCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInternshipOptions = async (obj = filterObject) => {
    try {
      const params = queryString.stringify(obj)
      const response = await get(
        `internship/applications/options/withdraw/internship?${params}`,
      )
      setInternshipOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`internship/applications/withdraw?${query}`)
    const { count, data } = response

    data.map((item, index) => {
      item.id = index + 1 + (currentPage - 1) * limit
      item.date = dateConverter(item.date)

      item.uniqueId = renderTableValue(item.internshipApplication?.uniqueId)
      item.internship = renderTableValue(item.internship?.name)
      item.company = renderTableValue(item.company?.name)

      const candidate = item.candidate
      item.candidateId = renderTableValue(candidate?.uniqueId)
      item.candidate = renderTableValue(candidate?.name)

      item.remarks = (
        <Tooltip title={item.remarks} placement="top" arrow>
          {item.remarks}
        </Tooltip>
      )
      const status = statusValues[item.status]
      if (status) {
        item.status = (
          <Button size="sm" color={status.color} disabled>
            {status.text}
          </Button>
        )
      } else
        item.status = (
          <Select
            styles={reactSelectCustomStyle}
            menuPosition="fixed"
            options={statusOptions}
            value={""}
            onChange={selected => {
              handleStatusChange({
                id: item.internshipApplication?._id,
                status: selected.value,
              })
            }}
          />
        )

      item.options = (
        <div className="d-flex align-items-center justify-content-center">
          {item?.document ? (
            <Tooltip title="Document" arrow placement="top">
              <a
                href={`${API_URL}${item.document}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-pdf pdf-icon"></i>
              </a>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
    setTotalCount(count)
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
      const response = await get(`internship/applications/withdraw?${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}
        const candidate = item.candidate

        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)

        exportData["Receipt No"] = item.uniqueId
        exportData["Institute"] = item.college?.name

        exportData["Candidate"] = candidate?.name
        exportData["Candidate Id"] = candidate?.uniqueId

        exportData["Fee"] = item?.feeAmount
        exportData["Amount"] = item?.amount

        exportData["Payment For"] =
          item?.paymentFor === 1
            ? "Registration Fee for Engineering students"
            : item?.paymentFor === 2
              ? "Completion Fee for Engineering students"
              : ""

        exportData["Status"] =
          item?.status === 0 ? "Success" : item.status === 1 ? "Failed" : ""

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Internship Withdrawn`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleStatusChange = async ({ id, status }) => {
    try {
      const response = await put(`internship/applications/withdraw`, {
        id,
        status,
      })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    setSelectedFields(prev => ({ ...prev, [name]: selected }))

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange({ value: value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
    toTop()
  }

  const reset = () => {
    toTop()

    formRef.current?.reset()
    setFilterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "ID", field: "uniqueId" },
      { label: "Company", field: "company" },
      { label: "Internship", field: "internship" },
      { label: "Candidate", field: "candidate" },
      { label: "Candidate Id", field: "candidateId" },
      { label: "Remarks", field: "remarks" },
      { label: "Status", field: "status" },
      { label: "Files", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Internship - Withdrawals" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={2}>
                  <Label>Company</Label>
                  <Select
                    options={companyOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "company" })
                    }}
                    value={selectedFields.company || ""}
                    isClearable
                  />
                </Col>

                <Col md={2}>
                  <Label>Internship</Label>
                  <Select
                    options={internshipOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "internship" })
                    }}
                    value={selectedFields.internship || ""}
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  <Label>Candidate</Label>
                  <SearchSelect
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "candidate" })
                    }}
                    value={selectedFields.candidate || ""}
                    isMulti
                    api="internship/applications/options/withdraw/candidate"
                    master={filterObject}
                  />
                </Col>

                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    onChange={selected => {
                      handleSelectValueChange({ selected, name: "status" })
                    }}
                    value={selectedFields.status || ""}
                    isClearable
                    menuPosition="fixed"
                  />
                </Col>

                <Col className="form-button d-flex gap-3">
                  <Button onClick={reset} color="danger" type="button">
                    Reset
                  </Button>
                  <LoadingButton
                    color="warning"
                    onClick={handleExport}
                    loading={loading.export}
                  >
                    Export
                  </LoadingButton>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId="internshipWithdrawalTable"
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default InternshipWithdrawal
