import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { get } from "../../../helpers/api_helper"

import {
  dateConverter,
  getDate,
  renderTableValue,
  timeConverter,
} from "../../../helpers/functions"

const MessageLog = () => {
  const todayDate = getDate()
  const formRef = useRef()

  const statusOptions = [
    { label: "Success", value: 0 },
    { label: "Failed", value: 2 },
  ]
  const TYPE_VALUES = { 1: "Email", 2: "Whatsapp", 3: "Firebase", 4: "SMS" }
  const FOR_VALUES = { CANDIDATE: 1, INSTITUTE: 2, COMPANY: 3, STAFF: 4 }
  const STATUS_VALUES = {
    0: { text: "Success", color: "success" },
    2: { text: "Failed", color: "danger" },
  }

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`

      const response = await get(`message-log${query}`)
      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        const FOR_MAPPING = {
          [FOR_VALUES.CANDIDATE]: { for: "Candidate", value: "candidate" },
          [FOR_VALUES.INSTITUTE]: { for: "Institute", value: "college" },
          [FOR_VALUES.COMPANY]: { for: "Company", value: "company" },
          [FOR_VALUES.STAFF]: { for: "CRM Users", value: "staff" },
        }

        const mapping = FOR_MAPPING[item.for]

        item.type = renderTableValue(TYPE_VALUES[item.type])

        if (mapping) {
          item.for = mapping.for
          item.name = renderTableValue(item?.[mapping.value]?.name)
          item.uniqueId = renderTableValue(item?.[mapping.value]?.uniqueId)
        } else {
          item.for = renderTableValue("")
          item.name = renderTableValue("")
          item.uniqueId = renderTableValue("")
        }

        const status = STATUS_VALUES[item.status]

        item.status = (
          <Button size="sm" disabled color={status.color}>
            {status.text}
          </Button>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectValueChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    formRef.current?.reset()
    setMasterObject({})
    setSelectedFields({})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Type", field: "type" },
      { label: "To", field: "to" },
      { label: "For", field: "for" },
      { label: "Name", field: "name" },
      { label: "Id", field: "uniqueId" },
      { label: "Subject", field: "head" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Message Log" />

          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="from"
                      value={masterObject.from || ""}
                      onChange={e => handleValueChange(e.target)}
                      max={todayDate}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="to"
                      value={masterObject.to || ""}
                      onChange={e => handleValueChange(e.target)}
                      min={masterObject.from}
                      max={todayDate}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Status</Label>
                    <Select
                      options={statusOptions}
                      value={selectedFields.status || ""}
                      onChange={selected =>
                        handleSelectValueChange({ selected, name: "status" })
                      }
                    />
                  </Col>
                  <Col md={3} className="form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="messageLog"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MessageLog
