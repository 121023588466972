import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "../Common/withRouter"
import { Link, useLocation } from "react-router-dom"

// import { Badge } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { getMenu } from "../../store/menu/actions"
const SidebarContent = props => {
  // const [count, setCount] = useState(0)

  const dispatch = useDispatch()
  const data = useSelector(state => state.Menu.data)
  // const ref = useRef();
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      // scrollElement(item);
      return false
    }
    // scrollElement(item);
    return false
  }, [])

  // useEffect(() => {
  //   get("leads/count").then(res => {
  //     setCount(res.count)
  //   })
  // }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
    // eslint-disable-next-line
  }, [path.pathname, activateParentDropdown, data])

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [data])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))._id
    const module = sessionStorage.getItem("module_id")
    if (data === null) {
      dispatch(getMenu({ user, module }))
    }
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {data &&
              data.map((data, idx) => {
                return (
                  <li key={data._id}>
                    <Link
                      to={data.main_link}
                      className={`${
                        data.submenu.length > 0
                          ? "has-arrow waves-effect"
                          : "waves-effect"
                      }`}
                    >
                      <i className={`${data.main_icon}`}></i>
                      <span>{props.t(data.main_menuname)}</span>
                    </Link>
                    {data.submenu.length > 0 && (
                      <>
                        <ul className="sub-menu">
                          {data.submenu.map((sub, index) => {
                            return (
                              <li key={sub._id}>
                                <Link to={sub.sub_link}>
                                  <i className={`${sub.sub_icon}`}></i>
                                  <span style={{ display: "inline" }}>
                                    {props.t(sub.sub_name)}
                                  </span>
                                  {/* {sub._id === "65af8f7e1d81df7293ffe749" && (
                                    <Badge
                                      style={{ marginLeft: "5px" }}
                                      className="rounded-pill bg-info"
                                    >
                                      {count}
                                    </Badge>
                                  )} */}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
