import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import Swal from "sweetalert2"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"

import {
    dateConverter,
    renderTableValue,
    setTitle,
    toTop,
} from "../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import SearchField from "../../components/Common/SearchField"
import "./Jobfairstyle.scss"
import { get, post, put } from "../../helpers/api_helper"


const ManageCompany = () => {
    const formRef = useRef()
    const [jobFairOptions, setjobFairOptions] = useState([])
    const [selectValues, setselectValues] = useState({})
    const [companyoptions, setcompanyoptions] = useState([])
    const [masterObj, setmasterObj] = useState({})
    const [tableData, settableData] = useState([])

    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)


    const data = {
        columns: [
            { label: "#", field: "id" },
            { label: "Date", field: "date" },
            { label: "Job Fair", field: "name" },
            { label: "ID", field: "uniqueId" },
            { label: "Company", field: "companyname" },
            { label: "Source", field: "source" },
            { label: "Status", field: "options" },

        ],
        rows: tableData,
    }

    useEffect(() => {
        getAllJobFair()
        getAllRegistration()
    }, [])

    function getAllJobFair() {
        get(`job-fair/manage-company/options`).then((res) => {
            console.log(res, "res");
            setjobFairOptions(res.data)
        }).catch((err) => {

        })
    }

    function handleselectchange(name, select) {
        setselectValues({ ...selectValues, [name]: select })
        if (name == "jobFair") getAllcompanies(select.value)
        if (name == "companies") {
            setmasterObj({ ...masterObj, [name]: select?.map(item => item.value) })
        } else {
            setmasterObj({ ...masterObj, [name]: select.value })
        }
    }


    function getAllcompanies(id) {
        get(`job-fair/manage-company/options/companies?jobFair=${id}`).then((res) => {
            console.log("copmpanies", res);
            setcompanyoptions(res.data)
        }).catch((err) => {

        })
    }

    function handleSubmit() {
        post(`job-fair/manage-company/register`, masterObj).then((res) => {
            console.log(res);
            toastr.success(res.message)
            setselectValues({})
            setmasterObj({})
            getAllRegistration()
        }).catch((err) => {
            console.log(err.response);
            toastr.error(err.response.data.message)
        })
    }

    function getAllRegistration() {
        get(`job-fair/manage-company`).then((res) => {
            console.log("list", res);
            const { data, count } = res
            data.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = dateConverter(item.date)
                item.name = item?.jobFair?.name
                item.source = item?.from == "1" ? "CRM" : "WEB"
                item.companyname = item?.company?.name
                item.options = (
                    <>
                        {item?.status == "0" ? (
                            <Select onChange={(selected) => { handlestatusChnage("status", selected.value, item._id) }} options={[{ label: "Approve", value: "1" }, { label: "Reject", value: "2" }]} />
                        ) : item?.status == "1" ? (
                            <Button size="sm" color="success" disabled>
                                Approved
                            </Button>
                        ) : item?.status == "2" ? (
                            <Button size="sm" color="danger" disabled>
                                Rejected
                            </Button>
                        ) : null}
                    </>
                )
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            settableData(data)
        }).catch((err) => {

        })
    }

    const handlePageChange = value => {
        setPage(value)
    }

    function handlestatusChnage(name, value, id) {
        console.log(name, value, id);
        put(`job-fair/manage-company/status`, { id: id, status: value }).then((res) => {
            console.log(res, "");
            toastr.success(res.message)
            getAllRegistration()

        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Registrations" />
                    <Card>
                        <CardBody>
                            <AvForm
                                ref={formRef}
                                onValidSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col md={3}>
                                        <Label>Select Job Fair</Label>
                                        <Select
                                            options={jobFairOptions}
                                            onChange={(selected) => {
                                                handleselectchange("jobFair", selected)
                                            }}
                                            value={selectValues?.jobFair || null}

                                        />
                                    </Col>

                                    <Col md={3}>
                                        <Label>Select Company</Label>
                                        <Select
                                            isMulti
                                            options={companyoptions}
                                            value={selectValues?.companies || null}
                                            onChange={(selected) => {
                                                handleselectchange("companies", selected)
                                            }}
                                        />
                                    </Col>

                                    <Col md={3} style={{ marginTop: "26px" }}>
                                        <Button type="submit" color="primary" >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>

                        </CardBody>
                    </Card>

                    <Row className="mt-4">
                        <Card>
                            <CardBody>
                                <TablePagination
                                    tableId="managecmpanyTable"
                                    page={page}
                                    onChange={handlePageChange}
                                    data={data}
                                    count={totalPage}
                                    totalCount={totalCount}
                                />
                            </CardBody>
                        </Card>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default ManageCompany
