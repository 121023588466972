import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import TablePagination from "../../../components/Common/TablePagination"
import SearchField from "../../../components/Common/SearchField"

import queryString from "query-string"
import toastr from "toastr"

import {
  dateConverter,
  genderValue,
  getDate,
  jsonToExcel,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"

import "./styles.scss"
import moment from "moment"

const CandidateReport = () => {
  const filterRef = useRef()
  const todayDate = getDate()

  const tagOptions = [
    { label: "ASAP", value: 1 },
    { label: "KTU", value: 2 },
    { label: "Groomed", value: 3 },
  ]

  const profileCompletionOptions = [
    { value: 30, label: "0 - 30%" },
    { value: 40, label: "31 - 40%" },
    { value: 50, label: "41 - 50%" },
    { value: 60, label: "51 - 60%" },
    { value: 70, label: "61 - 70%" },
    { value: 80, label: "71 - 80%" },
    { value: 90, label: "81 - 90%" },
    { value: 100, label: "91 - 100%" },
  ]

  const tempStatusValues = {
    1: "Pursuing",
    2: "Completed",
  }

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState({ export: false })

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  const [collegeOptions, setCollegeOptions] = useState([])

  useEffect(() => {
    handleCollegeOptions()
  }, [filterObject]) // eslint-disable-line

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [filterObject]) // eslint-disable-line

  const handleCollegeOptions = async () => {
    try {
      const response = await get(`report/options/candidate/college`)
      setCollegeOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`report/candidate${query}`)

    const { count, data } = response

    data.map((item, index) => {
      item.id = (currentPage - 1) * limit + index + 1
      item.date = dateConverter(item.date)
      item.time = timeConverter(item.time)

      item.qualification = renderTableValue(
        item.tempEducation?.qualification?.name,
      )
      item.university = renderTableValue(
        item.university?.name || item?.tempEducation?.universityOrBoard?.name,
      )
      item.college = renderTableValue(
        item.college?.name || item?.tempEducation?.institutionName?.name,
      )

      item.status = renderTableValue(
        tempStatusValues[item?.tempEducation?.status],
      )

      item.uniqueId = renderTableValue(item.uniqueId)
      item.name = renderTableValue(item.name)
      item.mobile = renderTableValue(item.mobile)
      item.email = renderTableValue(item.email)

      item.ktu = <StatusIcon isChecked={item.isKtu} />
      item.asap = <StatusIcon isChecked={item.isAsap} />
      item.groomed = <StatusIcon isChecked={item.isGroomed} />
      item.payment = <StatusIcon isChecked={item.registrationFeePaid === 0} />

      item.aadhar = renderTableValue(item.aadhar)
      item.cgpa = renderTableValue(item.cgpa)
      item.registerNo = renderTableValue(item.regNo)

      item.course = renderTableValue(
        item.course?.name || item?.tempEducation?.course?.name,
      )
      item.specialization = renderTableValue(item.specialization?.name)

      item.mobileVerified = <StatusIcon isChecked={item.verification?.mobile} />
      item.emailVerified = <StatusIcon isChecked={item.verification?.email} />
      item.aadharVerified = <StatusIcon isChecked={item.verification?.aadhar} />

      item.state = renderTableValue(item.state?.name)
      item.district = renderTableValue(item.district?.name)
      item.address = renderTableValue(item.address)
      item.pin = renderTableValue(item.pin)

      if (item.dob) {
        item.age = renderTableValue(moment().diff(moment(item.dob), "years"))
        item.dob = renderTableValue(dateConverter(item.dob))
      } else {
        item.age = renderTableValue("")
        item.dob = renderTableValue("")
      }

      item.gender = renderTableValue(genderValue(item.gender))
      item.completion = `${item.profileCompletion?.toFixed(1) || 0}%`

      item.skills = renderTableValue(
        item.skills?.map(skill => skill?.name).join(", "),
      )

      item.github = renderTableValue(item?.portfolio?.github)
      item.behance = renderTableValue(item?.portfolio?.behance)
      item.personalWebsite = renderTableValue(item?.portfolio?.personal_website)
      item.careerObjectives = renderTableValue(item.careerObjectives)

      item.dayPreference = <StatusIcon isChecked={item.preference?.day} />
      item.nightPreference = <StatusIcon isChecked={item.preference?.night} />
      item.flexible = <StatusIcon isChecked={item.preference?.flexible} />

      item.jobRoles = renderTableValue(
        item.preference?.jobRole?.map(role => role?.name).join(", "),
      )
      item.cities = renderTableValue(
        item.preference?.cities?.map(role => role?.name).join(", "),
      )

      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
    setTotalCount(count)
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
      const response = await get(`report/candidate${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no Candidates to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}

        exportData["#"] = index + 1
        exportData["Date"] = dateConverter(item.date)
        exportData["Time"] = timeConverter(item.time)
        exportData["Candidate Id"] = item.uniqueId
        exportData["Name"] = item.name || ""
        exportData["Mobile"] = item.mobile || ""
        exportData["Email"] = item.email || ""
        exportData["Education"] = item.tempEducation?.qualification?.name || ""
        exportData["University"] =
          item.university?.name ||
          item?.tempEducation?.universityOrBoard?.name ||
          ""
        exportData["Institute"] =
          item.college?.name || item?.tempEducation?.institutionName?.name || ""
        // Temporary
        exportData["Education Status"] =
          tempStatusValues[item?.tempEducation?.status] || ""

        exportData["Course"] =
          item.course?.name || item?.tempEducation?.course?.name || ""
        exportData["Specialization"] = item.specialization?.name || ""

        exportData["KTU"] = item.isKtu ? "Yes" : "No"
        exportData["Asap"] = item.isAsap ? "Yes" : "No"
        exportData["Groomed"] = item.isGroomed ? "Yes" : "No"
        exportData["Payment"] = item.registrationFeePaid === 0 ? "Yes" : "No"

        exportData["Aadhar"] = item.aadhar || ""
        exportData["CGPA"] = item.cgpa || ""
        exportData["Register No"] = item.regNo || ""

        exportData["Mobile Verified"] = item.verification?.mobile ? "Yes" : "No"
        exportData["Email Verified"] = item.verification?.email ? "Yes" : "No"
        exportData["Aadhar Verified"] = item.verification?.aadhar ? "Yes" : "No"

        exportData["State"] = item.state?.name || ""
        exportData["District"] = item.district?.name || ""
        exportData["Address"] = item.address || ""
        exportData["Pin Code"] = item.pin || ""

        exportData["DOB"] = item.dob ? dateConverter(item.dob) : ""
        exportData["Age"] = item.dob
          ? moment().diff(moment(item.dob), "years")
          : ""

        exportData["Gender"] = genderValue(item.gender) || ""
        exportData["Profile %"] = item.profileCompletion?.toFixed(1) || 0
        exportData["Skills"] =
          item.skills?.map(skill => skill?.name).join(", ") || ""

        exportData["Github"] = item?.portfolio?.github || ""
        exportData["Behance"] = item?.portfolio?.behance || ""
        exportData["Personal Website"] = item?.portfolio?.personal_website || ""
        exportData["Career Objectives"] = item.careerObjectives || ""

        exportData["Day Preference"] = item.preference?.day ? "Yes" : "No"
        exportData["Night Preference"] = item.preference?.night ? "Yes" : "No"
        exportData["Flexible"] = item.preference?.flexible ? "Yes" : "No"

        exportData["Job Roles"] =
          item.preference?.jobRole?.map(role => role?.name).join(", ") || ""
        exportData["Preferred Cities"] =
          item.preference?.cities?.map(city => city?.name).join(", ") || ""

        exportData["Staff"] = item.addedBy?.name || ""

        exports.push(exportData)
        return item
      })

      jsonToExcel(exports, `Candidates`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    setSelectFilter(prev => ({ ...prev, [name]: selected }))
    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleFilterValueChange({ value: value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    filterRef.current.reset()
    toTop()

    setFilterObject({})
    setSelectFilter({})

    setTotalCount(0)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Education", field: "qualification" },
      { label: "University", field: "university" },
      { label: "Institute", field: "college" },
      { label: "Education Status", field: "status" },
      { label: "Candidate Id", field: "uniqueId" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "KTU", field: "ktu" },
      { label: "ASAP", field: "asap" },
      { label: "Groomed", field: "groomed" },
      { label: "Payment", field: "payment" },
      { label: "Aadhar", field: "aadhar" },
      { label: "CGPA", field: "cgpa" },
      { label: "Register No", field: "registerNo" },
      { label: "Course", field: "course" },
      { label: "Specialization", field: "specialization" },
      { label: "Mobile Verified", field: "mobileVerified" },
      { label: "Email Verified", field: "emailVerified" },
      { label: "Aadhar Verified", field: "aadharVerified" },
      { label: "State", field: "state" },
      { label: "District", field: "district" },
      { label: "Address", field: "address" },
      { label: "Pin Code", field: "pin" },
      { label: "Date of Birth", field: "dob" },
      { label: "Age", field: "age" },
      { label: "Gender", field: "gender" },
      { label: "Profile %", field: "completion" },
      { label: "Skills", field: "skills" },
      { label: "Github", field: "github" },
      { label: "Behance", field: "behance" },
      { label: "Personal Website", field: "personalWebsite" },
      { label: "Career Objectives", field: "careerObjectives" },
      { label: "Day (Preference)", field: "dayPreference" },
      { label: "Night (Preference)", field: "nightPreference" },
      { label: "Flexible (Preference)", field: "flexible" },
      { label: "Job Roles (Preference)", field: "jobRoles" },
      { label: "Cites (Preference)", field: "cities" },
      // { label: "From", field: "from" },
      // { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={filterObject.from || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={filterObject.to || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    min={filterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Institute</Label>
                  <Select
                    menuPosition="fixed"
                    options={collegeOptions}
                    value={selectFilter.college || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "college" })
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Profile Completion</Label>
                  <Select
                    menuPosition="fixed"
                    options={profileCompletionOptions}
                    value={selectFilter.completion || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "completion" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Tags</Label>
                  <Select
                    isMulti
                    isClearable
                    menuPosition="fixed"
                    options={tagOptions}
                    value={selectFilter.tags || ""}
                    onChange={selected =>
                      handleFilterSelectChange({ selected, name: "tags" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={filterObject.search || ""}
                    onChange={e => handleFilterValueChange(e.target)}
                    placeholder="Search name, mobile, email"
                  />
                </Col>
                <Col className="form-button d-flex gap-2">
                  <Button color="danger" onClick={reset}>
                    Reset
                  </Button>
                  <Button color="warning" onClick={handleExport}>
                    {loading.export ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              key={JSON.stringify(data)}
              onChange={handlePageChange}
              data={data}
              tableId="candidateReportTable"
              count={totalPage}
              totalCount={totalCount}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

const StatusIcon = ({ isChecked }) => (
  <i
    className={`fas ${isChecked ? "fa-check-circle" : "fa-times-circle"} ${isChecked ? "check-icon" : "un-check-icon"}`}
  />
)

export default CandidateReport
