import React, { useRef, useState } from "react"
import { Button, Col, Label, Modal, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import toastr from "toastr"
import { put } from "../../../helpers/api_helper"
import { getDate } from "../../../helpers/functions"

const Retake = ({ id, isOpen, onToggle }) => {
  const formRef = useRef()
  const todayDate = getDate()

  const [masterObject, setMasterObject] = useState({ id })

  const handleRetake = async () => {
    try {
      if (
        !masterObject.startDate ||
        !masterObject.endDate ||
        !masterObject.startTime ||
        !masterObject.endTime
      ) {
        formRef.current?.submit()
        return
      }

      console.log(masterObject, id)
      const response = await put("screening/result/retake", {
        ...masterObject,
        id,
      })
      toastr.success(response.message)
      toggle()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data?.message || error)
    }
  }

  const handleValueChange = async ({ target: { value, name } }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const toggle = () => {
    setMasterObject({ id })
    onToggle()
    formRef.current?.reset()
  }

  return (
    <Modal isOpen={isOpen} centered size="lg">
      <div className="modal-header">
        <h5 className="modal-title">Retake</h5>
      </div>
      <div className="modal-body">
        <AvForm ref={formRef}>
          <Row>
            <Col md={3}>
              <Label>Start Date</Label>
              <AvField
                name="startDate"
                type="date"
                value={masterObject.startDate || ""}
                errorMessage="Enter Start date"
                validate={{ required: { value: true } }}
                min={todayDate}
                onChange={handleValueChange}
              />
            </Col>
            <Col md={3}>
              <Label>Start Time</Label>
              <AvField
                name="startTime"
                type="time"
                validate={{ required: { value: true } }}
                value={masterObject.startTime || ""}
                errorMessage="Enter Start time"
                onChange={handleValueChange}
              />
            </Col>
            <Col md={3}>
              <Label>End Date</Label>
              <AvField
                name="endDate"
                type="date"
                value={masterObject.endDate || ""}
                errorMessage="Enter Start date"
                validate={{ required: { value: true } }}
                min={masterObject.startDate}
                onChange={handleValueChange}
              />
            </Col>
            <Col md={3}>
              <Label>End Time</Label>
              <AvField
                name="endTime"
                type="time"
                value={masterObject.endTime || ""}
                validate={{ required: { value: true } }}
                errorMessage="Enter End time"
                onChange={handleValueChange}
              />
            </Col>

            <Col md={4}>
              <Label>Remarks</Label>
              <AvField
                name="remarks"
                type="textarea"
                rows={1}
                onChange={handleValueChange}
                value={masterObject.remarks}
                errorMessage="Enter Remarks"
                placeholder="Enter Remarks"
              />
            </Col>
          </Row>
        </AvForm>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleRetake}>
          Retake
        </Button>
        <Button color="light" type="button" onClick={toggle}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default Retake
