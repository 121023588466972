import React from "react"
import PropTypes from "prop-types"
import Pagination from "@mui/material/Pagination"
import { MDBDataTable } from "mdbreact"
import "./styles.scss"
import { numberToString } from "../../../helpers/functions"

const TablePagination = ({
  key,
  tableId,
  data = [],
  page = 1,
  totalCount,
  count,
  onChange,

  ...props
}) => {
  return (
    <React.Fragment>
      {totalCount > 0 ? (
        <div className="table-count">
          <span>Total Count: {numberToString(totalCount)}</span>
        </div>
      ) : (
        ""
      )}
      <MDBDataTable
        id={tableId}
        data={data}
        bordered
        key={key}
        responsive
        searching={false}
        entries={20}
        paging={false}
        info={false}
        sortable={false}
        disableRetreatAfterSorting={true}
        {...props}
      />
      <Pagination
        shape="rounded"
        key={page}
        id="paginationComponent"
        page={page}
        onChange={(e, value) => onChange(value)}
        count={count}
      />
    </React.Fragment>
  )
}

TablePagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  data: PropTypes.array,
  tableId: PropTypes.string,
  onChange: PropTypes.func,
}

export default TablePagination
