import React, { useCallback, useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import moment from "moment"
import toastr from "toastr"
import {
  dateConverter,
  renderTableValue,
  setTitle,
  jsonToExcel,
  getDate,
} from "../../helpers/functions"
import {  get } from "../../helpers/api_helper"

// import "./styles.scss"
import SearchField from "../../components/Common/SearchField"

const InternshipCompany

= () => {
  setTitle("Institute")

  const todayDate = getDate()

  const formRef = useRef()
  const filterRef = useRef()


  const [tableData, setTableData] = useState([])
  const [filterCompanyOptions, setfilterCompanyOptions] = useState([])
  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(1)


  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  



  const handleFilterUniversityOptions = async () => {
    try {
      const response = await get(`options/company`)
      // console.log("company",response);
      
      setfilterCompanyOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleTableData = useCallback(async (currentPage = page) => {
    try {
        const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
        const response = await get(`report/company-report${query}`)
        console.log("Response", response);

        const { data, count } = response

        const formattedData = data.map((item, index) => {
            item.id = (currentPage - 1) * limit + index + 1
            item.date = dateConverter(item.date) 

            item.companyName = renderTableValue(item.companyData?.name)
            item.companyId = renderTableValue(item.companyData?.uniqueId) 
            item.type = renderTableValue(item.industryData?.name)
            item.collegeName = renderTableValue(item.collegeData?.name) 
             item.candidateName = renderTableValue(`${item.candidateData?.firstName} ${item.candidateData?.lastName}`)
            item.candidateUniqueId = renderTableValue(item.candidateData?.uniqueId)
            item.mobile = renderTableValue(item.candidateData?.mobile)
            item.email = renderTableValue(item.candidateData?.email)
            item.regNo = renderTableValue(item.candidateData?.regNo)
            item.isGroomed = renderTableValue(item.candidateData?.isGroomed ? "Yes" : "No")
            item.state = renderTableValue(item.stateData?.name)
            item.district = renderTableValue(item.districtData?.name)
            item.specialization = renderTableValue(item.specializationData?.name)
            item.course = renderTableValue(item.courseData?.name)
            item.internshipRole = renderTableValue(item.internshipData?.jobRoles)
            item.internshipLocation = renderTableValue(`${item.internshipStateData?.name}, ${item.internshipDistrictData?.name}`) // State + District
            item.startDate = renderTableValue(item.internshipData?.startDate)
            item.endDate = renderTableValue(item.internshipData?.endDate)

            item.offerLetterStatus = renderTableValue(
              item.offerLetter?.status !== 0 ? "Yes" : "No"
            );
            item.stipend = renderTableValue(item.stipend) 
            return item
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(formattedData) 
        setTotalCount(count)

    } catch (error) {
        console.error(error)
    }
},[filterObject, limit, page])


useEffect(() => {
  handleTableData(1)
  setPage(1)
  handleFilterUniversityOptions()
}, [filterObject,handleTableData]) 

const data = {
  columns: [
    { label: "#", field: "id" },
    { label: "Date", field: "date" },
    { label: "Company Name", field: "companyName" }, 
    { label: "Company ID", field: "companyId" },
    { label: "Type", field: "type" },
    { label: "Candidate", field: "candidateName" },
    { label: "College", field: "collegeName" },
    { label: "Mobile", field: "mobile" },
    { label: "Email", field: "email" },
    { label: "State", field: "state" },
    { label: "District", field: "district" },
    { label: "Course", field: "course" }, 
    { label: "Specialization", field: "specialization" },
    { label: "Candidate Reg No", field: "regNo" },
    { label: "Grooming Status", field: "isGroomed" },
    { label: "Internship Location", field: "internshipLocation" },
    { label: "Internship Role", field: "internshipRole" },
    { label: "Offer Letter Status", field: "offerLetterStatus" },
    // { label: "Stipend", field: "stipend" },
    { label: "Start Date", field: "startDate" },
    { label: "End Date", field: "endDate" },
  ],
  rows: tableData,
}


  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

const reset = () => {
  if (formRef.current) {
    formRef.current.reset();
  }
  if (filterRef.current) {
    filterRef.current.reset();
  }

  setTotalPage(1);
  toTop();
  setSelectFilter({});
  setFilterObject({});
  handleFilterUniversityOptions();
  setTotalCount(0)
};






const handleExport = async () => {
  try {
    setLoading(prev => ({ ...prev, export: true }))
    
    const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
    const response = await get(`report/company-report${query}`)    
    const { data } = response

    const exports = []
    
    if (!data.length) {
      toastr.info(`There are no Data to export`)
      return
    }

    data.map((item, index) => {
      const exportData = {}

      exportData["#"] = index + 1
      exportData["Date"] = dateConverter(item.date)
      exportData["Company"] = renderTableValue(item.companyData?.name)
      exportData["Company ID"] = renderTableValue(item.companyData?.uniqueId)
      exportData["Type"] = renderTableValue(item.industryData?.name)
      exportData["Candidate"] =renderTableValue(`${item.candidateData?.firstName} ${item.candidateData?.lastName}`)
      exportData["College"] =renderTableValue(item.collegeData?.name) 
      exportData["Mobile"] =renderTableValue(item.candidateData?.mobile)
      exportData["Email"] = renderTableValue(item.candidateData?.email)
      exportData["State"] = renderTableValue(item.stateData?.name)
      exportData["District"] = renderTableValue(item.districtData?.name)
      exportData["Course"] = renderTableValue(item.courseData?.name)
      exportData["Specialization"] = renderTableValue(item.specializationData?.name)
      exportData["Reg No"] = renderTableValue(item.candidateData?.regNo)
      exportData["Grooming Status"] = renderTableValue(item.candidateData?.isGroomed ? "Yes" : "No")
      exportData["Internship Location"] = renderTableValue(`${item.internshipStateData?.name}, ${item.internshipDistrictData?.name}`)

      exportData["Role"] = renderTableValue(item.internshipData?.jobRoles)
      exportData["OfferletterStatus"] = renderTableValue(
        item.offerLetter?.status !== 0 ? "Yes" : "No"
      );
      exportData["Stipend"] = renderTableValue(item.stipend)

      exportData["Start Date"] =  renderTableValue(item.internshipData?.startDate)
      exportData["End Date"] =  renderTableValue(item.internshipData?.endDate)

      exports.push(exportData)

      return item
    })
    jsonToExcel(exports, `Company Internship-${moment().format("DD-MM-YY")}`)
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(prev => ({ ...prev, export: false }))
  }
}
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Candidate Mapped Reports" />
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      type="date"
                      name="fromDate"
                      value={filterObject.fromDate || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      max={todayDate}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      type="date"
                      name="toDate"
                      value={filterObject.toDate || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      min={filterObject.fromDate}
                      max={todayDate}
                    ></AvField>
                  </Col>

                  <Col md={2}>
                    <Label>Company</Label>
                    <Select
                      menuPosition="fixed"
                      options={filterCompanyOptions}
                      value={selectFilter.company || ""}
                      onChange={selected =>
                        handleFilterSelectChange({
                          selected,
                          name: "company",
                        })
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Label>Search</Label>
                    <SearchField
                      name="search"
                      value={filterObject.search || ""}
                      onChange={e => handleFilterValueChange(e.target)}
                      placeholder="Search by Company name, short code"
                    />
                  </Col>
                  <Col>
                    <Button
                      color="danger"
                      onClick={reset}
                      style={{ marginTop: "26px" }}
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col md={2} style={{ marginTop: "26px" }}>
                  <Button color="warning" onClick={handleExport}>
                    {loading.export ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                  </Col>
                </Row>
              </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="collegeTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InternshipCompany

