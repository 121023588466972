import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"

import toastr from "toastr"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import BulkUploadDetails from "./details"

import {
  dateConverter,
  numberToString,
  renderTableValue,
  setTitle,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import { get, post } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

import "./styles.scss"

const CompanyBulkUpload = () => {
  setTitle("Company Bulk Upload")

  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({})
  const [tableData, setTableData] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [modal, setModal] = useState({ show: false, id: null })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleTableData()
  }, []) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    const response = await get(
      `bulk-upload/company?page=${currentPage}&limit=${limit}`,
    )
    const { count, data } = response

    data.map((item, index) => {
      item.id = (currentPage - 1) * limit + index + 1
      item.date = dateConverter(item.date)
      item.time = timeConverter(item.time)

      item.staff = renderTableValue(item.addedBy?.name)

      item.total = numberToString(item.count?.total)
      item.success = numberToString(item.count?.success)
      item.failed = numberToString(item.count?.failed)

      if (item.status === 0)
        item.options = (
          <div>
            <Tooltip title="File" arrow placement="top">
              <a href={`${API_URL}` + item?.fileName} download>
                <i className="fa fa-file-excel excel-icon"></i>
              </a>
            </Tooltip>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye eye-icon"
                onClick={() => handleToggleModal({ show: true, id: item._id })}
              ></i>
            </Tooltip>
          </div>
        )
      else
        item.options = (
          <Button size="sm" color="warning" disabled>
            Pending
          </Button>
        )
      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    for (const key in masterObject) {
      formData.append(key, masterObject[key])
    }

    if (!loading) {
      setLoading(true)
      try {
        const response = await post(`bulk-upload/company`, formData)
        reset()
        toastr.success(response.message)
        setTimeout(handleTableData, 2000)
      } catch (error) {
        toastr.error(error.response.data.message)
      } finally {
        setLoading(false)
      }
    } else {
      toastr.warning("Your request is in progress")
    }
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleToggleModal = ({ show = false, id = null }) => {
    setModal({ show, id })
    if (!show) handleTableData()
  }

  const reset = () => {
    formRef.current.reset()
    handleTableData(1)
    setMasterObject({})
    setPage(1)
    setLoading(false)

    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Total", field: "total" },
      { label: "Success", field: "success" },
      { label: "Failed", field: "failed" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Company Bulk upload" />
        <Card>
          <CardBody>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
              <Row>
                <Col md={3}>
                  <Label>Upload File</Label>
                  <Tooltip title="(.xls, .xlsx)" arrow placement="bottom">
                    <div>
                      <AvField
                        name="file"
                        type="file"
                        onChange={e =>
                          handleValueChange({
                            value: e.target.files[0],
                            name: "file",
                          })
                        }
                        accept=".xls, .xlsx"
                        style={{ lineHeight: "1.8" }}
                        validate={{ required: { value: true } }}
                        errorMessage="Please select a file to import"
                      />
                    </div>
                  </Tooltip>
                </Col>
                <Col md={3}>
                  <Label>Remarks</Label>
                  <AvField
                    rows={1}
                    name="remarks"
                    type="textarea"
                    placeholder="Remarks"
                    value={masterObject.remarks || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <div className="d-flex" style={{ marginTop: "26px" }}>
                    <Button color="primary" className="me-2" disabled={loading}>
                      Submit
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Tooltip title="Download sample file">
                    <a
                      href={`${API_URL}sample/bulk_upload_company_sample.xlsx`}
                      download
                      style={{ fontSize: "1rem", width: "fit-content" }}
                      className="d-flex align-items-center btn btn-success "
                    >
                      <i
                        style={{ fontSize: "13px" }}
                        className="fa fa-solid fa-download "
                      />
                      <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                        Download Sample Sheet
                      </span>
                    </a>
                  </Tooltip>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <TablePagination
              page={page}
              data={data}
              count={totalPage}
              tableId="companyBulkTable"
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>

      <BulkUploadDetails
        isOpen={modal.show}
        onToggle={() => handleToggleModal({})}
        id={modal.id}
      />
    </div>
  )
}

export default CompanyBulkUpload
