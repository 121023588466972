import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap";
import CountUp from "react-countup";
import { get } from "../../../helpers/api_helper";
import moment from "moment";

const InternshipAnalyticsChart = () => {
  const [series, setSeries] = useState([
    { name: "Vacancies", type: "bar", data: [] },
    { name: "Applications", type: "bar", data: [] },
    { name: "Placed", type: "bar", data: [] },
  ]);
  const [totalCounts, setTotalCounts] = useState({ vacancies: 0, applied: 0, placed: 0 });
  const [selectedYear, setSelectedYear] = useState(moment().year()); 
  const [years, setYears] = useState([]);

  useEffect(() => {
    fetchAvailableYears();
    fetchCandidateStats(selectedYear); 
  }, [selectedYear]);

  const fetchAvailableYears = () => {
    const currentYear = moment().year();
    const yearsList = [];

    for (let i = currentYear - 1; i <= currentYear + 1; i++) {
      yearsList.push(i);
    }

    setYears(yearsList);
  };

  const fetchCandidateStats = (year) => {
    get(`dashboard/monthly-internship-status?selectedYear=${year}`)
      .then((res) => {
        const data = res?.data || [];

        const vacanciesData = data.map((item) => item.vacancies);
        const appliedData = data.map((item) => item.applied);
        const placedData = data.map((item) => item.placed);

        const totalVacancies = vacanciesData.reduce((acc, curr) => acc + curr, 0);
        const totalApplied = appliedData.reduce((acc, curr) => acc + curr, 0);
        const totalPlaced = placedData.reduce((acc, curr) => acc + curr, 0);

        setSeries([
          { name: "Vacancies", type: "bar", data: vacanciesData },
          { name: "Applications", type: "bar", data: appliedData },
          { name: "Placed", type: "bar", data: placedData },
        ]);

        setTotalCounts({ vacancies: totalVacancies, applied: totalApplied, placed: totalPlaced });
      })
      .catch((error) => {
        console.error("Error fetching candidate data:", error);
      });
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const options = {
    chart: {
      stacked: false,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        endingShape: "flat",
        distributed: false,
      },
    },
    colors: ["#008FFB", "#FF4560", "#00E396"],
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
    },
    yaxis: {
      title: { text: "Count" },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#333"]
      },
      formatter: (val) => (val !== undefined ? val : 0),
      offsetY: -5
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: { formatter: (val) => (val !== undefined ? val : 0) },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
    },
    grid: { borderColor: "#f1f1f1" },
  };

  return (
    <React.Fragment>
      <Card className="shadow-sm border-0">
        <CardBody>
          <div className="float-end">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-reset"
                id="dropdownMenuButton5"
                caret
              >
                <span className="fw-semibold">Sort By:</span>{" "}
                <span className="text-muted">
                  {selectedYear} <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                {years.map((year, index) => (
                  <DropdownItem key={index} onClick={() => handleYearChange(year)}>
                    {year}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle className="mb-4 h4">Internship Analytics</CardTitle>
          <div className="mt-1">
            <ul className="list-inline main-chart mb-0">
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h5 className="text-primary">
                  <span>
                    <CountUp end={totalCounts.vacancies} separator="," prefix="Vacancies: " />
                  </span>
                </h5>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h5>
                  <span>
                    <CountUp end={totalCounts.applied} separator="," prefix="Applications: " />
                  </span>
                </h5>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h5>
                  <span>
                    <CountUp end={totalCounts.placed} separator="," prefix="Placed: " />
                  </span>
                </h5>
              </li>
            </ul>
          </div>

          <div className="mt-3">
            <ReactApexChart
              options={options}
              series={series}
              height="339"
              type="bar"
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default InternshipAnalyticsChart;
