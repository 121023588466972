import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { dateConverter } from "../../helpers/functions"
import Select from "react-select"
import { Tooltip } from "@mui/material"
import { API_URL } from "../../config"
import { del, get, post, put } from "../../helpers/api_helper"
import TablePagination from "../../components/Common/TablePagination"
import Swal from "sweetalert2"
import toastr from "toastr"

const Course = () => {
  const formRef = useRef()
  const [filterobj, setfilterobj] = useState({})

  const [masterObject, setMasterObject] = useState({})

  const [tableData, setTableData] = useState([])
  const [selectedFields, setSelectedFields] = useState({})
  console.log("selectedfields", selectedFields)

  const [DropDownData, setDropDownData] = useState([])

  const [searchQuery, setSearchQuery] = useState("")

  const [filteredData, setFilteredData] = useState([])
  console.log("filter", filteredData)

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    setfilterobj({ ...filterobj, [name]: selected.value })
  }
  console.log(filterobj)

  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearchQuery(value)
    setfilterobj({ ...filterobj, [name]: value })
  }

  const filterData = () => {
    let filtered = tableData

    if (searchQuery) {
      filtered = filtered.filter(
        item =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.shortCode.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    }

    if (selectedFields.education) {
      filtered = filtered.filter(
        item => item.education?._id === selectedFields.education.value,
      )
    }

    setFilteredData(filtered)
  }

  useEffect(() => {
    filterData()
  }, [searchQuery, selectedFields.education]) //eslint-disable-line

  // const [error, setError] = useState(null);
  const setError = useState(null)[1]

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await get(`/education`)
        console.log("result", result)

        setDropDownData(result)
      } catch (err) {
        setError("Failed to fetch data")
      } finally {
      }
    }

    getData()
  }, [setError]) // Empty dependency array to run once on mount

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const limit = 50
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    handleTableData()
  }, [filterobj]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&education=${filterobj?.course}&search=${filterobj.search}`
      const response = await get(`course${query}`)

      console.log("responseeeeee", response)

      const { data, count } = response

      const formattedData = data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)
        item.state = item.state?.name
        item.staff = item.addedBy?.name
        item.education = item.education?.name

        if (item.logo)
          item.file = (
            <a href={API_URL + item.logo} target="_blank" rel="noreferrer">
              <i className="far fa-file-image file-icon"></i>
            </a>
          )

        item.options = (
          <div>
            <Tooltip title="Edit" arrow placement="top">
              <i
                className="fas fa-edit edit-icon"
                onClick={() => handleUpdate(item._id)}
              ></i>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <i
                className="fas fa-trash-alt delete-icon"
                onClick={() => handleDelete(item._id)}
              ></i>
            </Tooltip>
          </div>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(formattedData) // Update this line
    } catch (error) {
      console.error(error)
    }
  }

  // Update the data object for table pagination
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Education", field: "education" },
      { label: "Course Name", field: "name" },
      { label: "ShortCode", field: "shortCode" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const handleSubmit = async () => {
    try {
      let response
      if (masterObject.id) response = await put(`course`, masterObject)
      else response = await post(`course`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const reset = () => {
    if (formRef.current) {
      formRef.current.reset()
    }
    setMasterObject({})
    setSelectedFields({})
    setSearchQuery("") // Reset search query
    setfilterobj({}) // Reset filter object
    handleTableData(1)
    setPage(1)
    setTotalPage(1)
    toTop()
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`course/update/${id}`)
      const data = response.data
      setMasterObject({ ...data, id, state: data?.state?.value })
      setSelectedFields({ state: data?.state })
      toTop()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async id => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })

      if (!result.isConfirmed) return

      const response = await del(`course/${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      // Check if error.response is defined
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message)
      } else {
        // toastr.error("An unexpected error occurred.");
      }
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Course" />

        <Card>
          <CardBody>
            <AvForm
              ref={formRef}
              onValidSubmit={handleSubmit}
            >
              <Row>
                <Col md="3">
                  <Label>Select Education</Label>
                  <Select
                    menuPosition="fixed"
                    options={DropDownData.data?.map(education => ({
                      label: education.name,
                      value: education._id,
                    }))}
                    value={
                      masterObject.education
                        ? DropDownData.data?.find(
                            education =>
                              education._id === masterObject.education,
                          )
                          ? {
                              label: DropDownData.data.find(
                                education =>
                                  education._id === masterObject.education,
                              ).name,
                              value: masterObject.education,
                            }
                          : null
                        : null
                    }
                    onChange={selected =>
                      handleValueChange({
                        name: "education",
                        value: selected.value,
                      })
                    }
                    placeholder="-- Select Education --"
                  />
                </Col>

                <Col md="3">
                  <Label>Course Name</Label>
                  <AvField
                    name="name"
                    placeholder="Enter Course Name"
                    errorMessage="Please enter the course name"
                    validate={{ required: { value: true } }}
                    value={masterObject.name || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>

                <Col md="3">
  <Label>Short Code</Label>
  <AvField
    name="shortCode"
    placeholder="Short Code"
    type="text"
    errorMessage="Enter Short Code (only letters and numbers allowed)"
    validate={{
      required: { value: true, errorMessage: "Short Code is required" },
      pattern: { value: '^[a-zA-Z0-9]+$', errorMessage: "Only letters and numbers are allowed" },
    }}
    value={masterObject.shortCode || ""}
    onChange={e => handleValueChange(e.target)}
  />
</Col>

              </Row>

              <Row style={{ marginTop: "26px" }}>
                <Col md="6">
                  <div className="d-flex gap-3">
                    <Button color="primary">Submit</Button>
                    <Button color="danger" type="reset" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm>
              <Row>
                <Col md={3}>
                  <Label>Search</Label>
                  <AvField
                    name="search"
                    placeholder="Search name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>
                <Col md={3}>
                  <Label>Filter by Education</Label>
                  <Select
                    options={DropDownData.data?.map(course => ({
                      label: course.name,
                      value: course._id,
                    }))}
                    value={selectedFields.course || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "course")
                    }
                  />
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Button type="button" color="danger" onClick={reset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <TablePagination
              page={page}
              onChange={handlePageChange}
              data={data}
              tableId=""
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Course
