import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import queryString from "query-string"

import toastr from "toastr"
import moment from "moment"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchField from "../../../components/Common/SearchField"

import {
  dateConverter,
  getDate,
  jsonToExcel,
  renderTableValue,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import { STATUS } from "./helper"

import "./styles.scss"

const CompanyReports = props => {
  const todayDate = getDate()

  const formRef = useRef()

  const [tableData, setTableData] = useState([])

  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])
  const [industryOptions, setIndustryOptions] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    handleTableData(1)
    setPage(1)
  }, [masterObject]) // eslint-disable-line

  useEffect(() => {
    handleStateOptions()
    handleDistrictOptions()
    handleIndustryOptions()
  }, []) // eslint-disable-line

  const handleStateOptions = async () => {
    try {
      const response = await get(`report/options/company/state`)
      setStateOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDistrictOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`report/options/company/district?${params}`)
      setDistrictOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleIndustryOptions = async (state = "") => {
    try {
      const params = queryString.stringify({ state })
      const response = await get(`report/options/company/industry?${params}`)
      setIndustryOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(masterObject)}`
      const response = await get(`report/company${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)

        item.shortCode = renderTableValue(item.shortCode)
        item.contactPerson = renderTableValue(item.contactPerson)
        item.mobile = renderTableValue(item.mobile)
        item.email = renderTableValue(item.email)

        item.district = renderTableValue(item.district?.name)
        item.state = renderTableValue(item.state?.name)
        item.industry = renderTableValue(item.industry?.name)

        item.address = renderTableValue(item.address)
        item.status = renderTableValue(STATUS[item.status])

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTotalCount(count)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleExport = async () => {
    try {
      setLoading(prev => ({ ...prev, export: true }))
      const query = `?limit=${totalCount}&${queryString.stringify(masterObject)}`
      const response = await get(`report/company${query}`)

      const { data } = response

      const exports = []
      if (!data.length) {
        toastr.info(`There are no reports to export`)
        return
      }

      data.map((item, index) => {
        const exportData = {}

        exportData["#"] = index + 1 || ""
        exportData["Date"] = dateConverter(item.date) || ""
        exportData["Company Id"] = item.uniqueId || ""
        exportData["Industry"] = item.industry?.name || ""
        exportData["Name"] = item.name || ""
        exportData["Short Code"] = item.shortCode || ""
        exportData["Contact Person"] = item.contactPerson || ""
        exportData["Mobile"] = item.mobile || ""
        exportData["Email"] = item.email || ""
        exportData["State"] = item.state?.name || ""
        exportData["District"] = item.district?.name || ""
        exportData["Status"] = STATUS[item.status] || ""

        exports.push(exportData)

        return item
      })

      jsonToExcel(exports, `Companies-${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(prev => ({ ...prev, export: false }))
    }
  }

  const handleSelectChange = async ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (name === "state") {
      obj.district = null
    }
    setSelectedFields(obj)
    handleValueChange({ value: selected.value, name })
  }

  const handleValueChange = async ({ value, name }) => {
    const obj = { ...masterObject }

    obj[name] = value
    if (name === "state") {
      obj.district = null
      handleDistrictOptions(value)
    }

    setMasterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

  const reset = () => {
    formRef.current.reset()

    setTotalPage(1)
    toTop()

    setSelectedFields({})
    setMasterObject({})

    handleDistrictOptions()
    handleStateOptions()
    handleIndustryOptions()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Company ID", field: "uniqueId" },
      { label: "Industry", field: "industry" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Contact Person", field: "contactPerson" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "State", field: "state" },
      { label: "District", field: "district" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Company Reports" />

        <Card>
          <CardBody>
            <AvForm ref={formRef}>
              <Row>
                <Col md={2}>
                  <Label>From</Label>
                  <AvField
                    type="date"
                    name="from"
                    value={masterObject.from || ""}
                    onChange={e => handleValueChange(e.target)}
                    max={todayDate}
                  />
                </Col>
                <Col md={2}>
                  <Label>To</Label>
                  <AvField
                    type="date"
                    name="to"
                    value={masterObject.to || ""}
                    onChange={e => handleValueChange(e.target)}
                    min={masterObject.from}
                    max={todayDate}
                  />
                </Col>

                <Col md={3}>
                  <Label>Industry</Label>
                  <Select
                    menuPosition="fixed"
                    options={industryOptions}
                    value={selectedFields.industry || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "industry" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>State</Label>
                  <Select
                    menuPosition="fixed"
                    options={stateOptions}
                    value={selectedFields.state || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "state" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>District</Label>
                  <Select
                    menuPosition="fixed"
                    options={districtOptions}
                    value={selectedFields.district || ""}
                    onChange={selected =>
                      handleSelectChange({ selected, name: "district" })
                    }
                  />
                </Col>
                <Col md={3}>
                  <Label>Search</Label>
                  <SearchField
                    name="search"
                    value={masterObject.search || ""}
                    onChange={e => handleValueChange(e.target)}
                    placeholder="Search name, short code, mobile"
                  />
                </Col>
                <Col col={3}>
                  <div className="d-flex gap-3 form-button">
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                    <Button color="warning" onClick={handleExport}>
                      {loading.export ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        "Export"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
            <TablePagination
              tableId="companyReportTable"
              page={page}
              onChange={handlePageChange}
              data={data}
              count={totalPage}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CompanyReports
