import React from "react"
import propsTypes from "prop-types"

const ListItem = ({ label, value, hidden = false, children }) => {
  if (hidden) return null
  return (
    <li hidden={!value}>
      <label>{label}:</label> {value}
      {children ? React.cloneElement(children) : null}
    </li>
  )
}

ListItem.propTypes = {
  label: propsTypes.string.isRequired,
  value: propsTypes.any,
}

export default ListItem
