import React, { useEffect, useRef, useState } from "react"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TablePagination from "../../components/Common/TablePagination"
import moment from "moment"
import toastr from "toastr"
import {
  dateConverter,
  renderTableValue,
  setTitle,
  jsonToExcel,
  getDate,
} from "../../helpers/functions"
import {  get } from "../../helpers/api_helper"

// import "./styles.scss"
import SearchField from "../../components/Common/SearchField"

const CollegeReports
= () => {
  setTitle("Institute")

  const todayDate = getDate()

 const typeValues = {
    1: "Private",
    2: "Public",
    3: "Government",
    4: "Deemed",
  }

  const formRef = useRef()
  const filterRef = useRef()


  const [tableData, setTableData] = useState([])
  const [filterUniversityOptions, setFilterUniversityOptions] = useState([])
  const [loading, setLoading] = useState({ export: false })
  const [totalCount, setTotalCount] = useState(1)


  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})

  useEffect(() => {
    handleTableData(1)
    setPage(1)
    handleFilterUniversityOptions()
  }, [filterObject]) 



  const handleFilterUniversityOptions = async () => {
    try {
      const response = await get(`college/options/filter/university`)
      setFilterUniversityOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`

      const response = await get(`/report${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = (currentPage - 1) * limit + index + 1
        item.date = dateConverter(item.date)
      
        item.type = renderTableValue(typeValues[item.type])
        item.university = renderTableValue(item.university?.name)
        item.staff = renderTableValue(item.addedBy?.name)
      
        item.mobile = renderTableValue(item.mobile)
        item.shortCode = renderTableValue(item.shortCode)
        item.district = renderTableValue(item.district?.name)


        item.coordinatorName = renderTableValue(`${item.primaryUser?.firstName} ${item.primaryUser?.lastName}`)
        item.coordinatorId = renderTableValue(item.uniqueId)
        item.coordinatorNumber = renderTableValue(item.primaryUser?.mobile)
        item.coordinatorEmail = renderTableValue(item.primaryUser?.email)
        item.designationnName = renderTableValue(item.designation?.name)

      
        return item
      })
      
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
      setTotalCount(count)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }

    obj[name] = value

    setFilterObject(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }


  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

const reset = () => {
  if (formRef.current) {
    formRef.current.reset();
  }
  if (filterRef.current) {
    filterRef.current.reset();
  }

  setTotalPage(1);
  toTop();
  setSelectFilter({});
  setFilterObject({});
  handleFilterUniversityOptions();
  setTotalCount(0)
};


  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Institute Id", field: "uniqueId" },
      { label: "University", field: "university" },
      { label: "Name", field: "name" },
      { label: "Short Code", field: "shortCode" },
      { label: "Mobile", field: "mobile" },
      { label: "District", field: "district" },
      { label: "Type", field: "type" },
      { label: "Coordinator Name", field: "coordinatorName" },
      { label: "Coordinator ID", field: "coordinatorId" },
      { label: "Designation", field: "designationnName" },
      { label: "Coordinator Number", field: "coordinatorNumber" },
      { label: "Coordinator Email", field: "coordinatorEmail" },

    ],
    rows: tableData,
  }


const handleExport = async () => {
  try {
    setLoading(prev => ({ ...prev, export: true }))
    
    const query = `?limit=${totalCount}&${queryString.stringify(filterObject)}`
    const response = await get(`/report${query}`)
    console.log("get", response);
    
    const { data } = response

    const exports = []
    
    if (!data.length) {
      toastr.info(`There are no Institutes to export`)
      return
    }

    data.map((item, index) => {
      const exportData = {}

      exportData["#"] = index + 1
      exportData["Date"] = dateConverter(item.date)
      exportData["Id"] = item.uniqueId
      exportData["University"] = renderTableValue(item.university?.name)
      exportData["Name"] = item.name
      exportData["District"] = renderTableValue(item.district?.name)
      exportData["Address"] = renderTableValue(item.address)
      exportData["Short Code"] = renderTableValue(item.shortCode)
      exportData["Mobile"] = item.mobile
      exportData["Email"] = item.email
      exportData["Staff"] = item.addedBy?.name
      exportData["Type"] = renderTableValue(typeValues[item.type])
      exportData["Courses"] = item.courses?.map(course => course.name).join(', ') 
      exportData["Specializations"] = item.specializations?.map(specialization => specialization.name).join(', ') 
      exportData["Coordinator Name"] = renderTableValue(`${item.primaryUser?.firstName} ${item.primaryUser?.lastName}`)

      exportData["Coordinator Id"] = renderTableValue(item.uniqueId)
      exportData["Designation"] = renderTableValue(item.designation?.name)
      exportData["Coordinator Email"] = renderTableValue(item.primaryUser?.email)

      exportData["Coordinator Number"] = renderTableValue(item.primaryUser?.mobile)
      exports.push(exportData)

      return item
    })

    // Export as Excel
    jsonToExcel(exports, `Institutes-${moment().format("DD-MM-YY")}`)
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(prev => ({ ...prev, export: false }))
  }
}

 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Institute Reports" />
          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
              <Row>
  <Col md={2}>
    <Label>From</Label>
    <AvField
      type="date"
      name="from"
      value={filterObject.from || ""}
      onChange={e => handleFilterValueChange(e.target)}
      max={todayDate}
    />
  </Col>
  <Col md={2}>
    <Label>To</Label>
    <AvField
      type="date"
      name="to"
      value={filterObject.to || ""}
      onChange={e => handleFilterValueChange(e.target)}
      min={filterObject.from}
      max={todayDate}
    />
  </Col>

  <Col md={2}>
    <Label>University</Label>
    <Select
      menuPosition="fixed"
      options={filterUniversityOptions}
      value={selectFilter.university || ""}
      onChange={selected =>
        handleFilterSelectChange({
          selected,
          name: "university",
        })
      }
    />
  </Col>

  <Col md={3}>
    <Label>Search</Label>
    <SearchField
      name="search"
      value={filterObject.search || ""}
      onChange={e => handleFilterValueChange(e.target)}
      placeholder="Search name, short code, mobile"
    />
  </Col>

  <Col md={1}>
    <Button
      color="danger"
      onClick={reset}
      style={{ marginTop: "26px" }}
    >
      Reset
    </Button>
  </Col>
  <Col md={2}>
    <Button color="warning" onClick={handleExport} style={{ marginTop: "26px" }}>
      {loading.export ? (
        <i className="fas fa-spinner fa-spin" />
      ) : (
        "Export"
      )}
    </Button>
  </Col>
</Row>

              </AvForm>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="collegeTable"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollegeReports
