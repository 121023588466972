import { EditorView } from "codemirror"
import { en } from "suneditor/src/lang"
import plugins from "suneditor/src/plugins"
import katex from "katex"

export const sunEditorOptions = {
  plugins: plugins,
  height: 250,
  EditorView: {
    src: EditorView,
    options: { indentWithTabs: true, tabSize: 2 },
  },
  katex: katex,
  lang: en,
  buttonList: [
    [
      "font",
      "fontSize",
      "formatBlock",
      "bold",
      "underline",
      "italic",
      "paragraphStyle",
      "blockquote",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "undo",
      "redo",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",
      "fullScreen",
      "showBlocks",
      "codeView",
      "preview",
    ],
  ],
}

export const typeOptions = [
  { label: "KTU", value: 1 },
  { label: "Specialization", value: 2 },
  { label: "Internship", value: 3 },
]

export const statusOptions = [
  { label: "Active", value: 0 },
  { label: "In Active", value: 2 },
]

export const statusValues = {
  0: { text: "Active", color: "success" },
  2: { text: "In Active", color: "warning" },
}

export const typeValues = {
  1: "KTU",
  2: "Specialization",
  3: "Internship",
  KTU: 1,
  SPECIALIZATION: 2,
  INTERNSHIP: 3,
}
