import React, { useEffect, useState } from "react"
import { Row,Col, BreadcrumbItem } from "reactstrap";

import { Modal } from "react-bootstrap"
import Select from "react-select"
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { get } from "../../../helpers/api_helper"
import moment from "moment";



const CollegeUserDashboaard = (props) => {
  const {id} = useParams()
  console.log("id",id);
  

  const [instituteDetails,setinstituteDetails] = useState(null)
  const [studentDetail, setStudentDetail] = useState(false)



  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return 'Active';
      case 1:
        return 'Deleted';
      case 2:
        return 'Inactive';
      default:
        return 'Unknown';
    }
  };



useEffect(() => {
    handleTableData()
  }, [id])


function handleTableData() {
    get(`college/user/view?id=${id}`)
      .then(res => {
        const result = res?.data
        
        setStudentDetail(result)

      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">User - {studentDetail?.firstName} {studentDetail?.lastName}</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/college">Institute User Details</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{studentDetail?.firstName} {studentDetail?.lastName}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
  <Col xl="12">
    <div className="card" style={{ boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)" }}>
      <div className="card-body">
        <div className="d-flex flex-column align-items-center mb-4">
          <h5 style={{ textAlign: "center", fontFamily: "IBM Plex Sans,sans-serif" }}>
            {instituteDetails?.instituteName}
          </h5>
         
        </div>

       <div className="row">
  {/* Left Column */}
  <div className="col-md-6">
    <div className="mb-4">
      <p className="mb-1">UserName:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.firstName} {studentDetail?.lastName}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">Employee ID:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.uniqueId}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">Mobile:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.mobile}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">Email:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.email}</h5>
    </div>
  </div>

  {/* Right Column */}
  <div className="col-md-6">
    <div className="mb-4">
      <p className="mb-1">University:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.university}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">College:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.college}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">Address:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.address}</h5>
    </div>
    <div className="mb-4">
      <p className="mb-1">Status:</p>
      <h5 className="font-size-14 mb-0">{getStatusText(studentDetail?.status)}</h5>
    </div>
  </div>
</div>

<div className="row">
  <div className="col-md-6">
    <div className="mb-4">
      <p className="mb-1">State:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.state}</h5>
    </div>
  </div>
  <div className="col-md-6">
    <div className="mb-4">
      <p className="mb-1">Designation:</p>
      <h5 className="font-size-14 mb-0">{studentDetail?.designation}</h5>
    </div>
  </div>
</div>
          </div>
    </div>
  </Col>
</Row>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CollegeUserDashboaard