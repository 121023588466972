import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  BreadcrumbItem,
} from "reactstrap";
import Stack from "@mui/material/Stack";
import { Link, useParams } from "react-router-dom";
// import MiniWidget from "./mini-widget";
import "./style.scss";
import Summary from "./Tabs/Summary";
import Applied from "./Tabs/Applied";
// import Saved from "./Tabs/Saved";
import Placed from "./Tabs/Placed";
import Shortlisted from "./Tabs/Shoetlisted";
import moment from "moment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BallotIcon from "@mui/icons-material/Ballot";
import AssignmentIcon from "@mui/icons-material/Assignment";
// import BookmarkIcon from "@mui/icons-material/Bookmark";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Box, Divider } from "@mui/material";
import { get } from "../../../helpers/api_helper";
import { API_URL } from "../../../config"


const ViewJob = () => {
  const [tab3, setTab3] = useState("5");
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const { id } = useParams();


  const statusMap = {
    0: { label: "Ongoing", color: "green" },
    2: { label: "Inactive", color: "red" },
    3: { label: "Expired", color: "orange" },
    4: { label: "Filled", color: "blue" },
  };

  
  const [jobDetail, setJobDetail] = useState(null);

  const handleTableData = useCallback(() => {
    get(`job/view?id=${id}`)
      .then(res => {
        const result = res?.data;
        setJobDetail(result);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  useEffect(() => {
    handleTableData();
  }, [handleTableData]);

  const status = jobDetail && statusMap[jobDetail.status] 
  ? statusMap[jobDetail.status] 
  : { label: "Unknown", color: "black" };


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Job Post - {jobDetail?.name}</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/create-job-post">Job Post</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{jobDetail?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div className="card" style={{ height: "max-content", boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)" }}>
                      <div className="card-body">
                        <h5 className="mt-2 mb-2" style={{ textAlign: "center", fontFamily: "IBM Plex Sans,sans-serif" }}>
                          {jobDetail?.name}
                        </h5>
                        <Stack direction="row" style={{ justifyContent: "center" }}>
                          <img alt="logo" src={`${API_URL}/${jobDetail?.image}`} style={{ width: "5rem", height: "4rem", borderRadius: "5px" }} />
                        </Stack>
                         <p style={{ textAlign: "center", marginBottom: "3px", color: status.color }}>
                             {status.label}
                                  </p>
                        <Divider />
                        <div className="mt-2">
                          <p className="mb-1">Date</p>
                          <h5 className="font-size-14">{moment(jobDetail?.date).format("DD-MM-YYYY")}</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Company</p>
                          <h5 className="font-size-14">{jobDetail?.company}</h5>
                        </div>
                       <br/>
                       {
                        jobDetail?.branch?
                        <div className="mt-2">
                          <p className="mb-1">Branch</p>
                          <h5 className="font-size-14">{jobDetail?.branch}</h5>
                        </div>
                        :""
                        }

                        {
                        jobDetail?.category?
                        <div className="mt-3">
                          <p className="mb-1">Category</p>
                          <h5 className="font-size-14">{jobDetail?.category}</h5>
                        </div>
                        :""
                        }
                        {
                        jobDetail?.skills?
                        <div className="mt-3">
                          <p className="mb-1">Skills</p>
                          <h5 className="font-size-14">{jobDetail?.skills}</h5>
                        </div>
                        :""
                        }
                        <hr/>
                        {
  jobDetail?.workType ?
  <div className="mt-2">
    <p className="mb-1">Work Type</p>
    <h5 className="font-size-14">
      {
        jobDetail.workType === 1 ? "Part Time" :
        jobDetail.workType === 2 ? "Full Time" :
        jobDetail.workType === 3 ? "Contract" :
        "" 
      }
    </h5>
  </div>
  : ""
}
                        <hr />
                        {
  jobDetail?.workMode ?
  <div className="mt-2">
    <p className="mb-1">Work Mode</p>
    <h5 className="font-size-14">
      {
        jobDetail.workMode === 1 ? "Remote" :
        jobDetail.workMode === 2 ? "Hybrid" :
        jobDetail.workMode === 3 ? "In Person": ""
        
      }
    </h5>
  </div>
  : ""
}
            
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Course</p>
                          <h5 className="font-size-14">{jobDetail?.courses}</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Specialization</p>
                          <h5 className="font-size-14">{jobDetail?.specialization}</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Experience</p>
                          <h5 className="font-size-14">{jobDetail?.minExperience}-{jobDetail?.maxExperience} Years</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Gender</p>
                          <h5 className="font-size-14">{jobDetail?.gender === 1 ? "Male" : jobDetail?.gender === 2 ? "Female" : "No Preference"}</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">No. of Openings</p>
                          <h5 className="font-size-14">{jobDetail?.vacancies}</h5>
                        </div>
                        <hr />
                        {/* <div className="mt-2">
                          <p className="mb-1">Expiry Date</p>
                          <h5 className="font-size-14">{moment(jobDetail?.expiryDate).format("DD-MM-YYYY")}</h5>
                        </div> */}
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">State</p>
                          <h5 className="font-size-14">{jobDetail?.state}</h5>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">District</p>
                          <h5 className="font-size-14">{jobDetail?.district}</h5>
                        </div>
                        <hr />
                        {
  jobDetail?.schedule ?
  <div className="mt-2">
    <p className="mb-1">Schedule</p>
    <h5 className="font-size-14">
      {
        jobDetail.schedule === 1 ? "Remote" :
        jobDetail.schedule === 2 ? "Hybrid" :
        jobDetail.schedule === 3 ? "In-person" :
        ""
      }
    </h5>
  </div>
  : ""
}
                        <hr />
                        <div className="mt-2">
                          <p className="mb-1">Salary</p>
                          <h5 className="font-size-14">&#x20B9; {jobDetail?.salaryFrom}-{jobDetail?.salaryTo} LPA</h5>
                        </div>
                        <hr />
                        {
                        jobDetail?.remarks?
                        <div className="mt-2">
                          <p className="mb-1">Remarks</p>
                          <h5 className="font-size-14">{jobDetail?.remarks}</h5>
                        </div>
                        :""
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div className="mb-0 card" style={{ boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)", width: "100%" }}>
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab icon={<SummarizeIcon style={{ fontSize: "20px" }} />} label="Summary" value="5" />
                              <Tab icon={<AssignmentIcon style={{ fontSize: "20px" }} />} label="Applied" value="1" />
                              <Tab icon={<FactCheckIcon style={{ fontSize: "20px" }} />} label="Shortlisted" value="3" />
                              <Tab icon={<BallotIcon style={{ fontSize: "20px" }} />} label="Placed" value="4" />
                            </TabList>
                          </Box>
                          <TabPanel value="1" style={{ fontSize: "13px" }}>
                            <Applied />
                          </TabPanel>
                          <TabPanel value="3" style={{ fontSize: "13px" }}>
                            <Shortlisted />
                          </TabPanel>
                          <TabPanel value="4" style={{ fontSize: "13px" }}>
                             <Placed />
                          </TabPanel>
                          <TabPanel value="5" style={{ fontSize: "13px" }}>
                            <Summary />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewJob;
