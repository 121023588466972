import { Tooltip } from "@mui/material"
import React from "react"
import { Card, CardBody, Row, Col, Badge, Input } from "reactstrap"

const CandidateCard = ({
  id,
  name,
  specialization,
  regNo,
  cgpa,
  onCheckboxChange,
  checked,
  index,
}) => {
  const handleCheckboxChange = e => {
    e.stopPropagation()
    onCheckboxChange({ id, index, checked: !checked })
  }

  const handleCardClick = () => {
    onCheckboxChange({ id, index, checked: !checked })
  }
  return (
    <Card
      className={`candidate-card ${checked ? "bg-light-blue" : ""}`}
      onClick={handleCardClick}
    >
      <CardBody>
        <Row>
          <Col xs="auto" className="checkbox-col">
            <Input
              type="checkbox"
              name={id}
              checked={checked}
              onChange={handleCheckboxChange}
              onClick={e => e.stopPropagation()}
              style={{ margin: 0 }}
            />
          </Col>

          <Col className="candidate-info">
            <h6>
              <Tooltip title={name} arrow placement="top">
                {name || ""}
              </Tooltip>
            </h6>
            <p>
              {specialization && (
                <Tooltip title={specialization} arrow placement="bottom">
                  <span>
                    <i className="mdi mdi-school me-1" />
                    {specialization}
                  </span>
                </Tooltip>
              )}
            </p>
          </Col>

          {regNo && (
            <Col xs="auto" className="reg-badge">
              <Badge color="info" className="font-size-12">
                <Tooltip
                  title={`Register No: '${regNo}'`}
                  arrow
                  placement="top"
                >
                  {regNo}
                </Tooltip>
              </Badge>
            </Col>
          )}

          <Col xs="auto" className="cgpa-col">
            <span className="cgpa">
              <Tooltip title={`CGPA: '${cgpa}'`} arrow placement="top">
                {cgpa}
              </Tooltip>
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CandidateCard
