import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

import queryString from "query-string"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/TablePagination"

import { getUsersOptions } from "../../../store/options/actions"
import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  getDate,
  getFirstDay,
  setTitle,
  timeConverter,
} from "../../../helpers/functions"

import "./styles.scss"

const PagesStarter = () => {
  setTitle(`User Activity Log`)

  const dispatch = useDispatch()
  const userOptions = useSelector(state => state.Options.users)

  const [master, setMaster] = useState({ from: getFirstDay(), to: getDate() })

  const [selectedUser, setSelectedUser] = useState(null)
  const [tableData, setTableData] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    dispatch(getUsersOptions({}))
  }, [dispatch])

  const handleChange = e => {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    handleTableData()
  }, [master, page]) // eslint-disable-line

  const handleTableData = async (perPage = page) => {
    try {
      const query = `page=${perPage}&limit=${limit}&${queryString.stringify(master)}`
      const response = await get(`security/activity?${query}`)

      const { data, count } = response

      data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.user = item.userId?.name
        return item
      })
      setTableData(data)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    setMaster({ from: getFirstDay(), to: getDate() })
    setSelectedUser(null)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Created IP", field: "ip" },
      { label: "Activity", field: "description" },
      { label: "User", field: "user" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="User Activity Log" />

          <Card>
            <CardBody>
              <AvForm className="needs-validation">
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="from"
                        name="from"
                        value={master.from}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>

                      <input
                        className="form-control"
                        type="date"
                        id="to"
                        name="to"
                        value={master.to}
                        min={master.from}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>User</Label>
                      <Select
                        value={selectedUser}
                        onChange={selected => {
                          setSelectedUser(selected)
                          setMaster(pre => ({
                            ...pre,
                            staff: selected.value,
                          }))
                        }}
                        options={userOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="1" style={{ paddingTop: "4px" }}>
                    <div className="mt-4 mb-3">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                onChange={handlePageChange}
                data={data}
                tableId="userActivityLog"
                count={totalPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesStarter
