export const statusOptions = [
  { label: "Shortlist", value: 2 },
  { label: "Reject", value: 4 },
]

export const filterStatusOptions = [
  { label: "Applied", value: 0 },
  { label: "Shortlist", value: 2 },
  { label: "Placed", value: 3 },
  { label: "Rejected", value: 4 },
  { label: "Withdrawn", value: 6 },
  { label: "Withdrawn Pending", value: 7 },
  { label: "Completed", value: 9 },
]

export const typeOptions = [
  { label: "KTU", value: 1 },
  { label: "General", value: 2 },
  { label: "Public Sector", value: 3 },
]

export const STATUS = {
  2: { text: "Shortlisted", color: "warning" },
  3: { text: "Placed", color: "success" },
  4: { text: "Rejected", color: "danger" },
  5: { text: "Rejected", color: "danger" },
  6: { text: "Withdrawn", color: "danger" },
  7: { text: "Withdrawn Pending", color: "warning" },
  9: { text: "Completed", color: "info" },
  10: { text: "Pending", color: "primary" },
  APPLIED: 1,
  PENDING: 10,
  REJECTED_APPLICATION: 4,
  REJECTED_SHORT_LIST: 5,
}

export const INTERNSHIP_TYPE = { KTU: 1, GENERAL: 2, PSU: 3 }
export const FORWARD = { FORWARDED: 0, NOT: 1 }
