import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { MDBDataTable } from "mdbreact"
import TablePagination from '../../components/Common/TablePagination'
import { get } from '../../helpers/api_helper'
import moment from 'moment'
import { status } from '../Job/Post/helper'

const JobpostApplications = ({ jobObjId }) => {
    const [registration, setRegistration] = useState([])
    const limit = 100
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    useEffect(() => {
        fetchAllJobApplications()

    }, [])


    function fetchAllJobApplications() {
        get(`job-fair/job/applications?id=${jobObjId}&limit=${limit}&page=${page}`).then((response) => {
            let result = response.data
            const count = response.count
            result?.map((item, index) => {
                item.id = (page - 1) * limit + index + 1;
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "---"
                item.name = item?.candidate?.name;
                item.mobile = item?.candidate?.mobile;
                item.status = item?.status === 1 ? "APPLIED" : item?.status === 2 ? "SHORTLISTED" : item?.status === 3 ? "PLACED" : item?.status === 4 ? "REJECTED" : "---"
                return item
            })
            const totalPage = Math.ceil(Number(count) / limit)
            setTotalPage(totalPage)
            setTotalCount(count)
            setRegistration(result)

        })
    }
    const handlePageChange = value => {
        setPage(value)
      }

    const data1 = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },

            {
                label: "ID",
                field: "uniqueId",
                width: 150,
            },
            {
                label: "Name",
                field: "name",
                sort: "desc",
                width: 400,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                width: 150,
            },
        ],
        rows: registration,
    }
    return (
        <>
            <Row>
                <Col className="col-12 mt-2">
                    <TablePagination
                        id="registeredpeopleid"
                        page={page}
                        onChange={handlePageChange}
                        data={data1}
                        count={totalPage}
                        totalCount={totalCount}
                    />
                </Col>
            </Row>

        </>
    )
}

export default JobpostApplications
