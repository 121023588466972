import React, { useEffect, useState } from "react"
import { Row, Col, Collapse, BreadcrumbItem } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import AssignmentIcon from "@mui/icons-material/Assignment"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { Modal } from "react-bootstrap"
import Stack from "@mui/material/Stack"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import MiniWidget from "./mini-widget"
import { useParams } from "react-router-dom"
import { get } from "../../../../helpers/api_helper"
import { API_URL } from "../../../../config"
import moment from "moment"
import TablePagination from "../../../../components/Common/TablePagination"
import classnames from "classnames"
import { dateConverter, numberToCurrency, numberToString } from "../../../../helpers/functions"

const ViewInternship = props => {
  const navigate = useNavigate()
  const { internshipId } = useParams()
  const [internshipDetails, setinternshipDetails] = useState(null)
  const [appliedInternships, setAppliedInternships] = useState([])
  const [shortlistedInternships, setShortlistedInternships] = useState([])
  const [placedInternships, setPlacedInternships] = useState([])
  const [rejectedInternships, setRejectedInternships] = useState([])
  const [internshipDescription, setInternshipDescription] = useState([])
  const [summary, setSummary] = useState({
    applied: 0,
    shortlisted: 0,
    placed: 0,
  })
  const [modalData] = useState(null)
  const [popupView4, setPopupView4] = useState(false)
  const [tab3, setTab3] = React.useState("5")
  const [totalPage] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPage2] = useState(0)
  const [page2, setPage2] = useState(1)
  const [totalPage3] = useState(0)
  const [page3, setPage3] = useState(1)
  const [activeTab, setActiveTab] = useState(null)
  // const [totalPage4, setTotalPage4] = useState(0)
  const [setPage4] = useState(1)
  useEffect(() => {
    fetchinternshipDetails()
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchAppliedInternship()
    fetchshortlistedInternship()
    fetchplacedInternship()
    fetchInternshipSummary()
    fetchRejectedInternship()
  }, []) // eslint-disable-line

  useEffect(() => {
    let status = -1
    if (tab3 === 1) {
      status = 0
    } else if (tab3 === 3) {
      status = 2
    } else if (tab3 === 4) {
      status = 3
    }
    // if (status >= 0) {
    //   fetchInternship(status)
    // }
  }, [tab3, page2]) //eslint-disable-line

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleChange2 = (event, value) => {
    setPage2(value)
  }

  const handleChange3 = (event, value) => {
    setPage3(value)
  }

  const handleChange4 = (event, value) => {
    setPage4(value)
  }

  const fetchinternshipDetails = async () => {
    try {
      const response = await get(
        `/internship/details?internshipId=${internshipId}`,
      )
      setinternshipDetails(response?.data)
      if (response?.data?.details) {
        setInternshipDescription(response.data.details)
      }
    } catch (error) {}
  }

  // const fetchInternshipDescription = async () => {
  //   try {
  //     const response = await get(`/internship/details?internshipId=${internshipId}`);
  //     console.log("response?.data?.details",response?.data?.details)
  //     if (response?.data?.details) {
  //       setInternshipDescription(response.data.details);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching internship details:", error);
  //   }
  // };

  const fetchAppliedInternship = async type => {
    try {
      const response = await get(
        `/internship/application-list?type=0&internshipId=${internshipId}`,
      )
      setAppliedInternships(response.internships || [])
      // const count = response.data.count || 0;
      // setTotalPage(Math.ceil(count / 20));
    } catch (error) {
      // console.log("Fetch Internship Load error",error)
      // toastr.error("Failed to load internships");
    }
  }
  const fetchshortlistedInternship = async type => {
    try {
      const response = await get(
        `/internship/application-list?type=2&internshipId=${internshipId}`,
      )
      setShortlistedInternships(response.internships || [])
    } catch (error) {}
  }
  const fetchplacedInternship = async type => {
    try {
      const response = await get(
        `/internship/application-list?type=3&internshipId=${internshipId}`,
      )
      console.log("response of placed", response.internships)
      setPlacedInternships(response.internships || [])
    } catch (error) {}
  }
  const fetchRejectedInternship = async type => {
    try {
      const response = await get(
        `/internship/application-list?type=4&internshipId=${internshipId}`,
      )
      console.log("response of rejected", response.internships)
      setRejectedInternships(response.internships || [])
    } catch (error) {}
  }

  const fetchInternshipSummary = async () => {
    try {
      const response = await get(
        `/internship/summary?internshipId=${internshipId}`,
      )
      setSummary(response.summary)
    } catch (error) {}
  }
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  const applied = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "registerNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Staff",
      //   field: "Staff",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: appliedInternships.map((item, index) => {
      return {
        id: index + 1 + (page - 1) * 20,
        date: moment(item.date).format("DD-MM-YYYY"),
        registerNo: item.registerNo,
        name: item.name,
        mobileNo: item.mobileNo,
        email: item.email,
        // status: item.status,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/internship/applications`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </div>
        ),
      }
    }),
  }

  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "registerNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Staff",
      //   field: "Staff",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: shortlistedInternships.map((item, index) => {
      return {
        id: index + 1 + (page - 1) * 20,
        date: moment(item.date).format("DD-MM-YYYY"),
        registerNo: item.registerNo,
        name: item.name,
        mobileNo: item.mobileNo,
        email: item.email,
        // status: item.status,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`internship/shortlists`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </div>
        ),
      }
    }),
  }

  const placed = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "registerNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Staff",
      //   field: "Staff",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: placedInternships.map((item, index) => {
      return {
        id: index + 1 + (page - 1) * 20,
        date: moment(item.date).format("DD-MM-YYYY"),
        registerNo: item.registerNo,
        name: item.name,
        mobileNo: item.mobileNo,
        email: item.email,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`internship/placed`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </div>
        ),
      }
    }),
  }

  const rejected = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "registerNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 400,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 400,
      },
      {
        label: "Remarks",
        field: "remarks",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Staff",
      //   field: "Staff",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: rejectedInternships.map((item, index) => {
      return {
        id: index + 1 + (page - 1) * 20,
        date: moment(item.date).format("DD-MM-YYYY"),
        registerNo: item.registerNo,
        name: item.name,
        mobileNo: item.mobileNo,
        email: item.email,
        remarks: item.remarks,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/internship/rejected`, {
                  state: { id: item._id },
                })
              }}
            ></i>
          </div>
        ),
      }
    }),
  }

  const description = {
    columns: [
      // {
      //   label: "#",
      //   field: "id",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: "Tab",
        field: "tab",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tab Description",
        field: "tabDescription",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contents",
        field: "contents",
        sort: "asc",
        width: 300,
      },
    ],

    rows:
      internshipDescription?.map((item, index) => ({
        id: index + 1,
        tab: item.tab,
        tabDescription: item.tabDescription,
        contents: <div dangerouslySetInnerHTML={{ __html: item.contents }} />,
      })) || [],
  }

  const series1 = [50]
  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const series2 = [70]
  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const series3 = [50]
  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [50]
  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      title: "Applied",
      value: summary.applied,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      title: "Shortlisted",
      value: summary.shortlisted,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      title: "Placed",
      value: summary.placed,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      title: "Rejected",
      value: summary.rejected,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series4,
      options: options4,
    },
  ]

  const toggleTab = tab => {
    if (activeTab === tab) {
      setActiveTab(null)
    } else {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>
                    {modalData?.jobDetails?.name}
                  </td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  {/* <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{recruiterDetails?.name}</td> */}
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>{modalData?.uniqueId}</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.name}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.mobile}
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.applicantDetails?.email}
                  </td>
                </tr>
                {tab3 === 4 && (
                  <tr>
                    <th style={{ textAlign: "left", padding: "10px" }}>
                      Placed Date
                    </th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px" }}>
                      {moment(modalData?.placedDate).format("DD-MM-YYYY")}
                    </td>
                    <th style={{ textAlign: "left" }}>Placed Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                      {moment(modalData?.placedTime, "HH:mm:ss").format(
                        "hh:mm A",
                      )}{" "}
                    </td>
                  </tr>
                )}

                {tab3 === 4 && (
                  <tr>
                    <th style={{ textAlign: "left", padding: "10px" }}>
                      Shortlisted Date
                    </th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px" }}>
                      {moment(modalData?.shortlistDate).format("DD-MM-YYYY")}
                    </td>
                    <th style={{ textAlign: "left" }}>Shortlisted Time</th>
                    <th> : </th>
                    <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                      {moment(modalData?.shortlistTime, "HH:mm:ss").format(
                        "hh:mm A",
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {modalData?.qualificationDetails?.name}
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Internship - {internshipDetails?.name}</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/company">Internship Details</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{internshipDetails?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      {/* <div className="card-body" style={{ maxHeight: "400px", overflowY: "auto",marginTop:"-12px" }}> */}
                      <div className="float-end dropdown"></div>
                      <h5
                        className="mt-2 mb-2"
                        style={{
                          textAlign: "center",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >
                        {internshipDetails?.companyName}
                      </h5>
                      {/* <Stack
                        direction="row"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          alt="Imag"
                          src={`${API_URL}/${internshipDetails?.image}`}
                          style={{
                            width: "5rem",
                            height: "4rem",
                            borderRadius: "5px",
                          }}
                        />
                      </Stack> */}
                      <div
                        className="text-muted"
                        style={{
                          textAlign: "center",
                          color: "green !important",
                          fontFamily: "IBM Plex Sans,sans-serif",
                          fontSize: "14px",
                          marginBottom: "3px",
                        }}
                      ></div>
                      <Divider />
                      {/* <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Internship ID:</p>
                        <h5 className="font-size-14">{internshipDetails?.shortCode}</h5>
                      </div> */}
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Company Name:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.companyName}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Branch:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.branch}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Type:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.type === 1
                            ? "KTU"
                            : internshipDetails?.type === 2
                              ? "GENERAL"
                              : "PUBLIC SECTOR"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Fee:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.fees}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Name:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.name}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Internship ID:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.uniqueId}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Course:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.course}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Specializations:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.specializations?.length > 0
                            ? internshipDetails.specializations.join(", ")
                            : "-"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Duration:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.duration}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Unit:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.unit === 1
                            ? "Days"
                            : internshipDetails?.unit === 2
                              ? "Weeks"
                              : internshipDetails?.unit === 3
                                ? "Weeks"
                                : "Years"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Category:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.category}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Skills:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.skills?.length > 0
                            ? internshipDetails.skills?.join(", ")
                            : "-"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Job Roles:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.jobRoles}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Vacancies:</p>
                        <h5 className="font-size-14">
                          {numberToString(internshipDetails?.vacancies)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Stipend:</p>
                        <h5 className="font-size-14">
                          {numberToCurrency(internshipDetails?.stipend)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Expected Start Date:</p>
                        <h5 className="font-size-14">
                          {dateConverter(internshipDetails?.startDate)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Expected End Date:</p>
                        <h5 className="font-size-14">
                          {dateConverter(internshipDetails?.endDate)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Expiry Date:</p>
                        <h5 className="font-size-14">
                          {dateConverter(internshipDetails?.expiryDate)}
                        </h5>
                      </div>

                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">State:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.stateDetails?.length > 0
                            ? internshipDetails.stateDetails.join(", ")
                            : "-"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">District:</p>
                        <h5 className="font-size-14">
                          {" "}
                          {internshipDetails?.districtDetails?.length > 0
                            ? internshipDetails.districtDetails.join(", ")
                            : "-"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Total Placed:</p>
                        <h5 className="font-size-14">
                          {numberToString(internshipDetails?.totalPlaced)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Total Applied:</p>
                        <h5 className="font-size-14">
                          {numberToString(internshipDetails?.totalApplied)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">From:</p>
                        <h5 className="font-size-14">
                          {internshipDetails?.from === 1 ? "CRM" : "WEB"}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Available Vacancy:</p>
                        <h5 className="font-size-14">
                          {numberToString(internshipDetails?.availableVacancy)}
                        </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div className="mt-2" style={{ paddingLeft: "8px" }}>
                        <p className="mb-1">Total Rejected:</p>
                        <h5 className="font-size-14">
                          {numberToString(internshipDetails?.totalRejected)}
                        </h5>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value={"5"}
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applied"
                                value={6}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Shortlisted"
                                value={7}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Placed"
                                value={8}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Rejected"
                                value={9}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Description"
                                value={10}
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              /> */}
                            </TabList>
                          </Box>
                          <TabPanel
                            value={"5"}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <MiniWidget reports={reports} />
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  {/* Description Section */}
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingQualification"
                                    >
                                      <button
                                        type="button"
                                        onClick={() => toggleTab(1)}
                                        className={classnames(
                                          "accordion-button",
                                          "fw-medium",
                                          {
                                            collapsed: activeTab !== 1,
                                          },
                                        )}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <h6>Description</h6>
                                      </button>
                                    </h2>
                                    <Collapse
                                      id="collapseQualification"
                                      className="accordion-collapse"
                                      isOpen={activeTab === 1}
                                    >
                                      <div className="accordion-body">
                                        <Row>
                                          <Col md="12">
                                            <table className="table table-bordered dataTable">
                                              <thead>
                                                <tr>
                                                  <th>Tab</th>
                                                  <th>Tab Description</th>
                                                  <th>Contents</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {internshipDescription &&
                                                internshipDescription.length >
                                                  0 ? (
                                                  internshipDescription.map(
                                                    (item, index) => (
                                                      <tr key={index}>
                                                        <td>
                                                          {item.tab || "-----"}
                                                        </td>
                                                        <td>
                                                          {item.tabDescription ||
                                                            "-----"}
                                                        </td>
                                                        <td>
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                item.contents ||
                                                                "-----",
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    ),
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan="4"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      No description details
                                                      available.
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value={6}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="dashboardTableId"
                                  responsive
                                  bordered
                                  searching={true}
                                  entries={18}
                                  disableRetreatAfterSorting={true}
                                  data={applied}
                                  paginationLabel={true}
                                  paging={true}
                                />
                                <TablePagination
                                  page={page}
                                  onChange={handleChange}
                                  count={totalPage}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value={7}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={shortlisted}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={true}
                                />
                                <TablePagination
                                  page={page2}
                                  onChange={handleChange2}
                                  count={totalPage2}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value={8}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="table"
                                  responsive
                                  bordered
                                  data={placed}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={true}
                                />
                                <TablePagination
                                  page={page3}
                                  onChange={handleChange3}
                                  count={totalPage3}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value={9}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="table"
                                  responsive
                                  bordered
                                  data={rejected}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={true}
                                />
                                <TablePagination
                                  page={page3}
                                  onChange={handleChange3}
                                  count={totalPage3}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          {/* <TabPanel
                            value={10}
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="table"
                                  responsive
                                  bordered
                                  data={description}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={true}
                                />
                                <TablePagination
                                  page={page3}
                                  onChange={handleChange4}
                                  count={totalPage3}
                                />
                              </Col>
                            </Row>
                          </TabPanel> */}
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewInternship
