export const typeOptions = [
  { label: "General", value: 1 },
  { label: "Specialization", value: 2 },
]

export const answerOptions = [
  { label: "Option 1", value: 1 },
  { label: "Option 2", value: 2 },
  { label: "Option 3", value: 3 },
  { label: "Option 4", value: 4 },
]

export const typeValues = {
  1: "General",
  2: "Specialization",
}

export const answerValues = {
  1: "Option 1",
  2: "Option 2",
  3: "Option 3",
  4: "Option 4",
}

export const statusValues = {
  0: { text: "Active", color: "success" },
  2: { text: "In Active", color: "warning" },
}
