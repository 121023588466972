import { Tooltip } from "@mui/material"
import React from "react"
import { Card, CardBody, Row, Col, Input } from "reactstrap"

const CandidateCard = ({
  id,
  name,
  uniqueId,
  mobile,
  onCheckboxChange,
  checked,
  index,
}) => {
  const handleCheckboxChange = e => {
    e.stopPropagation()
    onCheckboxChange({ id, index, checked: !checked })
  }

  const handleCardClick = () => {
    onCheckboxChange({ id, index, checked: !checked })
  }
  return (
    <Card
      className={`candidate-card ${checked ? "bg-light-blue" : ""}`}
      onClick={handleCardClick}
    >
      <CardBody>
        <Row>
          <Col xs="auto" className="checkbox-col">
            <Input
              type="checkbox"
              name={id}
              checked={checked}
              onChange={handleCheckboxChange}
              onClick={e => e.stopPropagation()}
              style={{ margin: 0 }}
            />
          </Col>

          <Col className="candidate-info">
            <h6>
              <Tooltip title={name} arrow placement="top">
                {name || ""}
              </Tooltip>
            </h6>

            <span className="cgpa">
              <b>Id :</b>
              {uniqueId}
            </span>
            <br />
            <span className="cgpa">
              <b>Mobile :</b>
              {mobile}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CandidateCard
