import React from "react"
import { Label, Button } from "reactstrap"

import { API_URL } from "../../config"

const FileUpload = ({
  label,
  imageSize,
  name,
  fileUrl,
  onFileChange = () => {},
  onDelete = () => {},
  accept = "image/*, .pdf, .xls, .xlsx",
  ...props
}) => {
  return (
    <>
      <Label>
        {label}{" "}
        {imageSize ? (
          <span className="label-image-size">({imageSize})</span>
        ) : (
          ""
        )}
      </Label>
      {fileUrl ? (
        <div className="img-wraps d-flex flex-column">
          <img alt="" width="150" height="150" src={API_URL + fileUrl} />
          <Button
            color="danger"
            size="sm"
            block
            onClick={onDelete}
            style={{ maxWidth: "150px" }}
          >
            Delete
          </Button>
        </div>
      ) : (
        <input
          name={name}
          type="file"
          accept={accept}
          onChange={onFileChange}
          errorMessage="Select File"
          className="form-control"
          {...props}
        />
      )}
    </>
  )
}

export default FileUpload
