import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Table, TableCell, TableBody, TableHead, TableRow } from "@mui/material"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import toastr from "toastr"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getPrivileges } from "../../../store/options/actions"
import { get, post } from "../../../helpers/api_helper"
import { setTitle } from "../../../helpers/functions"

import "./styles.scss"

const Rules = props => {
  setTitle("Rules")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrivileges())
  }, [dispatch])

  useEffect(() => {
    fetch_option_list()
  }, [])

  const { privileges } = useSelector(state => state.Options)
  const [selectedPrivilege, setSelectedPrivilege] = useState(null)
  const [disabled, setDisabled] = useState(null)

  const [options, setOptions] = useState([])
  const [dataOptions, setDataOptions] = useState([])

  const fetch_option_list = () => {
    get(`rules/options/list`).then(res => {
      let result = res.data
      setOptions(result)
    })
  }

  const handleSelectChange = selected => {
    setSelectedPrivilege(selected)

    const data = {
      privilege: selected.value,
    }

    post(`rules/list`, data).then(res => {
      let result = res.data
      result &&
        result.map((item, index) => {
          item.sub_menu &&
            item.sub_menu.map(opt => {
              opt.option = options
              opt.mainmenu_id = item._id
              return opt
            })
          return item
        })
      setDataOptions(result)
    })
  }

  const handleCheckboxChange = (e, element, item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      submenu_id: element._id,
      status: e.target.checked === true ? 0 : 1,
    }

    post(`rules/submenu`, data)
      .then(res => {
        handleSelectChange(selectedPrivilege)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        return
      })
  }

  const handleMainmenuCheckboxChange = (e, item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      status: e.target.checked === true ? 0 : 1,
    }

    post(`rules/menu`, data)
      .then(res => {
        handleSelectChange(selectedPrivilege)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        return
      })
  }

  const handleAllmenuCheckboxChange = (e, item) => {
    let data = {
      status: e.target.checked === true ? 0 : 1,
      option_name: item._id,
      privilege: selectedPrivilege?.value,
    }
    // setDisabled(item._id);
    e.target.disabled = true
    post(`rules/options`, data)
      .then(res => {
        handleSelectChange(selectedPrivilege)
        setDisabled(null)
        e.target.disabled = false
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        // setDisabled(null)
        e.target.disabled = false
        return
      })
  }

  const handleOptionCheckboxChange = (e, el, element) => {
    let data = {
      status: e.target.checked === true ? 0 : 1,
      option_name: element._id,
      privilege: selectedPrivilege?.value,
      sub_menu: el?._id,
      main_menu: el?.mainmenu_id,
    }

    post(`rules/menu/option`, data)
      .then(res => {
        handleSelectChange(selectedPrivilege)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        return
      })
  }

  const handleOptionCheckboxChanges = (e, element, item) => {
    let data = {
      status: e.target.checked === true ? 0 : 1,
      option_name: element._id,
      privilege: selectedPrivilege?.value,
      //sub_menu: el?._id,
      main_menu: item?._id,
    }

    post(`rules/menu/option`, data)
      .then(res => {
        handleSelectChange(selectedPrivilege)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        return
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Rules" />

          <Row>
            <Col xl="12">
              <Card className="p-2">
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="rules"
                            value={selectedPrivilege}
                            onChange={selected => {
                              handleSelectChange(selected)
                            }}
                            classNamePrefix="select2-selection"
                            options={privileges}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <CardBody style={{ marginTop: "35px", marginBottom: "35px" }}>
                  <Table
                    style={{ textAlign: "center" }}
                    className="table table-bordered dataTable"
                    id="rulesTable"
                  >
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell
                          style={{ width: "180px", textAlign: "center" }}
                        >
                          Module
                        </TableCell>
                        <TableCell
                          style={{ width: "180px", textAlign: "center" }}
                        >
                          Menu
                        </TableCell>
                        <TableCell
                          style={{ width: "210px", textAlign: "center" }}
                        >
                          Sub Menu
                        </TableCell>

                        {options &&
                          options.map((item, idx) => {
                            let status = false
                            dataOptions &&
                              dataOptions.map(mainmenu => {
                                let submenu = mainmenu.sub_menu

                                submenu &&
                                  submenu.map(submenu => {
                                    let submenuOptions =
                                      submenu.sub_menu_options
                                    submenuOptions &&
                                      submenuOptions.map(submenuOptions => {
                                        if (submenuOptions === item?._id)
                                          status = true
                                        return submenuOptions

                                        // else status = false;
                                      })
                                    return submenu
                                  })
                                return mainmenu
                              })
                            return (
                              <TableCell key={idx}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {item.option_name}
                                  <input
                                    type="checkbox"
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                    checked={status === true ? true : false}
                                    onClick={e =>
                                      handleAllmenuCheckboxChange(e, item)
                                    }
                                  />
                                </div>
                              </TableCell>
                            )
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataOptions &&
                        dataOptions.map((item, index) => (
                          <TableRow
                            style={{ verticalAlign: "top" }}
                            key={index}
                          >
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ textAlign: "left" }}
                            >
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {item.module_name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ textAlign: "left" }}
                            >
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.allocation_status === 1
                                          ? true
                                          : false
                                      }
                                      onClick={e =>
                                        handleMainmenuCheckboxChange(e, item)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {item.main_menuname}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  {item.sub_menu &&
                                    item.sub_menu.map((element, index) => {
                                      return (
                                        <li>
                                          <input
                                            type="checkbox"
                                            checked={
                                              element.allocation_status === 1
                                                ? true
                                                : false
                                            }
                                            onClick={e =>
                                              handleCheckboxChange(
                                                e,
                                                element,
                                                item,
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {element.sub_name}
                                          </span>
                                        </li>
                                      )
                                    })}
                                </ul>
                              </div>
                            </TableCell>
                            {options &&
                              options.map((element, index) => {
                                var status = false
                                return (
                                  <TableCell key={index}>
                                    <div>
                                      <ul style={{ listStyleType: "none" }}>
                                        {item.sub_menu.length > 0
                                          ? // {item.sub_menu &&
                                            item.sub_menu.map((el, index) => {
                                              var status = false

                                              el.sub_menu_options &&
                                                el.sub_menu_options.map(
                                                  submenuOpt => {
                                                    if (
                                                      submenuOpt ===
                                                      element?._id
                                                    ) {
                                                      status = true
                                                    }
                                                    return submenuOpt
                                                  },
                                                )

                                              return (
                                                <li>
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      status === true
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={e =>
                                                      handleOptionCheckboxChange(
                                                        e,
                                                        el,
                                                        element,
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    disabled={
                                                      disabled === element?._id
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <span
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                  >
                                                    {""}
                                                  </span>
                                                </li>
                                              )
                                            })
                                          : //}
                                            (item.maimenu_options &&
                                              item.maimenu_options.map(
                                                mainmenuOpt => {
                                                  if (
                                                    mainmenuOpt === element?._id
                                                  ) {
                                                    status = true
                                                  }
                                                  return mainmenuOpt
                                                },
                                              ),
                                            (
                                              <li>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    status === true
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    disabled === element?._id
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={e =>
                                                    handleOptionCheckboxChanges(
                                                      e,
                                                      // el,
                                                      element,
                                                      item,
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                                <span
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  {""}
                                                </span>
                                              </li>
                                            ))}
                                      </ul>
                                    </div>
                                  </TableCell>
                                )
                              })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Rules
