import React, { useEffect, useState } from "react"

import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"

import toastr from "toastr"

import { get, put } from "../../../helpers/api_helper"
import { setTitle, timeConverter } from "../../../helpers/functions"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

const BlockIP = () => {
  setTitle("Blocked IP")

  const [master, setMaster] = useState({})
  const [details, setDetails] = useState([])

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    handleData()
  }, [master]) // eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`security/block-list?ip=${master.ip}`)
      const data = response.data
      data.map((item, index) => {
        item.id = index + 1

        item.date = item.login_on_date
        item.time = timeConverter(item.login_on_time)

        item.ip = item.login_ip

        if (item.attempts >= 5) {
          item.action = (
            <i
              onClick={() => updateStatus(item._id)}
              style={{ fontSize: "20px", color: "red", cursor: "pointer" }}
              className="uil-lock-open-alt"
            ></i>
          )
        } else {
          item.action = (
            <i
              style={{ fontSize: "20px", color: "green", cursor: "pointer" }}
              className="uil-lock-alt"
            ></i>
          )
        }
        return item
      })
      setDetails(data)
    } catch (error) {
      console.error(error)
    }
  }

  const updateStatus = item => {
    put(`security/unblock/${item}`, {})
      .then(res => {
        toastr.success(res.message)
        handleData()
      })
      .catch(err => {})
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "User", field: "username" },
      { label: "IP Address", field: "ip" },
      { label: "Attempts", field: "attempts" },
      { label: "Action", field: "action" },
    ],
    rows: details,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Blocked IP" />

          <Card>
            <CardBody>
              <AvForm className="needs-validation">
                <Row>
                  <Col md="3">
                    <label>IP Address</label>
                    <AvField
                      name="ip"
                      type="text"
                      value={master.ip}
                      onChange={handleChange}
                      placeholder="Search here"
                    />
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <MDBDataTable
                id="blockedIp"
                responsive
                bordered
                data={data}
                disableRetreatAfterSorting={true}
                searching={false}
                entries={100}
                paging={false}
                info={false}
                sortable={false}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlockIP
