import React, { useRef, useState } from "react"
import { Button, Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import toastr from "toastr"

import { put } from "../../helpers/api_helper"
import { STATUS } from "./helper"

const Reject = ({ id, isOpen, onToggle }) => {
  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({ id })

  const handleReject = async () => {
    try {
      if (!masterObject.remarks) {
        formRef.current?.submit()
        return
      }

      const response = await put(`job/applications/status`, {
        ...masterObject,
        status: STATUS.REJECTED,
        id,
      })
      toggle()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleValueChange = async ({ target: { value, name } }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }))
  }

  const toggle = () => {
    setMasterObject({})
    onToggle()
    formRef.current?.reset()
  }

  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-header">
        <h5 className="modal-title">Reject Application</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={toggle}
        />
      </div>
      <div className="modal-body">
        <AvForm ref={formRef}>
          <Label>Remarks</Label>
          <AvField
            name="remarks"
            type="textarea"
            rows={1}
            onChange={handleValueChange}
            value={masterObject.remarks}
            required
            errorMessage="Enter Remarks"
            placeholder="Enter Remarks"
          />
        </AvForm>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleReject}>
          Reject
        </Button>
        <Button color="light" type="button" onClick={toggle}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default Reject
