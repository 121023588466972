import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button, Container,Input } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Tooltip } from "@mui/material"
import Select from "react-select"

import toastr from "toastr"
import Swal from "sweetalert2"

import TablePagination from "../../../components/Common/TablePagination"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import { del, get, post, put } from "../../../helpers/api_helper"
import './style.scss'; 
import {
  dateConverter,
  getDate,
  renderTableValue,
  timeConverter,
  toTop,
} from "../../../helpers/functions"
import CardCandidate from "./CardCandidate"


const AssignCandidate = () => {
  const formRef = useRef()
  const filterRef = useRef()
  const todayDate = getDate()

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({})
  
  
  const [selectedFields, setSelectedFields] = useState({})

  const [selectedFilter, setSelectFilter] = useState({})

  const [interviewOptions, setInterviewOptions] = useState([])


  const [DropDownData, setDropDownData] = useState([])
  const [JobOptions, setJobOptions] = useState([])
  const [CandidateOptions, setCandidatesOptions] = useState([])
  const [selectedInternshipId, setSelectedInternshipId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [searchQuery, setSearchQuery] = useState("")
  const [filterobj, setfilterobj] = useState({
    search: "",
    type: "",
    status:""
  })  




 const STATUS_VALUES = {
    0: { text: "Active", color: "success" },
    2: { text: "InActive", color: "warning" },
  }


  const typeOptions=[
    { label: "Internship", value:1 },
    { label: "Job", value: 2 },
  ]

  const StatusOptions=[
    { label: "Active", value:0 },
    { label: "InActive", value: 2 },
  ]


  useEffect(() => {
    console.log("Filter Object:", filterobj); 
    handleTableData(1)
    setPage(1)
  }, [filterobj]) 

  useEffect(() => {
    handleInterviewOptions()
    handleInternshipOptions()
    handleJobOptions()
    handleCandidateOptions()
  }, []) 

  const handleInterviewOptions = async (id, type) => {
    try {
      // Only fetch interviews if an id is provided and type is specified
      if (!id || !type) {
        setInterviewOptions([]);
        return;
      }
    
      // Modify the API endpoint to support both internship and job
      const queryParam = type === 1 ? 'internship' : 'job';
      const response = await get(`options/assigned-interview?${queryParam}=${id}`);
      
      // Transform the response data to match the Select component's expected format
      const transformedInterviews = response.data.map(interview => ({
        label: interview.label,
        value: interview._id
      }));
  
      setInterviewOptions(transformedInterviews);
    } catch (error) {
      console.error(error);
      toastr.error("Failed to fetch interviews");
      setInterviewOptions([]);
    }
  };

  const handleInternshipOptions = async () => {
    try {
      const response = await get(`options/internship`)
      setDropDownData(response.data)
      if (response.data && response.data.length > 0) {
        setSelectedInternshipId(response.data[0]._id); 
      }
    } catch (error) {
      console.error(error)
    }
  }





  const handleJobOptions = async () => {
    try {
      const response = await get(`options/job`)
      setJobOptions(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCandidateOptions = async (internshipId) => {
    if (!internshipId) return; 
  
    try {
      const response = await get(`options/appliedcandidates?internshipId=${internshipId}`);
      
      if (response.data && response.data.length > 0) {
        setCandidatesOptions(response.data);
      } else {
        setCandidatesOptions([]);
        toastr.danger(`No candidates available for this internship.`);
        setMasterObject(prevState => ({
          ...prevState,
          candidate: [], 
        }));
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setCandidatesOptions([]);
        toastr.danger('No candidates available for this internship.', 'Warning', {
          timeOut: 2000  
        })
        setMasterObject(prevState => ({
          ...prevState,
          candidate: [], 
        }));
      } else {
        console.error("Error fetching candidates:", error);
      }
    }
  };
  
  
  
  

 

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `page=${currentPage}&limit=${limit}&&search=${filterobj.search || ""}&type=${filterobj.type || ""}&status=${filterobj.status || ""}`
      const response = await get(`interview-assign/list?${query}`)
  
      const { count, data } = response
      const updatedData = []
  
      data.forEach((item, index) => {
        item.candidate.forEach((candidate, candidateIndex) => {
          const newItem = { ...item }
          newItem.id = (currentPage - 1) * limit + index + candidateIndex + 1
          newItem.staff = renderTableValue(item.addedBy?.name)
          newItem.type = item.type==1?"Internship":"Job"
          newItem.typeName = item.internship?.[0]?.name || item.job?.[0]?.name 
          newItem.interview = item.interview?.name
          newItem.candidateName = `${candidate.candidateId?.firstName} ${candidate.candidateId?.lastName}`
          newItem.uniqueId = candidate.candidateId?.uniqueId
          newItem.date = renderTableValue(dateConverter(item.date))
          newItem.startDate = renderTableValue(dateConverter(item.startDate))
          newItem.endDate = renderTableValue(dateConverter(item.endDate))
  
          newItem.startTime = renderTableValue(timeConverter(item.startTime))
          newItem.endTime = renderTableValue(timeConverter(item.endTime))
  
          const status = Number(item.status)
          const statusObj = STATUS_VALUES[status]
          newItem.status = (
            <Button
              size="sm"
              color={statusObj.color}
              onClick={() => handleStatusChange(item._id, status === 0 ? 2 : 0)}
            >
              {statusObj.text}
            </Button>
          )
  
          newItem.options = (
            <div>
               <Tooltip title="Edit" arrow placement="top">
                <i
                  className="fas fa-edit edit-icon"
                  onClick={() => handleUpdate(item._id)}
                />
              </Tooltip> 
              <Tooltip title="Delete" arrow>
                <i
                  className="fas fa-trash-alt delete-icon"
                  onClick={() => handleDelete(item._id, candidate?.candidateId?._id)}
                />
              </Tooltip>
            </div>
          )
  
          updatedData.push(newItem)
        })
      })
  
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(updatedData)
    } catch (error) {
      console.error(error)
    }
  }
  

  const handleSubmit = async () => {
    try {
      let response

      if (masterObject.id)
        response = await put(`interview-assign/update`, masterObject)
      else response = await post(`interview-assign/create`, masterObject)

      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleUpdate = async id => {
    try {
      const response = await get(`interview-assign/update/${id}`)
      const data = response.data
      console.log("data", data);
      
      if (data.internship && data.internship.length > 0) {
        handleCandidateOptions(data.internship[0]._id);
        handleInterviewOptions(data.internship[0]._id, 1);
      } else if (data.job && data.job.length > 0) {
        handleInterviewOptions(data.job[0]._id, 2);
      }
      
      const selectedCandidates = data.candidate.map(candidate => ({
        _id: candidate.candidateId,
        name: `${candidate.candidateId?.firstName} ${candidate.candidateId?.lastName}` 
      }));
  
      setMasterObject({
        ...data,
        internship: data.internship && data.internship.length > 0 
          ? { 
              _id: data.internship[0]._id, 
              label: data.internship[0].label 
            } 
          : null,
        candidate: selectedCandidates, 
        id,
      })
      
      setSelectedFields({
        ...data,
      })
      
      // if (data.internship && data.internship.length > 0) {
      //   handleCandidateOptions(data.internship[0]._id);
      // }
      
      toTop()
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleDelete = async (id, candidateId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (!result.isConfirmed) return;
  
      const response = await del(`interview-assign/delete/${id}/${candidateId}`);
      toastr.success(response.message);
      reset();
    } catch (error) {
      toastr.error(error.response.data.message || "Something went wrong");
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`interview-assign/status`, { id, status })

      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      console.error(error)
      toastr.error(error?.response?.data.message)
    }
  }

  const handleSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    if (name === "type") {
      obj.internship = null
      obj.candidates = null
    }
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null

    handleValueChange({ name, value })
  }


  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    setfilterobj({ ...filterobj, [name]: selected.value })
  }

  const handleValueChange = ({ value, name }) => {
    const obj = { ...masterObject }
    obj[name] = value
    if (name === "type") {
      obj.internship = null
      obj.candidates = null
    }
    setMasterObject(obj)
  }

  const handleFilterSelectChange = ({ selected, name }) => {
    const obj = { ...selectedFilter }
    obj[name] = selected

    setSelectFilter(obj)

    handleFilterValueChange({ name, value: selected?.value })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterobj }
    obj[name] = value

    setfilterobj(obj)
  }

  const handlePageChange = value => {
    setPage(value)
    handleTableData(value)
  }

  const reset = () => {
    toTop()
  
    formRef.current?.reset()
    filterRef.current?.reset()
  
    setMasterObject({
      type: "",
      internship: null,
      job: null,
      candidate: [], 
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      interview: null
    })
  
    setSelectedFields({})
    setfilterobj({})
    setSelectFilter({})
    setCandidatesOptions([])
  }
  
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Type", field: "type" },
      { label: "internship/job name", field: "typeName" },
      { label: "Interview", field: "interview" },
      { label: "Candidate  Name", field: "candidateName" },
      { label: "Reg ID", field: "uniqueId" },
      // { label: "Skill Score", field: "" },
      { label: "Start Date", field: "startDate" },
      { label: "Start Time", field: "startTime" },
      { label: "End Date", field: "endDate" },
      { label: "End Time", field: "endTime" },
      { label: "Status", field: "status" },
      // { label: "Score", field: "" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }


  const handleSelectAll = () => {
    if (selectAll) {
      setMasterObject({ ...masterObject, candidate: [] });
    } else {
      const allCandidates = CandidateOptions.map(candidate => ({
        _id: candidate.value,
        name: candidate.label,
      }));
      setMasterObject({ ...masterObject, candidate: allCandidates });
    }
    setSelectAll(!selectAll);
  };

  const handleCandidateSelect = (candidate) => {
    const updatedCandidates = [...masterObject.candidate];
    const candidateIndex = updatedCandidates.findIndex(
      (cand) => cand._id === candidate.value
    );

    if (candidateIndex > -1) {
      updatedCandidates.splice(candidateIndex, 1);
    } else {
      updatedCandidates.push({ _id: candidate.value, name: candidate.label });
    }

    setMasterObject({
      ...masterObject,
      candidate: updatedCandidates,
    });
  };

  const handleSelectAllChange = (selectAllState) => {
    setSelectAll(selectAllState);  
  };


  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearchQuery(value)
    setfilterobj({ ...filterobj, [name]: value })
  }


  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Assign Candidate" />
        <Card>
          <CardBody>
            <AvForm
              className="needs-validation"
              ref={formRef}
              onValidSubmit={handleSubmit}
            >
              <Row>
              <Col md="3">
        <Label>Select Type</Label>
        <Select
          menuPosition="fixed"
          options={typeOptions}
          value={
            masterObject.type
              ? { label: masterObject.type === 1 ? "Internship" : "Job", value: masterObject.type }
              : null
          }
          onChange={selected =>
            handleValueChange({
              name: "type",
              value: selected.value, 
            })
          }
          placeholder="Select Type "
        />
      </Col>

      {masterObject.type === 1 && (
  <Col md="3">
    <Label>Select Internship</Label>
    <Select
      menuPosition="fixed"
      options={[ ...(DropDownData?.map(internship => ({ label: internship.label, value: internship._id })) || [])]}
      value={
        masterObject.internship
          ? { label: masterObject.internship.label, value: masterObject.internship._id }
          : null
      }
      onChange={selected => {
        setMasterObject(prevState => ({
          ...prevState,
          candidate: [],  
          internship: selected.value ? { _id: selected.value, label: selected.label } : null,
        }));
      
        setSelectedInternshipId(selected.value);
      
        handleCandidateOptions(selected.value);
        handleInterviewOptions(selected.value, 1);  // Add type parameter
      }}
      placeholder="Select Internship"
    />
  </Col>
)}

      {masterObject.type === 2 && (
        <Col md="3">
          <Label>Select Job</Label>
          <Select
            menuPosition="fixed"
            options={[
              ...(JobOptions?.map(job => ({
                label: job.label,
                value: job._id,
              })) || []),
            ]}
            value={
              masterObject.job
                ? {
                    label: masterObject.job.label,
                    value: masterObject.job._id,
                  }
                : null
            }
            onChange={selected => {
              handleValueChange({
                name: "job",
                value: selected.value
                  ? { _id: selected.value, label: selected.label }
                  : null,
              });
            
              // Add this line to fetch job-specific interviews
              handleInterviewOptions(selected.value, 2);
            }}
            placeholder="-- Select Job --"
          />
        </Col>
      )}


<Col md={3}>
  <Label>Interview</Label>
  <Select
    options={interviewOptions}
    value={selectedFields.interview || null}
    onChange={selected =>
      handleSelectChange({ selected, name: "interview" })
    }
    placeholder="Select Interview"
    isDisabled={!masterObject.internship && masterObject.type === 1}
  />
</Col>

                <Col md={2}>
                  <Label>Start Date</Label>
                  <AvField
                    name="startDate"
                    type="date"
                    value={masterObject.startDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={!masterObject.id && todayDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Start Time</Label>
                  <AvField
                    name="startTime"
                    type="time"
                    validate={{ required: { value: true } }}
                    value={masterObject.startTime || ""}
                    errorMessage="Enter Start time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>End Date</Label>
                  <AvField
                    name="endDate"
                    type="date"
                    value={masterObject.endDate || ""}
                    errorMessage="Enter Start date"
                    validate={{ required: { value: true } }}
                    min={masterObject.startDate}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <Label>End Time</Label>
                  <AvField
                    name="endTime"
                    type="time"
                    value={masterObject.endTime || ""}
                    validate={{ required: { value: true } }}
                    errorMessage="Enter End time"
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={3}>
                  <div className="d-flex gap-3 form-button">
                    <Button color="primary">
                     Assign
                    </Button>
                    <Button type="reset" color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

      
{CandidateOptions && CandidateOptions.length > 0 && (
  <Row>
    <Col md={2}>
      <Label>Select All</Label>
      <Input
      className="ms-1"
            type="checkbox"
            onChange={handleSelectAll}
          />
    </Col>
  </Row>
)}


<Row className="candidate-cards mt-3">
        {CandidateOptions.map((candidate) => (
          <CardCandidate
            key={candidate.value}
            candidate={candidate}
            isSelected={masterObject.candidate.some(
              (cand) => cand._id === candidate.value
            )}
            onSelect={handleCandidateSelect}
            selectAll={selectAll}
            onSelectAll={handleSelectAllChange}  
          />
        ))}
      </Row>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>

              <Col md={3}>
                  <Label>Search</Label>
                  <AvField
                    name="search"
                    placeholder="Search Candidate name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>

                <Col md={3}>
                  <Label>Filter by Type</Label>
                  <Select
                    options={typeOptions}
                    value={selectedFields.type || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "type")
                    }
                  />
                </Col>

                <Col md={3}>
                  <Label>Filter by Status</Label>
                  <Select
                    options={StatusOptions}
                    value={selectedFields.status || null}
                    onChange={selected =>
                      handleSelectValueChange(selected, "status")
                    }
                  />
                </Col>

                 <Col md={3} className="mt-4" >
                 
                 <Button style={{marginTop:'3px'}} color="danger" type="button" onClick={reset}>
                              Reset
                            </Button>

                 </Col>

              </Row>
            </AvForm>
            <TablePagination  
            className="assign-table"
              page={page}
              tableId="assignTest"
              data={data}
              count={totalPage}
              onChange={handlePageChange}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AssignCandidate
