import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    BreadcrumbItem,
    CardHeader,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BookmarkIcon from "@mui/icons-material/Bookmark"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
import { Button } from "reactstrap"
// Tabs
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Stack from "@mui/material/Stack"
import Summary from "./Summary"
import Registrations from "./Registrations"
import { get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
import moment from "moment"
import { genderOptions } from "../Job/Post/helper"
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import JobpostApplications from "./JobpostApplications"
import IntrestedCandidate from "./IntrestedCandidate"
import JobpostSummary from "./JobpostSummary"

const JobPostDetail = props => {
    const { state } = useLocation()
    const { jobId } = useParams()
    const [jobObjId, setjobObjId] = useState(null)

    const [details, setDetails] = useState({})
    const [tab3, setTab3] = React.useState("1")
    const [fairDetails, setfairDetails] = useState([])
    const toggle = (start = true) => {
        var body = document.body

        if (start) {
            body.classList.remove("vertical-collpsed")
            body.classList.remove("sidebar-enable")
            body.setAttribute("data-sidebar-size", "sm")
        } else {
            body.classList.add("vertical-collpsed")
            body.classList.add("sidebar-enable")
            body.setAttribute("data-sidebar-size", "lg")
        }
    }
    useEffect(() => {
        toggle()

        return () => {
            toggle(false)
        }
        // eslint-disable-next-line
    }, [])


    const handleChangeTab = (event, newValue) => {
        setTab3(newValue)
    }

    useEffect(() => {
        getAllJobFairDetails()
    }, [jobId])

    function getAllJobFairDetails() {
        get(`/job-fair/job/view?id=${jobId}`).then((res) => {
            setfairDetails(res.data)
            setjobObjId(res.data._id)
        }).catch((err) => {

        })
    }




    const jobpostStatus = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 400,
            },
            {
                label: "Staff",
                field: "user_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Prev Status",
                field: "prevStatus",
                sort: "asc",
                width: 200,
            },
            {
                label: "Current Status",
                field: "currentStatus",
                sort: "asc",
                width: 200,
            },
        ],
        rows: [
            {
                id: "1",
                date: "13-10-2023",
                time: "11:44 am",
                user_name: "Admin",
                prevStatus: <h6 style={{ color: "green" }}>{"Upcoming"}</h6>,
                currentStatus: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
            },
        ],
    }

    const generateTooltip = name => {
        let displayText = name
        if (name && name.length > 20) {
            displayText = `${name.substring(0, 20)}...`
        }

        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                    >
                        {name}
                    </Tooltip>
                }
            >
                <span>{displayText}</span>
            </OverlayTrigger>
        )
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">
                                    Job Fair Jobpost
                                </h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">Home</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="/">Job fair</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">{generateTooltip(fairDetails?.name)}</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            {/* First Dialogue */}
                            <div>
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div
                                            className="card"
                                            style={{
                                                // width: "fit-content",
                                                // width: "351px",
                                                height: "max-content",
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                            }}
                                        >
                                            <div className="card-body">
                                                <div className="float-end dropdown"></div>
                                                <h5
                                                    className="mt-2 mb-2"
                                                    style={{
                                                        textAlign: "center",
                                                        fontFamily: "IBM Plex Sans,sans-serif",
                                                    }}
                                                >
                                                    {fairDetails.name}
                                                </h5>
                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <p className="mb-1">Jobfair:</p>
                                                    <h5 className="font-size-14">{fairDetails?.jobFair?.name}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <p className="mb-1">Company:</p>
                                                    <h5 className="font-size-14">{fairDetails?.company?.name}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <p className="mb-1">Branch:</p>
                                                    <h5 className="font-size-14">{fairDetails?.branch?.name}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Category:</p>
                                                    <h5 className="font-size-14">{fairDetails?.category?.name}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Min.Experience:</p>
                                                    <h5 className="font-size-14">{fairDetails?.minExperience}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Max.Experience:</p>
                                                    <h5 className="font-size-14">{fairDetails?.maxExperience}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Salary From:</p>
                                                    <h5 className="font-size-14">{fairDetails?.salaryFrom}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Salary To:</p>
                                                    <h5 className="font-size-14">{fairDetails?.salaryTo}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Vacancy</p>
                                                    <h5 className="font-size-14">{fairDetails?.vacancies}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Work Mode</p>
                                                    <h5 className="font-size-14">{fairDetails?.workMode == "1" ? "Remote" : fairDetails?.workMode == "2" ? "Hybrid" : fairDetails?.workMode == "3" ? "In Person" : "---"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Work Type:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.workType == "1" ? "Part-Time" : fairDetails?.workType == "2" ? "Full-Time" : fairDetails?.workType == "3" ? "Contract" : "---"}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Schedule:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.schedule === 1 ? "Day-Shift" : fairDetails?.schedule === 2 ? "Night-Shift" : fairDetails?.schedule === 3 ? "Flexible" : "---"}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p className="mb-1">Genders:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.gender
                                                            ?.map((g) => genderOptions.find((option) => option.value === g)?.label)
                                                            .join(", ") || "Unknown"}</h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2">
                                                    <p className="mb-1">Skills:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.skills?.map((item) => item.name).join(", ")}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2">
                                                    <p className="mb-1">Specializations:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.specializations?.map((item) => item.name).join(", ")}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2">
                                                    <p className="mb-1">Courses:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.courses?.map((item) => item.name).join(", ")}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />
                                                <div
                                                    className="mt-2">
                                                    <p className="mb-1">Sates:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.state?.map((item) => item.name).join(", ")}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                                <div
                                                    className="mt-2">
                                                    <p className="mb-1">District:</p>
                                                    <h5 className="font-size-14">
                                                        {fairDetails?.district?.map((item) => item.name).join(", ")}
                                                    </h5>
                                                </div>
                                                <hr style={{ margin: "0", color: "#00afc3" }} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                                        <div
                                            className="mb-0 card"
                                            style={{
                                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                                width: "100%",
                                            }}
                                        >
                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={tab3}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            variant="fullWidth"
                                                            onChange={handleChangeTab}
                                                            aria-label="lab API tabs example"
                                                        >
                                                            <Tab
                                                                icon={
                                                                    <SummarizeIcon style={{ fontSize: "20px" }} />
                                                                }
                                                                label="Summary"
                                                                value="1"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />
                                                            <Tab
                                                                icon={
                                                                    <AssignmentIcon
                                                                        style={{ fontSize: "20px" }}
                                                                    />
                                                                }
                                                                label="Applications"
                                                                value="2"
                                                                size="small"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />

                                                            <Tab
                                                                icon={
                                                                    <ThumbUpIcon
                                                                        style={{ fontSize: "20px" }}
                                                                    />
                                                                }
                                                                label="Intresetd Candidates"
                                                                value="3"
                                                                size="small"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    fontFamily: "IBM Plex Sans,sans-serif",
                                                                }}
                                                            />

                                                        </TabList>
                                                    </Box>
                                                    <TabPanel
                                                        value="1"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <Row>
                                                            <JobpostSummary jobObjId={jobObjId} />
                                                        </Row>
                                                        <Row>
                                                            <Col md="3">
                                                                {details && details.pdf ? (
                                                                    <Button
                                                                        className="view-syllabus syllabus"
                                                                    >
                                                                        Download File <i class="fa fa-download"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </TabPanel>
                                                    <TabPanel
                                                        value="2"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <JobpostApplications jobObjId={jobObjId} />
                                                    </TabPanel>

                                                    <TabPanel
                                                        value="3"
                                                        style={{
                                                            fontSize: "13px",
                                                            fontFamily: "IBM Plex Sans,sans-serif",
                                                        }}
                                                    >
                                                        <IntrestedCandidate jobObjId={jobObjId} />
                                                    </TabPanel>



                                                </TabContext>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JobPostDetail
