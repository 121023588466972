import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import toastr from "toastr";
import { API_URL } from "../../../../config";

function Registrations(props) {
  const [registration, setRegistration] = useState([]);
  const [modal, setModal] = useState({});
  const [popupView, setPopupView] = useState(false);

  useEffect(() => {
    handleTableData();
  }, []);

  const generatResume = async requestId => {
    // Simulate resume generation
    toastr.success("Resume generated successfully.");
  };

  function handleTableData() {
    // Dummy data
    const dummyData = [
     
    ];

    let data = dummyData.map((item, index) => {
      item.id = index + 1;
      item.date1 = moment(item?.placed?.date).format("DD-MM-YYYY");
      item.added = item.placed.staff;
      item.registration = item?.applicant?.uniqueId;
      item.name = item?.applicant?.name;
      item.mobile = item?.applicant?.mobile;
      item.options = (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <i
            className="fas fa-eye"
            style={{ fontSize: "1em", cursor: "pointer", marginRight: "0.5rem" }}
            onClick={() => {
              setPopupView(true);
              setModal(item);
            }}
          ></i>

          {item?.applicant?.uploadedResume && (
            <a href={API_URL + item?.applicant?.uploadedResume} target="_blank" rel="noreferrer">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="copy-event-tooltip3">Uploaded Resume</Tooltip>}
              >
                <i
                  className="fas fa-file-pdf"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    color: "red",
                  }}
                ></i>
              </OverlayTrigger>
            </a>
          )}

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="copy-event-tooltip">Generated Resume</Tooltip>}
          >
            <i
              className="fas fa-file-pdf"
              onClick={() => generatResume(item?.applicant?._id)}
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginRight: "0.5rem",
                color: "#0066b2",
              }}
            ></i>
          </OverlayTrigger>
        </div>
      );
      return item;
    });

    setRegistration(data);
  }

  const placed = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 100 },
      { label: "Date", field: "date1", sort: "asc", width: 200 },
      { label: "Reg No", field: "registration", sort: "asc", width: 200 },
      { label: "Name", field: "name", sort: "asc", width: 200 },
      { label: "Mobile", field: "mobile", sort: "asc", width: 200 },
      { label: "Staff", field: "added", sort: "asc", width: 200 },
      { label: "Action", field: "options", sort: "asc", width: 200 },
    ],
    rows: registration,
  };

  return (
    <div>
      <Modal
        show={popupView}
        onHide={() => setPopupView(false)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applicant Details - {modal?.applicant?.name}</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">

<p><strong>Name</strong></p>
<p><strong>Reg No</strong></p>
<p><strong>Date</strong></p>
<p><strong>Mobile</strong></p>


</div>
      </Modal>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="registeredpeopleid"
            responsive
            bordered
            data={placed}
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Registrations;
