import React, { useEffect, useState } from "react"

import { Card, CardBody } from "reactstrap"

import {
  dateConverter,
  numberToCurrency,
  setTitle,
} from "../../../../helpers/functions"
import { get } from "../../../../helpers/api_helper"

import TablePagination from "../../../../components/Common/TablePagination"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import "./styles.scss"

const ExpenseReport = () => {
  setTitle("Expense")

  const [tableData, setTableData] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 100

  useEffect(() => {
    handleTableData()
  }, [page]) // eslint-disable-line

  const handleTableData = async () => {
    try {
      const params = `page=${page}&limit=${limit}&type=${2}`
      const response = await get(`finance-report/list?${params}`)

      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit

        item.date = dateConverter(item.date)
        item.total = numberToCurrency(item.total)
        item.amount = numberToCurrency(item.amount)

        item.subHead = item.subHead.name
        item.fundSource = item.fundSource.name
        item.chartOfAccount = item.chartOfAccount.name
        item.staff = item.addedBy?.name
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Sub Head", field: "subHead" },
      { label: "Chart of Account", field: "chartOfAccount" },
      { label: "Fund Source", field: "fundSource" },
      { label: "Amount", field: "amount" },
      { label: "Total", field: "total" },
      { label: "Remarks", field: "remarks" },
      { label: "Created By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Expense" />
          <Card>
            <CardBody>
              <TablePagination
                page={page}
                data={data}
                count={totalPage}
                onChange={handlePageChange}
                tableId="expenseReport"
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default ExpenseReport
