export const typeValues = {
  1: "Private",
  2: "Public",
  3: "Government",
  4: "Deemed",
}
export const typeOptions = [
  { label: "Private", value: 1 },
  { label: "Public", value: 2 },
  { label: "Government", value: 3 },
  { label: "Deemed", value: 4 },
]
